


// import { getId } from "../../../universal_methods/getId.js";
// import { setId } from "../../../universal_methods/setId.js";
// import { generateId } from "../../../universal_methods/generateId.js";
// import { isEmpty } from "../../../universal_methods/isEmpty.js";
// import { getAttributes } from "../../../universal_methods/getAttributes.js";
// import { toggleDisplay } from "../../../universal_methods/toggleDisplay.js";

// import { setTxt } from "../../../universal_methods/setTxt.js";
// import { removeSplashScreen } from "../../../universal_methods/removeSplashScreen.js";







////import "./styles/_main.scss";
// import { SMETIO_LINK_LOGIN_PAGE } from "../../../../constants/links.js";
import { SmetioAppUserInterface } from "../../smetioAppUserInterface/v1.0.0/SmetioAppUserInterface.js";
import { SmetioAppUiSideMenuItemContent } from "../../../smetioAppUiSideMenuItemContent/v1.0.0/SmetioAppUiSideMenuItemContent.js";
import { SmetioSetUpAndInitialization } from "../../../smetioSetUpAndInitialization/v1.0.0/SmetioSetUpAndInitialization.js";

// import { SmetioAjax } from "../../../smetioAjax/SmetioAjax.js";
// import { LabelTopFormInputDown } from './../../../labelAndFormInputCombination/labelTopFormInputDown/labelTopFormInputDown';

// import { SmetioComingSoon } from "../../../smetioComingSoon/v1.0.0/SmetioComingSoon.js";


export class SmetioAppsBasics extends SmetioSetUpAndInitialization {

    constructor(data = {
        appName: "",
        brandText: "",
        themeName: "",
        logo: "",
        sideMenuItems: "",
        brandName: "",
        language: "",
        apiEndPoint: "",
        accountType: "",
        useBrandNameNotLogo: false,
        defaultPath: ""
    }) {


        super(data);

        localStorage.setItem("lastUsedAppLink", window.parent.location.href);

        // this.getId = getId;
        // this.setId = setId;
        // this.generateId = generateId;
        // this.isEmpty = isEmpty;
        // this.getAttributes = getAttributes;
        // this.toggleDisplay = toggleDisplay;
        // this.setTxt = setTxt;

        // this.init = init;

        // // this.removeSplashScreen = removeSplashScreen;

        // // this.smetioAppUi = new SmetioAppUserInterface();

        // this.smetioAppUi = "";

        // this.setId();
        // this.waitingForVisitorToRegister = "";
        // // this.appName = "";

        // this.sideMenuItems = "";


        // this.appName = data.appName || "smetio_profile";
        // this.brandText = data.brandText || "";
        // this.themeName = data.themeName || "true-brand-light-100";
        // this.logo = data.logo || "img/3ack_logo.svg";
        // this.sideMenuItems = data.sideMenuItems || false;
        // this.brandName = data.brandName || "3ack";
        // this.useBrandNameNotLogo = data.useBrandNameNotLogo || false;
        // this.language = data.language || "en";
        // window.smetioEnv = data.env || "development";
        // this.comingSoon = "Coming soon"; // TEMPORARY
        // this.defaultPath = data.defaultPath || "http://localhost:4000/";
        // this.apiEndPoint = data.apiEndPoint || "http://localhost:4000/api";
        // this.accountType = data.accountType || "businessOwner";

        // window.defaultPath = this.defaultPath;
        // window.apiEndPoint = this.apiEndPoint;
        // window.userLanguage = this.language;
        // window.themeName = this.themeName;
        // window.appName = this.appName;

        // // this.smetioAjax = new SmetioAjax();



        // // console.log("The language is ", this.language, "from data ", data.language);

        // // window.smetioVisitor = new SmetioVisitor({
        // //   language:this.language
        // // });
        // // window.smetioVisitor.render();

        // // this.createUserInterface();

        // this.setup();
    };

    // async setup(){
    //   window.smetioVisitor = new SmetioVisitor({language:this.language});
    //   await window.smetioVisitor.render();
    //   this.createUserInterface();

    // };

    createUserInterface() {
        this.data["sideMenuToUse"] = this.sideMenuToUse;

        console.log("this.data.sideMenuToUse is @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@", this.data["sideMenuToUse"]);
        this.smetioAppUi = new SmetioAppUserInterface(this.data);
    };

    async setComingSoon() {
        const { SmetioComingSoon } = await import(/* webpackPrefetch: true */ "../../../smetioComingSoon/v1.0.0/SmetioComingSoon.js");
        const smetioComingSoon = new SmetioComingSoon();
        this.comingSoon = smetioComingSoon.render();
    };

    getComingSoon() {

        if (this.comingSoon == "Coming soon") this.setComingSoon();

        return this.comingSoon;
    };

    // async getSideMenuItems() {

    //   throw "Smetio said you have to impliment a getSideMenuItems that is specific to the app";
    //   /*
    //   return [

    //     {
    //       id: "personalDetails",
    //       text: "txt203",
    //       icon: "profile",
    //       showViewBtns: true,
    //       contentHolder: "",
    //       onClick: () => {
    //         console.log("you clicked personal ");
    //         this.smetioAppUi.actionBar.hideAll();
    //         this.smetioAppUi.actionBar.show("edit");
    //         this.smetioAppUi.actionBar.buttons.edit.events["mousedown"] = () => {
    //           console.log("Personal details edited");
    //         };
    //       },
    //       onRendered: () => {

    //       }
    //     },

    //     {
    //       id: "userAddresses",
    //       text: "txt204",
    //       icon: "home7",
    //       showViewBtns: true,
    //       contentHolder: "",
    //       onClick: () => {

    //         console.log("you clicked addresses ");
    //         this.smetioAppUi.actionBar.hideAll();
    //         this.smetioAppUi.actionBar.show("createNew");
    //         this.smetioAppUi.actionBar.buttons.createNew.events["mousedown"] = () => {
    //           console.log("new Address Created");
    //         };


    //       },
    //       onRendered: () => {

    //       }
    //     },
    //     {
    //       id: "userEmails",
    //       text: "txt205",
    //       icon: "mail1",
    //       showViewBtns: false,
    //       contentHolder: "",
    //       onClick: () => {
    //         console.log("you clicked email ");
    //         this.smetioAppUi.actionBar.hideAll();

    //         this.smetioAppUi.actionBar.show("createNew");
    //         this.smetioAppUi.actionBar.buttons.createNew.events["mousedown"] = () => {
    //           console.log("you clicked new after clicking email");
    //           return this.createNewEmail();
    //         };
    //       },
    //       onRendered: () => {
    //         console.log("i am being rendered now %%%%%%")
    //       }
    //     }

    //   ];
    //   */

    // };

    getSampleDataPresentation() {

        const submissionEndpoint = "http://localhost:4000/";
        return {
            presentationClass: "",

            presentationData: {
                id: "sampleDataPresentation",
                coverImageIsDynamic: true,
                viewStyle: "cardView",
                coverImage: "http://localhost:3000/img/appImages/SVG/bankDetails.txt",
                logo: "",
                showLogo: false,
                title: "txt496",
                subTitle: "",
                paragraph: "",
                onclickException: "",
                uniqueValues: {
                    inputsModel: {
                        modelPath: "loggedInUser/profileDetailsModels/bankAccountDetailsModel",
                        map: {
                            subTitle: {
                                seperator: "none",
                                profileId: true,
                            },
                            paragraph: {
                                seperator: "none",
                                accountType: true
                            }
                        },
                        endpoints: {
                            submission: {
                                method: "POST",
                                url: submissionEndpoint
                            },
                            retrieval: {
                                method: "GET",
                                url: `${submissionEndpoint}/models`
                            }
                        },

                    }
                },
                menuItems: {
                    addAccount: {
                        text: "txt489",
                        icon: "plus-square-o",
                        isVisible: true,
                        onClick: {
                            functionPath: "stripe/getCreditCardDetails",
                            functionParameters: {
                                testParameter: "testParameter",
                                // cardId
                            },
                        }
                    },

                    deleteAccount: {
                        text: "txt490",
                        icon: "trash-2",
                        isVisible: true,
                        onClick: {
                            functionPath: "stripe/deleteCreditCard",
                            functionParameters: {
                                testParameter: "testParameter",
                                // cardId
                            },
                        }
                    },
                }
            }
        };
    };



    async getTotalNumberOfAvailableData(table) {

        const axios = (await import(/* webpackPrefetch: true */ "axios")).default;
        const SmetioDataGroup = (await import(/* webpackPrefetch: true */ "../../../smetioDataGroup/v1.0.0/SmetioDataGroup.js")).SmetioDataGroup;

        const filters = this.filters[table];

        // this.smetioAppUi.sideMenu.dataGroups["businessLocations"] = new SmetioDataGroup({});

        this.smetioAppUi.sideMenu.dataGroups[table] = new SmetioDataGroup({});
        this.smetioAppUi.sideMenu.dataGroups[table].dataRetrieverEndpoint = this.dataRetrieverEndpoint[table];
        this.smetioAppUi.sideMenu.dataGroups[table].dataRetrieverEndpointFilters = filters;
        this.smetioAppUi.sideMenu.dataGroups[table].itemsPerSection = this.itemsPerSection[table];
        const dataGroups = await this.smetioAppUi.sideMenu.dataGroups[table].render();

        this.sideMenuItems[table]["contentHolder"].update(dataGroups);

        this.smetioAppUi.sideMenu.dataGroups[table].preloader.show();


        // const url = `${apiEndPoint}/dataCount/${this.language}`;
        const url = `${window.apiEndPoint}/apps/${this.appUserInterface}/${table}/count/${language}`;

        const res = await axios.get(url, { params: { filters } }).then(async (response) => {

            let totalNumberOfAvailableData = response.data;

            // console.log("totalNumberOfAvailableData ", totalNumberOfAvailableData);

            if (totalNumberOfAvailableData.error) return window.smetioVisitor.showNotification({ type: "danger", body: [{ message: totalNumberOfAvailableData.msg }] });

            if (typeof totalNumberOfAvailableData.msg !== "number") return window.smetioVisitor.showNotification({ type: "friendly", body: [{ message: totalNumberOfAvailableData.msg }] });

            totalNumberOfAvailableData = parseInt(totalNumberOfAvailableData.msg);


            // console.log(" totalNumberOfAvailableData is ", totalNumberOfAvailableData);

            this.smetioAppUi.sideMenu.dataGroups[table].totalNumbersOfData = totalNumberOfAvailableData;

            for (let i = 0; i < totalNumberOfAvailableData; i++) {

                const dataPresentation = this.getSampleDataPresentation();
                dataPresentation.presentationData.id = `${dataPresentation.presentationData.id}-${i}`;

                // console.log("===== dataPresentation.id is ", dataPresentation.presentationData.id);

                this.smetioAppUi.sideMenu.dataGroups[table].addNewDataPresentation(dataPresentation);

                // if(i == (totalNumberOfAvailableData - 1)) this.smetioAppUi.sideMenu.dataGroups[table].doneWithAddingSampleDataPresentation();

            };

            this.smetioAppUi.sideMenu.dataGroups[table].doneWithAddingSampleDataPresentation();

        }).catch((error) => {

            console.log(error);
            // this.smetioAppUi.sideMenu.dataGroups[table].preloader.hide();
            return window.smetioVisitor.showNotification({ type: "danger", body: [{ message: "txt423" }] });

        }).finally(() => {
            this.smetioAppUi.sideMenu.dataGroups[table].preloader.hide();
        });

    };

    async addContentHolderTosideMenuItems() {

        this.sideMenuItems = await this.getSideMenuItems();

        for (const sideMenu in this.sideMenuItems) {

            const item = this.sideMenuItems[sideMenu];

            item["contentHolder"] = new SmetioAppUiSideMenuItemContent({
                id: item["id"],
                appId: this.appName,
                viewStyle: "",
                content: ""
            });
            // item["contentHolder"].id = item["id"];
            // item["contentHolder"].appId = this.appName;

            // console.log("This is the item ", item);

            let storedOnClick = item["onClick"];
            let storedOnClickParameters = {};

            if (storedOnClick && typeof storedOnClick !== "function") {

                const { functionPath, functionParameters } = item["onClick"];
                const functionName = functionPath.split("/").pop();
                const func = await import(/* webpackPrefetch: true */ `./../../../../functions/${functionPath}.js`);

                storedOnClick = func[functionName];
                storedOnClickParameters = functionParameters;
                // if (func[functionName]) func[functionName](functionParameters || {});

            };


            const newOnClick = () => {

                if (storedOnClick) storedOnClick(storedOnClickParameters);

                item["contentHolder"].show();

                /** USED TO IMPLEMENT DIFFERENT SEARCH WORD FOR EACH SIDE MENU ITEM
* SO THE SEARCH TERM CHANGES AS EACH SIDE MENU ITEM IS CL
* 
*/

                const activeMenu = this.smetioAppUi.sideMenu.activeMenu;
                const searchWord = this.smetioAppUi.sideMenu.searchTerms[activeMenu];
                // this.sideMenu.dataGroups[activeMenu].searchForData(searchWord);
                // console.log("activeMenu is ", activeMenu, " searchWord is ", searchWord);
                if (!this.isEmpty(searchWord)) this.smetioAppUi.actionBar.changeSearchWord(searchWord);

                /** MAKE THE DATA GROUP OF ALL OTHER SIDE MENU INACTIVE */
                for (const key in this.smetioAppUi.sideMenu.dataGroups) {
                    if (this.smetioAppUi.sideMenu.dataGroups[key]) this.smetioAppUi.sideMenu.dataGroups[key].deactivate();
                };

                /**** 
* THE FUNCTION OF THE VIEW BUTTONS ARE CONTROLLED HERE 
*    NOT IN THE this.smetioAppUi.actionBar
* 
* 
* SHOW THE CURRENT VIEW OF THE CLICKED SIDE MENU ITEM 
* 
* SOME SIDE MENU ITEM DO NOT NEED THE ACTION BAR BUTTONS AND THIS CAN BE INDICATED 
* BY SETTING showViewBtns TO FALSE 
*    SO THAT THE VIEW BUTTONS ARE NOT SHOWN
* 
* ****/

                if (item["showViewBtns"] === false) return false;

                const currentViewStyle = item["contentHolder"]["viewStyle"];

                let oppositeView = "cardView";

                if (currentViewStyle !== "listView") oppositeView = "listView";

                // console.log("currentViewStyle is ", currentViewStyle);

                item["contentHolder"]["changeView"](currentViewStyle);

                this.smetioAppUi.actionBar.show(oppositeView);
                this.smetioAppUi.actionBar.hide(currentViewStyle);

                /**** WHEN THE VIEW IS CLICKED CHANGE THE VIEW ON THE SIDE ITEM CONTENT ****/

                this.smetioAppUi.actionBar.buttons.listView.events["mousedown"] = () => {
                    item["contentHolder"]["changeView"]("listView");
                    this.smetioAppUi.actionBar.hide("listView");
                    this.smetioAppUi.actionBar.show("cardView");
                };

                this.smetioAppUi.actionBar.buttons.cardView.events["mousedown"] = () => {
                    item["contentHolder"]["changeView"]("cardView");
                    this.smetioAppUi.actionBar.hide("cardView");
                    this.smetioAppUi.actionBar.show("listView");
                };


            };

            item["onClick"] = newOnClick;

        };


    };

    async getSideMenuItems() {

        if (this.sideMenuItems) return this.sideMenuItems;

        // console.log("Requesting side menu items ********** ");

            
        const {getParameterFromUrl} = (await import(/* webpackPrefetch: true */ "../../../../functions/get/getParameterFromUrl.js"));
        // const {convertStringToBoolean } = (await import(/* webpackPrefetch: true */ "../../../functions/convert/convertStringToBoolean.js"));  
            

        // const employerPublicProfileId = getParameterFromUrl({paramName:"epp"}); // FOR EMPLOYEE INTERFACE
        // const businessOwnerPublicProfileId = getParameterFromUrl({paramName:"bpp"}); // FOR CONSUMER INTERFACE
        const businessNameAndCountry = getParameterFromUrl({
            paramName: "smetio_bnac",
            preferedUrlString: window.location.href
        }); // FOR EMPLOYEE AND CONSUMER INTERFACE
            // const emp = getParameterFromUrl({paramName:"emp"});

        console.log("businessNameAndCountry is ", businessNameAndCountry);

        // alert(" businessNameAndCountry emp is ",businessNameAndCountry, "and simple emp is ", emp);

        const axios = (await import(/* webpackPrefetch: true */ "axios")).default;

        const apiEndPoint = window.apiEndPoint;
        const language = window.userLanguage;

        // console.log("window.location.href is ", window.location.href, " and window.parent.location.href is ", window.parent.location.href);

        // const url = `${apiEndPoint}/sideMenuItems/${language}`;
        const url = `${apiEndPoint}/apps/${this.appUserInterface}/sideMenuItems/retrieve/${language}`;

        const res = await axios.get(url, { params: { app: this.appUserInterface, businessNameAndCountry } }).then(async (response) => {

            let result = response.data;

            // console.log("result is ", result);

            if (result.error) return window.smetioVisitor.showNotification({ type: "danger", body: [{ message: result.msg }] });

            this.sideMenuItems = result.msg;

            if (this.sideMenuItems.redirectPage) return window.parent.location.href = `${this.baseUrl}/${this.sideMenuItems.directory}`;

            // console.log("The base url is ", this.baseUrl);

            window.currentSideMenuItems = this.sideMenuItems;
            // window.currentlyVisibleSideMenuItemName = "businessLocations";
            window.currentSmetioAppUi = this.smetioAppUi;

            return this.sideMenuItems;

        }).catch((error) => {

            console.log(error);
            return window.smetioVisitor.showNotification({ type: "danger", body: [{ message: "txt423" }] });

        }).finally(() => {
            // preloader.hide();
        });

        return res;

    };

    async checkIfAccountSetupIsNeeded(){

        const {checkIfAccountSetupIsNeededByBusinessInterface} = (await import(/* webpackPrefetch: true */ "../../../../functions/apps/accountSetup/checkIfAccountSetupIsNeededByBusinessInterface.js"));

        if(this.appUserInterface === "business" ) return await checkIfAccountSetupIsNeededByBusinessInterface();

        // if(this.appUserInterface == "business" ) {

                
        //       // const setupNeeded = await checkIfAccountSetupIsNeededByBusinessInterface();
        //       // if(setupNeeded !== true) return false;

                  
        //       // const { getParameterFromUrl } = (await import(/* webpackPrefetch: true */ "../../../../functions/get/getParameterFromUrl.js"));
        //       // const { convertStringToBoolean } = (await import(/* webpackPrefetch: true */ "../../../../functions/convert/convertStringToBoolean.js"));
        //       // const {changePageUrlFromIframe} = (await import(/* webpackPrefetch: true */ "../../../../functions/change/changePageUrlFromIframe.js"));

        //       // const redirectPage = "";
        //       // let isIframed = getParameterFromUrl({ paramName: "is_iframed" });
        //       // isIframed = convertStringToBoolean(isIframed);
      
        //       // if (isIframed === true) return changePageUrlFromIframe({ link: redirectPage });
      
        //       // location.href = redirectPage;

        //       // alert("YES SETUP IS NEEDED ");

        //       // return setupNeeded;
        // }

        return false;

    };

    async setupUserAccount(){
        //   alert("USER ACCOUNT IS NOW SET UP");
    };

    async render() {

        // await this.checkIfAccountSetupIsNeeded();

        await this.addContentHolderTosideMenuItems();

        this.smetioAppUi.appInUse = this.appName;
        this.smetioAppUi.sideMenuItems = this.sideMenuItems;
        this.smetioAppUi.brandText = this.brandText;
        this.smetioAppUi.logo = this.logo;
        this.smetioAppUi.brandName = this.brandName;
        this.smetioAppUi.useBrandNameNotLogo = this.useBrandNameNotLogo;
        this.smetioAppUi.render();

        $("html").attr("data-theme", this.themeName);
        $("body").attr("data-smetio-page-type", "smetio_app");

        // this.removeSplashScreen();

        this.events();

    };

    events() {
        //throw "Smetio said you have to impliment an events function that is specific to the app";

    };


    // init() {

    //   this.waitingForVisitorToRegister = setInterval(() => {

    //     if (!window.smetioVisitor) return true;

    //     if (!window.smetioVisitor.isRegistered) return true;

    //     // if (!window.smetioVisitor.isLoggedIn) return location.href = SMETIO_LINK_LOGIN_PAGE;

    //     clearInterval(this.waitingForVisitorToRegister);

    //     return this.render();

    //   }, 500);
    // };


};



