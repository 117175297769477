export async function getVisitorsToken(){

	if(!this.hasLocalStorage) return "";

	let vToken = await localStorage.getItem("vToken");

	if( this.isEmpty(vToken) || vToken == "undefined" ){

		vToken = "";

	}; 

	return vToken;

};