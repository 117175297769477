
import { getId } from "../../universal_methods/getId.js";
import { setId } from "../../universal_methods/setId.js";
import { generateId } from "../../universal_methods/generateId.js";
import { isEmpty } from "../../universal_methods/isEmpty.js";
import { getAttributes } from "../../universal_methods/getAttributes.js";
import { toggleDisplay } from "../../universal_methods/toggleDisplay.js";

import { setTxt } from "../../universal_methods/setTxt.js";




//import "./styles/_main.scss";
import { SmetioSmartbox } from "../../smetioSmartbox/v1.0.0/SmetioSmartbox.js";
import { SmetioComingSoon } from "../../smetioComingSoon/v1.0.0/SmetioComingSoon.js";
import { convertModelToInputs } from "../../universal_methods/convertModelToInputs.js";
import { SmetioOnOffSwitch } from "../../smetioOnOffSwitch/SmetioOnOffSwitch.js";




export class SmetioAppUiContactHandler {

  constructor() {

    console.log("Creating contact handler");

    this.getId = getId;
    this.setId = setId;
    this.generateId = generateId;
    this.isEmpty = isEmpty;
    this.getAttributes = getAttributes;
    this.toggleDisplay = toggleDisplay;
    this.setTxt = setTxt;

    this.setId();

    this.title = "txt194";

    this.smartboxIsOpen = false;

    this.smartbox = new SmetioSmartbox();
    this.comingSoon = new SmetioComingSoon();

    this.convertModelToInputs = convertModelToInputs;
    this.model={};
  };

  async getContacts() {
    // return this.comingSoon.render();


    this.model = {

      firstname: {

        inputClass: false,
        details: {

          label: "txt50",
          attributes: {
            value:"Charles"
          },
          capitalizeFirstChar: true,
          explanation: {

            title: "txt175",
            article: [
              {
                heading: "txt175",
                paragraphs: ["txt176", "txt179"]
              },
              {
                heading: "txt177",
                paragraphs: ["txt178"]
              }
            ]

          },
          sendToServer: {
            type: "text",
            required: true,
            minLength: 2,
            maxLength: 50,
            value: "Charles"
          }
        }
      },

      lastname: {
        inputClass: false,
        details: {

          label: "Comment",
          element: "textarea",
          attributes: {
            value: "My name is Charles Emakpor and i really love to leave comments"
          },
          capitalizeFirstChar: true,
          sendToServer: {
            type: "text",
            required: true,
            minLength: 2,
            maxLength: 50,
            value: "My name is Charles Emakpor and i really love to leave comments"
          }
        }

      },

      username: {

        inputClass: false,
        details: {

          label: "txt77",
          instruction: "txt78",
          attributes: {
            value: "chem_charles@yahoo.com"
          },
          unCapitalizeAll: true,
          sendToServer: {
            type: "sun",
            required: true,
            value: "chem_charles@yahoo.com"
          },
          events: {
            onChange: (value) => {

              // if (this.emailIsValid(value) === true) this.model.usernameType.details.sendToServer.value = "email";
              // if (this.validateInternationalPhoneNumber(value) === true) this.model.usernameType.details.sendToServer.value = "mobile";

            }
          }
        }

      },

      usernameConfirm: {
        inputClass: false,
        details: {

          label: "txt79",
          instruction: "txt80",
          preventCopyAndPaste: true,
          unCapitalizeAll: true,
          attributes: {
            value: ""
          },
          mustBeSameAs: {
            key: "username",
            errorMessage: "txt80"
          },
          sendToServer: {
            type: "sun", // SUN = SMETIO USERNAME
            required: true,
            value: ""
          }
        }
      },

      password: {

        inputClass: false,
        details: {

          label: "txt47",
          instruction: "txt42",
          attributes: {
            value: "myPassword"
          },
          sendToServer: {
            type: "spwd", // spwd = SMETIO PASSWORD
            required: true,
            value: ""
          }
        }
      },

      passwordConfirm: {
        inputClass: false,
        details: {

          label: "txt48",
          // orientation: "horizontal",
          preventCopyAndPaste: true,
          instruction: "txt49",
          attributes: {
            value: "myPassword"
          },
          mustBeSameAs: {
            key: "password",
            errorMessage: "txt49"
          },
          sendToServer: {
            type: "spwd",
            required: true,
            value: ""
          }
        }
      },

      testCheckbox: {
        inputClass: false,
        details: {

          noAsteriskWithLabel: true,
          label: "txt53",
          labelPosition: "down",
          appendToLabel: `<a href="https://schaniag.com/legal_policies">Legal policies</a>`,
          element: "smetioCustomCheckbox",
          orientation: "horizontal",
          // orientation: "vertical",
          attributes: {
            checked:true
          },
          expectedValue: true,
          errorMessage: "txt69",
          sendToServer: {
            type: "boolan",
            required: true,
            value: ""
          }
        }
      },    
      
      legalPoliciesAccepted: {
        inputClass: false,
        details: {

          label: "txt53",
          noAsteriskWithLabel: true,
          labelPosition: "down",
          appendToLabel: `<a href="https://schaniag.com/legal_policies">Legal policies</a>`,
          element: "smetioOnOffSwitch",
          orientation: "horizontal",
          // orientation: "vertical",
          attributes: {
            checked:true
          },
          expectedValue: true,
          errorMessage: "txt69",
          sendToServer: {
            type: "boolan",
            required: true,
            value: ""
          }
        }
      },

  
      usernameType: {
        renderAsInput: false,
        details: {
          sendToServer: {
            type: "text",
            required: true,
            value: ""
          }
        }
      }

    };

    console.log("Creating contact handler 2");
    const inputsFromModel = await this.convertModelToInputs();
    console.log("Creating contact handler 3");
    return inputsFromModel;

  };



  async open() {

    // console.log("Creating contact handler 4");

    if( this.smartbox.hasBeenRendered && this.smartbox.currentState !== "close") return this.smartbox.reactivate();


    this.smartbox.title = this.title;
    this.smartbox.content = await this.getContacts();


    const newSwitch = new SmetioOnOffSwitch();
    this.smartbox.content += newSwitch.render();

    this.smartbox.content += ` <input type="checkbox" checked >`;

    this.smartbox.render();


  };

  /*
    render() {
  
      this.setTxt({
        attributes: this.mainAttributes,
        txt: this.title,
        type:"title" 
      });	
  
      const output = `
  
        <div ${this.getAttributes(this.mainAttributes, false)} >
                        
          <i class="smetio-icon smetio-icon-language"></i>
        
        </div>         
      `;
  
      this.events();
  
      return output;
  
    };
  */

  /*
    events() {
  
      
      $("body").on("mousedown", "."+this.mainClass, () => {
  
        return this.open();
  
      });
      
  
  
    }
  */

};
