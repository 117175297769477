export async function register(){

	// TEMPORARY FIX
	this.isRegistered = true;
	console.log("User is now fakely registered");
	return false;

	this.SmetioAjax.dataType = "json";
	this.SmetioAjax.timeOut = 20000;
	this.SmetioAjax.urlToConnectTo = this.server;
	this.SmetioAjax.dataToSendToServer = {

		visitedWebsite: {
			type: "json", 
			value:JSON.stringify(this.visitedWebsite) 
		},
		browser:{
			type: "json", 
			value:JSON.stringify(this.browser) 
		},
		device: {
			type: "json", 
			value:JSON.stringify(this.device) 
		},
		latitude: {
			type: "lat", 
			value:this.geoLocation.latitude 
		},
		longitude: {
			type: "lo",  
			value:this.geoLocation.longitude 
		},
		//geoLocation:this.geoLocation,
        timeZone: {
        	type: "str", 
        	value:new Date().getTimezoneOffset() 
    	},
        isUsingIncognito: {
        	type: "bo", 
        	value:this.detectRTC.browser.isPrivateBrowsing 
    	},
    	visitorsToken: {
    		type: "str",
    		value: this.visitorToken
    	}

	};

	this.SmetioAjax.beforeSend = async ()=>{

		if(!this.data.isOnline){

			return this.showOfflineNotification();

		};

		if(this.isRegistered){

			return false;

		};

	};


	this.SmetioAjax.success = (result)=>{

		this.visitedWebsite["visitedUrlsToken"] = result.visitedUrlsToken;
		this.setVisitorToken(result.visitorsToken);
		//console.log(result);

		if(result.visitedUrlsToken !== false) this.isRegistered = true;

		//this.isLoggedIn = result.userLogInStatus.isLoggedIn;
		
		delete this.SmetioAjax.request.data.browser;
		delete this.SmetioAjax.request.data.device;

		//if( !this.isEmpty(result.userLogInStatus.redirectLink)) location.href = result.userLogInStatus.redirectLink;  		

		this.handleUserLoginStatus(result);

	};

	this.SmetioAjax.error = (request, status, error)=>{

		//console.log(request);

		//console.log(status);

		console.log(error);

	};	

	await this.SmetioAjax.sendRequest();

	

};