

// import {getId} from "../../universal_methods/getId.js";  
// import {setId} from "../../universal_methods/setId.js";
// import {generateId} from "../../universal_methods/generateId.js";
// import {isEmpty} from "../../universal_methods/isEmpty.js";  
// import {getAttributes} from "../../universal_methods/getAttributes.js";  
// import {toggleDisplay} from "../../universal_methods/toggleDisplay.js"; 

import { SmetioPage } from "../../smetioPage/v1.0.0/SmetioPage";


//import "./styles/_main.scss";


export class SmetioResetPasswordPage extends SmetioPage{

  constructor(data = {}) {

    super(data);

    this.showSignUpLink = true;
    this.showLoginLink = true;

    this.mainClass = "smetio-reset-password-page" + this.id;
    this.mainAttributes = {
      class: ["smetio-reset-password-page", this.mainClass],
    };
  };

  async getModelParameters(data = {}) {

    const getParameterFromUrl = (await import(/* webpackPrefetch: true */ `../../../functions/get/getParameterFromUrl.js`)).getParameterFromUrl;
    
    const st = getParameterFromUrl({paramName:"st"}); // submitText
    const it = getParameterFromUrl({paramName:"it"}); // instructionTitle
    const pn = getParameterFromUrl({paramName:"pn"}); // phoneNumber


    const apiEndPoint = window.apiEndPoint;
    const language = window.userLanguage;

    const url = `${window.defaultPath}resetPassword/model/${language}`;

    return {
      url,
      submitText: st || "txt741",
      instructionTitle: it || "txt137",
      params:{phoneNumber:pn},
      // instructionSubtitle: "txt598",
      submissionFunctionDetails:{
        // functionPath: "reset-password_and_signup/submitLoginDetails",
        predefinedFunction:this.submitFormData,
        functionParameters: {
          preloaderClass: this.preloader,
          url:`${window.defaultPath}resetPassword/${language}`,
          modelPath: "pages/resetPassword/resetPasswordModel"
        }
      }
    };

  };

  events() {

    // $("body").on("mousedown", "."+this.uploadFilesBtnClass, ()=>{

    //     $("."+this.uploadFilesInputClass).trigger("click");

    // });	

  };

};
