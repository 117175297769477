export function acceptLegalPolicies(){

	$(".smetio-legalPolicyNotification").fadeOut();

	if( !this.browser.hasLocalStorage){

		return false;
		
	};

	localStorage.setItem("legalPoliciesAccepted", "yes");
	localStorage.setItem("legalPoliciesAcceptedDate", this.data.currentDate);
	localStorage.setItem("versionOfLegalPoliciesAccepted", this.smetioLegalPolicyCurrentVersion);

	return false;

};