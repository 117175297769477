import { checkIfItIsEmpty } from "../../functions/checks/checkIfItIsEmpty";
import { setTxt } from "./setTxt";

export function setUserReadableTextAttributes(attribute={}) {
 
    if(!this.setTxt) this.setTxt = setTxt;

    /**** TEXT THAT THE USER CAN/MAY ACTUALLY SEE THAT ARE SET VIA HTML ATTRIBUTES ****/
    const userReadableTextAttributes = ["placeholder", "title", "alt"];

    $.each(attribute, (key, value) => { 

        if( checkIfItIsEmpty(value) ) {
            
            return true;

        };

        /**** CHECK IF THE ATTRIBUTE IS ONE THAT CAN RECEIVE TEXT THAT THE USERS WILL SEE ****/
           
        if( $.inArray(key, userReadableTextAttributes) != -1 ){

            value = this.setTxt({
                attributes:attribute,
                txt: value,
                type: key
            }); 

        };

        attribute[key] = value;

    });
    
    return attribute;

};
