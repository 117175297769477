export function trackGeolocation(data={}){
	
	this.geoLocationTrackingId = navigator.geolocation.watchPosition((position)=>{

		return this.updateGeolocation({
			position:position,
			callback: data.callback
		});

	}, (error)=>{

		return this.handGeolocationError({
			error:error,
			callback: data.callback
		});		

	},this.geoLocationOptions);


	/**** 
	 * 
	 * 	EXAMPLE USAGE
	 * 
	    this.trackGeolocation({
	        priority: "important",
	        callback: (data)=>{

	            if(!data){

	                alert("an error occured");

	                return false
	            };
	            $(".smetio-signup-page-input-firstname").val(data.latitude+" "+data.longitude);

	        },
	    });	 

	 * ****/
	
};