export async function registerMultimediaInteractions(event, action){

		// TEMPORARY FIX
		return false

	/**** 
	 * data-smetio-multimedia-uniqueToken IS USED TO ID SPECIFIC 
	 * MULTIMEDIA ON THE PAGE THIS IS USED INSTEAD OF USING ID SINCE THE 
	 * MULTIMEIDA ELEMENT MAY NOT HAVE ITS OWN ID 
	 * data-smetio-multimedia-uniqueToken ATTRIBUTE WILL BE ADDED TO THE ELEMENT
	 * IF IT IS MISSING FROM THE FIRST INTERACTION
	 * 
	 * //'played','paused', 'stopped','ended', 'shared'
	 * ****/	

	const target = $(event.currentTarget);
	const src = target.attr("src");
	const totalDuration = target[0].duration;
	const currentTime = target[0].currentTime;
	const uniqueToken = target.attr("data-smetio-multimedia-uniqueToken");
	//let action = "played";
	let tag = "video";

	if(target.is("audio")){

		tag = "audio";

	};


	const interactionData = {

		tag: tag,
		src: src,
		totalDuration: totalDuration,
		currentTime: currentTime,
		uniqueToken: uniqueToken,
		action: action

	};

	//console.log(interactionData);

	await this.getGeolocation();

	this.SmetioAjax.dataToSendToServer = {

		visitedWebsite: {
			type: "json", 
			value:JSON.stringify(this.visitedWebsite) 
		},
		latitude: {
			type: "lat", 
			value:this.geoLocation.latitude 
		},
		longitude: {
			type: "lo",  
			value:this.geoLocation.longitude 
		},	
		interactionData: {
			type: "json",
			value: JSON.stringify(interactionData)
		}		
	
	};

	this.SmetioAjax.success = (result)=>{

		if( this.isEmpty(uniqueToken) ){

			target.attr("data-smetio-multimedia-uniqueToken", result.uniqueToken);
		};
		
		delete this.SmetioAjax.request.data.interactionData;

		this.handleUserLoginStatus(result);

	};

	this.SmetioAjax.urlToConnectTo = this.serverMultimediaInteractLocation;

	await this.SmetioAjax.sendRequest();	


	
};