import { isEmpty } from "./isEmpty";

export function removeTextsToTextsInVeiw(txt) {
    
	if(!txt) return false;

	if(!window.smetioVisitor){

		const textInViewQueue = JSON.parse( localStorage.getItem("textInViewQueue") ) || {};

		if( isEmpty(textInViewQueue[txt]) ) return false;

		textInViewQueue[txt] -= 1;

        if(textInViewQueue[txt] <= 0 ) delete textInViewQueue[txt];

		localStorage.setItem( "textInViewQueue", JSON.stringify(textInViewQueue) );

		return true;

	};

    if(!window.smetioVisitor.textsInView) window.smetioVisitor.textsInView = {};

	if( isEmpty(window.smetioVisitor.textsInView[txt])  ) return false;

	window.smetioVisitor.textsInView[txt] -= 1;

    if( window.smetioVisitor.textsInView[txt] <= 0 ) delete window.smetioVisitor.textsInView[txt];

	return true;    


};
