export function showLegalPolicyNotice(){

	let mainMsg = window["smetioTexts"].txt71;

	if( parseInt(this.smetioLegalPolicyCurrentVersion) > parseInt(this.browser.versionOfLegalPoliciesAccepted) ){

		mainMsg = window["smetioTexts"].txt74;

	};

	const output =`

		<div class="smetio-legalPolicyNotification">

			<h2>${window["smetioTexts"].txt70}</h2>

			<h5>${mainMsg}</h5>

			<h5>${window["smetioTexts"].txt72}</h5>

			<h5>
				<a href="${this.gv.link_to_legal_policies}" target="_blank">
					${window["smetioTexts"].txt54}
				</a>
			</h5>

			<button class="smetio-legalPolicyNotification-accept">

				${window["smetioTexts"].txt73}

			</button>

		</div>

	`;

	$("body").append(output);

};