
import { getId } from "../../universal_methods/getId.js";
import { setId } from "../../universal_methods/setId.js";
import { generateId } from "../../universal_methods/generateId.js";
import { isEmpty } from "../../universal_methods/isEmpty.js";
import { getAttributes } from "../../universal_methods/getAttributes.js";
import { toggleDisplay } from "../../universal_methods/toggleDisplay.js";

import { setTxt } from "../../universal_methods/setTxt.js";




//import "./styles/_main.scss";
import { SmetioSmartbox } from "../../smetioSmartbox/v1.0.0/SmetioSmartbox.js";
import { SmetioComingSoon } from "../../smetioComingSoon/v1.0.0/SmetioComingSoon.js";
import { SmetioOnOffSwitch } from "../../smetioOnOffSwitch/SmetioOnOffSwitch.js";

import { convertModelToInputs } from "../../universal_methods/convertModelToInputs.js";



export class SmetioAppUiSettingsHandler {

      constructor() {


            this.getId = getId;
            this.setId = setId;
            this.generateId = generateId;
            this.isEmpty = isEmpty;
            this.getAttributes = getAttributes;
            this.toggleDisplay = toggleDisplay;
            this.setTxt = setTxt;

            this.convertModelToInputs = convertModelToInputs;

            this.setId();

            this.title = "txt196";
            this.model = "";

            this.smartboxIsOpen = false;

            this.smartbox = new SmetioSmartbox();
            this.comingSoon = new SmetioComingSoon();

            this.smartbox.onRender = () => {
                  this.smartboxIsOpen = true;
            };
            this.smartbox.onClose = () => {
                  this.smartboxIsOpen = false;
            };

      };

      isCurrentTheme(theme) {

            const currentTheme = $("html").attr("data-theme");

            // console.log(currentTheme+" is current theme ******** ")
            const output = currentTheme == theme ? true : false;

            return output;

            // if( this.isEmpty(currentTheme) && theme == "light"){

            //   return true;

            // }else{

            //   return currentTheme == theme ? true: false;

            // };

      }

      getModel() {

            return {

                  theme: {
                        inputClass: false,
                        details: {
                              label: "txt224",
                              labelPosition: "up",
                              appendToLabel: "",
                              element: "select",
                              orientation: "vertical",
                              attributes: {},
                              //expectedValue: true,
                              //errorMessage: "txt69",
                              explanation: {
                                    title: "txt41",
                                    article: [
                                          {
                                                heading: "txt42",
                                                paragraphs: ["txt43", "txt44"],
                                          },
                                          {
                                                heading: "txt42",
                                                paragraphs: ["txt43", "txt44"],
                                          }
                                    ]

                              },
                              options: [ // USEFULL FOR CHECK RADIO AND SELECT AND DATALIST
                                    {
                                          text: "txt225",
                                          attributes: {
                                                value: "light",
                                                selected: this.isCurrentTheme("light")// OR FALSE   
                                          }
                                    },
                                    {
                                          text: "txt226",
                                          attributes: {
                                                value: "dark",
                                                selected: this.isCurrentTheme("dark") // OR FALSE   
                                          }
                                    },
                                    {
                                          text: "light 100",
                                          attributes: {
                                                value: "light-100",
                                                selected: this.isCurrentTheme("light-100") // OR FALSE   
                                          }
                                    },
                                    {
                                          text: "dark 100",
                                          attributes: {
                                                value: "dark-100",
                                                selected: this.isCurrentTheme("dark-100") // OR FALSE   
                                          }
                                    },
                                    {
                                          text: "light 80",
                                          attributes: {
                                                value: "light-80",
                                                selected: this.isCurrentTheme("light-80") // OR FALSE   
                                          }
                                    },
                                    {
                                          text: "dark 80",
                                          attributes: {
                                                value: "dark-80",
                                                selected: this.isCurrentTheme("dark-80") // OR FALSE   
                                          }
                                    },
                                    {
                                          text: "light 60",
                                          attributes: {
                                                value: "light-60",
                                                selected: this.isCurrentTheme("light-60") // OR FALSE   
                                          }
                                    },
                                    {
                                          text: "dark 60",
                                          attributes: {
                                                value: "dark-60",
                                                selected: this.isCurrentTheme("dark-60") // OR FALSE   
                                          }
                                    },
                                    {
                                          text: "light 40",
                                          attributes: {
                                                value: "light-40",
                                                selected: this.isCurrentTheme("light-40") // OR FALSE   
                                          }
                                    },
                                    {
                                          text: "dark 40",
                                          attributes: {
                                                value: "light-40",
                                                selected: this.isCurrentTheme("dark-40") // OR FALSE   
                                          }
                                    },
                                    {
                                          text: "light 20",
                                          attributes: {
                                                value: "light-20",
                                                selected: this.isCurrentTheme("light-20") // OR FALSE   
                                          }
                                    },
                                    {
                                          text: "dark 20",
                                          attributes: {
                                                value: "light-20",
                                                selected: this.isCurrentTheme("dark-20") // OR FALSE   
                                          }
                                    },
                                    {
                                          text: "Plain light 100",
                                          attributes: {
                                                value: "plain-light-100",
                                                selected: this.isCurrentTheme("plain-light-100") // OR FALSE   
                                          }
                                    },
                                    {
                                          text: "plain dark 100",
                                          attributes: {
                                                value: "plain-dark-100",
                                                selected: this.isCurrentTheme("plain-dark-100") // OR FALSE   
                                          }
                                    },
                                    {
                                          text: "True brand light 100",
                                          attributes: {
                                                value: "true-brand-light-100",
                                                selected: this.isCurrentTheme("true-brand-light-100") // OR FALSE   
                                          }
                                    },
                                    {
                                          text: "True brand dark 100",
                                          attributes: {
                                                value: "true-brand-dark-100",
                                                selected: this.isCurrentTheme("true-brand-dark-100") // OR FALSE   
                                          }
                                    }

            
                              ],
                              validation: {
                                    type: "string",
                                    required: false,
                                    value: ""
                              },
                              events: {
                                    onChange: (value) => {
                                          // $("html").attr("data-theme", value);
                                          $("*").attr("data-theme", value);
                                          window.themeName = value;
                                          //console.log(value);
                                    }
                              }
                        }
                  },

            };

      };

      async getSettings() {

            this.model = this.getModel();

            const inputsFromModel = await this.convertModelToInputs();

            const output = `
      <div class="smetio-app-ui-settings">

        ${inputsFromModel}
      
      </div>
    `;

            return output;

            ///return this.comingSoon.render();
      };

      resetSmartbox() {

            this.smartbox.sidelize();
            this.smartbox.activate();

      };

      async open() {

            if (this.smartboxIsOpen) return this.resetSmartbox();

            this.smartbox.title = this.title;
            this.smartbox.content = await this.getSettings();
            this.smartbox.render();
            this.smartbox.sidelize();

      };


      /*
  render() {

    this.events();

    return output;

  };
*/

      events() {


            $("body").on("mousedown", ".smetio-app-ui-settings", () => {

                  console.log("you clicked me settings ^^^^^^^^^^");

            });



      }


};
