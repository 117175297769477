

// import {getId} from "../../universal_methods/getId.js";  
// import {setId} from "../../universal_methods/setId.js";
// import {generateId} from "../../universal_methods/generateId.js";
// import {isEmpty} from "../../universal_methods/isEmpty.js";  
// import {getAttributes} from "../../universal_methods/getAttributes.js";  
// import {toggleDisplay} from "../../universal_methods/toggleDisplay.js"; 

import { SmetioPage } from "../../smetioPage/v1.0.0/SmetioPage";


//import "./styles/_main.scss";


export class SmetioLogoutPage extends SmetioPage{

      constructor(data = {}) {

            super(data);

            this.modelNotNeeded = true;
            this.displayMessageAfterRender = true;
            this.mainClass = "smetio-logout-page" + this.id;
            this.mainAttributes = {
                  class: ["smetio-logout-page", this.mainClass],
            };

            // setTimeout(() => {

            //   this.displayGoodByeMessage();
      
            // }, this.defaultTimeout);

      };

      displayMessage(){

            const logingoutText = `
      <div class="smetio-margin-top-50 smetio-text-center "> 

        <h4 class="smetio-h4-heading smetio-text-center">
          ${ this.setTxt({ attributes: {}, txt:"txt739", type: "html"}) }
        </h4>

        <p class="smetio-p-text smetio-text-center smetio-margin-top-30">
          ${ this.setTxt({ attributes: {}, txt:"txt740", type: "html"}) }
        </p>
      </div>
    `;

            console.log("Displaying good bye message...");

            this.changeRightSideUniqueContent(logingoutText);

            setTimeout(() => {

                  this.logoutUser();

            }, 3000);

      };

      logoutUser(){

            const functionParameters =  {
                  preloaderClass: this.preloader,
                  url:`${window.defaultPath}logout/${this.language}`,
                  modelPath: "pages/logout/logoutModel",
                  submissionData: {
                        // requestSource: window.parent.location.href
                        requestSource: this.isIframed !== true ? location.href : this.whiteLabelUrl
                  }
            };


            this.submitFormData (functionParameters);

      };

      events() {

            // $("body").on("mousedown", "."+this.uploadFilesBtnClass, ()=>{

            //     $("."+this.uploadFilesInputClass).trigger("click");

            // });	

      };

};
