
import { getId } from "../../universal_methods/getId.js";
import { setId } from "../../universal_methods/setId.js";
import { generateId } from "../../universal_methods/generateId.js";
import { isEmpty } from "../../universal_methods/isEmpty.js";
import { getAttributes } from "../../universal_methods/getAttributes.js";
import { toggleDisplay } from "../../universal_methods/toggleDisplay.js";
import { debounce } from "../../universal_methods/debounce.js";

import { setTxt } from "../../universal_methods/setTxt.js";




//import "./styles/_main.scss";

export class SmetioAppUiSideMenuItemContent {

  constructor(data={
    id: "",
    appId:"", 
    viewStyle: "",
    content: ""
}) {

    this.id = data.id;

    this.getId = getId;
    this.setId = setId;
    this.generateId = generateId;
    this.isEmpty = isEmpty;
    this.getAttributes = getAttributes;
    this.toggleDisplay = toggleDisplay;
    this.setTxt = setTxt;
    this.debounce = debounce;

    this.setId();

    /**** USED TO IDENTIFY A MEMBERS BELONGING TO THE SAME APP ****/
    this.appId = data.appId;
    this.content = data.content || "";
    this.viewStyle = data.viewStyle || "cardView";
    this.lastScrollTop = 0;

    this.onScrollingUp = false;
    this.onScrollingDown = false;
    this.onScrolledToTop = false;
    this.onScrolledToBottom = false;
    this.onScrolling = false;

    // this.groupClass = "";
    // this.mainClass = "";
    // this.mainAttributes = "";

    this.groupClass = "smetio-app-ui-menu-item-content-"+this.appId;
    this.mainClass = this.groupClass+"-"+this.id;
    this.mainAttributes = {
        class: [
            "smetio-app-ui-menu-item-content",  
            this.groupClass,            
            this.mainClass
        ],
    };
    this.mainAttributes["data-smetio-view-style"] = this.viewStyle;
    this.mainAttributes["data-smetio-is-active"] = "no";    
    
  };

  render() {
    
    for (let i = 0; i < 100; i++) {

      this.content += "I am container for menu item "+this.id+"<br>";
      
    };

    setTimeout(() => {
      this.events();
    }, 500);
    

    return`
      <div ${this.getAttributes(this.mainAttributes, false)} >
        ${this.content}
      </div>      
    `;
    
  };

  show(){

    // console.log("this.groupClass ", this.groupClass);

    // console.log("this.mainClass ", this.mainClass);
    
    $("."+this.groupClass).attr("data-smetio-view-style", "");
    $("."+this.groupClass).attr("data-smetio-is-active", "no");

    $("."+this.groupClass).attr("data-smetio-view-style", this.viewStyle);
    $("."+this.mainClass).attr("data-smetio-is-active", "yes");

    

  };

  changeView(newView){

    this.viewStyle = newView;  

    $("."+this.mainClass).attr("data-smetio-view-style", this.viewStyle);
    $("."+this.mainClass+" div.smetio-data-presentation").attr("data-smetio-view-style", this.viewStyle);
    $("."+this.mainClass+" div.smetio-data-presentation *").attr("data-smetio-view-style", this.viewStyle);

    $("."+this.mainClass+" div.smetio-slideShow-item").attr("data-smetio-view-style", this.viewStyle);
    

    // $(".smetio-data-presentation").html("You are now on "+newView);

  };

  update(newContent){

    $("."+this.mainClass).html(newContent);
    
  };

  handleScrollingEvent(){

    const scrollTop = $("."+this.mainClass).scrollTop();
    const innerHeight = $("."+this.mainClass).innerHeight();
    const scrollHeight = $("."+this.mainClass)[0].scrollHeight;

    if(scrollTop > this.lastScrollTop){
      
      //console.log("You are scrolling down %%%%%%%%%%%%%%%%");
      if(this.onScrollingDown) this.onScrollingDown();

    }else{

      //console.log("You are scrolling up %%%%%%%%%%%%%%%%%%%%%");
      if(this.onScrollingUp) this.onScrollingUp();

    };

    this.lastScrollTop = scrollTop;

    if( scrollTop + innerHeight >= scrollHeight ) {

      //console.log("You have reached the bottom ************* ");
      if(this.onScrolledToBottom) this.onScrolledToBottom();

    } else if(scrollTop <= 0 ) {
       
      //console.log("You have reached the top ************* ");
      if(this.onScrolledToTop) this.onScrolledToTop();

    }else{

      //console.log("You are still scroll ************* ");
      if(this.onScrolling) this.onScrolling();

    };

  };

  events() {
   
    $("."+this.mainClass).on("scroll", this.debounce(this.handleScrollingEvent, 150));

  };


};
