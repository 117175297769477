

export function validateValueLength(theValue, minLength, maxLength){

    if( minLength !== false && theValue.length <  minLength ) return "txt180";

    if( maxLength !== false && theValue.length >  maxLength ) return "txt181";

    return true;


};