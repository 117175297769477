export function supportsServiceWorker(){

	if ("serviceWorker" in navigator ) {

		return true;
	
	};

	return false;


/*

if ("serviceWorker" in navigator && "PushManager" in window) {
*/

};