import { Smetio3ackMainWebsiteController } from "../../smetio3ackMainWebsiteController.js/v1.0.0/Smetio3ackMainWebsiteController.js";





export class Smetio3ackBlogsPageController extends Smetio3ackMainWebsiteController {

      constructor(data = {}) {

            super(data);

            this.data = data;
            this.addToSubmissionData = {};
     
            // this.filters = { orderBy: "createdOn ASC", limit: 12, offset: 0 };
            // this.usefulLinks = data.usefulLinksObj ? data.usefulLinksObj.links : [];

            this.filters = { orderBy: "createdOn ASC", limit: 10, offset: 0 };

            this.mainClass = "smetio-3ack-blogs-page-controller" + this.id;
            this.mainAttributes = {
              class: ["smetio-3ack-blogs-page-controller", this.mainClass],
            };

            this.loadMoreClass = "smetio-3ack-blogs-page-controller-load-more-btn-" + this.id;
            this.loadMoreAttributes = {
              class: ["smetio-3ack-blogs-page-controller-load-more-btn", this.loadMoreClass],
            };            

      };

      async showPreloader() {
            const { SmetioPreloader } = (await import(/* webpackPrefetch: true */ "../../smetioPreloader/SmetioPreloader.js"));
            if (!this.preloaderClass) this.preloaderClass = new SmetioPreloader();
            if (!this.preloader) this.preloader = this.preloaderClass.render();
            $(".smetio-3ack-blogs-page-controller-items-holder").append(this.preloader);
            this.preloaderClass.show();
      }

      async getBlogsFromServer(data = {}) {

            await this.showPreloader();
      
            const { loadingMore, filters } = data;
            const blogPosts = await this.getPublicDataFromServer({ table: "blogs", filters });
            
            if (blogPosts === false) return false;

            if (Object.keys(blogPosts).length < 1) $("." + this.loadMoreClass).remove();

            const { SmetioPhotoAndDescription } = (await import(/* webpackPrefetch: true */ "../../smetioPhotoAndDescription/v1.0.0/smetioPhotoAndDescription.js"));
      
            let output = "";

            for (const key in blogPosts) {
    
                  const { title, coverPhoto, author, publisher, date, description, token } = blogPosts[key];
                  const descriptionDiv = `
                        <div class="smetio-3ack-blogs-page-controller-item-description" >
                              <h4 class="smetio-h4-text-size" title="${title}"> ${title} </h4>
                              <p class="smetio-p-text-size"> ${description} </p>

                              <div class="smetio-3ack-blogs-page-controller-item-description-authorship">
                                    <p class="smetio-h6-text-size"> ${author} - ${publisher} </p>
                                    <p class="smetio-p-text-size"> ${date} </p>
                              </div>                              
                        </div>
                  `;

                  const spad = new SmetioPhotoAndDescription({
                        photo: coverPhoto.src,
                        alt: coverPhoto.alt,
                        orientation: "vertical",
                        description: descriptionDiv,
                        boxHeight: "100%",
                        boxMinHeight:"100%"
                  });

                  output += `                       
                        <a class="smetio-3ack-blogs-page-controller-item" href="${this.defaultPath}blogs/${token}/${this.language}" target="_self">
                              ${spad.render()} 
                        </a>                      
                  `;
            
            };

            if (loadingMore !== true) return $(".smetio-3ack-blogs-page-controller-items-holder").html(output);
            
            $(".smetio-3ack-blogs-page-controller-items-holder").append(output);

            if (this.preloaderClass) this.preloaderClass.hide();
            
    };
  
      async render() {
   
            this.displayLanguageIcon();
  
            this.removeSplashScreen();

            const loadMoreText = this.setTxt({
                  attributes: {},
                  txt: "txt1831",
                  type: "html"
            });

            const output = `
            
                  <div ${this.getAttributes(this.mainAttributes, false)}>
                        <div class="smetio-3ack-blogs-page-controller-items-holder"> 
                              
                        </div>
                        
                        <button ${this.getAttributes(this.loadMoreAttributes, false)}> ${loadMoreText} </button> 
                  </div>
            `;

            $(".smetio-3ack-sub-page-blogs-posts").html(output);

            setTimeout(() => {
                  this.getBlogsFromServer({});
            }, 300);

            this.events();

            return output;

      };

      loadMore() {
            const { orderBy, limit, offset } = this.filters;
            this.filters = { orderBy, limit, offset: offset === 0 ? limit : offset + limit };
            this.getBlogsFromServer({ filters: this.filters, loadingMore: true });
      };

      events() {

            $("body").on("mousedown", "."+this.loadMoreClass, ()=>{

                  this.loadMore();

            });	

      };

};
