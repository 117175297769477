export function showOfflineNotification(){

	if( this.offlineNotice != false ){

		$(".smetio-offlineNotification").fadeIn();

		return false;
	};

	this.offlineNotice =`

		<div class="smetio-offlineNotification">

			<i class="smetio-icon smetio-icon-wifi-off"></i>

			<h5>${window["smetioTexts"].txt76}</h5>

		</div>		

	`;

	$("body").append(this.offlineNotice);

	return false;



};