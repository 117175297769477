//import {test_function} from "./../functions/test_function.js";



import {getId} from "./../../universal_methods/getId.js";  
import {setId} from "./../../universal_methods/setId.js";
//import {generateId} from "./../universal_methods/generateId.js";
import {isEmpty} from "./../../universal_methods/isEmpty.js";  
import {getAttributes} from "./../../universal_methods/getAttributes.js";  
import { setTxt } from "../../universal_methods/setTxt.js";

import { generateId } from "./../../universal_methods/generateId.js";

//import { addTextsToTextsInVeiw } from "../../universal_methods/addTextsToTextsInVeiw.js";






 

import { SmetioSmartbox } from "./../../smetioSmartbox/v1.0.0/SmetioSmartbox.js";  


import {render} from "./methods/render.js";
import {events} from "./methods/events.js";

import {getParagraphs} from "./methods/getParagraphs.js";
import {showExplanation} from "./methods/showExplanation.js";




// //import "./styles/_main.scss";



export class SmetioExplanation{
    
    constructor(obj={}){
        

        this.getId = getId;
        this.setId = setId;     
        this.generateId = generateId;
        this.isEmpty = isEmpty;
        this.getAttributes = getAttributes;
        this.setTxt = setTxt;
        this.SmetioSmartbox = SmetioSmartbox;
        //this.addTextsToTextsInVeiw = addTextsToTextsInVeiw;
        //this.toggleDisplay = toggleDisplay;
        //this.universal_handleErrorFromServer = universal_handleErrorFromServer;
        
        //this.SmetioOverlayPreloader = new SmetioOverlayPreloader();
        //this.SmetioAjax = new SmetioAjax();
        //this.errorCode = errorCode;

        this.setId();

        this.render = render;
        this.events = events;
        this.getParagraphs = getParagraphs;
        this.showExplanation = showExplanation;
        


        /**** 
         *      
         * this.mainObj = {
            title: "txt41",
            article: [
                {
                    heading: "txt42"
                    paragraphs:["txt43","txt44"]
                }
            ]
            
            }
         * 
         * ****/

        this.mainObj = obj;
        this.renderWithSpan = this.mainObj.renderWithSpan || true;
        this.icon = this.mainObj.icon || "question-circle-o";
        this.title = this.mainObj.title || "Smetio Wiki";
        this.smartBoxContainer = this.mainObj.smartBoxContainer || "body";

        this.mainClass = "smetio-explanation-"+this.id;
        this.mainAttributes = {
            id: "smetio-explanation-id-"+this.id,
            class: [
                
                "smetio-explanation",
                "smetio-icon",
                this.mainClass
            ],
        };

        
        this.articleTitleClass = "smetio-explanation-article-title"+this.id;
        this.articleTitleAttributes = {
            class: [
                
                "smetio-explanation-article-title",
                this.articleTitleClass
            ],
        };

        this.paragraphClass = "smetio-explanation-paragraph"+this.id;
        this.paragraphAttributes = {
            class: [
                
                "smetio-explanation-paragraph",
                this.paragraphClass
            ],
        };

    };
    

};
