export async function windowIsAboutToUnload(event){

	/**** THE USER IS TRYING TO NAVIGATE AWAY FROM THE PAGE ****/

	this.pageIsClosed = true;

	await this.getGeolocation();

	delete this.SmetioAjax.dataToSendToServer;

	this.SmetioAjax.dataToSendToServer = {

		visitedWebsite: {
			type: "json", 
			value:JSON.stringify(this.visitedWebsite) 
		},
		latitude: {
			type: "lat", 
			value:this.geoLocation.latitude 
		},
		longitude: {
			type: "lo",  
			value:this.geoLocation.longitude 
		},
		pageIsClosed: {
			type: "str",
			value: this.pageIsClosed
		}	
	};

	this.SmetioAjax.urlToConnectTo = this.serverPingLocation;

	await this.SmetioAjax.sendRequest();

	window.close();

};