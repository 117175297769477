//import {test_function} from "./../functions/test_function.js";

import { isEmpty } from "./../../universal_methods/isEmpty.js";
import { getAttributes } from "./../../universal_methods/getAttributes.js";
import { getId } from "./../../universal_methods/getId.js";
import { setId } from "./../../universal_methods/setId.js";
import { setTxt } from "../../universal_methods/setTxt.js";

import { debounce } from "./../../universal_methods/debounce";




// import { render } from "./methods/render.js";
// import { events } from "./methods/events.js";
// import { close } from "./methods/close.js";
// import { normalize } from "./methods/normalize.js";
// import { setHead } from "./methods/setHead.js";
// import { setBody } from "./methods/setBody.js";
// import { activate } from "./methods/activate.js";
// import { deactivate } from "./methods/deactivate.js";
// import { maximize } from "./methods/maximize.js";
// import { minimize } from "./methods/minimize.js";
// import { toggleLock } from "./methods/toggleLock.js";








// //import "./styles/_main.scss";

export class SmetioSmartbox {

    constructor() {

        this.getId = getId;
        this.setId = setId;
        this.isEmpty = isEmpty;
        this.getAttributes = getAttributes;
        this.setTxt = setTxt;
        this.debounce = debounce;

        this.setId();

        // this.render = render;
        // this.events = events;
        // this.close = close;
        // this.normalize = normalize;
        // this.setHead = setHead;
        // this.setBody = setBody;
        // this.activate = activate;
        // this.deactivate = deactivate;
        // this.maximize = maximize;
        // this.minimize = minimize;
        // this.toggleLock = toggleLock;
        this.hasBeenRendered = false;

        this.onActivate = null;
        this.onDeactivate = null;
        this.onClose = null;
        this.onMaximize = null;
        this.onMinimize = null;
        this.onNormalize = null;
        this.onDragStart = null;
        this.onDragStop = null;
        this.onResizeStart = null;
        this.onResizeStop = null;
        this.onSidelize = null;
        this.onRender = null;

        this.currentState = "normalize";
        this.title = "Forms";
        this.entranceAnimation = "slideInRight"; //"fadeInUp";
        this.exitAnimation = "slideOutRight"; //" "fadeOutDown";
        this.openState = "normalize";
        this.lockState = "unlocked";
        this.smetioTransition = "yes";
        this.lockIcon = "smetio-icon-lock8";
        this.unLockIcon = "smetio-icon-lock-open1";
        this.position = "absolute";


        /**** 
 * this.contentType IS USED TO TELL IF IT IS A FORM THAT IS INSIDE THE SMARTBOX OR SOMETHING ELSE
 * THIS IS NEEDED SO THAT THE COMPUTER MEMORY CAN BE CLEANED OR MAXIMIZED BY REMOVING THE FORM AND 
 * INPUT DETIALS FROM MEMORY BY REMOVING THEM FROM THE GLOBAL VARIABLE
 * 
 * this.contentUniqueIdentifier IS THE ID/FormId OF THE FORM OR THE CONTENT OF THE SMARTBOX
 * 
 * THIS CAN BE USED TO TARGET THE CONTENT ON THE GLOBAL VAR
 * 
 *  ****/

        this.content = "Please wait...";
        this.contentType = "";
        this.contentUniqueIdentifier = "";
        this.appendTo = "body";
        this.shakeAnimation = "animate__shakeX";


        this.mainClass = "smetio-smartbox2-" + this.id;
        this.mainAttributes = {
            id: this.id,
            class: [

                "animate__animated",
                "animate__" + this.entranceAnimation,
                "smetio-smartbox2",
                "smetio-app-noLeftSideMenuSwipControl",
                this.mainClass
            ],
        };


        this.smartboxHeadClass = "smetio-smartbox-head-" + this.id;
        this.smartboxHeadAttributes = {

            class: [
                "smetio-smartbox-head",
                this.smartboxHeadClass
            ],
        };

        this.smartboxHeadTitleClass = "smetio-smartbox-head-title-" + this.id;
        this.smartboxHeadTitleAttributes = {

            class: [
                "smetio-smartbox-head-title",
                this.smartboxHeadTitleClass
            ],
        };


        this.smartboxHeadTitleTextClass = "smetio-smartbox-head-title-text-" + this.id;
        this.smartboxHeadTitleTextAttributes = {

            class: [
                "smetio-smartbox-head-title-text",
                this.smartboxHeadTitleTextClass
            ],
        };

        this.smartboxHeadControlsClass = "smetio-smartbox-head-controls-" + this.id;
        this.smartboxHeadControlsAttributes = {

            class: [
                "smetio-smartbox-head-controls",
                this.smartboxHeadControlsClass
            ],
        };

        this.smartboxHeadControlsAttributes["data-smetio-target"] = this.id;
        this.smartboxHeadControlMaximizeClass = "smetio-smartbox-head-control-maximize-" + this.id;
        this.smartboxHeadControlMaximizeAttributes = {

            title: "txt171",
            class: [
                "smetio-smartbox-head-control",
                "smetio-smartbox-head-control-maximize",
                this.smartboxHeadControlMaximizeClass,
            ],
        };
        this.smartboxHeadControlMaximizeAttributes["data-smetio-action"] = "maximize";


        this.smartboxHeadControlNormalizeClass = "smetio-smartbox-head-control-normalize-" + this.id;
        this.smartboxHeadControlNormalizeAttributes = {

            title: "txt172",
            class: [
                "smetio-smartbox-head-control",
                "smetio-smartbox-head-control-normalize",
                this.smartboxHeadControlNormalizeClass
            ],
        };
        this.smartboxHeadControlNormalizeAttributes["data-smetio-action"] = "normalize";


        this.smartboxHeadControlMinimizeClass = "smetio-smartbox-head-control-minimize-" + this.id;
        this.smartboxHeadControlMinimizeAttributes = {
            title: "txt173",
            class: [
                "smetio-smartbox-head-control",
                "smetio-smartbox-head-control-minimize",
                this.smartboxHeadControlMinimizeClass
            ],
        };
        this.smartboxHeadControlMinimizeAttributes["data-smetio-action"] = "minimize";


        this.smartboxHeadControlCloseClass = "smetio-smartbox-head-control-close-" + this.id;
        this.smartboxHeadControlCloseAttributes = {
            title: "txt9",
            class: [
                "smetio-smartbox-head-control",
                "smetio-smartbox-head-control-close",
                this.smartboxHeadControlCloseClass
            ],
        };
        this.smartboxHeadControlCloseAttributes["data-smetio-action"] = "close";


        this.smartboxHeadControlLockToggleClass = "smetio-smartbox-head-control-lock-toggle-" + this.id;
        this.smartboxHeadControlLockToggleAttributes = {
            title: "txt174",
            class: [
                "smetio-smartbox-head-control",
                "smetio-smartbox-head-control-lock-toggle",
                this.smartboxHeadControlLockToggleClass
            ],
        };


        this.smartboxBodyClass = "smetio-smartbox-body-" + this.id;
        this.smartboxBodyAttributes = {
            class: [
                "smetio-smartbox-body",
                this.smartboxBodyClass
            ],
        };

        this.smartboxBodyContentHolderClass = "smetio-smartbox-body-content-holder-" + this.id;
        this.smartboxBodyContentHolderAttributes = {
            class: [
                "smetio-smartbox-body-content-holder",
                this.smartboxBodyContentHolderClass
            ],
        };



    };

    async animate(data = {}) {

        const { animation } = data;

        $("." + this.mainClass).removeClass(`animate__${this.entranceAnimation}`);
        $("." + this.mainClass).addClass(`animate__${animation}`);

        setTimeout(() => {
            $("." + this.mainClass).removeClass(`animate__${animation}`);
        }, 400);

    };

    sidelize() {

        $("." + this.mainClass).css({
            "width": "300px",
            "height": "100vh",
            "position": this.position,
            "right": "0",
            "top": "0"
        });

        this.currentState = "sidelized";

        if (this.onSidelize) this.onSidelize();
    };

    activate() {

        // const mouseResponse = $("." + this.mainClass).attr("data-smetio-do-not-respond");

        // console.log(" mouseResponse is ", mouseResponse);

        // if(mouseResponse === true) return false;

        $(".smetio-smartbox, .smetio-smartbox2").css("z-index","900").attr("data-smetio-do-not-respond", true);

        $("." + this.mainClass).css("z-index", "901").attr("data-smetio-do-not-respond", false);


        $("#smetio-app-ui-body-section-2").addClass("smetio-app-noLeftSideMenuSwipControl");

            
        // $(".smetio-smartbox, .smetio-smartbox2").css("z-index", "900");

            
        // this.show();

        if (this.onActivate) this.onActivate();

        setTimeout(() => {
            $(".smetio-smartbox, .smetio-smartbox2").attr("data-smetio-do-not-respond", false);
        }, 500);

    };

    reactivate() {
        this.normalize();
        this.activate();
    };

    deactivate() {

        $("#smetio-app-ui-body-section-2").removeClass("smetio-app-noLeftSideMenuSwipControl");

        // this.currentState = "deactivated";
        if (this.onDeactivate) return this.onDeactivate();

    };

    maximize() {

        $("." + this.mainClass).css({
            "width": "100vw",
            "height": "100vh",
            "position": this.position,
            "left": "0",
            "top": "-5px"
        });

        this.currentState = "maximize";

        //$("."+this.mainClass).attr("data-smetio-open-state","maximize");

        if (this.onMaximize) this.onMaximize();

    };

    minimize() {

        $("." + this.mainClass).css({
            "width": "200px",
            "height": "35px"
        });

        this.currentState = "minimize";

        //$("."+this.mainClass).attr("data-smetio-open-state","minimize");

        if (this.onMinimize) this.onMinimize();

    };

    close(data = {}) {

        $("." + this.mainClass).removeClass(this.entranceAnimation);
        $("." + this.mainClass).addClass(this.exitAnimation);
        this.currentState = "close";

        setTimeout(() => {

            $("." + this.mainClass).slideUp(400).remove();

            if (this.onClose) this.onClose(data);

        }, 100);

    };

    normalize() {

        $("." + this.mainClass).css({
            "width": "350px",
            "height": "500px"
        });

        this.currentState = "normalize";

        //$("."+this.mainClass).attr("data-smetio-open-state","normalize");

        if (this.onNormalize) this.onNormalize();

    };

    setHead() {

        this.smartboxHeadTitleTextAttributes["title"] = this.title;

        const headTitle = this.setTxt({
            attributes: this.smartboxHeadTitleTextAttributes,
            txt: this.title,
            type: "html"
        });

        this.head = `
        
            <div ${this.getAttributes(this.smartboxHeadAttributes, false)} >
            
                <div ${this.getAttributes(this.smartboxHeadTitleAttributes, false)} > 
                    
                    <h3 ${this.getAttributes(this.smartboxHeadTitleTextAttributes, false)} >
                        ${headTitle}
                    </h3>    
                    
                </div>
    
                <div ${this.getAttributes(this.smartboxHeadControlsAttributes, false)} > 
    
                    <div ${this.getAttributes(this.smartboxHeadControlLockToggleAttributes, false)} >
                        <i class="smetio-icon ${this.lockIcon}"></i>                     
                    </div>
    
                    <div ${this.getAttributes(this.smartboxHeadControlMaximizeAttributes, false)} >
                        <i class="smetio-icon smetio-icon-expand1"></i>                     
                    </div>
    
                    <div ${this.getAttributes(this.smartboxHeadControlNormalizeAttributes, false)} >
                        <i class="smetio-icon smetio-icon-compress"></i>                     
                    </div>
    
                    <div ${this.getAttributes(this.smartboxHeadControlMinimizeAttributes, false)} > 
                        <i class="smetio-icon smetio-icon-caret-down"></i>                     
                    </div>
                                                        
                    <div ${this.getAttributes(this.smartboxHeadControlCloseAttributes, false)} >
                        <i class="smetio-icon smetio-icon-close"></i>                     
                    </div>
                    
                </div>
            
            </div>
        `;

    };

    setBody() {

        // this.content = `
        // <div class="testingSmartBox" style="z-index:2000; width:400px; height:600px; max-height:600px; overflow:scroll; background-color:green;">
        //     ${this.content}
        // </div>
        // `


        this.body = `
        
            <div ${this.getAttributes(this.smartboxBodyAttributes, false)} >
    
                <div ${this.getAttributes(this.smartboxBodyContentHolderAttributes, false)} >            
                    ${this.content}
                </div>
            
            </div>
        
        `;

    };
    scrollToTop() {
        // console.log("Gladly scrolling to top ");
        $(`.${this.smartboxBodyContentHolderClass}, .${this.smartboxBodyClass}, .${this.mainClass}`).animate({ scrollTop: 0 }, "fast");
    };
    getCurrentContent() {
        return $("." + this.smartboxBodyContentHolderClass).html();
    };

    updateContent(newContent) {
        $("." + this.smartboxBodyContentHolderClass).html(newContent);
    };

    toggleLock() {

        if (this.lockState != "unlocked") {

            $("." + this.smartboxHeadControlLockToggleClass + " i").removeClass(this.unLockIcon);

            $("." + this.smartboxHeadControlLockToggleClass + " i").addClass(this.lockIcon);

            $("#" + this.id).draggable("enable");

            this.lockState = "unlocked";

            return false;

        };

        $("." + this.smartboxHeadControlLockToggleClass + " i").removeClass(this.lockIcon);

        $("." + this.smartboxHeadControlLockToggleClass + " i").addClass(this.unLockIcon);

        $("#" + this.id).draggable("disable");

        this.lockState = "locked";

    };

    hide() {
        $("." + this.mainClass).fadeOut(() => {
            $("." + this.mainClass).addClass(`animate__${this.entranceAnimation}`);
        });
        this.currentState = "hidden";
    };
    show() {
        $("." + this.mainClass).fadeIn();
        this.currentState = "visible";
    };

    render(receivingElem) {

        /**** this.getId(); MUST ALWAYS BE BEFORE setHead and setBody ****/
        $(".smetio-smartbox, .smetio-smartbox2").css("z-index","900").attr("data-smetio-do-not-respond", true);

        this.getId();

        this.setHead();
        this.setBody();


        // this.mainAttributes["data-smetio-entranceanimation"] = this.entranceAnimation;
        this.mainAttributes["data-smetio-exitanimation"] = this.exitAnimation;
        this.mainAttributes["data-smetio-open-state"] = this.openState;
        this.mainAttributes["data-smetio-transition"] = this.smetioTransition;
        this.mainAttributes["data-smetio-position"] = this.position;
        // this.mainAttributes["data-smetio-contenttype"] = this.contentType;
        // this.mainAttributes["data-smetio-contentuniqueidentifier"] = this.contentUniqueIdentifier;

        const output = `
        
            <div ${this.getAttributes(this.mainAttributes, false)} >        
    
                ${this.head}
                ${this.body}
            
            </div>
        `;



        // const output = `

        //     <div class="testingSmartBox" style="z-index:2000; width:400px; height:600px; max-height:600px; overflow:scroll; background-color:green;">
        //         ${this.content}
        //     </div>
        // `;

        /**** THE CODE BELOW MUST NOT BE REMOVED ****/

        $(this.appendTo).prepend(output);

            

        this.events();

        if (window.smetioVisitor.device.formFactor == "Mobile") {

            setTimeout(() => {

                this.maximize();
                this.toggleLock();

            }, 200);

        };


        $("#" + this.id).draggable({
            containment: this.appendTo,
            scroll: false,
            start: () => {
                if (this.onDragStart) this.onDragStart();
            },
            stop: () => {
                if (this.onDragStop) this.onDragStop();
            }
        });

        $("#" + this.id).resizable({
            containment: this.appendTo,
            start: () => {
                $(receivingElem).addClass("smetio-app-noLeftSideMenuSwipControl");
                $("#" + this.id).attr("data-smetio-transition", "no");

                if (this.onResizeStart) this.onResizeStart();
            },
            stop: () => {
                $(receivingElem).removeClass("smetio-app-noLeftSideMenuSwipControl");
                $("#" + this.id).attr("data-smetio-transition", "yes");
                if (this.onResizeStop) this.onResizeStop();
            },
        });


        this.hasBeenRendered = true;

        if (this.onRender) this.onRender();

        this.activate();

        // $(".smetio-smartbox, .smetio-smartbox2").css("z-index","900").attr("data-smetio-do-not-respond", true);
        // $("."+this.mainClass).css("z-index","901").attr("data-smetio-do-not-respond", false);
        // setTimeout(() => {
        //     $(".smetio-smartbox, .smetio-smartbox2").attr("data-smetio-do-not-respond", false);
        // }, 500);

    };

    events() {

        // $("body").on("mouseenter click", "." + this.mainClass + ", ." + this.mainClass + " *", () => {

        //       return this.activate();

        // });

        // $("body").on("mouseenter", "." + this.mainClass + ", ." + this.mainClass + " *", this.debounce(this.activate, 3000));

        $("body").on("click", "." + this.mainClass + ", ." + this.mainClass + " *", () => {
            this.activate();
        });


            

        // $("body").on("mouseout", "." + this.mainClass + ", ." + this.mainClass + " *", () => {

        //       return this.deactivate();

        // });


        $("body").on("mousedown", "." + this.smartboxHeadControlMaximizeClass, () => {

            return this.maximize();

        });


        $("body").on("mousedown", "." + this.smartboxHeadControlNormalizeClass, () => {

            return this.normalize();

        });


        $("body").on("mousedown", "." + this.smartboxHeadControlMinimizeClass, () => {

            return this.minimize();

        });


        $("body").on("mousedown", "." + this.smartboxHeadControlCloseClass, () => {

            return this.close();

        });

        $("body").on("mousedown", "." + this.smartboxHeadControlLockToggleClass, () => {

            return this.toggleLock();

        });



    };

};
