//CONSTANTS RELATED TO GEOLOCATION

export const SMETIO_GEOLOCATION_AVAILABLE = "geolocation available";

export const SMETIO_GEOLOCATION_UNAVAILABLE = "geolocation not available";

export const SMETIO_GEOLOCATION_TIMEOUT = "geolocation call ended in a timeout";

export const SMETIO_GEOLOCATION_UNKNOWN_ERROR = "An unknown error occured";

export const SMETIO_LOCATION_KNOWN = "LOCATION IS NOW KNOWN";

export const SMETIO_PERMISSION_DENIED = "PERMISSION DENIED";

export const SMETIO_POSITION_UNAVAILABLE = "POSITION UNAVAILABLE";



export const SMETIO_TIME_TO_WAIT_FOR_GEOLOCATION = 10;













