
export async function render(){

    this.getId(); 

    //this.texts[this.title] = true;
    //this.mainAttributes["title"] = window["smetioTexts"][this.title];
    //this.mainAttributes["class"].push("smetio-"+this.title+"-title");

    await this.setTxt({
        attributes:this.mainAttributes,
        txt: this.title,
        type:"title" 
    });	

    /**** 
     * THE ICON/this.icon IS PUSHED INTO THE CLASS BECAUSE THE ICON IS GENERATED BASED ON THE CLASS NAME 
     * ****/
    this.mainAttributes["class"].push("smetio-icon-"+this.icon);

    let output = `
        
        <i ${this.getAttributes(this.mainAttributes, false)}></i>
       
    `
    if(this.renderWithSpan) {

        output = `
            <span class="smetio-explannation-icon-holder">
                <i ${this.getAttributes(this.mainAttributes, false)}></i>
            </span>            
        `;
    }
   
    this.events();

    /**** THE CODE BELOW MUST NOT BE REMOVED ****/

    return output;



    
};