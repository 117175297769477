
export const SMETIO_ICONS = {

	delete: 		"trash-o",
	edit: 			"edit2",
	view: 			"eye5", 
	viewInput: 		"archive",
	copy:   		"copy2",
	deselect:		"checkbox-unchecked",
	select:			"checkbox-checked",
};

