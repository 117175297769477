import { isEmpty } from "./isEmpty";

export function addTextsToTextsInVeiw(txt){

/**** 
 * window.smetioVisitor.textsInView OBJECT REFERS TO THE TEXTS THAT USER CAN SEE AND/OR ENGAGE WITH AT 
 * THE CURRENT STATE OF THE APPLICATION. THESE TEXTS COULD BE html value, title, alt, placeholder etc
 *  	
 * 		STORING THIS IN THE window.smetioVisitor.textsInView OBJECT WILL MAKE IT EASIER TO CHANGE THE LANGAUGE OF THE 
 * 		VISIBLE TEXT FOR THE USER WITHOUT HAVING TO LOOP THROUGH ALL THE POSSIBLE TEXTS IN THE SMETIO
 * 		PLATFORM
 * 
 * THE TEXT HERE DOES NOT REFER TO THE ACTUAL TEXT LIKE "login to your account". IT REFERES TO THE NAME 
 * OF THE VARIABLE FOR EXAMPLE IT REFERS TO txt4 WHERE txt4: "Please try again later.",
 * 
 * THE txt* ARE ASSIGNED VALUES IN THE smetioTxt.js FILE BUT ALSO CAN BE DYNAMICALLY DOWNLOADED FROM THE SERVER 
 * THROUGH THE setTxt.js FILE
 * 
 * SOMETIMES A CLASS NEEDS TXT BUT THE window.smetioVisitor IS NOT YET RENDERED OR READY 
 * IN THESE CASES LOCAL STORAGE IS USED TO HOLD THE TEXTS IN VIEW AND 
 * THE window.smetioVisitor WILL THEN RETRIVE THE textInViewQueue WHEN IT IS READY/RENDERED
 * 
 * ****/

	

	if(!txt) return false;


	if(!window.smetioVisitor){

		let textInViewQueue = JSON.parse( localStorage.getItem("textInViewQueue") ) || {};

		if( isEmpty(textInViewQueue[txt]) ) textInViewQueue[txt] = 0;

		textInViewQueue[txt] += 1;

		localStorage.setItem( "textInViewQueue", JSON.stringify(textInViewQueue) );

		return true;

	};
	
	if( isEmpty(window.smetioVisitor["textsInView"] )) window.smetioVisitor["textsInView"] = {};

	if( isEmpty(window.smetioVisitor["textsInView"][txt]) ) window.smetioVisitor["textsInView"][txt] = 0;
	
	window.smetioVisitor["textsInView"][txt] += 1;

	return true;
	

};