


// //import "./styles/_main.scss";

// import { removeAllWhiteSpace } from "../../../../functions/remove/removeAllWhiteSpace.js";
import { SmetioAppsBasics } from "../../../smetioApps/smetioAppsBasics/v1.0.0/SmetioAppsBasics.js";

// import axios from "axios";
// import { SmetioPreloader } from "../../../smetioPreloader/SmetioPreloader.js";
// import { SmetioDataPresentation } from "../../../smetioDataPresentation/v1.0.0/SmetioDataPresentation.js";
// import { hide } from './../../../smetioOverlayPreloader/methods/hide';
// import { SmetioDataGroup } from "../../../smetioDataGroup/v1.0.0/SmetioDataGroup.js";
// import { SmetioPreloader } from "../../../smetioPreloader/SmetioPreloader.js";
// import axios from "axios";
// import personalDetails from "../../../../../../production/img/appImages/SVG/personalDetails.js";
// import personalDetails_test from "../../../../../../production/img/appImages/SVG/personalDetails_test.js";



export class Employee3ack extends SmetioAppsBasics {

      constructor(data = {
            appName: "",
            brandText: "",
            themeName: "",
            logo: "",
            sideMenuItems: "",
            brandName: "",
            language: "",
            apiEndPoint: "",
            accountType: "",
            useBrandNameNotLogo: false
      }) {

            super(data);

            this.appUserInterface  = "employee";
            window.appUserInterface = this.appUserInterface;

            this.filters = {
                  businessLocations: {
                        orderBy: "createdOn DESC",
                        limit: 20
                  },
                  profileDetails: {
                        limit: 20
                  },
                  teamMembers: {
                        limit: 20
                  },
                  treatments: {
                        limit: 20
                  }
      
            };

            this.itemsPerSection = {
                  businessLocations: 12,
                  profileDetails: 12,
                  teamMembers: 12,
                  treatments: 12
            };

            this.dataRetrieverEndpoint = {
            // businessLocations: `${window.apiEndPoint}/businessLocations/${this.language}`,
                  businessLocations:`${window.apiEndPoint}/apps/${this.appUserInterface }/businessLocations/retrieve/${this.language}`,
                  // profileDetails: `${window.apiEndPoint}/profileDetails/businessOwner/${this.language}`,
                  profileDetails: `${window.apiEndPoint}/apps/${this.appUserInterface }/profileDetails/retrieve/${this.language}`,
                  teamMembers: `${window.apiEndPoint}/apps/${this.appUserInterface }/teamMembers/retrieve/${this.language}`,
                  treatments: `${window.apiEndPoint}/apps/${this.appUserInterface }/treatments/retrieve/${this.language}`,
     
            // kyc: `${window.apiEndPoint}/teamMembers/businessOwner/${this.language}`
            };

            // const apiEndPoint = window.apiEndPoint;
            // const language = window.userLanguage;
  
    

            // this.appName = data.appName || "smetio_profile";
            // this.brandText = data.brandText || "";
            // this.themeName = data.themeName || "true-brand-light-100";
            // this.logo = data.logo || "img/3ack_logo.svg";
            // this.sideMenuItems = data.sideMenuItems || false;
            // this.brandName = data.brandName || "3ack";
            // this.useBrandNameNotLogo = data.useBrandNameNotLogo || false;
            // this.language = data.language || "en";

            // super();

      };


      setSideMenuItems(sideMenuItems) {
            this.sideMenuItems = sideMenuItems;
      };

      events() {


      };


};


