
export function events() {

      $("body").on("mousedown", ".smetio-legalPolicyNotification-accept", () => {

            return this.acceptLegalPolicies();

      });


      // $("body").on("mousedown", this.debounce(this.register, 5000));

      window.addEventListener("offline", (e) => {

            this.data.isOnline = false;

            return this.showOfflineNotification();

      });

      window.addEventListener("online", (e) => {

            this.data.isOnline = true;
            $(".smetio-offlineNotification").fadeOut();

      });

      // window.addEventListener("beforeunload", (event) => {

      //       /**** THE USER IS TRYING TO NAVIGATE AWAY FROM THE PAGE ****/

      //       return this.windowIsAboutToUnload(event);

      // });

      // window.addEventListener("unload", function (event) {

      //       /**** THE USER HAS NAVIGATED AWAY FROM THE PAGE ****/
      //       return this.windowIsAboutToUnload(event);

      // });

      document.addEventListener("visibilitychange", (e) => {

            /**** THIS MEANS THAT THE USER HAS NAVIGATED TO ANOTHER 
		 * TAB OR RETURN FROM ANOTHER TAB TO THE PAGE 
		 * 
		 * ****/

            return this.documentVisibilityHasChanged(e);

      });


      /**** 
	 * 
	 * ONLY REGISTER THE MOUSE POSITION IF THE USER STOP THE MOUSE FOR 
	 * AT LEAST 250 MILLISECONDS
	 * 
	 * ****/
      // $(document).on("mousemove", this.debounce(this.updateMousePosition, 250));

      /**** 
	 * 
	 * ONLY REGISTER THE SCROLL POSITION IF THE USER STOP SCROLLING FOR 
	 * AT LEAST 1000 MILLISECONDS
	 * 
	 * ****/
      // $(window).on("scroll", this.debounce(this.registerMouseAndScrollPosition, 1000));


      // $("body").on("click", "a", (event) => {

      //       event.preventDefault();
      //       return this.registerClickedLinks(event);

      //       // this.debounce((event)=>{
      //       //       return this.registerClickedLinks(event);
      //       // }, 500);
            

      // });


      // $("audio, video").on("play", this.debounce((event) => {

      //       return this.registerMultimediaInteractions(event, "played");

      // }, 1000));

      // $("audio, video").on("pause", this.debounce((event) => {

      //       return this.registerMultimediaInteractions(event, "paused");

      // }, 1000));

      // $("audio, video").on("ended", this.debounce((event) => {

      //       return this.registerMultimediaInteractions(event, "ended");

      // }, 1000));




      /*
	
	/var/smetio_private_code/cronJobs/smetioVisitors
	
		$("body").on("mousedown", "."+this.uploadFilesBtnClass, ()=>{
	
			$("."+this.uploadFilesInputClass).trigger("click");
	
		});	
	*/




};