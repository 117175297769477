export function toggleDisplay(element){

	const isVisible = $(element).attr("data-smetio-isVisible");

	if(isVisible == "no"){

		$(element).attr("data-smetio-isVisible", "yes");

		return false;

	};

	$(element).attr("data-smetio-isVisible", "no");

};