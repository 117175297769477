export function getParagraphs(paragraphArr){

	let output = "";

	for (var i = 0; i < paragraphArr.length; i++) {

		let newParagraphAttributes = {};
		newParagraphAttributes = {...this.paragraphAttributes, ...newParagraphAttributes};

		const text = this.setTxt({
			attributes:newParagraphAttributes,
			txt: paragraphArr[i],
			type:"html" 
		});		

		output += `

			<p ${this.getAttributes(newParagraphAttributes, false)} >

				${text}

			</p>

		`;
		
	}

	return output;

};