export function hasIndexDb(){

	try{
	
		return !!window.indexedDB;
	
	} catch(e) {
		
		return true;

	};

};