

export function randomGenerator(characters, characterLength){
	
	/*___THIS FUNCTION GENERATES RANDOM VALUES/CHARACTERS USING THE SET/S CHARACTERS AND LENGHT THAT IT HAS BEEN GIVEN ___*/
	
	let output = "";
	
	// GET THE LENGHT OF THE CHARACTERS TO BE USED FOR GENERATION
	let charactersLen = characters.length; 
	
	for(let i = 0; i < characterLength; i++){
		
		output += characters.charAt( Math.floor(Math.random() * charactersLen) );
		
	};
	
	return output;
	
	
};
