// import {setupRequest} from 
//     "./methods/setupRequest.js";

// import {sendRequest} from 
//     "./methods/sendRequest.js";

// import {resetParameters} from
//     "./methods/resetParameters.js";

// import {showMessage} from
//     "./methods/showMessage.js";

import { checkIfItIsEmpty } from "../../../app.js";
import { SmetioPreloader } from "../smetioPreloader/SmetioPreloader.js";
// import {createPreloader} from
//     "./methods/createPreloader.js";




/**** 

    THIS CLASS USED JQUERY AJAX TO MAKE ASYNC CONNECTION TO SERVER

****/

export class SmetioAjax {

    constructor() {

        this.preloader = "";
        this.request = null;
        this.showError = false;
        this.showSuccessMsg = false;

        this.resetParameters();

        this.createPreloader();

    };

    resetParameters() {

        /**** 
        
            THIS FUNCTION RESETS THE AJAX SETUP PARAMENTERS TO THE DEFAULT THAT IS DEFINED HERE
            
            THIS IS ALSO USED TO SET DEFAULT VALUES THAT CAN BE CHANGED FOR EVERY GIVEN INSTANCE AND/OR
            
            AFTER ANY GIVEN CONNECTION
        
        ****/

        this.connectionType = "POST";

        this.dataType = "json";

        this.cacheResult = false;

        this.timeOut = 3000;

        this.contentType = "application/x-www-form-urlencoded",

        this.processData = true,

        this.async = true,

        this.urlToConnectTo = "";

        this.dataToSendToServer = {};

        this.errorMsgHolder = "";

        this.defaultErrorMsg = `
             <h4>  An error occured while retrieving data. <strong>  Please try again </strong> </h4>
        `;

        this.xhrProgress = (event) => { return false; };


    };

    sendRequest() {

        this.setupRequest();

        $.ajax().done( (data)=>{

            console.log("@@@@@@@@@@@@@@@@ This is the ajax data ",data);
        });

    };

    showMessage( result = { msg: "", type: "danger", errorCode: "", affectedField: "" }) {

        return window.smetioVisitor.showNotification({
            title: result.title ||"txt111",
            type: result.type || "danger",
            body: [
                {
                    message: result.msg || "Process could not be completed due to error. Please try again.",
                    errorCode: result.errorCode || "",
                    affectedField: result.affectedField || ""
                }
            ]

        });

    };

    setupRequest() {

        // if(this.usefulData)

        this.request = $.ajaxSetup({

            type: this.connectionType || this.method,

            url: this.urlToConnectTo,

            data: this.usefulData ? this.dataToSendToServer[this.usefulData] : this.dataToSendToServer,

            dataType: this.dataType,

            cache: this.cacheResult,

            xhrFields: {
                // withCredentials: true
            },
            crossDomain: true,

            contentType: this.contentType,

            processData: this.processData,

            async: this.async,


            xhr: () => {

                this.xhr = new XMLHttpRequest();

                this.xhr.onprogress = (event) => {

                    return this.xhrProgress(event);

                };

                return this.xhr;

            },

            beforeSend: () => {

                if (this.beforeSend) this.beforeSend();

            },

            success: async (result) => {
                if(this.dataType == "json" && typeof result !== 'object') result = JSON.parse(result);
                if (result.error && this.showError) this.showMessage({msg:result.msg, type:"danger"})
                if(result.success && this.showSuccessMsg) this.showMessage({msg:result.msg, type:"success", title:"txt118"})
                if (this.success) await this.success(result);                
                this.resetParameters();

                if(result.redirect) location.href = result.url;
            },

            error: (request, status, error) => {

                if (this.error) this.error(request, status, error);
                if(this.showError) this.showMessage({msg:error, type:"danger"});
                this.resetParameters();

            },

            timeout: this.timeOut

        });

    };
    createPreloader() {

        this.preloader = new SmetioPreloader();

    };

};








