import { validateValueLength } from "./validateValueLength";
import { validate_email } from "./validate_email";
import { validate_phoneNumber } from "./validate_phoneNumber";

export function validate_smetioUsername(data) {
    
	/**** FIRST CHECK IF THE USERNAME IS A VALID EMAIL OR MOBILE NUMBER ****/

	const usernameIsValidEmail = validate_email(data);
	const usernameIsValidMobileNumber = validate_phoneNumber(data);

	if( usernameIsValidEmail !== true && usernameIsValidMobileNumber !== true) return "txt81";

	/**** CHECK THAT THE USERNAME IS NOT MORE THAN 50 CHARACTERS ****/	

	const lengthIsValid = validateValueLength(data, 2, 50);

	if( lengthIsValid !== true ) return lengthIsValid;

	return true;


};
