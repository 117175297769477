

import {getId} from "../../universal_methods/getId.js";  
import {setId} from "../../universal_methods/setId.js";
import {generateId} from "../../universal_methods/generateId.js";
import {isEmpty} from "../../universal_methods/isEmpty.js";  
import {getAttributes} from "../../universal_methods/getAttributes.js";  
import {toggleDisplay} from "../../universal_methods/toggleDisplay.js"; 
import {combineSmetioAttributes} from "../../universal_methods/combineSmetioAttributes.js";  
import { setTxt } from "../../universal_methods/setTxt.js";
import { changeFirstLetterToLowerCase } from "../../../functions/change/changeFirstLetterToLowerCase.js";

 




//import "./styles/_main.scss";



export class SmetioNotification{
    
    constructor(data={
        type: null, // OPTIONAL - "friendly" IS DEFAULT BUT CAN ALSO BE "success" OR "danger" 
        icon: null, // OPTIONAL - TAKEN FROM SMETIO ICONS 
        duration: null, // OPTIONAL - HOW THE NOTIFICATION SHOULD BE VISIBLE BEFORE DISAPPEARING
        isTimed: null, // OPTIONAL - WEATHER THE NOTIFICATION SHOULD DISAPPEAR AFTER THE SET TIME OR WAIT TILL THE USER CLOSES IT
        title: null, // THE TITLE OF THE NOTIFICATION 
        body: null, // COMPULSORY - IS AN ARRAY OF OBJECT AND EACH OBJECT MUST CONTAIN FOR EXAMPLE {message: "txt100", errorCode: 1} - SEE THE getBody FUNCTION FOR DETAILS   {
        id: null // OPTIONAL - USED TO INTEREACT WITH THE NOTIFICATION ELEMENT
    }){
        

        this.getId = getId;
        this.setId = setId;     
        this.generateId = generateId;
        this.isEmpty = isEmpty;
        this.getAttributes = getAttributes;
        this.setTxt = setTxt;
        this.toggleDisplay = toggleDisplay;
        this.combineSmetioAttributes = combineSmetioAttributes;
        this.changeFirstLetterToLowerCase = changeFirstLetterToLowerCase;

        

        this.noticeType = data.type || "friendly";
        this.icon = data.icon || window.smetioVisitor.smetioLogoIcon;
        this.duration = data.duration || 10000;
        this.isTimed = data.isTimed || true;	     
        this.title = data.title || "txt75";   

        /**** SEE getBody.js FOR INSTRUCTION ON HOW THE BODY SHOULD LOOK LIKE ****/
        this.body = data.body;

        this.id = data.id || this.setId();

        this.mainClass = "smetio-notification-"+this.id;
        this.mainAttributes = {
            id: this.id,
            class: [
                
                "smetio-notification",
            
                this.mainClass
            ],
        };     
        this.mainAttributes["data-smetio-noticeType"] = this.noticeType; 

        this.closeBtnClass = "smetio-notification-closeBtn-"+this.id;
        this.closeBtnAttributes = {

            class: [
                
                "smetio-notification-closeBtn",
            
                this.closeBtnClass
            ],
        };          
        
        this.titleClass = "smetio-notification-title-"+this.id;
        this.titleAttributes = {
          
            class: [
                
                "smetio-notification-title",
            
                this.titleClass
            ],
        };  
        this.titleAttributes["data-smetio-noticeType"] = this.noticeType;    
        

        this.bodyParagraphClass = "smetio-notification-bodyParagraph-"+this.id;
        this.bodyParagraphAttributes = {
          
            class: [
                
                "smetio-notification-bodyParagraph",
            
                this.bodyParagraphClass
            ],
        };  


    };

    close() {

        $("#"+this.id).fadeOut().remove();
    
        return false;
    };

    getTitle() {

        const titleText = this.setTxt({
            attributes:this.titleAttributes,
            txt: this.title,
            type:"html" 
        });	
    
        const output = `
            <h2 ${this.getAttributes(this.titleAttributes, false)}>
                ${titleText}
            </h2>    
        `;
    
        return output;
        
    };    

    getBody() {

        /**** 
         * this.body = [
         *      {
         *          message: "txt100", @COMPULSORY
         *          errorCode: 1 @OPTIONAL
         *      },
                {
         *          message: "txt100", @COMPULSORY
         *          errorCode: ""
         *      },* 
         * ];
         * ****/
        
        if( !$.isArray(this.body) ) throw "Smetio said notification body must be an array of object";
    
        let output = "";
    
        for( let i=0; i < this.body.length; i++ ){
    
            let textsAttributes = {};
            textsAttributes = {...this.bodyParagraphAttributes, textsAttributes};
    
            let affectedFieldAttributes = {};
            affectedFieldAttributes = {...this.bodyParagraphAttributes, affectedFieldAttributes};
            affectedFieldAttributes["class"].push("smetio-notification-affectedField");
            affectedFieldAttributes["data-smetio-noticeType"] = this.noticeType;
            
            let text = this.body[i]["message"];
    
            if( this.isEmpty(text) ) throw "Smetio said text must be provided";
    
            let affectedField = this.body[i]["affectedField"] || "";
            affectedField = this.setTxt({
                attributes:affectedFieldAttributes,
                txt: affectedField,
                type:"html" 
            });	
    
            text = this.setTxt({
                attributes:textsAttributes,
                txt: text,
                type:"html" 
            });	
    
            if( !this.isEmpty(affectedField) ) text = this.changeFirstLetterToLowerCase(text);
    
            const errorCode = this.body[i]["errorCode"] || "";
    
            output += `
            
                <p>
    
                    <span ${this.getAttributes(affectedFieldAttributes, false)}>
                        ${affectedField}
                    </span>            
                    <span ${this.getAttributes(textsAttributes, false)}>
                        ${text}
                    </span>
                    <span>${errorCode}</span>
                </p>
            `;
    
        };
    
        return output;
    
    };
        
    render(data={}){
    
        const title = this.getTitle();
        const body = this.getBody();
    
        const output =`
    
            <div ${this.getAttributes(this.mainAttributes, false)}>
    
                <img class="smetio-notification-icon" src="${this.icon}">
    
                <div ${this.getAttributes(this.closeBtnAttributes, false)} >
    
                    <i class="smetio-icon smetio-icon-close"></i>
    
                </div>
    
                ${title}
    
                <div class=" animate__animated animate__headShake smetio-notification-body">
    
                    ${body}
    
                </div>
    
            </div>		
    
        `;
    
        $("body").append(output);
    
        this.events();
    
        if(!this.isTimed) return false;
        
        setTimeout(() => {
    
            this.close();
    
        }, this.duration);    
    
        return false;
    
    };  
    
    events(){


        $("body").on("mousedown", "."+this.closeBtnClass, ()=>{
    
            this.close();
    
        });	
        
    
    };    
    

};
