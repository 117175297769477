import { sendDataToServer } from "./sendDataToServer";
import { SmetioAjax } from "../smetioAjax/SmetioAjax";

export async function examineModelValuesAndSendToServer(params={}) {

    if(!this.sendDataToServer) this.sendDataToServer = sendDataToServer;
    if(!this.SmetioAjax) this.SmetioAjax = new SmetioAjax();
    
    if(typeof this.SmetioAjax.dataToSendToServer !== "object" ) this.SmetioAjax.dataToSendToServer = {};

	for( let key in this.model ){
        
		if(!this.model[key]["details"] ) continue;

        if( this.model[key]["renderAsInput"] !== false) {

            const hasPassedValidation = await this.model[key]["inputClass"].valueHasChanged();
        
            if( hasPassedValidation !== true ) return false;
       
        };

        this.SmetioAjax.dataToSendToServer[key] = this.model[key]["details"]["sendToServer"];
        
	};    

    return this.sendDataToServer(params);


};
