

// import {getId} from "../../universal_methods/getId.js";  
// import {setId} from "../../universal_methods/setId.js";
// import {generateId} from "../../universal_methods/generateId.js";
// import {isEmpty} from "../../universal_methods/isEmpty.js";  
// import {getAttributes} from "../../universal_methods/getAttributes.js";  
// import {toggleDisplay} from "../../universal_methods/toggleDisplay.js"; 



import { SmetioPage } from "../../smetioPage/v1.0.0/SmetioPage";


//import "./styles/_main.scss";


export class SmetioCongratulationPage extends SmetioPage{

      constructor(data = {}) {

            super(data);

            this.modelNotNeeded = true;
            this.displayMessageAfterRender = true;
            this.mainClass = "smetio-congratulation-page" + this.id;
            this.mainAttributes = {
                  class: ["smetio-congratulation-page", this.mainClass],
            };

            // setTimeout(() => {

            //   this.displayCongratulationMessage();
      
            // }, this.defaultTimeout);

      };

      async displayMessage(){

            const getCongratulationHeading = (await import(/* webpackPrefetch: true */ "../../../functions/get/getCongratulationHeading")).getCongratulationHeading;
            const getParameterFromUrl = (await import(/* webpackPrefetch: true */ "../../../functions/get/getParameterFromUrl")).getParameterFromUrl;

            await this.getBaseUrl();

            const loginLink = `${this.baseUrl}/login/${this.language}`;
            const txt = getParameterFromUrl({paramName:"msg"});

            const congrateTeXT = await getCongratulationHeading({txt});

            const content = `
              <div class="smetio-margin-top-50 smetio-text-center "> 

                ${congrateTeXT}

                <h6 class="smetio-h6-heading smetio-text-center smetio-margin-top-30">
                  <a href="${loginLink}" target="_self" class="smetio-anchor-text " >
                    ${ this.setTxt({ attributes: {}, txt:"txt742", type: "html"}) }
                  </a>          
                </h6>

              </div>
            `;

            // console.log("Displaying good bye message...");

            this.changeRightSideUniqueContent(content);

      };

      events() {

            // $("body").on("mousedown", "."+this.uploadFilesBtnClass, ()=>{

            //     $("."+this.uploadFilesInputClass).trigger("click");

            // });	

      };

};
