export function combineSmetioAttributes(data=[]){

	let combinedClass = [];
	let output = {}

	for(var i = 0; i < data.length; i++){

		const attr = data[i];

		/****
		 *  EXTRACT THE CLASS FROM THE ATTRIBUTE AND STORE IT IN THE combinedClass ARRAY ABOVE 
		 * ****/
		const attrClass = attr["class"];

		if( $.isArray(attrClass) ){

			const newCombinedClass = [...combinedClass, ...attrClass];
			combinedClass = newCombinedClass;
		
		}else{

			combinedClass.push(attrClass);
		};

		/****
		 * EXTRACT OTHER ELEMENTS/PROPERTIES OF THE ATTRIBUTE OBJECT AND ADD THEM TO THE 
		 * OUTPUT ABOVE
		 *  ****/
		for (const key in attr) {

			if(key === "class") continue;

			const value = attr[key];
			
			output[key] = value;

		};

		//output = {...output, ...attr};

	};

	output["class"] = combinedClass;

	return output;
	
};