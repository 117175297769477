export function universal_handleErrorFromServer(result){

	if( $.isArray(result.message) ) throw "Smetio said Error from server cannot be an array contact backend developer";

	let affectedField = "";

	if( result.affectedField !== "notification" && this.model[result.affectedField] ){

		this.model[result.affectedField]["inputClass"].error = result.message;
		this.model[result.affectedField]["inputClass"].showError();

		affectedField = this.model[result.affectedField]["details"]["label"];

	};

	const title = result.title || "txt111";

	return window.smetioVisitor.showNotification({
		title: title,
		type: "danger",
		body:  [
			{
				message:result.message,
				errorCode: result.errorCode,
				affectedField:affectedField
			}
		]
		
	});	

	
};