//import {test_function} from "./../functions/test_function.js";



// import {getId} from "../../universal_methods/getId.js";  
// import {setId} from "../../universal_methods/setId.js";
// import {generateId} from "../../universal_methods/generateId.js";
// import {isEmpty} from "../../universal_methods/isEmpty.js";  
// import {getAttributes} from "../../universal_methods/getAttributes.js";  
// import {toggleDisplay} from "../../universal_methods/toggleDisplay.js"; 
// import {universal_handleErrorFromServer} from "../../universal_methods/universal_handleErrorFromServer.js";  
// import {combineSmetioAttributes} from "../../universal_methods/combineSmetioAttributes.js";  
// import { setTxt } from "../../universal_methods/setTxt.js";






// import {SmetioOverlayPreloader} from
//   "../../smetioOverlayPreloader/SmetioOverlayPreloader.js";  

// import {SmetioObjectToInput} from
//   "../../smetioObjectToInput/v1.0.0/SmetioObjectToInput.js";    


// import {SmetioAjax} from
//   "../../smetioAjax/SmetioAjax.js";   




import { validateHTMLColorHsl } from "validate-color";
import { validate_hslColor } from "../../../functions/validations/validate_hslColor";
//import "./styles/_main.scss";

import convert from 'color-convert';
import { copyToClipboard } from "../../../functions/copyAndPaste/copyToClipboard";

export class SmetioColorSchemeMaker {

  constructor(data) {

    this.reRender(data);

  };

  reRender(
    {
      accentColor = {
        code: "#3A6AAB",
        tintGap: 10,
        shadeGap: 10,
        matchBrand: false,
        gap: 10
      },
      dominantColor = {
        code: "#3A6AAB",
        tintGap: 10,
        shadeGap: 10,
        matchBrand: false,
        gap: 10
      },
      neutralColor = {
        code: "#3A6AAB",
        tintGap: 10,
        shadeGap: 10,
        matchBrand: false,
        gap: 10
      },
      greyColor = {
        code: "#3A6AAB",
        tintGap: 10,
        shadeGap: 10,
        matchBrand: false,
        gap: 10
      },
      warningColor = {
        code: "#3A6AAB",
        tintGap: 10,
        shadeGap: 10,
        matchBrand: false,
        gap: 10
      },
      successColor = {
        code: "#3A6AAB",
        tintGap: 10,
        shadeGap: 10,
        matchBrand: false,
        gap: 10
      },
      informationColor = {
        code: "#3A6AAB",
        tintGap: 10,
        shadeGap: 10,
        matchBrand: false,
        gap: 10
      },
      dangerColor = {
        code: "#3A6AAB",
        tintGap: 10,
        shadeGap: 10,
        matchBrand: false,
        gap: 10
      },
      prefix = "",
      gapBetweenShadesAndTints = "",
      adjustContrast = ""
    }
  ) {


    // const { accentColor, dominantColor, warningColor, successColor, informationColor, dangerColor, prefix, gapBetweenShadesAndTints, adjustContrast } = data;

    this.accentColor = accentColor.code || "#3A6AAB";
    this.dominantColor = dominantColor.code || "#A1A1A1";
    this.neutralColor = neutralColor.code || "#A1A1A1";
    this.greyColor = greyColor.code || "#A1A1A1";
    this.warningColor = warningColor.code || "#FBB856";
    this.successColor = successColor.code || "#39AA6A";
    this.informationColor = informationColor.code || "#3B8CAB";
    this.dangerColor = dangerColor.code || "#AB3A45";
    this.prefix = prefix || "smetio";
    this.gapBetweenShadesAndTints = gapBetweenShadesAndTints || 10;
    this.adjustContrast = adjustContrast == false ? false : true;
    this.opacityShiftForNeutralColors = 9;
    // this.neutralTransColorsCss = {};
    this.neutralAndTransColorsCss = {
      background: {
        // "smetio-neutral-background-plain": {
        //   "background-color": `var(--smetio-neutral-color)`
        // },
      },
      text: {
        // "smetio-neutral-text-plain ": {
        //   color: `var(--smetio-neutral-color)`
        // },
      },
      border: {
        // "smetio-neutral-border-plain": {
        //   border: `solid 1px var(--smetio-neutral-color)`
        // },
      },
      shadow: {
        // "smetio-neutral-shadow-around-plain": {
        //   "box-shadow": `0px 0px 10px 5px var(--smetio-neutral-color)`,
        //   "-webkit-box-shadow": `0px 0px 10px 5px var(--smetio-neutral-color)`
        // },
        // "smetio-neutral-shadow-bottom-plain": {
        //   "box-shadow": `0px 5px 10px 5px var(--smetio-neutral-color)`,
        //   "-webkit-box-shadow": `0px 5px 10px 5px var(--smetio-neutral-color)`
        // },
      },
    },

      // this.neutralAndTransColorsCss = {
      //   background: {
      //     "smetio-neutral-background-plain": {
      //       "background-color": `var(--smetio-dominant-color-rgb)`
      //     },
      //   },
      //   text: {
      //     "smetio-neutral-text": {
      //       color: `var(--smetio-dominant-color-rgb)`
      //     },
      //   },
      //   border: {
      //     "smetio-neutral-border": {
      //       border: `solid 1px var(--smetio-dominant-color-rgb)`
      //     },
      //   },
      //   shadow: {
      //     "smetio-neutral-shadow-around": {
      //       "box-shadow": `0px 0px 10px 5px var(--smetio-dominant-color-rgb)`,
      //       "-webkit-box-shadow": `0px 0px 10px 5px var(--smetio-dominant-color-rgb)`
      //     },
      //     "smetio-neutral-shadow-bottom": {
      //       "box-shadow": `0px 5px 10px 5px var(--smetio-dominant-color-rgb)`,
      //       "-webkit-box-shadow": `0px 5px 10px 5px var(--smetio-dominant-color-rgb)`
      //     },
      //   },
      // },

      this.colorsInHSL = {
        accentColor: validateHTMLColorHsl(this.accentColor) ? this.accentColor : convert.hex.hsl(this.accentColor),
        dominantColor: validateHTMLColorHsl(this.dominantColor) ? this.dominantColor : convert.hex.hsl(this.dominantColor),
        neutralColor: validateHTMLColorHsl(this.neutralColor) ? this.neutralColor : convert.hex.hsl(this.neutralColor),
        greyColor: validateHTMLColorHsl(this.greyColor) ? this.greyColor : convert.hex.hsl(this.greyColor),
        warningColor: validateHTMLColorHsl(this.warningColor) ? this.warningColor : convert.hex.hsl(this.warningColor),
        successColor: validateHTMLColorHsl(this.successColor) ? this.successColor : convert.hex.hsl(this.successColor),
        informationColor: validateHTMLColorHsl(this.informationColor) ? this.informationColor : convert.hex.hsl(this.informationColor),
        dangerColor: validateHTMLColorHsl(this.dangerColor) ? this.dangerColor : convert.hex.hsl(this.dangerColor)
      };

    this.interactiveProperties = ["link", "border", "icon"];
    this.cssPropertiesToSet = {
      button: ["background-color", "color"],
      link: ["color"],
      text: ["color"],
      icon: ["color"],
      background: ["background-color"],
      border: ["border"],
    };

    this.shadesAndTints = {
      accentColor: {
        cssName: "accent-color",
        readableName: "Accent color",
        uses: ["button", "link", "text", "background", "icon", "border"],
        gap: accentColor.gap,
        tintGap: accentColor.tintGap,
        shadeGap: accentColor.shadeGap,
        matchBrand: accentColor.matchBrand,
      },
      dominantColor: {
        cssName: "dominant-color",
        readableName: "Dominant color",
        uses: ["button", "link", "text", "background", "icon", "border"],
        createNeutrals: false,
        gap: dominantColor.gap,
        tintGap: dominantColor.tintGap,
        shadeGap: dominantColor.shadeGap,
        matchBrand: dominantColor.matchBrand,
      },
      neutralColor: {
        cssName: "neutral-color",
        readableName: "Neutral color",
        uses: ["background", "text"],
        createNeutrals: true,
        gap: neutralColor.gap,
        tintGap: neutralColor.tintGap,
        shadeGap: neutralColor.shadeGap,
        matchBrand: neutralColor.matchBrand,

      },
      greyColor: {
        cssName: "grey-color",
        readableName: "Grey color",
        uses: ["text", "background", "icon"],
        gap: greyColor.gap,
        tintGap: greyColor.tintGap,
        shadeGap: greyColor.shadeGap,
        matchBrand: greyColor.matchBrand,
      },
      warningColor: {
        cssName: "warning-color",
        readableName: "Warning color",
        uses: ["text", "background", "icon"],
        gap: warningColor.gap,
        tintGap: warningColor.tintGap,
        shadeGap: warningColor.shadeGap,
        matchBrand: warningColor.matchBrand,
      },
      successColor: {
        cssName: "success-color",
        readableName: "Success color",
        uses: ["text", "background", "icon"],
        gap: successColor.gap,
        tintGap: successColor.tintGap,
        shadeGap: successColor.shadeGap,
        matchBrand: successColor.matchBrand,
      },
      informationColor: {
        cssName: "information-color",
        readableName: "Information color",
        uses: ["text", "background", "icon"],
        gap: informationColor.gap,
        tintGap: informationColor.tintGap,
        shadeGap: informationColor.shadeGap,
        matchBrand: informationColor.matchBrand,
      },
      dangerColor: {
        cssName: "danger-color",
        readableName: "Danger color",
        uses: ["text", "background", "icon"],
        gap: dangerColor.gap,
        tintGap: dangerColor.tintGap,
        shadeGap: dangerColor.shadeGap,
        matchBrand: dangerColor.matchBrand,
      },
    };


    this.cssColorVariables = "";
    this.cssColorVariablesInPTag = "";

    this.themes = "";
    this.themesInTag = "";
    this.themeNames = [];

    this.cssClasses = {};
    this.cssClassesInTag = {};

    this.createShadesAndTints();


  };

  adjustColorFit(colorInHSL) {

    /*
      THE DIFFERENCE BETWEEN THE CONTRAST OR BRIGHTNESS OF THE ACCENT COLOR AND THE CONTRAST OR BRIGHTNESS OF THE DOMINANT AND SECONDARY COLORS MUST NOT BE MORE THAN 10
      FOR EXAMPLE IF ACCENT COLOR HAS HSL(231, 60, 50), BOTH DOMINANT COLOR AND SECONDARY COLORS SHOULD HAVE CONTRAST OR BRIGHTNESS AS FOLLOWS HLS(X, 50-70, 40-60)

    */

    // if (!this.adjustContrast) return false;


    const hue = colorInHSL[0];
    let contrast = colorInHSL[1];
    const brightness = colorInHSL[2];
    const [accentColorHue, accentColorContrast, accentColorBrightness] = this.colorsInHSL["accentColor"];

    if (Math.abs(accentColorContrast - contrast) > 10 && contrast > 5) {
      contrast = contrast > accentColorContrast ? accentColorContrast + 10 : accentColorContrast - 10;
      // contrast = accentColorContrast;
    }

    return [hue, contrast, brightness];

  };

  generateRgbaColorVariables({ colorInHSL = "", prefix = "", colorName }) {
    const opacityShift = this.opacityShiftForNeutralColors;
    const colorInRgb = convert.hsl.rgb(colorInHSL);


    let cssRgbaColorVariable = `
      --${prefix}-rgb: rgb(${colorInRgb[0]},${colorInRgb[1]},${colorInRgb[2]});
    `;
    let cssRgbaColorVariableInPTag = `<p>${cssRgbaColorVariable}</p>`

    for (let i = 1; i < opacityShift + 1; i++) {
      // const newShift = i <= 2 ? opacityShift * i : opacityShift * i * 2;
      const newShift = i * 10;
      const opacity = newShift / 100;

      const cssVariableDeclaration = `--${prefix}-rgba-${newShift}`;
      const newOutput = `
        ${cssVariableDeclaration}: rgba(${colorInRgb[0]},${colorInRgb[1]},${colorInRgb[2]},${opacity});
      `
      this.writeCssForNeutralTransColors({
        theNeutralColorCssVariable: cssVariableDeclaration,
        transNumber: newShift,
        colorName
      });
      cssRgbaColorVariable += newOutput;
      cssRgbaColorVariableInPTag += `<p>${newOutput}</p>`

    }


    return [cssRgbaColorVariable, cssRgbaColorVariableInPTag];
  }

  createCssTheme({ surfixNumber = 20, preferedThemeName = "" }) {
    const themeTypes = ["light", "dark"];

    let cssThemeOutput = "";
    preferedThemeName = !preferedThemeName ? "" : preferedThemeName + "-";

    for (let i = 0; i < themeTypes.length; i++) {
      const themeType = themeTypes[i];
      const themeName = preferedThemeName + themeType + "-" + surfixNumber;
      let themeDynamic = preferedThemeName.includes("plain") ? this.getPlainThemeDynamic({ theme: themeType }) : "";

      if (preferedThemeName.includes("true-brand")) themeDynamic = this.getTrueBrandedColorThemeDynamic({ theme: themeType });

      if (this.themes.includes(themeName)) return false;

      this.themeNames.push(themeName);

      const classes = this.writeCssClassForTheme({
        contrastNumber: surfixNumber,
        theme: themeType,
        themeDynamic
      });
      this.themesInTag += `
        <div>
          html[data-theme="${themeName}"]{
            ${classes["withTag"]}
          }
        </div>
      `;
      this.themes += `
        html[data-theme="${themeName}"]{
          ${classes["withoutTag"]}
        }
      `;
    }

  };


  writeCssForNeutralTransColors({ theNeutralColorCssVariable = "--smetio-dominant-color-rgba-5", transNumber = 5, colorName = "" }) {

    colorName = colorName ? colorName : "grey";

    this.neutralAndTransColorsCss["background"][`smetio-${colorName}-background-trans-${transNumber}`] = { "background-color": `var(${theNeutralColorCssVariable})` };

    // this.neutralAndTransColorsCss["text"]["smetio-grey-text-trans-" + transNumber] = { "color": `var(${theNeutralColorCssVariable})` };

    // this.neutralAndTransColorsCss["border"]["smetio-grey-border-trans-" + transNumber] = { "border": `solid 1px var(${theNeutralColorCssVariable})` };

    this.neutralAndTransColorsCss["shadow"][`smetio-${colorName}-shadow-around-trans-${transNumber}`] = { "box-shadow": `0px 0px 10px 5px var(${theNeutralColorCssVariable})`, "-webkit-box-shadow": `0px 0px 10px 5px var(${theNeutralColorCssVariable})` };

    this.neutralAndTransColorsCss["shadow"][`smetio-${colorName}-shadow-bottom-trans-${transNumber}`] = { "box-shadow": `0px 5px 10px 5px var(${theNeutralColorCssVariable})`, "-webkit-box-shadow": `0px 5px 10px 5px var(${theNeutralColorCssVariable})` };



    // this.neutralAndTransColorsCss["background"]["smetio-neutral-background-trans-" + transNumber] = { "background-color": `var(${theNeutralColorCssVariable})` };

    // this.neutralAndTransColorsCss["text"]["smetio-neutral-text-trans-" + transNumber] = { "color": `var(${theNeutralColorCssVariable})` };

    // this.neutralAndTransColorsCss["border"]["smetio-neutral-border-trans-" + transNumber] = { "border": `solid 1px var(${theNeutralColorCssVariable})` };

    // this.neutralAndTransColorsCss["shadow"]["smetio-neutral-shadow-around-trans-" + transNumber] = { "box-shadow": `0px 0px 10px 5px var(${theNeutralColorCssVariable})`, "-webkit-box-shadow": `0px 0px 10px 5px var(${theNeutralColorCssVariable})` };

    // this.neutralAndTransColorsCss["shadow"]["smetio-neutral-shadow-bottom-trans-" + transNumber] = { "box-shadow": `0px 5px 10px 5px var(${theNeutralColorCssVariable})`, "-webkit-box-shadow": `0px 5px 10px 5px var(${theNeutralColorCssVariable})` };


  }

  writeCssClassFromColorType({ colorType, output }) {

    output = !output ? { withTag: "", withoutTag: "" } : output;

    for (const components in colorType) {

      const component = colorType[components];

      for (const smetioClasses in component) {

        const smetioClass = component[smetioClasses];

        output["withoutTag"] += `.${smetioClasses}{`
        output["withTag"] += `<p>.${smetioClasses}{</p>`

        for (const cssProperty in smetioClass) {
          const cssValue = smetioClass[cssProperty];
          output["withoutTag"] += `${cssProperty}:${cssValue};`;
          output["withTag"] += `<p>${cssProperty}:${cssValue};</p>`;

          /* FOR SVG */
          if (cssProperty.includes("background-color")) {
            output["withoutTag"] += `fill:${cssValue};`;
            output["withTag"] += `<p>fill:${cssValue};</p>`;
          };

          if (cssProperty.includes("border-color")) {
            output["withoutTag"] += `stroke:${cssValue};`;
            output["withTag"] += `<p>stroke:${cssValue};</p>`;
          };

        };

        output["withoutTag"] += `}`;
        output["withTag"] += `<p> } </p>`;
      }

    }

    return output;
  };

  getTrueBrandedColorThemeDynamic({ contrastNumber = 100, theme = "light" }) {

    // THIS THEME USES THE ACTUAL BRAND COLOR NOT SHADES AND TINTS EXCEPT FOR DOMINANT COLOR
    // const shade = theme == "light" ? "shade" : "tint";
    // let tint = theme == "light" ? "tint" : "shade";

    // let accentcolor = theme == "light" ?  `var(--smetio-accent-color)` : `var(--smetio-accent-color-${tint}-${contrastNumber})`;

    console.log("GENERATING BRANDED THEME DYNAMIC *******", "And the theme is ", theme);

    const output = {
      accentColor: {
        background: {
          "smetio-accent-background": {
            "background-color": theme == "light" ? `var(--smetio-accent-color)` : `var(--smetio-accent-color-shade-100)`
          },
          "smetio-accent-background-reverse": {
            "background-color": theme == "light" ? `var(--smetio-accent-color-shade-100)` : `var(--smetio-accent-color-tint-100)`
          },
        },
        text: {
          "smetio-accent-text": {
            color: theme == "light" ? `var(--smetio-accent-color)` : `var(--smetio-accent-color-shade-100)`
          },
          "smetio-accent-text-reverse": {
            color: theme == "light" ? `var(--smetio-accent-color-shade-100)` : `var(--smetio-accent-color-tint-100)`
          },
        },
        button: {
          "smetio-accent-button": {
            "background-color": theme == "light" ? `var(--smetio-accent-color)` : `var(--smetio-accent-color-shade-100)`,
            color: theme == "light" ? `var(--smetio-accent-color-shade-100)` : `var(--smetio-accent-color-tint-100)`
          },
          "smetio-accent-button:hover": {
            "background-color": theme == "light" ? `var(--smetio-accent-color-shade-100)` : `var(--smetio-accent-color-tint-100)`,
            color: theme == "light" ? `var(--smetio-accent-color-tint-100)` : `var(--smetio-accent-color-shade-100)`
          },
          "smetio-accent-button:active": {
            "background-color": theme == "light" ? `var(--smetio-accent-color-tint-100)` : `var(--smetio-accent-color-shade-100)`,
            color: theme == "light" ? `var(--smetio-accent-color-shade-100)` : `var(--smetio-accent-color-tint-100)`
          },
          "smetio-accent-button[data-smetio-status='active']": {
            "background-color": theme == "light" ? `var(--smetio-accent-color-tint-100)` : `var(--smetio-accent-color-shade-100)`,
            color: theme == "light" ? `var(--smetio-accent-color-shade-100)` : `var(--smetio-accent-color-tint-100)`
          },
        },
        link: {
          "smetio-accent-link": {
            color: theme == "light" ? `var(--smetio-accent-color)` : `var(--smetio-accent-color-shade-100)`
          },
          "smetio-accent-link-interactive": {
            color: theme == "light" ? `var(--smetio-accent-color)` : `var(--smetio-accent-color-shade-100)`
          },
          "smetio-accent-link-interactive:hover": {
            color: theme == "light" ? `var(--smetio-accent-color-shade-100)` : `var(--smetio-accent-color-tint-100)`
          },
          "smetio-accent-link-interactive:active": {
            color: theme == "light" ? `var(--smetio-accent-color-shade-100)` : `var(--smetio-accent-color-tint-100)`
          },
        },
        icon: {
          "smetio-accent-icon": {
            color: theme == "light" ? `var(--smetio-accent-color)` : `var(--smetio-accent-color-shade-100)`
          },
          "smetio-accent-icon-interactive": {
            color: theme == "light" ? `var(--smetio-accent-color)` : `var(--smetio-accent-color-shade-100)`
          },
          "smetio-accent-icon-interactive:hover": {
            color: theme == "light" ? `var(--smetio-accent-color-shade-100)` : `var(--smetio-accent-color-tint-100)`
          },
          "smetio-accent-icon-interactive:active": {
            color: theme == "light" ? `var(--smetio-accent-color-shade-100)` : `var(--smetio-accent-color-tint-100)`
          },
        },
        border: {
          "smetio-accent-border": {
            border: `solid 1px var(--smetio-accent-color)`
          },
          "smetio-accent-border-interactive": {
            "border-color": `var(--smetio-accent-color)`
          },
          "smetio-accent-border-interactive:hover": {
            "border-color": theme == "light" ? `var(--smetio-accent-color-shade-100)` : `var(--smetio-accent-color-tint-100)`
          },
          "smetio-accent-border-interactive:active": {
            "border-color": `solid 1px var(--smetio-accent-color)`
          },
          "smetio-accent-border-interactive[data-smetio-status='active']": {
            "border-color": `solid 1px var(--smetio-accent-color)`
          },
        },

      },
      dominantColor: {
        background: {
          "smetio-dominant-background": {
            "background-color": theme == "light" ? `var(--smetio-dominant-color)` : `var(--smetio-dominant-color-shade-100)`
          },
          "smetio-dominant-background-reverse": {
            "background-color": theme == "light" ? `var(--smetio-dominant-color-shade-100)` : `var(--smetio-dominant-color-tint-100)`
          },
        },
        text: {
          "smetio-dominant-text": {
            color: theme == "light" ? `var(--smetio-dominant-color)` : `var(--smetio-dominant-color-shade-100)`
          },
          "smetio-dominant-text-reverse": {
            color: theme == "light" ? `var(--smetio-dominant-color-shade-100)` : `var(--smetio-dominant-color-tint-100)`
          },
        },
        border: {
          "smetio-dominant-border": {
            border: `solid 1px var(--smetio-dominant-color)`
          },
          "smetio-dominant-border-interactive": {
            "border-color": theme == "light" ? `var(--smetio-dominant-color)` : `var(--smetio-dominant-color-shade-100)`
          },
          "smetio-dominant-border-interactive:hover": {
            "border-color": theme == "light" ? `var(--smetio-dominant-color-shade-100)` : `var(--smetio-dominant-color-tint-100)`
          },
          "smetio-dominant-border-interactive:active": {
            "border-color": theme == "light" ? `var(--smetio-dominant-color-tint-100)` : `var(--smetio-dominant-color-shade-100)`
          },
          "smetio-dominant-border-interactive[data-smetio-status='active']": {
            "border-color": theme == "light" ? `var(--smetio-dominant-color-tint-100)` : `var(--smetio-dominant-color-shade-100)`
          },
        },
      },
      neutralColor: {
        background: {
          "smetio-neutral-background": {
            "background-color": theme === "light" ? `var(--smetio-neutral-color)` : `var(--smetio-neutral-color-shade-100)`
          },
          "smetio-neutral-background-reverse": {
            "background-color": theme === "light" ? `var(--smetio-neutral-color-tint-100)` : `var(--smetio-neutral-color-shade-100)`
          },
        },
        text: {
          "smetio-neutral-text": {
            color: theme === "light" ? `var(--smetio-neutral-color)` : `var(--smetio-neutral-color-tint-100)`
          },
          "smetio-neutral-text-reverse": {
            color: theme === "light" ? `var(--smetio-neutral-color-shade-100)` : `var(--smetio-neutral-color)`
          },
        },
        border: {
          "smetio-neutral-border": {
            border: `solid 1px`,
            "border-color": theme === "light" ? `var(--smetio-neutral-color)` : `var(--smetio-neutral-color-tint-100)`
          }
        },
      },
      greyColor: {
        background: {
          "smetio-grey-background": {
            "background-color": `var(--smetio-grey-color)`
          },
          "smetio-grey-background-reverse": {
            "background-color": theme === "light" ? `var(--smetio-grey-color-tint-100)` : `var(--smetio-grey-color-shade-100)`
          },
        },
        text: {
          "smetio-grey-text": {
            color: theme === "light" ? `var(--smetio-grey-color-shade-100)` : `var(--smetio-grey-color-tint-100)`
          },
          "smetio-grey-text-reverse": {
            color: theme === "light" ? `var(--smetio-grey-color-tint-100)` : `var(--smetio-grey-color-shade-100)`
          },
        },
        border: {
          "smetio-grey-border": {
            border: `solid 1px var(--smetio-grey-color)`,
            "border-color": theme === "light" ? `var(--smetio-grey-color-shade-100)` : `var(--smetio-grey-color-tint-100)`
          }
        },
      },
      warningColor: {
        background: {
          "smetio-warning-background": {
            "background-color": theme == "light" ? `var(--smetio-warning-color)` : `var(--smetio-warning-color-shade-100)`
          },
          "smetio-warning-background-reverse": {
            "background-color": theme == "light" ? `var(--smetio-warning-color-shade-100)` : `var(--smetio-warning-color-tint-100)`
          },
        },
        text: {
          "smetio-warning-text": {
            color: theme == "light" ? `var(--smetio-warning-color) ` : `var(--smetio-warning-color-tint-100)`
          },
          "smetio-warning-text-reverse": {
            color: theme == "light" ? `var(--smetio-warning-color-shade-100)` : `var(--smetio-warning-color)`
          },
        },
        icon: {
          "smetio-warning-icon": {
            color: theme == "light" ? `var(--smetio-warning-color)` : `var(--smetio-warning-color-shade-100)`
          },
          "smetio-warning-icon-interactive": {
            color: theme == "light" ? `var(--smetio-warning-color-shade-100)` : `var(--smetio-warning-color-tint-100)`
          },
          "smetio-warning-icon-interactive:hover": {
            color: theme == "light" ? `var(--smetio-warning-color-shade-100)` : `var(--smetio-warning-color-tint-100)`
          },
          "smetio-warning-icon-interactive:active": {
            color: theme == "light" ? `var(--smetio-warning-color-tint-100)` : `var(--smetio-warning-color-shade-100)`
          },
        },
        border: {
          "smetio-warning-border": {
            border: `solid 1px var(--smetio-warning-color)`
          },
        }
      },
      successColor: {

        background: {
          "smetio-success-background": {
            "background-color": theme == "light" ? `var(--smetio-success-color)` : `var(--smetio-success-color-shade-100)`
          },
          "smetio-success-background-reverse": {
            "background-color": theme == "light" ? `var(--smetio-success-color-shade-100)` : `var(--smetio-success-color-tint-100)`
          },
        },
        text: {
          "smetio-success-text": {
            color: theme == "light" ? `var(--smetio-success-color)` : `var(--smetio-success-color-tint-100)`
          },
          "smetio-success-text-reverse": {
            color: theme == "light" ? `var(--smetio-success-color-shade-100)` : `var(--smetio-success-color)`
          },
        },
        icon: {
          "smetio-success-icon": {
            color: theme == "light" ? `var(--smetio-success-color)` : `var(--smetio-success-color-tint-100)`
          },
          "smetio-success-icon-interactive": {
            color: theme == "light" ? `var(--smetio-success-color)` : `var(--smetio-success-color-tint-100)`
          },
          "smetio-success-icon-interactive:hover": {
            color: theme == "light" ? `var(--smetio-success-color-shade-100)` : `var(--smetio-success-color-tint-100)`
          },
          "smetio-success-icon-interactive:active": {
            color: theme == "light" ? `var(--smetio-success-color-tint-100)` : `var(--smetio-success-color-shade-100)`
          },
        },
        border: {
          "smetio-success-border": {
            border: `solid 1px var(--smetio-success-color)`,
            "border-color": theme == "light" ? `var(--smetio-success-color)` : `var(--smetio-success-color-tint-100)`
          },
        }
      },
      informationColor: {

        background: {
          "smetio-information-background": {
            "background-color": theme == "light" ? `var(--smetio-information-color)` : `var(--smetio-information-color-shade-100)`
          },
          "smetio-information-background-reverse": {
            "background-color": theme == "light" ? `var(--smetio-information-color-shade-100)` : `var(--smetio-information-color-tint-100)`
          },
        },
        text: {
          "smetio-information-text": {
            color: theme == "light" ? `var(--smetio-information-color)` : `var(--smetio-information-color-tint-100)`
          },
          "smetio-information-text-reverse": {
            color: theme == "light" ? `var(--smetio-information-color-shade-100)` : `var(--smetio-information-color)`
          },
        },
        icon: {
          "smetio-information-icon": {
            color: theme == "light" ? `var(--smetio-information-color)` : `var(--smetio-information-color-tint-100)`
          },
          "smetio-information-icon-interactive": {
            color: theme == "light" ? `var(--smetio-information-color)` : `var(--smetio-information-color-tint-100)`
          },
          "smetio-information-icon-interactive:hover": {
            color: theme == "light" ? `var(--smetio-information-color-shade-100)` : `var(--smetio-information-color-tint-100)`
          },
          "smetio-information-icon-interactive:active": {
            color: theme == "light" ? `var(--smetio-information-color-tint-100)` : `var(--smetio-information-color-shade-100)`
          },
        },
        border: {
          "smetio-information-border": {
            border: `solid 1px var(--smetio-information-color)`,
            "border-color": theme == "light" ? `var(--smetio-information-color)` : `var(--smetio-information-color-tint-100)`
          },
        }
      },
      dangerColor: {

        background: {
          "smetio-danger-background": {
            "background-color": theme == "light" ? `var(--smetio-danger-color)` : `var(--smetio-danger-color-shade-100)`
          },
          "smetio-danger-background-reverse": {
            "background-color": theme == "light" ? `var(--smetio-danger-color-shade-100)` : `var(--smetio-danger-color-tint-100)`
          },
        },
        text: {
          "smetio-danger-text": {
            color: theme == "light" ? `var(--smetio-danger-color)` : `var(--smetio-danger-color-tint-100)`
          },
          "smetio-danger-text-reverse": {
            color: theme == "light" ? `var(--smetio-danger-color-shade-100)` : `var(--smetio-danger-color)`
          },
        },
        icon: {
          "smetio-danger-icon": {
            color: theme == "light" ? `var(--smetio-danger-color)` : `var(--smetio-danger-color-tint-100)`
          },
          "smetio-danger-icon-interactive": {
            color: theme == "light" ? `var(--smetio-danger-color)` : `var(--smetio-danger-color-tint-100)`
          },
          "smetio-danger-icon-interactive:hover": {
            color: theme == "light" ? `var(--smetio-danger-color-shade-100)` : `var(--smetio-danger-color-tint-100)`
          },
          "smetio-danger-icon-interactive:active": {
            color: theme == "light" ? `var(--smetio-danger-color-tint-100)` : `var(--smetio-danger-color-shade-100)`
          },
        },
        border: {
          "smetio-danger-border": {
            border: `solid 1px var(--smetio-danger-color)`,
            "border-color": theme == "light" ? `var(--smetio-danger-color)` : `var(--smetio-danger-color-tint-100)`
          },
        }
      },
    };



    // const output = {
    //   accentColor: {
    //     background: {
    //       "smetio-accent-background": {
    //         "background-color": accentcolor
    //       },
    //       "smetio-accent-background-reverse": {
    //         "background-color": `var(--smetio-accent-color-${tint}-${contrastNumber})`
    //       },
    //     },
    //     text: {
    //       "smetio-accent-text": {
    //         color: `var(--smetio-accent-color)`
    //       },
    //       "smetio-accent-text-reverse": {
    //         color: `var(--smetio-accent-color-${shade}-${contrastNumber})`
    //       },
    //     },
    //     button: {
    //       "smetio-accent-button": {
    //         "background-color": `var(--smetio-accent-color)`,
    //         color: `var(--smetio-dominant-color-${tint}-${contrastNumber})`
    //       },
    //       "smetio-accent-button:hover": {
    //         "background-color": `var(--smetio-accent-color-${tint}-${contrastNumber})`,
    //         color: `var(--smetio-dominant-color-${shade}-${contrastNumber})`
    //       },
    //       "smetio-accent-button:active": {
    //         "background-color": `var(--smetio-accent-color-${shade}-${contrastNumber})`,
    //         color: `var(--smetio-dominant-color-${tint}-${contrastNumber})`
    //       },
    //       "smetio-accent-button[data-smetio-status='active']": {
    //         "background-color": `var(--smetio-dominant-color-${tint}-${contrastNumber})`,
    //         color: `var(--smetio-accent-color-${shade}-${contrastNumber})`
    //       },
    //     },
    //     link: {
    //       "smetio-accent-link": {
    //         color: `var(--smetio-accent-color)`
    //       },
    //       "smetio-accent-link-interactive": {
    //         color: `var(--smetio-accent-color)`
    //       },
    //       "smetio-accent-link-interactive:hover": {
    //         color: `var(--smetio-accent-color-${shade}-${contrastNumber})`
    //       },
    //       "smetio-accent-link-interactive:active": {
    //         color: `var(--smetio-accent-color)`
    //       },
    //     },
    //     icon: {
    //       "smetio-accent-icon": {
    //         color: `var(--smetio-accent-color)`
    //       },
    //       "smetio-accent-icon-interactive": {
    //         color: `var(--smetio-accent-color)`
    //       },
    //       "smetio-accent-icon-interactive:hover": {
    //         color: `var(--smetio-accent-color-${shade}-${contrastNumber})`
    //       },
    //       "smetio-accent-icon-interactive:active": {
    //         color: `var(--smetio-accent-color)`
    //       },
    //     },
    //     border: {
    //       "smetio-accent-border": {
    //         border: `solid 1px var(--smetio-accent-color)`
    //       },
    //       "smetio-accent-border-interactive": {
    //         "border-color": `var(--smetio-accent-color)`
    //       },
    //       "smetio-accent-border-interactive:hover": {
    //         "border-color": `solid 1px var(--smetio-accent-color-${shade}-${contrastNumber})`
    //       },
    //       "smetio-accent-border-interactive:active": {
    //         "border-color": `solid 1px var(--smetio-accent-color)`
    //       },
    //       "smetio-accent-border-interactive[data-smetio-status='active']": {
    //         "border-color": `solid 1px var(--smetio-accent-color)`
    //       },
    //     },

    //   },
    //   dominantColor: {
    //     background: {
    //       "smetio-dominant-background": {
    //         "background-color": `var(--smetio-dominant-color-${tint}-${contrastNumber})`
    //       },
    //       "smetio-dominant-background-reverse": {
    //         "background-color": `var(--smetio-dominant-color-${shade}-${contrastNumber})`
    //       },
    //     },
    //     text: {
    //       "smetio-dominant-text": {
    //         color: `var(--smetio-dominant-color-${shade}-${contrastNumber})`
    //       },
    //       "smetio-dominant-text-reverse": {
    //         color: `var(--smetio-dominant-color-${tint}-${contrastNumber})`
    //       },
    //     },
    //     border: {
    //       "smetio-dominant-border": {
    //         border: `solid 1px var(--smetio-dominant-color)`
    //       },
    //       "smetio-dominant-border-interactive": {
    //         "border-color": `var(--smetio-dominant-color)`
    //       },
    //       "smetio-dominant-border-interactive:hover": {
    //         "border-color": `solid 1px var(--smetio-dominant-color-${shade}-${contrastNumber})`
    //       },
    //       "smetio-dominant-border-interactive:active": {
    //         "border-color": `solid 1px var(--smetio-dominant-color)`
    //       },
    //       "smetio-dominant-border-interactive[data-smetio-status='active']": {
    //         "border-color": `solid 1px var(--smetio-dominant-color)`
    //       },
    //     },
    //   },
    //   neutralColor: {
    //     background: {
    //       "smetio-neutral-background": {
    //         "background-color": `var(--smetio-neutral-color-${tint}-${contrastNumber})`
    //       },
    //       "smetio-neutral-background-reverse": {
    //         "background-color": `var(--smetio-neutral-color-${shade}-${contrastNumber})`
    //       },
    //     },
    //     text: {
    //       "smetio-neutral-text": {
    //         color: `var(--smetio-neutral-color-${shade}-${contrastNumber})`
    //       },
    //       "smetio-neutral-text-reverse": {
    //         color: `var(--smetio-neutral-color-${tint}-${contrastNumber})`
    //       },
    //     }
    //   },
    //   warningColor: {
    //     background: {
    //       "smetio-warning-background": {
    //         "background-color": `var(--smetio-warning-color)`
    //       },
    //       "smetio-warning-background-reverse": {
    //         "background-color": `var(--smetio-warning-color-${shade}-${contrastNumber})`
    //       },
    //     },
    //     text: {
    //       "smetio-warning-text": {
    //         color: `var(--smetio-warning-color)`
    //       },
    //       "smetio-warning-text-reverse": {
    //         color: `var(--smetio-warning-color-${tint}-${contrastNumber})`
    //       },
    //     },
    //     icon: {
    //       "smetio-warning-icon": {
    //         color: `var(--smetio-warning-color)`
    //       },
    //       "smetio-warning-icon-interactive": {
    //         color: `var(--smetio-warning-color)`
    //       },
    //       "smetio-warning-icon-interactive:hover": {
    //         color: `var(--smetio-warning-color-${tint}-${contrastNumber})`
    //       },
    //       "smetio-warning-icon-interactive:active": {
    //         color: `var(--smetio-warning-color)`
    //       },
    //     },
    //     border: {
    //       "smetio-warning-border": {
    //         border: `solid 1px var(--smetio-warning-color)`
    //       },
    //     }
    //   },
    //   successColor: {
    //     background: {
    //       "smetio-success-background": {
    //         "background-color": `var(--smetio-success-color)`
    //       },
    //       "smetio-success-background-reverse": {
    //         "background-color": `var(--smetio-success-color-${shade}-${contrastNumber})`
    //       },
    //     },
    //     text: {
    //       "smetio-success-text": {
    //         color: `var(--smetio-success-color)`
    //       },
    //       "smetio-success-text-reverse": {
    //         color: `var(--smetio-success-color-${tint}-${contrastNumber})`
    //       },
    //     },
    //     icon: {
    //       "smetio-success-icon": {
    //         color: `var(--smetio-success-color)`
    //       },
    //       "smetio-success-icon-interactive": {
    //         color: `var(--smetio-success-color)`
    //       },
    //       "smetio-success-icon-interactive:hover": {
    //         color: `var(--smetio-success-color-${tint}-${contrastNumber})`
    //       },
    //       "smetio-success-icon-interactive:active": {
    //         color: `var(--smetio-success-color)`
    //       },
    //     },
    //     border: {
    //       "smetio-success-border": {
    //         border: `solid 1px var(--smetio-success-color)`
    //       },
    //     }
    //   },
    //   informationColor: {
    //     background: {
    //       "smetio-information-background": {
    //         "background-color": `var(--smetio-information-color)`
    //       },
    //       "smetio-information-background-reverse": {
    //         "background-color": `var(--smetio-information-color-${shade}-${contrastNumber})`
    //       },
    //     },
    //     text: {
    //       "smetio-information-text": {
    //         color: `var(--smetio-information-color)`
    //       },
    //       "smetio-information-text-reverse": {
    //         color: `var(--smetio-information-color-${tint}-${contrastNumber})`
    //       },
    //     },
    //     icon: {
    //       "smetio-information-icon": {
    //         color: `var(--smetio-information-color)`
    //       },
    //       "smetio-information-icon-interactive": {
    //         color: `var(--smetio-information-color)`
    //       },
    //       "smetio-information-icon-interactive:hover": {
    //         color: `var(--smetio-information-color-${tint}-${contrastNumber})`
    //       },
    //       "smetio-information-icon-interactive:active": {
    //         color: `var(--smetio-information-color)`
    //       },
    //     },
    //     border: {
    //       "smetio-information-border": {
    //         border: `solid 1px var(--smetio-information-color)`
    //       },
    //     }
    //   },
    //   dangerColor: {
    //     background: {
    //       "smetio-danger-background": {
    //         "background-color": `var(--smetio-danger-color)`
    //       },
    //       "smetio-danger-background-reverse": {
    //         "background-color": `var(--smetio-danger-color)`
    //       },
    //     },
    //     text: {
    //       "smetio-danger-text": {
    //         color: `var(--smetio-danger-color)`
    //       },
    //       "smetio-danger-text-reverse": {
    //         color: `var(--smetio-danger-color-${tint}-${contrastNumber})`
    //       },
    //     },
    //     icon: {
    //       "smetio-danger-icon": {
    //         color: `var(--smetio-danger-color)`
    //       },
    //       "smetio-danger-icon-interactive": {
    //         color: `var(--smetio-danger-color)`
    //       },
    //       "smetio-danger-icon-interactive:hover": {
    //         color: `var(--smetio-danger-color-${tint}-${contrastNumber})`
    //       },
    //       "smetio-danger-icon-interactive:active": {
    //         color: `var(--smetio-danger-color)`
    //       },
    //     },
    //     border: {
    //       "smetio-danger-border": {
    //         border: `solid 1px var(--smetio-danger-color)`
    //       }
    //     }
    //   },
    // };

    return output;
  };
  getBrandedThemeDynamic({ contrastNumber = 100, theme = "light" }) {

    const shade = theme == "light" ? "shade" : "tint";
    let tint = theme == "light" ? "tint" : "shade";



    // const backgroundColor = theme == "light" ? `var(--smetio-accent-color)` : `var(--smetio-accent-color-shade-100)`;
    // const brandedThemeDynamic = {
    //   accentColor: {
    //     background: {
    //       "smetio-accent-background": {
    //         "background-color": theme == "light" ? `var(--smetio-accent-color)` : `var(--smetio-accent-color-shade-100)`
    //       },
    //       "smetio-accent-background-reverse": {
    //         "background-color": theme == "light" ? `var(--smetio-accent-color-shade-100)` : `var(--smetio-accent-color-tint-100)`
    //       },
    //     },
    //     text: {
    //       "smetio-accent-text": {
    //         color: theme == "light" ? `var(--smetio-accent-color)` : `var(--smetio-accent-color-shade-100)`
    //       },
    //       "smetio-accent-text-reverse": {
    //         color: theme == "light" ? `var(--smetio-accent-color-shade-100)` : `var(--smetio-accent-color-tint-100)`
    //       },
    //     },
    //     button: {
    //       "smetio-accent-button": {
    //         "background-color": theme == "light" ? `var(--smetio-accent-color)` : `var(--smetio-accent-color-shade-100)`,
    //         color: theme == "light" ? `var(--smetio-accent-color-shade-100)` : `var(--smetio-accent-color-tint-100)`
    //       },
    //       "smetio-accent-button:hover": {
    //         "background-color": theme == "light" ? `var(--smetio-accent-color-shade-100)` : `var(--smetio-accent-color-tint-100)`,
    //         color: theme == "light" ? `var(--smetio-accent-color-tint-100)` : `var(--smetio-accent-color-shade-100)`
    //       },
    //       "smetio-accent-button:active": {
    //         "background-color": theme == "light" ? `var(--smetio-accent-color-tint-100)` : `var(--smetio-accent-color-shade-100)`,
    //         color: theme == "light" ? `var(--smetio-accent-color-shade-100)` : `var(--smetio-accent-color-tint-100)`
    //       },
    //       "smetio-accent-button[data-smetio-status='active']": {
    //         "background-color": theme == "light" ? `var(--smetio-accent-color-tint-100)` : `var(--smetio-accent-color-shade-100)`,
    //         color: theme == "light" ? `var(--smetio-accent-color-shade-100)` : `var(--smetio-accent-color-tint-100)`
    //       },
    //     },
    //     link: {
    //       "smetio-accent-link": {
    //         color: theme == "light" ? `var(--smetio-accent-color)` : `var(--smetio-accent-color-shade-100)`
    //       },
    //       "smetio-accent-link-interactive": {
    //         color: theme == "light" ? `var(--smetio-accent-color)` : `var(--smetio-accent-color-shade-100)`
    //       },
    //       "smetio-accent-link-interactive:hover": {
    //         color: theme == "light" ? `var(--smetio-accent-color-shade-100)` : `var(--smetio-accent-color-tint-100)`
    //       },
    //       "smetio-accent-link-interactive:active": {
    //         color: theme == "light" ? `var(--smetio-accent-color-shade-100)` : `var(--smetio-accent-color-tint-100)`
    //       },
    //     },
    //     icon: {
    //       "smetio-accent-icon": {
    //         color: theme == "light" ? `var(--smetio-accent-color)` : `var(--smetio-accent-color-shade-100)`
    //       },
    //       "smetio-accent-icon-interactive": {
    //         color: theme == "light" ? `var(--smetio-accent-color)` : `var(--smetio-accent-color-shade-100)`
    //       },
    //       "smetio-accent-icon-interactive:hover": {
    //         color: theme == "light" ? `var(--smetio-accent-color-shade-100)` : `var(--smetio-accent-color-tint-100)`
    //       },
    //       "smetio-accent-icon-interactive:active": {
    //         color: theme == "light" ? `var(--smetio-accent-color-shade-100)` : `var(--smetio-accent-color-tint-100)`
    //       },
    //     },
    //     border: {
    //       "smetio-accent-border": {
    //         border: `solid 1px var(--smetio-accent-color)`
    //       },
    //       "smetio-accent-border-interactive": {
    //         "border-color": `var(--smetio-accent-color)`
    //       },
    //       "smetio-accent-border-interactive:hover": {
    //         "border-color": theme == "light" ? `var(--smetio-accent-color-shade-100)` : `var(--smetio-accent-color-tint-100)`
    //       },
    //       "smetio-accent-border-interactive:active": {
    //         "border-color": `solid 1px var(--smetio-accent-color)`
    //       },
    //       "smetio-accent-border-interactive[data-smetio-status='active']": {
    //         "border-color": `solid 1px var(--smetio-accent-color)`
    //       },
    //     },

    //   },
    //   dominantColor: {
    //     background: {
    //       "smetio-dominant-background": {
    //         "background-color": theme == "light" ? `var(--smetio-dominant-color)` : `var(--smetio-dominant-color-shade-100)`
    //       },
    //       "smetio-dominant-background-reverse": {
    //         "background-color": theme == "light" ? `var(--smetio-dominant-color-shade-100)` : `var(--smetio-dominant-color-tint-100)`
    //       },
    //     },
    //     text: {
    //       "smetio-dominant-text": {
    //         color: theme == "light" ? `var(--smetio-dominant-color)` : `var(--smetio-dominant-color-shade-100)`
    //       },
    //       "smetio-dominant-text-reverse": {
    //         color: theme == "light" ? `var(--smetio-dominant-color-shade-100)` : `var(--smetio-dominant-color-tint-100)`
    //       },
    //     },
    //     border: {
    //       "smetio-dominant-border": {
    //         border: `solid 1px var(--smetio-dominant-color)`
    //       },
    //       "smetio-dominant-border-interactive": {
    //         "border-color": theme == "light" ? `var(--smetio-dominant-color)` : `var(--smetio-dominant-color-shade-100)`
    //       },
    //       "smetio-dominant-border-interactive:hover": {
    //         "border-color": theme == "light" ? `var(--smetio-dominant-color-shade-100)` : `var(--smetio-dominant-color-tint-100)`
    //       },
    //       "smetio-dominant-border-interactive:active": {
    //         "border-color": theme == "light" ? `var(--smetio-dominant-color-tint-100)` : `var(--smetio-dominant-color-shade-100)`
    //       },
    //       "smetio-dominant-border-interactive[data-smetio-status='active']": {
    //         "border-color": theme == "light" ? `var(--smetio-dominant-color-tint-100)` : `var(--smetio-dominant-color-shade-100)`
    //       },
    //     },
    //   },
    //   neutralColor: {
    //     background: {
    //       "smetio-neutral-background": {
    //         "background-color": theme == "light" ? `var(--smetio-neutral-color)` : `var(--smetio-neutral-color-shade-100)`
    //       },
    //       "smetio-neutral-background-reverse": {
    //         "background-color": theme == "light" ? `var(--smetio-neutral-color-shade-100)` : `var(--smetio-neutral-color-tint-100)`
    //       },
    //     },
    //     text: {
    //       "smetio-neutral-text": {
    //         color: theme == "light" ? `var(--smetio-neutral-color)` : `var(--smetio-neutral-color-shade-100)`
    //       },
    //       "smetio-neutral-text-reverse": {
    //         color: theme == "light" ? `var(--smetio-neutral-color-shade-100)` : `var(--smetio-neutral-color-tint-100)`
    //       },
    //     }
    //   },
    //   warningColor: {
    //     background: {
    //       "smetio-warning-background": {
    //         "background-color": theme == "light" ? `var(--smetio-warning-color)` : `var(--smetio-warning-color-shade-100)`
    //       },
    //       "smetio-warning-background-reverse": {
    //         "background-color": theme == "light" ? `var(--smetio-warning-color-shade-100)` : `var(--smetio-warning-color-tint-100)`
    //       },
    //     },
    //     text: {
    //       "smetio-warning-text": {
    //         color: theme == "light" ? `var(--smetio-warning-color)` : `var(--smetio-warning-color-shade-100)`
    //       },
    //       "smetio-warning-text-reverse": {
    //         color: theme == "light" ? `var(--smetio-warning-color-shade-100)` : `var(--smetio-warning-color-tint-100)`
    //       },
    //     },
    //     icon: {
    //       "smetio-warning-icon": {
    //         color: theme == "light" ? `var(--smetio-warning-color)` : `var(--smetio-warning-color-shade-100)`
    //       },
    //       "smetio-warning-icon-interactive": {
    //         color: theme == "light" ? `var(--smetio-warning-color-shade-100)` : `var(--smetio-warning-color-tint-100)`
    //       },
    //       "smetio-warning-icon-interactive:hover": {
    //         color: theme == "light" ? `var(--smetio-warning-color-shade-100)` : `var(--smetio-warning-color-tint-100)`
    //       },
    //       "smetio-warning-icon-interactive:active": {
    //         color: theme == "light" ? `var(--smetio-warning-color-tint-100)` : `var(--smetio-warning-color-shade-100)`
    //       },
    //     },
    //     border: {
    //       "smetio-warning-border": {
    //         border: `solid 1px var(--smetio-warning-color)`
    //       },
    //     }
    //   },
    //   successColor: {

    //     background: {
    //       "smetio-success-background": {
    //         "background-color":  theme == "light" ? `var(--smetio-success-color)` : `var(--smetio-success-color-shade-100)`
    //       },
    //       "smetio-success-background-reverse": {
    //         "background-color":  theme == "light" ? `var(--smetio-success-color-shade-100)` : `var(--smetio-success-color-tint-100)`
    //       },
    //     },
    //     text: {
    //       "smetio-success-text": {
    //         color: theme == "light" ? `var(--smetio-success-color)` : `var(--smetio-success-color-tint-100)`
    //       },
    //       "smetio-success-text-reverse": {
    //         color: theme == "light" ? `var(--smetio-success-color-shade-100)` : `var(--smetio-success-color-tint-100)`  
    //       },
    //     },
    //     icon: {
    //       "smetio-success-icon": {
    //         color: theme == "light" ? `var(--smetio-success-color)` : `var(--smetio-success-color-tint-100)`
    //       },
    //       "smetio-success-icon-interactive": {
    //         color: theme == "light" ? `var(--smetio-success-color)` : `var(--smetio-success-color-tint-100)`
    //       },
    //       "smetio-success-icon-interactive:hover": {
    //         color: theme == "light" ? `var(--smetio-success-color-shade-100)` : `var(--smetio-success-color-tint-100)`
    //       },
    //       "smetio-success-icon-interactive:active": {
    //         color: theme == "light" ? `var(--smetio-success-color-tint-100)` : `var(--smetio-success-color-shade-100)`
    //       },
    //     },
    //     border: {
    //       "smetio-success-border": {
    //         border: `solid 1px var(--smetio-success-color)`,
    //         "border-color": theme == "light" ? `var(--smetio-success-color)` : `var(--smetio-success-color-tint-100)`
    //       },
    //     }
    //   },
    //   informationColor: {

    //     background: {
    //       "smetio-information-background": {
    //         "background-color":  theme == "light" ? `var(--smetio-information-color)` : `var(--smetio-information-color-shade-100)`
    //       },
    //       "smetio-information-background-reverse": {
    //         "background-color":  theme == "light" ? `var(--smetio-information-color-shade-100)` : `var(--smetio-information-color-tint-100)`
    //       },
    //     },
    //     text: {
    //       "smetio-information-text": {
    //         color: theme == "light" ? `var(--smetio-information-color)` : `var(--smetio-information-color-tint-100)`
    //       },
    //       "smetio-information-text-reverse": {
    //         color: theme == "light" ? `var(--smetio-information-color-shade-100)` : `var(--smetio-information-color-tint-100)`  
    //       },
    //     },
    //     icon: {
    //       "smetio-information-icon": {
    //         color: theme == "light" ? `var(--smetio-information-color)` : `var(--smetio-information-color-tint-100)`
    //       },
    //       "smetio-information-icon-interactive": {
    //         color: theme == "light" ? `var(--smetio-information-color)` : `var(--smetio-information-color-tint-100)`
    //       },
    //       "smetio-information-icon-interactive:hover": {
    //         color: theme == "light" ? `var(--smetio-information-color-shade-100)` : `var(--smetio-information-color-tint-100)`
    //       },
    //       "smetio-information-icon-interactive:active": {
    //         color: theme == "light" ? `var(--smetio-information-color-tint-100)` : `var(--smetio-information-color-shade-100)`
    //       },
    //     },
    //     border: {
    //       "smetio-information-border": {
    //         border: `solid 1px var(--smetio-information-color)`,
    //         "border-color": theme == "light" ? `var(--smetio-information-color)` : `var(--smetio-information-color-tint-100)`
    //       },
    //     }
    //   },
    //   dangerColor: {

    //     background: {
    //       "smetio-danger-background": {
    //         "background-color":  theme == "light" ? `var(--smetio-danger-color)` : `var(--smetio-danger-color-shade-100)`
    //       },
    //       "smetio-danger-background-reverse": {
    //         "background-color":  theme == "light" ? `var(--smetio-danger-color-shade-100)` : `var(--smetio-danger-color-tint-100)`
    //       },
    //     },
    //     text: {
    //       "smetio-danger-text": {
    //         color: theme == "light" ? `var(--smetio-danger-color)` : `var(--smetio-danger-color-tint-100)`
    //       },
    //       "smetio-danger-text-reverse": {
    //         color: theme == "light" ? `var(--smetio-danger-color-shade-100)` : `var(--smetio-danger-color-tint-100)`  
    //       },
    //     },
    //     icon: {
    //       "smetio-danger-icon": {
    //         color: theme == "light" ? `var(--smetio-danger-color)` : `var(--smetio-danger-color-tint-100)`
    //       },
    //       "smetio-danger-icon-interactive": {
    //         color: theme == "light" ? `var(--smetio-danger-color)` : `var(--smetio-danger-color-tint-100)`
    //       },
    //       "smetio-danger-icon-interactive:hover": {
    //         color: theme == "light" ? `var(--smetio-danger-color-shade-100)` : `var(--smetio-danger-color-tint-100)`
    //       },
    //       "smetio-danger-icon-interactive:active": {
    //         color: theme == "light" ? `var(--smetio-danger-color-tint-100)` : `var(--smetio-danger-color-shade-100)`
    //       },
    //     },
    //     border: {
    //       "smetio-danger-border": {
    //         border: `solid 1px var(--smetio-danger-color)`,
    //         "border-color": theme == "light" ? `var(--smetio-danger-color)` : `var(--smetio-danger-color-tint-100)`
    //       },
    //     }
    //   },
    // };

    const brandedThemeDynamic = {
      accentColor: {
        background: {
          "smetio-accent-background": {
            "background-color": `var(--smetio-accent-color-${shade}-${contrastNumber})`
          },
          "smetio-accent-background-reverse": {
            "background-color": `var(--smetio-accent-color-${tint}-${contrastNumber})`
          },
        },
        text: {
          "smetio-accent-text": {
            color: `var(--smetio-accent-color-${tint}-${contrastNumber})`
          },
          "smetio-accent-text-reverse": {
            color: `var(--smetio-accent-color-${shade}-${contrastNumber})`
          },
        },
        button: {
          "smetio-accent-button": {
            "background-color": `var(--smetio-accent-color-${shade}-${contrastNumber})`,
            color: `var(--smetio-dominant-color-${tint}-${contrastNumber})`
          },
          "smetio-accent-button:hover": {
            "background-color": `var(--smetio-accent-color-${tint}-${contrastNumber})`,
            color: `var(--smetio-dominant-color-${shade}-${contrastNumber})`
          },
          "smetio-accent-button:active": {
            "background-color": `var(--smetio-accent-color-${shade}-${contrastNumber})`,
            color: `var(--smetio-dominant-color-${tint}-${contrastNumber})`
          },
          "smetio-accent-button[data-smetio-status='active']": {
            "background-color": `var(--smetio-dominant-color-${tint}-${contrastNumber})`,
            color: `var(--smetio-accent-color-${shade}-${contrastNumber})`
          },
        },
        link: {
          "smetio-accent-link": {
            color: `var(--smetio-accent-color-${shade}-${contrastNumber})`
          },
          "smetio-accent-link-interactive": {
            color: `var(--smetio-accent-color-${tint}-${contrastNumber})`
          },
          "smetio-accent-link-interactive:hover": {
            color: `var(--smetio-accent-color-${shade}-${contrastNumber})`
          },
          "smetio-accent-link-interactive:active": {
            color: `var(--smetio-accent-color)`
          },
        },
        icon: {
          "smetio-accent-icon": {
            color: `var(--smetio-accent-color-${shade}-${contrastNumber})`
          },
          "smetio-accent-icon-interactive": {
            color: `var(--smetio-accent-color-${tint}-${contrastNumber})`
          },
          "smetio-accent-icon-interactive:hover": {
            color: `var(--smetio-accent-color-${shade}-${contrastNumber})`
          },
          "smetio-accent-icon-interactive:active": {
            color: `var(--smetio-accent-color)`
          },
        },
        border: {
          "smetio-accent-border": {
            border: `solid 1px var(--smetio-accent-color-${shade}-${contrastNumber})`
          },
          "smetio-accent-border-interactive": {
            "border-color": `var(--smetio-accent-color-${tint}-${contrastNumber})`
          },
          "smetio-accent-border-interactive:hover": {
            "border-color": `solid 1px var(--smetio-accent-color-${shade}-${contrastNumber})`
          },
          "smetio-accent-border-interactive:active": {
            "border-color": `solid 1px var(--smetio-accent-color)`
          },
          "smetio-accent-border-interactive[data-smetio-status='active']": {
            "border-color": `solid 1px var(--smetio-accent-color)`
          },
        },

      },
      dominantColor: {
        background: {
          "smetio-dominant-background": {
            "background-color": `var(--smetio-dominant-color-${tint}-${contrastNumber})`
          },
          "smetio-dominant-background-reverse": {
            "background-color": `var(--smetio-dominant-color-${shade}-${contrastNumber})`
          },
        },
        text: {
          "smetio-dominant-text": {
            color: `var(--smetio-dominant-color-${shade}-${contrastNumber})`
          },
          "smetio-dominant-text-reverse": {
            color: `var(--smetio-dominant-color-${tint}-${contrastNumber})`
          },
        },
        border: {
          "smetio-dominant-border": {
            border: `solid 1px var(--smetio-dominant-color)`
          },
          "smetio-dominant-border-interactive": {
            "border-color": `var(--smetio-dominant-color)`
          },
          "smetio-dominant-border-interactive:hover": {
            "border-color": `solid 1px var(--smetio-dominant-color-${shade}-${contrastNumber})`
          },
          "smetio-dominant-border-interactive:active": {
            "border-color": `solid 1px var(--smetio-dominant-color)`
          },
          "smetio-dominant-border-interactive[data-smetio-status='active']": {
            "border-color": `solid 1px var(--smetio-dominant-color)`
          },
        },
      },
      neutralColor: {
        background: {
          "smetio-neutral-background": {
            "background-color": `var(--smetio-neutral-color-${tint}-${contrastNumber})`
          },
          "smetio-neutral-background-reverse": {
            "background-color": `var(--smetio-neutral-color-${shade}-${contrastNumber})`
          },
        },
        text: {
          "smetio-neutral-text": {
            color: theme === "light" ? `var(--smetio-neutral-color-shade-100)` : `var(--smetio-neutral-color-tint-100)`
          },
          "smetio-neutral-text-reverse": {
            color: theme === "light" ? `var(--smetio-neutral-color-tint-100)` : `var(--smetio-neutral-color-shade-100)`
          },
        },
        border: {
          "smetio-neutral-border": {
            border: `solid 1px var(--smetio-neutral-color)`,
            "border-color": theme === "light" ? `var(--smetio-neutral-color-shade-100)` : `var(--smetio-neutral-color-tint-100)`
          }
        },
      },
      greyColor: {
        background: {
          "smetio-grey-background": {
            "background-color": `var(--smetio-grey-color)`
          },
          "smetio-grey-background-reverse": {
            "background-color": theme === "light" ? `var(--smetio-grey-color-tint-100)` : `var(--smetio-grey-color-shade-100)`
          },
        },
        text: {
          "smetio-grey-text": {
            color: theme === "light" ? `var(--smetio-grey-color-shade-100)` : `var(--smetio-grey-color-tint-100)`
          },
          "smetio-grey-text-reverse": {
            color: theme === "light" ? `var(--smetio-grey-color-tint-100)` : `var(--smetio-grey-color-shade-100)`
          },
        },
        border: {
          "smetio-grey-border": {
            border: `solid 1px var(--smetio-grey-color)`,
            "border-color": theme === "light" ? `var(--smetio-grey-color-shade-100)` : `var(--smetio-grey-color-tint-100)`
          }
        },
      },
      warningColor: {
        background: {
          "smetio-warning-background": {
            "background-color": `var(--smetio-warning-color-${tint}-${contrastNumber})`
          },
          "smetio-warning-background-reverse": {
            "background-color": `var(--smetio-warning-color-${shade}-${contrastNumber})`
          },
        },
        text: {
          "smetio-warning-text": {
            color: `var(--smetio-warning-color-${shade}-${contrastNumber})`
          },
          "smetio-warning-text-reverse": {
            color: `var(--smetio-warning-color-${tint}-${contrastNumber})`
          },
        },
        icon: {
          "smetio-warning-icon": {
            color: `var(--smetio-warning-color-${shade}-${contrastNumber})`
          },
          "smetio-warning-icon-interactive": {
            color: `var(--smetio-warning-color-${shade}-${contrastNumber})`
          },
          "smetio-warning-icon-interactive:hover": {
            color: `var(--smetio-warning-color-${tint}-${contrastNumber})`
          },
          "smetio-warning-icon-interactive:active": {
            color: `var(--smetio-warning-color)`
          },
        },
        border: {
          "smetio-warning-border": {
            border: `solid 1px var(--smetio-warning-color-${shade}-${contrastNumber})`
          },
        }
      },
      successColor: {
        background: {
          "smetio-success-background": {
            "background-color": `var(--smetio-success-color-${tint}-${contrastNumber})`
          },
          "smetio-success-background-reverse": {
            "background-color": `var(--smetio-success-color-${shade}-${contrastNumber})`
          },
        },
        text: {
          "smetio-success-text": {
            color: `var(--smetio-success-color-${shade}-${contrastNumber})`
          },
          "smetio-success-text-reverse": {
            color: `var(--smetio-success-color-${tint}-${contrastNumber})`
          },
        },
        icon: {
          "smetio-success-icon": {
            color: `var(--smetio-success-color-${shade}-${contrastNumber})`
          },
          "smetio-success-icon-interactive": {
            color: `var(--smetio-success-color-${shade}-${contrastNumber})`
          },
          "smetio-success-icon-interactive:hover": {
            color: `var(--smetio-success-color-${tint}-${contrastNumber})`
          },
          "smetio-success-icon-interactive:active": {
            color: `var(--smetio-success-color)`
          },
        },
        border: {
          "smetio-success-border": {
            border: `solid 1px var(--smetio-success-color-${shade}-${contrastNumber})`
          },
        }
      },
      informationColor: {
        background: {
          "smetio-information-background": {
            "background-color": `var(--smetio-information-color-${tint}-${contrastNumber})`
          },
          "smetio-information-background-reverse": {
            "background-color": `var(--smetio-information-color-${shade}-${contrastNumber})`
          },
        },
        text: {
          "smetio-information-text": {
            color: `var(--smetio-information-color-${shade}-${contrastNumber})`
          },
          "smetio-information-text-reverse": {
            color: `var(--smetio-information-color-${tint}-${contrastNumber})`
          },
        },
        icon: {
          "smetio-information-icon": {
            color: `var(--smetio-information-color-${shade}-${contrastNumber})`
          },
          "smetio-information-icon-interactive": {
            color: `var(--smetio-information-color-${shade}-${contrastNumber})`
          },
          "smetio-information-icon-interactive:hover": {
            color: `var(--smetio-information-color-${tint}-${contrastNumber})`
          },
          "smetio-information-icon-interactive:active": {
            color: `var(--smetio-information-color)`
          },
        },
        border: {
          "smetio-information-border": {
            border: `solid 1px var(--smetio-information-color-${shade}-${contrastNumber})`
          },
        }
      },
      dangerColor: {
        background: {
          "smetio-danger-background": {
            "background-color": `var(--smetio-danger-color-${tint}-${contrastNumber})`
          },
          "smetio-danger-background-reverse": {
            "background-color": `var(--smetio-danger-color-${shade}-${contrastNumber})`
          },
        },
        text: {
          "smetio-danger-text": {
            color: `var(--smetio-danger-color-${shade}-${contrastNumber})`
          },
          "smetio-danger-text-reverse": {
            color: `var(--smetio-danger-color-${tint}-${contrastNumber})`
          },
        },
        icon: {
          "smetio-danger-icon": {
            color: `var(--smetio-danger-color-${shade}-${contrastNumber})`
          },
          "smetio-danger-icon-interactive": {
            color: `var(--smetio-danger-color-${shade}-${contrastNumber})`
          },
          "smetio-danger-icon-interactive:hover": {
            color: `var(--smetio-danger-color-${tint}-${contrastNumber})`
          },
          "smetio-danger-icon-interactive:active": {
            color: `var(--smetio-danger-color)`
          },
        },
        border: {
          "smetio-danger-border": {
            border: `solid 1px var(--smetio-danger-color-${shade}-${contrastNumber})`
          }
        }
      },
    };


    return brandedThemeDynamic;
  };

  getPlainThemeDynamic({ theme = "light", contrastNumber = 100 }) {

    // contrastNumber = 100;
    const shade = theme == "light" ? "shade" : "tint";
    let tint = theme == "light" ? "tint" : "shade";

    const plainThemeDynamic = {
      accentColor: {
        background: {
          "smetio-accent-background": {
            "background-color": `var(--smetio-dominant-color-rgba-10)`
          },
          "smetio-accent-background-reverse": {
            "background-color": `var(--smetio-dominant-color-rgba-10)`
          },
        },
        text: {
          "smetio-accent-text": {
            color: `var(--smetio-accent-color-tint-100)`
          },
          "smetio-accent-text-reverse": {
            color: `var(--smetio-accent-color-tint-100)`
          },
        },
        button: {
          "smetio-accent-button": {
            "background-color": `var(--smetio-accent-color-tint-100)`,
            color: `var(--smetio-dominant-color-shade-100)`
          },
          "smetio-accent-button:hover": {
            // "background-color": `var(--smetio-accent-color-${tint}-${contrastNumber})`,
            color: `var(--smetio-dominant-color-tint-100)`
          },
          "smetio-accent-button:active": {
            "background-color": `var(--smetio-accent-color-tint-100)`,
            color: `var(--smetio-dominant-color-shade-100)`
          },
          "smetio-accent-button[data-smetio-status='active']": {
            "background-color": `var(--smetio-dominant-color-${tint}-${contrastNumber})`,
            color: `var(--smetio-dominant-color-${shade}-${contrastNumber})`
          },
        },
        link: {
          "smetio-accent-link": {
            color: `var(--smetio-accent-color-tint-100)`,
          },
          "smetio-accent-link-interactive": {
            color: `var(--smetio-accent-color-tint-100)`,
          },
          "smetio-accent-link-interactive:hover": {
            color: `var(--smetio-accent-color-shade-100)`,
          },
          "smetio-accent-link-interactive:active": {
            color: `var(--smetio-accent-color)`
          },
        },
        icon: {
          "smetio-accent-icon": {
            color: `var(--smetio-accent-color-tint-100)`,
          },
          "smetio-accent-icon-interactive": {
            color: `var(--smetio-accent-color-tint-100)`,
          },
          "smetio-accent-icon-interactive:hover": {
            color: `var(--smetio-accent-color-shade-100)`,
          },
          "smetio-accent-icon-interactive:active": {
            color: `var(--smetio-accent-color)`
          },
        },
        border: {
          "smetio-accent-border": {
            border: `solid 1px var(--smetio-accent-color-tint-100)`,
          },
          "smetio-accent-border-interactive": {
            "border-color": `var(--smetio-accent-color-tint-100)`,
          },
          "smetio-accent-border-interactive:hover": {
            "border-color": `solid 1px var(--smetio-accent-color-shade-100)`
          },
          "smetio-accent-border-interactive:active": {
            "border-color": `solid 1px var(--smetio-accent-color)`
          },
          "smetio-accent-border-interactive[data-smetio-status='active']": {
            "border-color": `solid 1px var(--smetio-accent-color)`
          },
        },

      },
      dominantColor: {
        background: {
          "smetio-dominant-background": {
            "background-color": `var(--smetio-dominant-color-${tint}-${contrastNumber})`
          },
          "smetio-dominant-background-reverse": {
            "background-color": `var(--smetio-dominant-color-${shade}-${contrastNumber})`
          },
        },
        text: {
          "smetio-dominant-text": {
            color: `var(--smetio-dominant-color-${shade}-${contrastNumber})`
          },
          "smetio-dominant-text-reverse": {
            color: `var(--smetio-dominant-color-${tint}-${contrastNumber})`
          },
        },
        border: {
          "smetio-dominant-border": {
            border: `solid 1px var(--smetio-dominant-color)`
          },
          "smetio-dominant-border-interactive": {
            "border-color": `var(--smetio-dominant-color)`
          },
          "smetio-dominant-border-interactive:hover": {
            "border-color": `solid 1px var(--smetio-dominant-color-${shade}-${contrastNumber})`
          },
          "smetio-dominant-border-interactive:active": {
            "border-color": `solid 1px var(--smetio-dominant-color)`
          },
          "smetio-dominant-border-interactive[data-smetio-status='active']": {
            "border-color": `solid 1px var(--smetio-dominant-color)`
          },
        },
      },
      neutralColor: {
        background: {
          "smetio-neutral-background": {
            "background-color": `var(--smetio-neutral-color-${tint}-${contrastNumber})`
          },
          "smetio-neutral-background-reverse": {
            "background-color": `var(--smetio-neutral-color-${shade}-${contrastNumber})`
          },
        },
        text: {
          "smetio-neutral-text": {
            color: `var(--smetio-neutral-color-${shade}-${contrastNumber})`
          },
          "smetio-neutral-text-reverse": {
            color: `var(--smetio-neutral-color-${tint}-${contrastNumber})`
          },
        }
      },
      greyColor: {
        background: {
          "smetio-grey-background": {
            "background-color": `var(--smetio-grey-color)`
          },
          "smetio-grey-background-reverse": {
            "background-color": theme === "light" ? `var(--smetio-grey-color-tint-100)` : `var(--smetio-grey-color-shade-100)`
          },
        },
        text: {
          "smetio-grey-text": {
            color: theme === "light" ? `var(--smetio-grey-color-shade-100)` : `var(--smetio-grey-color-tint-100)`
          },
          "smetio-grey-text-reverse": {
            color: theme === "light" ? `var(--smetio-grey-color-tint-100)` : `var(--smetio-grey-color-shade-100)`
          },
        },
        border: {
          "smetio-grey-border": {
            border: `solid 1px var(--smetio-grey-color)`,
            "border-color": theme === "light" ? `var(--smetio-grey-color-shade-100)` : `var(--smetio-grey-color-tint-100)`
          }
        },
      },
      warningColor: {
        background: {
          "smetio-warning-background": {
            "background-color": `var(--smetio-warning-color-${tint}-${contrastNumber})`
          },
          "smetio-warning-background-reverse": {
            "background-color": `var(--smetio-warning-color-${shade}-${contrastNumber})`
          },
        },
        text: {
          "smetio-warning-text": {
            color: `var(--smetio-warning-color)`
          },
          "smetio-warning-text-reverse": {
            color: `var(--smetio-warning-color-${tint}-${contrastNumber})`
          },
        },
        icon: {
          "smetio-warning-icon": {
            color: `var(--smetio-warning-color-${shade}-${contrastNumber})`
          },
          "smetio-warning-icon-interactive": {
            color: `var(--smetio-warning-color-${shade}-${contrastNumber})`
          },
          "smetio-warning-icon-interactive:hover": {
            color: `var(--smetio-warning-color-${tint}-${contrastNumber})`
          },
          "smetio-warning-icon-interactive:active": {
            color: `var(--smetio-warning-color)`
          },
        },
        border: {
          "smetio-warning-border": {
            border: `solid 1px var(--smetio-warning-color)`
          },
        }

      },
      successColor: {
        background: {
          "smetio-success-background": {
            "background-color": `var(--smetio-success-color-${tint}-${contrastNumber})`
          },
          "smetio-success-background-reverse": {
            "background-color": `var(--smetio-success-color-${shade}-${contrastNumber})`
          },
        },
        text: {
          "smetio-success-text": {
            color: `var(--smetio-success-color)`
          },
          "smetio-success-text-reverse": {
            color: `var(--smetio-success-color-${tint}-${contrastNumber})`
          },
        },
        icon: {
          "smetio-success-icon": {
            color: `var(--smetio-success-color-${shade}-${contrastNumber})`
          },
          "smetio-success-icon-interactive": {
            color: `var(--smetio-success-color-${shade}-${contrastNumber})`
          },
          "smetio-success-icon-interactive:hover": {
            color: `var(--smetio-success-color-${tint}-${contrastNumber})`
          },
          "smetio-success-icon-interactive:active": {
            color: `var(--smetio-success-color)`
          },
        },
        border: {
          "smetio-success-border": {
            border: `solid 1px var(--smetio-success-color)`
          },
        }
      },
      informationColor: {
        background: {
          "smetio-information-background": {
            "background-color": `var(--smetio-information-color-${tint}-${contrastNumber})`
          },
          "smetio-information-background-reverse": {
            "background-color": `var(--smetio-information-color-${shade}-${contrastNumber})`
          },
        },
        text: {
          "smetio-information-text": {
            color: `var(--smetio-information-color)`
          },
          "smetio-information-text-reverse": {
            color: `var(--smetio-information-color-${tint}-${contrastNumber})`
          },
        },
        icon: {
          "smetio-information-icon": {
            color: `var(--smetio-information-color-${shade}-${contrastNumber})`
          },
          "smetio-information-icon-interactive": {
            color: `var(--smetio-information-color-${shade}-${contrastNumber})`
          },
          "smetio-information-icon-interactive:hover": {
            color: `var(--smetio-information-color-${tint}-${contrastNumber})`
          },
          "smetio-information-icon-interactive:active": {
            color: `var(--smetio-information-color)`
          },
        },
        border: {
          "smetio-information-border": {
            border: `solid 1px var(--smetio-information-color)`
          },
        }

      },
      dangerColor: {
        background: {
          "smetio-danger-background": {
            "background-color": `var(--smetio-danger-color-${tint}-${contrastNumber})`
          },
          "smetio-danger-background-reverse": {
            "background-color": `var(--smetio-danger-color-${shade}-${contrastNumber})`
          },
        },
        text: {
          "smetio-danger-text": {
            color: `var(--smetio-danger-color)`
          },
          "smetio-danger-text-reverse": {
            color: `var(--smetio-danger-color-${tint}-${contrastNumber})`
          },
        },
        icon: {
          "smetio-danger-icon": {
            color: `var(--smetio-danger-color-${shade}-${contrastNumber})`
          },
          "smetio-danger-icon-interactive": {
            color: `var(--smetio-danger-color-${shade}-${contrastNumber})`
          },
          "smetio-danger-icon-interactive:hover": {
            color: `var(--smetio-danger-color-${tint}-${contrastNumber})`
          },
          "smetio-danger-icon-interactive:active": {
            color: `var(--smetio-danger-color)`
          },
        },
        border: {
          "smetio-danger-border": {
            border: `solid 1px var(--smetio-danger-color)`
          },
        }
      },
    };

    return plainThemeDynamic;

  };

  writeCssClassForTheme({ contrastNumber = 100, theme = "light", themeDynamic = null }) {

    const shade = theme == "light" ? "shade" : "tint";
    let tint = theme == "light" ? "tint" : "shade";

    themeDynamic = themeDynamic || this.getBrandedThemeDynamic({ contrastNumber, theme });

    let output = {
      withTag: "",
      withoutTag: ""
    };

    for (const key in themeDynamic) {

      const colorType = themeDynamic[key];

      output["withoutTag"] += ` /* ${key} */`;
      output["withTag"] += ` <p>/* ${key} */ </p>`;

      this.writeCssClassFromColorType({ colorType, output });
    }

    return output;

  };

  generateShadesAndTints(colorInHSL, colorName) {
    const hue = colorInHSL[0];
    const contrast = colorInHSL[1];
    const brightness = colorInHSL[2];

    const cssNamingGap = 10;
    const gapBetweenShadesAndTints = this.shadesAndTints[colorName]["gap"] || this.gapBetweenShadesAndTints;
    const numbersOfShadesAndTints = 5;
    const prefix = this.prefix + "-" + this.shadesAndTints[colorName]["cssName"];
    const shadePrefix = prefix + "-shade";
    const tintPrefix = prefix + "-tint";

    const colorInHex = convert.hsl.hex(colorInHSL);

    this.shadesAndTints[colorName][prefix] = colorInHex;
    this.shadesAndTints[colorName]["color"] = {}
    this.shadesAndTints[colorName]["color"]["hex"] = colorInHex;
    this.shadesAndTints[colorName]["color"]["hsl"] = colorInHSL;

    this.shadesAndTints[colorName]["shades"] = {};
    this.shadesAndTints[colorName]["tints"] = {};

    if (this.cssColorVariables.includes(colorName)) return false;

    this.cssColorVariables += `
      /* ${colorName} */
      --${prefix}: #${colorInHex};
    `;

    this.cssColorVariablesInPTag += `
      /* ${colorName} */
      <p>--${prefix}: #${colorInHex};</p>
    `;

    if (colorName === "greyColor") {
      const [cssRgbaColorVariable, cssRgbaColorVariableInPTag] = this.generateRgbaColorVariables({ colorInHSL, prefix, colorName: "grey" });
      this.cssColorVariables += cssRgbaColorVariable;
      this.cssColorVariablesInPTag += cssRgbaColorVariableInPTag;
    };

    if (colorName === "neutralColor") {
      const [cssRgbaColorVariable, cssRgbaColorVariableInPTag] = this.generateRgbaColorVariables({ colorInHSL, prefix, colorName: "neutral" });
      this.cssColorVariables += cssRgbaColorVariable;
      this.cssColorVariablesInPTag += cssRgbaColorVariableInPTag;
    };

    // if (colorName === "dominantColor") {
    //   const [cssRgbaColorVariable, cssRgbaColorVariableInPTag] = this.generateRgbaColorVariables({ colorInHSL, prefix });
    //   this.cssColorVariables += cssRgbaColorVariable;
    //   this.cssColorVariablesInPTag += cssRgbaColorVariableInPTag;
    // };


    for (let i = 1; i < numbersOfShadesAndTints + 1; i++) {

      const shift = gapBetweenShadesAndTints * i;
      const surfix = cssNamingGap * 2 * i;
      const shadeColorKey = shadePrefix + "-" + surfix;
      const tintColorKey = tintPrefix + "-" + surfix;

      // let newShade = brightness - shift;
      let newShade = brightness - (this.shadesAndTints[colorName]["shadeGap"] * i);
      newShade = newShade < 0 ? 0 : newShade;

      // let newTint = brightness + shift;
      let newTint = brightness + (this.shadesAndTints[colorName]["tintGap"] * i);
      newTint = newTint > 100 ? 100 : newTint;

      const shadeColor = [hue, contrast, newShade];
      const tintColor = [hue, contrast, newTint];

      const shadeInHex = convert.hsl.hex(shadeColor);
      const tintInHex = convert.hsl.hex(tintColor);

      this.shadesAndTints[colorName]["shades"][shadeColorKey] = {}
      this.shadesAndTints[colorName]["shades"][shadeColorKey]["hex"] = shadeInHex;
      this.shadesAndTints[colorName]["shades"][shadeColorKey]["hsl"] = shadeColor;

      this.shadesAndTints[colorName]["tints"][tintColorKey] = {}
      this.shadesAndTints[colorName]["tints"][tintColorKey]["hex"] = tintInHex;
      this.shadesAndTints[colorName]["tints"][tintColorKey]["hsl"] = tintColor;

      this.cssColorVariables += `
        --${shadeColorKey}: #${shadeInHex};
        --${tintColorKey}: #${tintInHex};
      `;

      this.cssColorVariablesInPTag += `
        <p> --${shadeColorKey}: #${shadeInHex}; </p>
        <p> --${tintColorKey}: #${tintInHex}; </p>
      `;

      this.createCssTheme({
        surfixNumber: surfix
      })
    };

  };

  getSchemeBox(color) {

    const title = `
      #${color["hex"]}
      hsl(${color["hsl"][0]}, ${color["hsl"][1]}, ${color["hsl"][2]})
    `;

    return `
      <div class="smetio-color-scheme-box" >
        <div class="smetio-color-scheme-box-color" style="background-color:#${color["hex"]};"> </div>
        <div class="smetio-color-scheme-box-text"> <p> #${color["hex"]} </p> </div>
        <div class="smetio-color-scheme-box-text"> <p> hsl(${color["hsl"][0]}, ${color["hsl"][1]}, ${color["hsl"][2]}) </p> </div>
      </div>
      

    `
  }

  displayTintOrShade(tintOrShade) {

    let output = "";
    for (const key in tintOrShade) {

      const color = tintOrShade[key];
      output += this.getSchemeBox(color);

    };

    return output;
  }

  getFullColorSchemeForDisplay() {
    return this.displayColorScheme();
  };

  getColorPallete(){
    return this.displayColorScheme(null, { onlyPalette: true });
  };

  displayColorScheme(container, data={onlyPalette: false}) {

    const { onlyPalette } = data;

    let output = "";

    for (const key in this.shadesAndTints) {
      const colorType = this.shadesAndTints[key];
      let color = this.getSchemeBox(colorType["color"]);
      color = `<div class=" smetio-color-scheme-color-type-tints-or-shades smetio-color-scheme-color-type-main-color"> ${color} </div>`;
      const readableName = colorType["readableName"];
      let shades = this.displayTintOrShade(colorType["shades"]);
      shades = `<div class=" smetio-color-scheme-color-type-tints-or-shades smetio-color-scheme-color-type-shades"> ${shades} </div>`;
      let tints = this.displayTintOrShade(colorType["tints"]);
      tints = `<div class="smetio-color-scheme-color-type-tints-or-shades  smetio-color-scheme-color-type-tints"> ${tints} </div>`;

      output += `
        <div class="smetio-color-scheme-color-type">
          <div>${readableName}</div>
          <div class="smetio-color-scheme-color-type-colors">
            ${tints} ${color} ${shades}
          </div>
        </div>
      `
    }

    // console.log("the this.neutralTransColorsCss is ", this.neutralAndTransColorsCss);

    const neutralAndTransClasses = this.writeCssClassFromColorType({ colorType: this.neutralAndTransColorsCss });

    // CREATE EXTRA PLAIN THEME USING THE plainThemeDynamic
    this.createCssTheme({ surfixNumber: 100, preferedThemeName: "plain" });
    this.createCssTheme({ surfixNumber: 100, preferedThemeName: "true-brand" });

    let cssCode =  "";
    if( !onlyPalette) cssCode = `
      <div class="smetio-color-scheme-copyable" >
        :root{
          ${this.cssColorVariablesInPTag}
        }
      </div>
      <div class="smetio-color-scheme-copyable" >${neutralAndTransClasses["withTag"]}</div>
      <div class="smetio-color-scheme-copyable" >${this.themesInTag}</div>
    `;

    output = `
      <div class="smetio-color-scheme-container">
        ${output}
        ${cssCode}
      </div>
    `;

    // $("body").html(output);

    this.events();

    if (container) $(container).html(output);

    return output;

  }

  getCssColorVariables() {
    return this.cssColorVariables;
  };

  createShadesAndTints(displayColorScheme = false) {

    for (const key in this.colorsInHSL) {

      const matchBrand = this.shadesAndTints[key]["matchBrand"]; 

      // const colorInHSL = !this.adjustContrast ? this.colorsInHSL[key] : this.adjustColorFit(this.colorsInHSL[key]);
      const colorInHSL = !matchBrand ? this.colorsInHSL[key] : this.adjustColorFit(this.colorsInHSL[key]);
      this.generateShadesAndTints(colorInHSL, key);
      // console.log("The color in hsl is ", colorInHSL);
    };

    if (displayColorScheme === true) this.displayColorScheme();

  }

  getCssCodeForStyleFile() {

    this.createShadesAndTints(false);

    // CREATE EXTRA PLAIN THEME USING THE plainThemeDynamic

    // console.log("Creating new theme");
    this.createCssTheme({ surfixNumber: 100, preferedThemeName: "plain" });
    this.createCssTheme({ surfixNumber: 100, preferedThemeName: "trueBrand" });


    const neutralAndTransClasses = this.writeCssClassFromColorType({ colorType: this.neutralAndTransColorsCss });

    const fileContent = `:root{${this.cssColorVariables} } ${neutralAndTransClasses["withoutTag"]} ${this.themes}`;

    return fileContent;
  }

  render() {


  };

  events() {

    $("body").on("mousedown", ".smetio-color-scheme-copyable", (event) => {

      event.stopPropagation();

      const theDataToCopy = $(".smetio-color-scheme-copyable").text();
      copyToClipboard({
        theDataToCopy,
        onSuccessFunc: () => {
          // alert("data copied successfully");
          console.log("data copied successfully");
        },
        onFailedFunc: () => {
          alert("Failed to copy data");
        }
      })
      return false;
    });

  };

};
