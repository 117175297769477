/*
import {checkIfItIsEmpty} from
    "./../../../functions/checks/checkIfItIsEmpty.js";   

import {globalVar} from 
    "./../../../variables/global_variables.js";

import {SmetioSmartbox} from
   "./../../smetioSmartbox/SmetioSmartbox.js";

//import {SmetioThreeVerticalLayout} from
  // "./../../smetioThreeVerticalLayout/SmetioThreeVerticalLayout.js";
*/


export async function render(){

    await this.getSmetioTexts();

    await this.checkBrowserPermissions(); // MUST BE ON TOP

    await this.getGeolocation();

    // await this.getPeerJsId();
    
    await this.isLegalPoliciesAccepted();

    this.device.canvasFingerprint = await this.getCanvasFingerprint();
    this.device.screenResolution = await this.getScreenResolution();
    this.device.formFactor = await this.getFormFactor();

    this.browser.brand = await this.getBrowserBrand();
    this.browser.platform = await this.getBrowserPlatform();
    this.browser.hasSessionStorage = await this.hasSessionStorage();
    this.browser.hasLocalStorage = await this.hasLocalStorage();
    this.browser.hasIndexDb = await this.hasIndexDb();
    this.browser.supportsCanvas = await this.supportsCanvas();
    this.browser.supportsGeolocation = await this.supportsGeolocation();
    this.browser.supportsNotification = await this.supportsNotification();
    this.browser.supportsServiceWorker = await this.supportsServiceWorker();
    this.browser.supportsPushManager = await this.supportsPushManager();

    const textInViewQueue = JSON.parse( localStorage.getItem("textInViewQueue") );
    this.textsInView = {...textInViewQueue, ...this.textsInView};
    this.visitorToken = await this.getVisitorsToken();

    localStorage.removeItem("textInViewQueue");

    this.events();

    // await this.registerServiceWorker();

    await this.register();



/*
    await this.showCustomNotification({
        title: "Error",
        body: "There is an error from server",
        type: "danger"
    });

    await this.showNotification({
        title: "Error",
        body: "There is an error from server",
        type: "danger"
    });
*/    

    /**** 
     * SEND PERIODIC PINGS TO THE SERVER -- BY DOING THIS, THE SERVER WILL KNOW IF THE
     * PAGE IS STILL ACTIVE
     * 
     * this.pingInterval = 240000 IS 4 MINUTES IN MILLISECONDS
     * ****/

     setInterval( async ()=>{

        return await this.pingServer();

     }, this.pingInterval);

     return true;

};