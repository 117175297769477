import { SMETIO_ICONS } from "./smetio_icons_constants"

export const smetio_delete = {
    text: "txt258",
    icon: SMETIO_ICONS["delete"],
    className: "", // IS SET IN THE SMETIO DATA PRESENTATION CLASS BUT CAN BE SET BEFORE BEING PASSED TO THE CLASS IF NEEDED
    onClick: (
        uniqueValues={smetioPresentationId:"" } // SEE SMETIO DATA PRESENTATION CLASS FOR DETAILS
    ) => { }
};
export const smetio_edit = {
    text: "txt202",
    icon: SMETIO_ICONS["edit"],
    className: "", // IS SET IN THE SMETIO DATA PRESENTATION CLASS BUT CAN BE SET BEFORE BEING PASSED TO THE CLASS IF NEEDED
    onClick: (
        uniqueValues={smetioPresentationId:"" } // SEE SMETIO DATA PRESENTATION CLASS FOR DETAILS
    ) => { }
};
export const smetio_copy = {
    text: "txt259",
    icon: SMETIO_ICONS["copy"],
    className: "", // IS SET IN THE SMETIO DATA PRESENTATION CLASS BUT CAN BE SET BEFORE BEING PASSED TO THE CLASS IF NEEDED
    onClick: (
        uniqueValues={smetioPresentationId:"" } // SEE SMETIO DATA PRESENTATION CLASS FOR DETAILS
    ) => { }
};
export const smetio_view = {
    text: "txt260",
    icon: SMETIO_ICONS["view"],
    className: "", // IS SET IN THE SMETIO DATA PRESENTATION CLASS BUT CAN BE SET BEFORE BEING PASSED TO THE CLASS IF NEEDED
    onClick: () => { }
};

export const smetio_selectAll = {
    text: "txt265",
    icon: SMETIO_ICONS["select"],
    className: "", // IS SET IN THE SMETIO DATA PRESENTATION CLASS BUT CAN BE SET BEFORE BEING PASSED TO THE CLASS IF NEEDED
    onClick: () => { }
};

export const smetio_deselectAll = {
    text: "txt263",
    icon: SMETIO_ICONS["deselect"],
    className: "", // IS SET IN THE SMETIO DATA PRESENTATION CLASS BUT CAN BE SET BEFORE BEING PASSED TO THE CLASS IF NEEDED
    onClick: () => { }
};