export let globalVar = {

    smetio_cdn: "https://cdn.smetio.com/",
    smetio_retrieveData_url: "https://smetio.com/retrieveData/",
    link_to_circular_flags: "img/flags/countries/circular_flags/",
    link_to_rectangular_flags: "https://cdn.smetio.com/img/flags/countries/rectangular_flags/",
    profileImgPlaceholder: "https://cdn.smetio.com/img/placeholders/profil_placeholder.png",
    link_to_legal_policies: "https://schaniag.com/legal_policies/",
    link_to_webRtcSignal: "signal.smetio.com",
    link_to_loginPage: "smetio.com/login",
 

    app_in_use: "",

    // FUNCTIONS THAT NEEDS TO BE CALLED WHEN THESE EVENTS HAPPEN SHOULD BE PUSHED INTO THE ARRAYS
    menuBarIsClickedFunctions: [],
    windowResizeFuntions: [],
    documentResizeFuntions: [],

    chunkDataRetrievalSize: 50,
    leftSideMenuAnimationTime:600,

    leftSideMenuState: "closed",
    sideMenuIsClickedFunctions: {},

    preload_for_language_selection: "",
    preload_for_region_selection: "",
    preload_for_currency_selection: "",
    
    worldCountries: [],
    totalNumbersOfWorldCountries: 0,
    worldCountriesSelectionOffset: 0,
    allWorldCountriesAreLoaded: false,
    worldCountriesIsBeingLoaded: false,    

    worldCurrencies: [],
    totalNumbersOfWorldCurrencies: 0,
    worldCurrenciesSelectionOffset: 0,
    allWorldCurrenciesAreLoaded: false,
    worldCurrencyIsBeingLoaded: false,
    
    
    worldLanguages: [],
    totalNumbersOfWorldLanguages: 0,
    worldLanguagesSelectionOffset: 0,
    allWorldLanguagesAreLoaded: false,
    worldLanguageIsBeingLoaded: false,
    
    
    region_language_search_bar: "",
    region_language_select_bar: "",
    
    selected_language: "en",
    
    selected_currency: "NOK",
    
    selected_country: "no",
    selected_country_phone_code: "",
    selected_country_continent: "",



    
    region_or_language_tab_in_view: "language",
    
    language_search_string: "",
    country_search_string: "",
    currency_search_string: "",
    general_search_string: "",
    
    language_filter_value: "all",
    country_filter_value: "all",
    currency_filter_value: "all",
    general_filter_value: "all",
    
    
    allLeftSideMenuItemsAreLoaded: false,
    leftSideMenuItemsAreBeingLoaded: false,  
    
    ideTabsDefaultMoveMargin: 500,
    ideTabsCurrentMoveMargin: 0,
    ideHasBeenLoaded: false,
    
    text_on_page: {},

    allHtmlElement : ["div", "p", "h1", "h2", "h3", "h4", "h5", "span", "form" ],
    colorFormats : ["hex", "rgb", "rgba"],
    urlFormat : ["url"],
    alphanumbericFormat : ["alphanumberic"],
    numbericFormat : ["number"],
    cssUnits : ["px", "%", "em", "vh", "vw", "pt", "cm", "mm"],
    passwordContainedSpecialCharacters: "!\"$%'()*+,-./:=?@[]^_{|}~",

    userIsConnectedToSmetioTelecom: false,     

};













