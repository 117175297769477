//import {test_function} from "./../functions/test_function.js";



import { getId } from "../../universal_methods/getId.js";
import { setId } from "../../universal_methods/setId.js";
import { generateId } from "../../universal_methods/generateId.js";
import { isEmpty } from "../../universal_methods/isEmpty.js";
import { getAttributes } from "../../universal_methods/getAttributes.js";
import { toggleDisplay } from "../../universal_methods/toggleDisplay.js";
// import { setTxt } from "../../universal_methods/setTxt.js";


// //import "./styles/_main.scss";
import { SmetioVisitor } from "../../smetioVisitor/SmetioVisitor.js";

import { setTxtV2 } from "./../../../classes/universal_methods/setTxtV2.js";
import { removeSplashScreen } from "./../../universal_methods/removeSplashScreen";


export class SmetioSetUpAndInitialization {

    constructor(data = {
        appName: "",
        brandText: "",
        themeName: "",
        logo: "",
        sideMenuItems: "",
        brandName: "",
        language: "",
        apiEndPoint: "",
        accountType: "",
        useBrandNameNotLogo: false,
        defaultPath,
        extraParameters
    }) {


        this.getId = getId;
        this.setId = setId;
        this.generateId = generateId;
        this.isEmpty = isEmpty;
        this.getAttributes = getAttributes;
        this.toggleDisplay = toggleDisplay;
        // this.setTxt = setTxt;
        this.setTxt = setTxtV2;


        // this.init = init;


        this.smetioAppUi = "";

        this.setId();
        this.waitingForVisitorToRegister = "";
        // this.appName = "";

        this.sideMenuItems = "";
        this.data = data;
        this.extraParameters = data.extraParameters || {};
        this.smetioBnac = data.smetioBnac;
        this.usefulLinks = data.usefulLinksObj ? data.usefulLinksObj.links : [];

        this.appName = data.appName || "smetio_profile";
        this.brandText = data.brandText || "";
        this.themeName = data.themeName || "true-brand-light-100";
        this.logo = data.logo || "img/3ack/logos_and_icons/3ack_logo_dominant.svg";
        this.sideMenuItems = data.sideMenuItems || false;
        this.brandName = data.brandName || "3ack";
        this.useBrandNameNotLogo = data.useBrandNameNotLogo || false;
        this.language = data.language || "en";
        window.smetioEnv = data.env || "development";
        this.comingSoon = "Coming soon"; // TEMPORARY
        this.defaultPath = data.defaultPath;
        this.cdnPath = data.cdnPath;
        this.apiEndPoint = data.apiEndPoint;
        this.accountType = data.accountType || "businessOwner";
        this.appUserInterface = data.appUserInterface || "business";
        this.removeSplashScreen = removeSplashScreen;
        this.baseUrl = "";
           

        window.defaultPath = this.defaultPath;
        window.cdnPath = this.cdnPath;
        window.apiEndPoint = this.apiEndPoint;
        window.userLanguage = this.language;
        window.themeName = this.themeName;
        window.appName = this.appName;
            

    

        // this.smetioAjax = new SmetioAjax();



        // console.log("The language is ", this.language, "from data ", data.language);

        // window.smetioVisitor = new SmetioVisitor({
        //   language:this.language
        // });
        // window.smetioVisitor.render();

        // this.createUserInterface();

        this.setup();
    };

    async setBasicUrlParameters (){

        // if(this.isIframed) return this.isIframed;

        const {getParameterFromUrl} = (await import(/* webpackPrefetch: true */ "../../../functions/get/getParameterFromUrl.js"));
        const {convertStringToBoolean } = (await import(/* webpackPrefetch: true */ "../../../functions/convert/convertStringToBoolean.js"));  
            
        this.isIframed = getParameterFromUrl({paramName:"is_iframed"});
        this.isIframed = convertStringToBoolean(this.isIframed);

        this.whiteLabelReferer = getParameterFromUrl({paramName:"white_label_referer"});
        this.whiteLabelUrl = getParameterFromUrl({paramName:"white_label_url"});
        this.smetioBnac = getParameterFromUrl({paramName:"smetio_bnac"});
        this.baseUrl = getParameterFromUrl({paramName:"baseUrl"});
        this.coverImage = getParameterFromUrl({ paramName: "coverImage" });
        this.sideMenuToUse = getParameterFromUrl({ paramName: "sideMenuToUse" });
    };
    async getBaseUrl() {

        if (this.baseUrl) return this.baseUrl;

        const getBaseUrl = (await import(/* webpackPrefetch: true */ "../../../functions/get/getBaseUrl.js")).getBaseUrl;

        this.baseUrl = await getBaseUrl();

    };

    async setup() {

        const {changePageUrlFromIframe} = (await import(/* webpackPrefetch: true */ "../../../functions/change/changePageUrlFromIframe.js"));

        this.changePageUrlFromIframe = changePageUrlFromIframe;
            
        await this.setBasicUrlParameters();

        window.smetioVisitor = new SmetioVisitor({ language: this.language, logo:this.logo });
        await window.smetioVisitor.render();

        await this.getBaseUrl();

        if (this.createUserInterface) this.createUserInterface();

    };

    // removeSplashScreen() {

    //   setTimeout(() => {
    //     $(".smetio-page-splash-screen").fadeOut(200);
    //   }, 200);

    // };

    init() {

        // console.log("The init function is called ");

        this.waitingForVisitorToRegister = setInterval(() => {

            if (!window.smetioVisitor) return true;

            if (!window.smetioVisitor.isRegistered) return true;

            // if (!window.smetioVisitor.isLoggedIn) return location.href = SMETIO_LINK_LOGIN_PAGE;

            clearInterval(this.waitingForVisitorToRegister);

            return this.render();

        }, 500);

    };


    // render() {

    //   this.events();

    //   return `
    //     <div ${this.getAttributes(this.mainAttributes, false)}>

    //     </div>
    //   `;

    // };

    // events() {

    //   // $("body").on("mousedown", "."+this.uploadFilesBtnClass, ()=>{

    //   //     $("."+this.uploadFilesInputClass).trigger("click");

    //   // });	

    // };

};
