import {globalVar} from 
    "./../../variables/global_variables.js";

import {SmetioSmartbox} from 
    "./../../classes/smetioSmartbox/v1.0.0/SmetioSmartbox.js";

import {checkIfItIsEmpty} from
    "./../checks/checkIfItIsEmpty.js";  

export function quickNotification(obj){

    /**** 
     * THIS FUNCTION IS USED TO SHOW A QUICK NOTIFICATION THAT LASTS FOR FEW SECONDS AND THEN DISAPPEARS
     * 
     * 
     * 
     *  ****/

    const message = obj.message;
    const putMessageOnElement = obj.putMessageOnElement;
    
    const smetioSmartbox = new SmetioSmartbox();
    smetioSmartbox.title = "Notice";
    smetioSmartbox.content = message;
    smetioSmartbox.contentType = "not form";
    smetioSmartbox.contentUniqueIdentifier = "gfg";
    const smartBoxId = smetioSmartbox.getId(); 

    const smartBox = smetioSmartbox.render(putMessageOnElement);

    setTimeout(function(){

        smetioSmartbox.close();

    }, 5000);



};