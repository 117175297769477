
import { getId } from "../../universal_methods/getId.js";
import { setId } from "../../universal_methods/setId.js";
import { generateId } from "../../universal_methods/generateId.js";
import { isEmpty } from "../../universal_methods/isEmpty.js";
import { getAttributes } from "../../universal_methods/getAttributes.js";
import { toggleDisplay } from "../../universal_methods/toggleDisplay.js";

import { setTxt } from "../../universal_methods/setTxt.js";




//import "./styles/_main.scss";
import { SmetioAppUiWikiHandler } from "../../smetioAppUiWikiHandler/v1.0.0/SmetioAppUiWikiHandler.js";
import { SmetioAppUiSettingsHandler } from "../../smetioAppUiSettingsHandler/v1.0.0/SmetioAppUiSettingsHandler.js";
import { SmetioAppUiAppsAndUtilitiesHandler } from "../../smetioAppUiAppsAndUtilitiesHandler/v1.0.0/SmetioAppUiAppsAndUtilitiesHandler.js";




export class SmetioSideBar {

  constructor() {


    this.getId = getId;
    this.setId = setId;
    this.generateId = generateId;
    this.isEmpty = isEmpty;
    this.getAttributes = getAttributes;
    this.toggleDisplay = toggleDisplay;
    this.setTxt = setTxt;

    this.setId();

    this.wikiHandler = new SmetioAppUiWikiHandler();
    this.settingsHandler = new SmetioAppUiSettingsHandler();
    this.appsAndUtilitiesHandler = new SmetioAppUiAppsAndUtilitiesHandler();


    this.mainClass = "smetio-app-ui-side-bar-"+this.id;
    this.mainAttributes = {
      class: [
        "smetio-app-ui-side-bar",
        this.mainClass
      ],
    };

    this.iconClass = [
      "smetio-app-ui-side-bar-icon",
      "smetio-icon-secondary",
    ];

    this.wikiBtnClass = "smetio-app-ui-side-bar-wikiBtn-"+this.id;
    this.wikiBtnAttributes = {
      class: [
        ...this.iconClass,
        this.wikiBtnClass
      ],
    };
    this.setTxt({
      attributes: this.wikiBtnAttributes,
      txt: "txt195",
      type:"title" 
    });	

    this.settingsBtnClass = "smetio-app-ui-side-bar-settingsBtn-"+this.id;
    this.settingsBtnAttributes = {
      class: [
        ...this.iconClass,
        this.settingsBtnClass
      ],
    };
    this.setTxt({
      attributes: this.settingsBtnAttributes,
      txt: "txt196",
      type:"title" 
    });	

    this.appsAndUtilitiesBtnClass = "smetio-app-ui-side-bar-appsAndUtilitiesBtn-"+this.id;
    this.appsAndUtilitiesBtnAttributes = {
      class: [
        ...this.iconClass,
        this.appsAndUtilitiesBtnClass
      ],
    };
    this.setTxt({
      attributes: this.appsAndUtilitiesBtnAttributes,
      txt: "txt197",
      type:"title" 
    });

    this.activeMainMenuClass = "smetio-app-ui-side-bar-activeMainMenu-"+this.id;
    this.activeMainMenuAttributes = {
      class: [
        "smetio-app-ui-side-bar-activeMainMenu",
        this.activeMainMenuClass
      ],
    };


  };

  render() {

    const output = `

      <div ${this.getAttributes(this.mainAttributes, false)} >
                
        <div ${this.getAttributes(this.wikiBtnAttributes, false)} >        
            <i class="smetio-icon smetio-icon-question-circle-o"></i>     
        </div>
                          
        <div ${this.getAttributes(this.settingsBtnAttributes, false)} >        
            <i class="smetio-icon smetio-icon-gears"></i>
        </div>

        <div ${this.getAttributes(this.appsAndUtilitiesBtnAttributes, false)} >        
            <i class="smetio-icon smetio-icon-server"></i>     
        </div>

        <!-- 
        <div ${this.getAttributes(this.activeMainMenuAttributes, false)} >                            
            <p> Contacts </p>  
        </div> 
        -->
                                              
      </div>
    
    `;

    this.events();

    return output;

  };

  events() {

    $("body").on("mousedown", "." + this.wikiBtnClass, () => {

      //console.log("you clicked wiki");
      this.wikiHandler.open();

    });

    $("body").on("mousedown", "." + this.settingsBtnClass, () => {

      //console.log("you clicked settings");
      this.settingsHandler.open();
      
    });

    $("body").on("mousedown", "." + this.appsAndUtilitiesBtnClass, () => {

      //console.log("you clicked apps and utilities");
      this.appsAndUtilitiesHandler.open();
      
    });    


  }


};
