//import {test_function} from "./../functions/test_function.js";



// import {getId} from "../../universal_methods/getId.js";  
// import {setId} from "../../universal_methods/setId.js";
// import {generateId} from "../../universal_methods/generateId.js";
// import {isEmpty} from "../../universal_methods/isEmpty.js";  
// import {getAttributes} from "../../universal_methods/getAttributes.js";  
// import {toggleDisplay} from "../../universal_methods/toggleDisplay.js"; 



// //import "./styles/_main.scss";

import { SmetioPage } from "../../smetioPage/v1.0.0/SmetioPage.js";



export class SmetioCodeVerificationPage extends SmetioPage {

  constructor(data = {}) {

    super(data);

    this.mainClass = "smetio-code-verification-page-" + this.id;
    this.mainAttributes = {
      class: ["smetio-code-verification-page", this.mainClass],
    };

  };

  async getModelParameters(data = {}) {

    const getParameterFromUrl = (await import(/* webpackPrefetch: true */ `../../../functions/get/getParameterFromUrl.js`)).getParameterFromUrl;
    
    const hlp = getParameterFromUrl({paramName:"hlp"}); // hide_legal_policy

    const apiEndPoint = window.apiEndPoint;
    const language = window.userLanguage;

    const url = `${window.defaultPath}verificationCode/model/${language}`;
    const {publicProfileId, verificationToken} = this.extraParameters;
    this.addToSubmissionData = {publicProfileId, verificationToken};

    // console.log("publicProfileId, verificationToken are ", publicProfileId, verificationToken);

    return {
      url,
      submitText: "txt106",
      instructionTitle: "txt105",
      params:{hlp},
      // instructionSubtitle: "txt598",
      submissionFunctionDetails:{
        // functionPath: "login_and_signup/submitLoginDetails",
        predefinedFunction:this.submitFormData,
        functionParameters: {
          preloaderClass: this.preloader,
          url:`${window.defaultPath}verificationCode/${language}`,
          modelPath: "pages/verificationCode/verificationCodeModel"
        }
      }
    };

  };

  events() {

    // $("body").on("mousedown", "."+this.uploadFilesBtnClass, ()=>{

    //     $("."+this.uploadFilesInputClass).trigger("click");

    // });	

  };

};
