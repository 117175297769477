//import {test_function} from "./../functions/test_function.js";



import { getId } from "../../universal_methods/getId.js";
import { setId } from "../../universal_methods/setId.js";
import { generateId } from "../../universal_methods/generateId.js";
import { isEmpty } from "../../universal_methods/isEmpty.js";
import { getAttributes } from "../../universal_methods/getAttributes.js";
import { toggleDisplay } from "../../universal_methods/toggleDisplay.js";
// import { universal_handleErrorFromServer } from "../../universal_methods/universal_handleErrorFromServer.js";
// import { combineSmetioAttributes } from "../../universal_methods/combineSmetioAttributes.js";
import { setTxt } from "../../universal_methods/setTxt.js";




// import { smetioTxt } from
//   "../../../variables/smetioTxt.js";

// import { SmetioOverlayPreloader } from
//   "../../smetioOverlayPreloader/SmetioOverlayPreloader.js";



// import { SmetioAjax } from
//   "../../smetioAjax/SmetioAjax.js";




// //import "./styles/_main.scss";
import { SmetioDataPresentation } from "../../smetioDataPresentation/v1.0.0/SmetioDataPresentation.js";

import * as usableMenu from "../../../constants/smetioDataRepresentationMenuItems.js";
import { SmetioSmartbox } from "../../smetioSmartbox/v1.0.0/SmetioSmartbox.js";
import { SmetioSlideShow } from "../../smetioSlideShow/SmetioSlideShow.js";
import { SmetioPreloader } from "../../smetioPreloader/SmetioPreloader.js";
// import axios from "axios";
// import { getBackBtnToUseOnSlideShow } from "../../../functions/get/getBackBtnToUseOnSlideShow.js";
// import { SmetioPreloader } from "../../smetioPreloader/SmetioPreloader.js";



export class SmetioDataGroup {

    constructor(data = {
        uniqueIdentifier1: {
            presentationClass: "OPTIONAL", // HAS TO BE AN INSTANCE OF SmetioDataPresentation 
            presentationData: {
                id: "",
                viewStyle: "cardView",
                coverImage: "",
                logo: "",
                showLogo: true,
                title: "",
                subTitle: "",
                paragraph: "",
                menuItems: {
                    delete: {
                        text: "",
                        icon: "",
                        onClick: () => { }
                    },
                    view: {
                        text: "",
                        icon: "",
                        onClick: () => { }
                    }
                },
                uniqueValues: {
                    // "Some id that can be used to identify the data in a database should be put here",  
                    inputsModel: {
                        // SEE SmetioObjectToInput FOR ALL THE OPTIONS THAT EACH ITEM/INPUT MODEL CAN CONTAIN
                    }
                },
                onSelected: (uniqueValues) => {
                    // WHAT SHOULD HAPPEN WHEN THIS DATA PRESENTATION IS SELECTED
                },
                onDeselected: (uniqueValues) => {
                    // WHAT SHOULD HAPPEN WHEN THIS DATA PRESENTATION IS DESELECTED
                },
            },
        }
    }) {

        // SmetioDataGroup IS A COLLECTION OF SEVERAL RELATED SmetioDataPresentation 
        // RELATED LIKE GROUPS OF BUSINESS LOCATIONS, BOOKINGS -- BASICALLY CORRESPONDING TO A GIVIN/CLICKED SMETIO SIDE MENU ITEM
        /**
     * EXAMPLE OF DATA THAT SHOULD BE PASSED TO THIS CLASS
     data={
      uniqueIdentifier1:{},
      uniqueIdentifier2:{},
      uniqueIdentifier3: {
        presentationClass: "OPTIONAL", // HAS TO BE AN INSTANCE OF SmetioDataPresentation 
        presentationData: {
          id: "",
          viewStyle: "cardView",
          coverImage: "",
          logo: "",
          showLogo: true,
          title: "",
          subTitle: "",
          paragraph: "",
          menuItems: {
            delete: {
              text: "",
              icon: "",
              onClick: () => { }
            },
            view: {
              text: "",
              icon: "",
              onClick: () => { }
            }
          },
          uniqueValues: {
            // "Some id that can be used to identify the data in a database should be put here",  
            inputsModel: {
              // SEE SmetioObjectToInput FOR ALL THE OPTIONS THAT EACH ITEM/INPUT MODEL CAN CONTAIN
            }
          },
          onSelected: (uniqueValues) => {
            // WHAT SHOULD HAPPEN WHEN THIS DATA PRESENTATION IS SELECTED
          },
          onDeselected: (uniqueValues) => {
            // WHAT SHOULD HAPPEN WHEN THIS DATA PRESENTATION IS DESELECTED
          },
  },
    }
    }
     */

        this.getId = getId;
        this.setId = setId;
        this.generateId = generateId;
        this.isEmpty = isEmpty;
        this.getAttributes = getAttributes;
        this.toggleDisplay = toggleDisplay;
        this.setTxt = setTxt;

        this.smartbox = "";

        this.smetioSlideShow = new SmetioSlideShow();

        // const SmetioPreloader = (await import(/* webpackPrefetch: true */ `../../smetioPreloader/SmetioPreloader.js`)).SmetioPreloader;
        this.preloader = new SmetioPreloader();


        this.setId();

        this.data = data || {};
        this.dataRetrieverEndpoint = "";
        this.dataRetrieverEndpointFilters = "";


        this.keysOfSelectedItems = {};
        this.availableMenus = {};
        this.sections = {};
        this.sectionsWithLoadedData = {};
        this.itemsPerSection = 4;
        this.defaultLimit = 5;
        this.totalNumbersOfData = 0;
        this.currentlyVisibleSection = 0;
        this.currentlyVisibleData = {};
        this.currentViewStyle = "cardView";
        this.axiosRequest = null;
        this.axiosController = null;

        this.mainClass = "smetio-data-group-" + this.id;
        this.mainAttributes = {
            "data-smetio-view-style": this.currentViewStyle,
            class: ["smetio-data-group", this.mainClass],
        };

        this.menuItemClass = "smetio-data-group-menu-item-" + this.id;

    };
    selectAll() {
        for (const key in this.currentlyVisibleData) {
            if (!this.data[key]["presentationClass"]) continue;
            this.data[key]["presentationClass"].smetioCustomCheckbox.select();
        }
    };
    deselectAll() {

        for (const key in this.currentlyVisibleData) {
            if (!this.data[key]["presentationClass"]) continue;
            this.data[key]["presentationClass"].smetioCustomCheckbox.deselect();
        };
    };
    getSelectMenuItem(menuName) {

        const uniqueClass = `${this.menuItemClass}-${menuName}`;

        menuName = `smetio_${menuName}`;
        const menuItem = usableMenu[menuName];

        const textAttributes = {
            class: ["smetio-data-group-menu-item-text"],
        };
        const text = this.setTxt({
            attributes: textAttributes,
            txt: menuItem.text,
            type: "html"
        });

        const newAttributes = {
            class: ["smetio-data-group-menu-item", this.menuItemClass, uniqueClass]
        };
        return `
      <div ${this.getAttributes(newAttributes, false)}>
        <div class="smetio-data-group-menu-item-icon">
          <i class="smetio-icon smetio-icon-${menuItem.icon}"></i>
        </div>
        <div ${this.getAttributes(textAttributes, false)}>
          ${text}
        </div>          
      </div>
    `;
    };

    getSelectMenu(data = {
        uniqueValues: {
            groupKey: "",
            smetioPresentationId: ""
        },
        menuItems: {}
    }) {
        // WE WANT TO DISPLAY ONLY THE MENU THAT IS COMMON AMONG THE SELECTED GROUP MEMBERS

        let output = "";

        for (const key in this.availableMenus) {

            const numberAssigned = Object.keys(this.availableMenus[key]).length;

            if (numberAssigned == Object.keys(this.keysOfSelectedItems).length) output += this.getSelectMenuItem(key);
        };

        if (output == "") return false;

        return `
    <div class="smetio-data-group-menu">
      ${output}
    </div>
    `;

    };

    groupMemberIsDeSelected(data = {
        uniqueValues: {
            groupKey: "",
            smetioPresentationId: ""
        },
        menuItems: {}
    }) {

        const groupKey = data.uniqueValues.groupKey;

        if (this.keysOfSelectedItems[groupKey]) delete this.keysOfSelectedItems[groupKey];

        const menuItems = data.menuItems;

        for (const menu in menuItems) {

            if (!this.availableMenus[menu]) continue;

            if (this.availableMenus[menu][groupKey]) delete this.availableMenus[menu][groupKey];

            if (Object.keys(this.availableMenus[menu]).length < 1) delete this.availableMenus[menu];

        };

        const menuToShowOnSelect = this.getSelectMenu(data);

        if (Object.keys(this.keysOfSelectedItems).length < 1 || menuToShowOnSelect == false) {

            if (this.smartbox) this.smartbox.close();
            return false;

        };

        if (!this.smartbox) return false;

        this.smartbox.updateContent(menuToShowOnSelect);

        return this.smartbox.reactivate();

    };

    groupMemberIsSelected(data = {
        uniqueValues: {
            groupKey: "",
            smetioPresentationId: ""
        },
        menuItems: {}
    }) {

        const groupKey = data.uniqueValues.groupKey;

        this.keysOfSelectedItems[groupKey] = true;

        const { menuItems } = data;

        for (const menu in menuItems) {

            if (!this.availableMenus[menu]) this.availableMenus[menu] = {};
            this.availableMenus[menu][groupKey] = true;
        };

        const menuToShowOnSelect = this.getSelectMenu(data);

        if (!this.smartbox) this.smartbox = new SmetioSmartbox();

        if (this.smartbox.hasBeenRendered && this.smartbox.currentState !== "close") {

            this.smartbox.updateContent(menuToShowOnSelect);

            return this.smartbox.reactivate();
        };

        this.smartbox.title = "txt264";
        this.smartbox.content = menuToShowOnSelect;
        this.smartbox.onClose = () => {
            this.deselectAll();
            this.smartbox = null;
        };

        this.smartbox.render();

    };

    hideAllData() {
        $(".smetio-data-presentation-" + this.id).attr("data-smetio-is-visible", false);
    };

    showAllData() {
        $(".smetio-data-presentation-" + this.id).attr("data-smetio-is-visible", true);
    };

    getAllValuesFromInputModel(model) {
        const output = [];

        for (const key in model) {
            const item = model[key];
            if (item["renderAsInput"] == false) continue;
            if (!item["details"]) continue;
            if (!item["details"]["attributes"] && !item["details"]["sendToServer"]) continue;
            const value = item["details"]["attributes"]["value"] || item["details"]["sendToServer"]["value"] || "";

            if (!this.isEmpty(value)) output.push(value);
        };

        return output;
    };

    findString(data = { valuesTosearch, searchWord }) {

        const { valuesTosearch, searchWord } = data;

        for (let i = 0; i < valuesTosearch.length; i++) {

            let value = valuesTosearch[i];
            if (this.isEmpty(value)) continue;

            console.log("Value one is ", value);

            value = this.setTxt({
                attributes: {},
                txt: value,
                type: "html"
            });

            value = value.toLowerCase();

            console.log("Value two is ", value);

            if (value.includes(searchWord.toLowerCase())) return true;

        };

        return false;
    };

    async searchForData(searchWord) {

        if (this.isEmpty(searchWord)) {

            return this.renderSection(this.currentlyVisibleSection);
            // return this.showAllData();
        };

        await this.unRenderSection(this.currentlyVisibleSection);

        // this.hideAllData();

        const temporarySection = {};

        for (const key in this.data) {

            if (!this.data[key]["presentationData"]["uniqueValues"]) continue;

            const inputsModel = this.data[key]["presentationData"]["uniqueValues"]["inputsModel"];
            const valuesFromModel = this.getAllValuesFromInputModel(inputsModel);
            const { title, subTitle, paragraph } = this.data[key]["presentationData"];
            const valuesTosearch = [title, subTitle, paragraph, ...valuesFromModel];

            // if (this.data[key]["presentationClass"].containSearchWord(searchWord)) this.data[key]["presentationClass"].show();
            // if ( this.data[key]["presentationClass"].containSearchWord(searchWord) ) temporarySection[key] = true;
            if (this.findString({ valuesTosearch, searchWord })) temporarySection[key] = true;

            if (Object.keys(temporarySection).length >= 50) break;

        };

        this.renderSection(this.currentlyVisibleSection, temporarySection);

    };

    renderDataPresentation(data) {

        this.setCurrentViewStyle();

        let output = "";


        console.log("This is the data ", data);

        this.currentlyVisibleData = {};

        for (const key in data) {

            // if(key.includes("placeholder")) continue;
            if (key.includes("sampleDataPresentation-")) continue;

            this.currentlyVisibleData[key] = true;

            console.log("this is the this.data[key] ", this.data[key]);

            if (!this.data[key]["presentationData"]["uniqueValues"]) this.data[key]["presentationData"]["uniqueValues"] = {};
            this.data[key]["presentationData"]["uniqueValues"]["groupKey"] = key; // WILL BE USED TO TARGET EACH DATA PRESENTATION LATER
            this.data[key]["presentationData"]["uniqueValues"]["groupId"] = this.id;
            this.data[key]["presentationData"]["onSelected"] = (data = { uniqueValues, menuItems }) => {
                data.menuItems["selectAll"] = {};
                data.menuItems["deselectAll"] = {};
                this.groupMemberIsSelected(data);
            };
            this.data[key]["presentationData"]["onDeselected"] = (data = { uniqueValues, menuItems }) => {
                data.menuItems["selectAll"] = {};
                data.menuItems["deselectAll"] = {};
                this.groupMemberIsDeSelected(data);
            };

            this.data[key]["presentationData"]["viewStyle"] = this.currentViewStyle;
            this.data[key]["presentationData"]["id"] = key;
            // if (!this.data[key]["presentationClass"]) this.data[key]["presentationClass"] = new SmetioDataPresentation(this.data[key]["presentationData"]);
            if (!this.data[key]["presentationClass"]) this.data[key]["presentationClass"] = null;

            this.data[key]["presentationClass"] = new SmetioDataPresentation(this.data[key]["presentationData"]);
            output += this.data[key]["presentationClass"].render();

        };

        return output;

    };

    splitDataIntoSections() {

        let newSection = 0;
        let dataAddedToSection = 0;
        this.sections[newSection] = {};

        for (const key in this.data) {

            // this.sections[newSection][key] = this.data[key];
            this.sections[newSection][key] = true;

            dataAddedToSection = dataAddedToSection + 1;

            if (dataAddedToSection === this.itemsPerSection) {

                dataAddedToSection = 0;
                newSection = newSection + 1;
                this.sections[newSection] = {};

            };

        };

    };

    async getDataFromServer(data = {}) {

        const { filters, isFinalCall } = data;

        // if(isFinalCall == true) return this.preloader.hide();

        const axios = (await import(/* webpackPrefetch: true */ "axios")).default;

        // let cancelled = null;

        // if (this.axiosController ) cancelled = await this.axiosController.abort();

        this.axiosController = new AbortController();

        this.preloader.show();

        const res = await axios.get(this.dataRetrieverEndpoint, { signal: this.axiosController.signal, params: { ...filters } }).then(async (response) => {

            let dataPresentations = response.data;

            if (dataPresentations.error) return window.smetioVisitor.showNotification({ type: "danger", body: [{ message: dataPresentations.msg }] });

            if (isFinalCall == true) this.preloader.hide();

            return dataPresentations.msg;

        }).catch((error) => {

            this.preloader.hide();
            console.log(error);
            return window.smetioVisitor.showNotification({ type: "danger", body: [{ message: "txt423" }] });

        }).finally(() => {
            // this.preloader.hide();
            this.axiosController = null;
        });

        return res;

    };

    async replaceDataInSections(sectionNumber, offset, dataPresentations) {

        for (const key in this.sections[sectionNumber]) {

            if (!key.includes("sampleDataPresentation-")) continue;

            delete this.sections[sectionNumber][key];

            if (!this.data[key]) continue;

            if (this.data[key]["presentationClass"]) {

                this.data[key]["presentationClass"].delete();
                this.data[key]["presentationClass"] = null;

            };

            if (this.data[key]) delete this.data[key];

        };


        for (const key in dataPresentations) {

            const dataPresentation = dataPresentations[key];

            if (!dataPresentation.presentationData) {

                console.log("########## dataPresentation is ", dataPresentation);

                continue;
            };

            const dataPresentationId = dataPresentation.presentationData.id;

            console.log("dataPresentationId is ", dataPresentationId);

            this.sections[sectionNumber][dataPresentationId] = true;
            this.data[dataPresentationId] = dataPresentation;

            // i++;

            // const element = i + offset;
            // const idToDelete = `sampleDataPresentation-${element}`;

            // console.log("idToDelete is ", idToDelete);


            // if(!this.sections[sectionNumber][idToDelete]) continue;

            // if (this.sections[sectionNumber][idToDelete]) delete this.sections[sectionNumber][idToDelete];

            // if(!this.data[idToDelete]) continue;

            // if(this.data[idToDelete]["presentationClass"]) {

            //   this.data[idToDelete]["presentationClass"].delete();
            //   this.data[idToDelete]["presentationClass"] = null;

            // };

            // if (this.data[idToDelete]) delete this.data[idToDelete];



        };

    };

    async getDataFromServerAndAddToSectionBeforeRendingTheSection(sectionNumber) {


        let { limit, searchWord } = this.dataRetrieverEndpointFilters;

        console.log("************************ The limit here ", limit, "The searchWord here ", searchWord);

        if (this.isEmpty(limit)) limit = Math.min(this.itemsPerSection, this.defaultLimit);

        console.log("The limit again", limit);

        let totalItemsToDisplay = this.totalNumbersOfData; // Total number of items to display on the slideshow

        let itemsToRetrieve;
        let offset;

        // For the first slide (sectionNumber = 0), display 8 items
        if (sectionNumber === 0) {
            itemsToRetrieve = Math.min(totalItemsToDisplay, this.itemsPerSection);
            offset = 0;
        } else {
            // For subsequent slides, display the remaining items
            // itemsToRetrieve = Math.max(totalItemsToDisplay - this.itemsPerSection, 0);
            itemsToRetrieve = Math.min(totalItemsToDisplay, this.itemsPerSection);
            offset = this.itemsPerSection * sectionNumber; // Start retrieving from the 9th item
        }

        console.log("The offset ", offset);

        console.log("itemsToRetrieve is ", itemsToRetrieve);

        // Calculate the required number of calls based on the limit
        let requiredNumbersOfCall = Math.ceil(itemsToRetrieve / limit);

        if ((offset + limit) >= totalItemsToDisplay) requiredNumbersOfCall = 1;

        console.log("Numbers of call ", requiredNumbersOfCall);

        console.log("The sum of (offset + limit) is ", (offset + limit));

        // requiredNumbersOfCall = 1;
        // Iterate over the number of calls
        for (let i = 0; i < requiredNumbersOfCall; i++) {

            const currentOffset = offset + i * limit;

            const filters = { limit, offset: currentOffset, searchWord };

            // Determine if this is the final call
            const isFinalCall = i == (requiredNumbersOfCall - 1) ? true : false;

            const dataPresentations = await this.getDataFromServer({ filters, isFinalCall });

            // console.log("dataPresentations is ", dataPresentations);

            const dataIsReplaced = await this.replaceDataInSections(sectionNumber, offset, dataPresentations);

            const sectionIsRendered = await this.renderSection(sectionNumber);

        };

        this.sectionsWithLoadedData[sectionNumber] = true;
        // this.totalNumbersOfData = 0;

    };

    async renderSection(sectionNumber, sectionData) {

        console.log("this.currentlyVisibleSection is ", this.currentlyVisibleSection);

        this.currentlyVisibleSection = sectionNumber == 0 ? 0 : sectionNumber || this.currentlyVisibleSection;

        console.log("the new this.currentlyVisibleSection is ", this.currentlyVisibleSection);

        sectionData = sectionData || this.sections[this.currentlyVisibleSection];

        console.log("This is the section ", this.sections);

        console.log("sectionData is ", sectionData);

        const newContent = this.renderDataPresentation(sectionData);

        this.smetioSlideShow.changeItemContent(sectionNumber, newContent);

        console.log("Just changed slide content !!!!!!!!!!!!!!!! ");

    };

    async unRenderSection(sectionNumber, sectionData) {

        sectionData = sectionData || this.sections[sectionNumber];

        this.currentViewStyle = false;

        for (const key in sectionData) {

            if (!this.data[key]["presentationClass"]) continue;

            if (this.currentlyVisibleData[key]) delete this.currentlyVisibleData[key];

            if (!this.currentViewStyle) this.currentViewStyle = this.data[key]["presentationClass"].getViewStyle();

            await this.data[key]["presentationClass"].unRender();
            delete this.data[key]["presentationClass"];

        };

    };

    setCurrentViewStyle() {

        this.currentViewStyle = $(`.${this.mainClass}`).attr("data-smetio-view-style") || this.currentViewStyle;

    };

    async getSlideShowFromSections(data = {}) {

        const { calledFromRenderFunc } = data;

        this.setCurrentViewStyle();

        // const getBackBtnToUseOnSlideShow = (await import(/* webpackPrefetch: true */ `../../../functions/get/getBackBtnToUseOnSlideShow.js`)).getBackBtnToUseOnSlideShow;
        // const getForwardBtnToUseOnSlideShow = (await import(/* webpackPrefetch: true */ `../../../functions/get/getForwardBtnToUseOnSlideShow.js`)).getForwardBtnToUseOnSlideShow;



        // this.currentViewStyle = $(`.${this.mainClass}`).attr("data-smetio-view-style") || this.currentViewStyle;
        // if (!this.smetioSlideShow) this.smetioSlideShow = new SmetioSlideShow();
        if (!this.smetioSlideShow) this.smetioSlideShow = null;
        this.smetioSlideShow = new SmetioSlideShow();
        this.smetioSlideShow.viewStyle = this.currentViewStyle;

        let numberOfItems = 0;

        // if(reRender == true) this.smetioSlideShow.clearItems();

        for (const key in this.sections) {

            const sectionData = this.sections[key];

            let content = "";
            let isActive = "no";

            if (numberOfItems == this.currentlyVisibleSection) {
                content = this.renderDataPresentation(sectionData);
                isActive = "yes";
            };

            let slideShowItem = {

                class: "",
                content,
                showIcon: false,
                indicator: {
                    icon: "thumbs-up",
                    text: numberOfItems,
                    isActive,
                    class: ""
                }

            };

            numberOfItems = numberOfItems + 1;

            this.smetioSlideShow.add_items(slideShowItem);

        };

        this.smetioSlideShow.show_indicator = "yes";
        this.smetioSlideShow.show_controller = "yes";
        this.smetioSlideShow.controllerPlacement = "bottom";
        this.smetioSlideShow.allow_swipe = "no";
        // this.smetioSlideShow.customController = {
        //   left: await getBackBtnToUseOnSlideShow(),
        //   right: await getForwardBtnToUseOnSlideShow()
        // };

        this.smetioSlideShow.onSlideChanged = async (data = { nextSlidPosition: "", direction: "right", previouslyViewedSlide: "" }) => {

            let { nextSlidPosition, direction, previouslyViewedSlide } = data;

            let previousSectionNumber = direction === "right" ? nextSlidPosition - 1 : nextSlidPosition + 1;
            let nextSectionNumber = nextSlidPosition;

            if (!this.isEmpty(previouslyViewedSlide)) previousSectionNumber = previouslyViewedSlide;

            await this.unRenderSection(previousSectionNumber);
            // this.renderSection(nextSectionNumber);
            const dataAlreadyLoaded = this.sectionsWithLoadedData[nextSectionNumber] ? true : false;
            const whatToDo = dataAlreadyLoaded ? this.renderSection(nextSectionNumber) : this.getDataFromServerAndAddToSectionBeforeRendingTheSection(nextSectionNumber);
        };

        // if(calledFromRenderFunc){
        // setTimeout(() => {
        //   this.smetioSlideShow.triggerIndicatorClick(1);
        // }, 300);
        // };


        // if(reRender == true) return this.smetioSlideShow.reRender();
        const preloader = this.preloader.render();
        return ` ${preloader} ${this.smetioSlideShow.render()}`;

    };

    deactivate() {
        this.unRenderSection(this.currentlyVisibleSection, this.currentlyVisibleData);
    };

    activate() {
        this.renderSection(this.currentlyVisibleSection);
    };

    async render() {

        this.splitDataIntoSections();

        this.events();

        // const SmetioPreloader = (await import(/* webpackPrefetch: true */ `../../smetioPreloader/SmetioPreloader.js`)).SmetioPreloader;
        // if(!this.preloader) this.preloader = new SmetioPreloader();
        // const preloader = this.preloader.render();

        // return this.getSlideShowFromSections();

        const slideShow = await this.getSlideShowFromSections();

        return `
            <div ${this.getAttributes(this.mainAttributes, false)} >
            ${slideShow}
            </div>
            `;

    };

    async addNewItemToSection(presentationData) {

        // console.log("Add new item to section ************* ", presentationData);


        for (const key in this.sections) {

            // console.log("THIS IS THE KEY IN THE SECTIONS", key);
            // console.log("THIS IS THE VALUE IN THE SECTIONS", this.sections[key]);
            // console.log("THIS IS THE LENGTH OF THE SECTIONS", Object.keys(this.sections[key]).length);
            // console.log("THIS IS THE ITEMS PER SECTION", this.itemsPerSection);

            if (Object.keys(this.sections[key]).length < this.itemsPerSection) {
                this.sections[key][presentationData.id] = true;

                // console.log("THIS IS THE SECTION AFTER ADDING THE NEW ITEM", this.sections);

                return false;
            }

        };

        // console.log("A NEW SECTION IS NEEDED ****************");
        const newSectionNumber = parseInt(Object.keys(this.sections).pop()) + 1;

        // console.log("THIS IS THE NEW SECTION NUMBER ", newSectionNumber);
        // console.log("THIS IS THE SECTIONS BEFORE A NEW SECTION IS CREATED ", this.sections);

        this.sections[newSectionNumber] = {
            [presentationData.id]: true
        };


        const slideShow = await this.getSlideShowFromSections();

        $(`.${this.mainClass}`).html(`${slideShow}`);
        // this.getSlideShowFromSections();

        /*     let slideShowItem = {
    
          class: "",
          content: "",
          showIcon: false,
          indicator: {
            icon: "thumbs-up",
            text: newSectionNumber,
            isActive:"no",
            class: ""
          }
    
        };
    
        this.smetioSlideShow.appendNewItem(slideShowItem); */

        // console.log("THIS IS THE SECTION AFTER A NEW SECTION IS CREATED ", this.sections);


    };

    deleteItemToSection(dataPresentationId) {

        for (const key in this.sections) {

            if (!this.sections[key][dataPresentationId]) continue;

            delete this.sections[key][dataPresentationId];

            break;
        };

        this.splitDataIntoSections();
    };

    addNewDataPresentation(dataPresentationObj) {

        console.log("Add new data presentation to group ************* ", dataPresentationObj);

        const { presentationData } = dataPresentationObj;

        this.data[presentationData.id] = dataPresentationObj;

        // this.splitDataIntoSections();
        this.addNewItemToSection(presentationData);
        this.renderSection(this.currentlyVisibleSection);
        // this.getDataFromServerAndAddToSectionBeforeRendingTheSection(this.currentlyVisibleSection);


        // return this.getSlideShowFromSections();

    };

    deleteDataPresentation(dataPresentationId) {

        this.data[dataPresentationId]["presentationClass"].delete();
        this.data[dataPresentationId]["presentationClass"] = null;
        if (this.data[dataPresentationId]) delete this.data[dataPresentationId];
        this.deleteItemToSection(dataPresentationId);
        this.renderSection(this.currentlyVisibleSection);
    };

    doneWithAddingSampleDataPresentation() {

        console.log("So you are doneWithAddingSampleDataPresentation @@@@@@@@@@@@@@@@@@@@@@@@@@");
        setTimeout(() => {
            this.smetioSlideShow.triggerIndicatorClick(1);
        }, 500);
        // this.smetioSlideShow.triggerIndicatorClick(1);
    };

    events() {

        $("body").on("mousedown", "." + this.menuItemClass + "-selectAll", () => {

            this.selectAll();

        });

        $("body").on("mousedown", "." + this.menuItemClass + "-deselectAll", () => {

            this.deselectAll();

        });

    };

};
