
export const SMETIO_LINK_HOME_PAGE = "https://smetio.com";

export const SMETIO_LINK_CDN = "https://cdn.smetio.com";

export const SMETIO_LINK_LOGIN_PAGE = SMETIO_LINK_HOME_PAGE+"/login";

export const SMETIO_LINK_SIGNUP_PAGE = SMETIO_LINK_HOME_PAGE+"/signup";

export const SMETIO_LINK_LOGOUT_PAGE = SMETIO_LINK_HOME_PAGE+"/logout";

export const SMETIO_LINK_RETRIVE_DATA = SMETIO_LINK_HOME_PAGE+"/retrieveData";

export const SMETIO_LINK_GET_DATA = SMETIO_LINK_RETRIVE_DATA+"/get";

export const SMETIO_LINK_POST_DATA = SMETIO_LINK_RETRIVE_DATA+"/post";

export const SMETIO_LINK_DELETE_DATA = SMETIO_LINK_RETRIVE_DATA+"/delete";

export const SMETIO_LINK_UPDATE_DATA = SMETIO_LINK_RETRIVE_DATA+"/update";

export const SMETIO_LINK_IMAGES = SMETIO_LINK_CDN+"/img";

export const SMETIO_LINK_BRAND_IMAGES = SMETIO_LINK_IMAGES+"/smetio_brands";

export const SMETIO_LINK_LOGO_TEXT = SMETIO_LINK_BRAND_IMAGES+"/smetio/smetio_logo.svg";

export const SMETIO_LINK_PROFILE_PIC_PLACEHOLDER = SMETIO_LINK_IMAGES+"/placeholders/profil_placeholder.png";

export const SMETIO_LINK_PROFILE_PAGE = "https://profile.smetio.com";



/*
smetio_cdn: "https://cdn.smetio.com/",
smetio_retrieveData_url: "https://smetio.com/retrieveData/",
link_to_circular_flags: "img/flags/countries/circular_flags/",
link_to_rectangular_flags: "https://cdn.smetio.com/img/flags/countries/rectangular_flags/",
profileImgPlaceholder: "https://cdn.smetio.com/img/placeholders/profil_placeholder.png",
link_to_legal_policies: "https://schaniag.com/legal_policies/",
link_to_webRtcSignal: "signal.smetio.com",
link_to_loginPage: "smetio.com/login",
*/