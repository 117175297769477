//import {test_function} from "./../functions/test_function.js";



import { getId } from "../../universal_methods/getId.js";
import { setId } from "../../universal_methods/setId.js";
import { generateId } from "../../universal_methods/generateId.js";
import { isEmpty } from "../../universal_methods/isEmpty.js";
import { getAttributes } from "../../universal_methods/getAttributes.js";
import { toggleDisplay } from "../../universal_methods/toggleDisplay.js";

import { setTxt } from "../../universal_methods/setTxt.js";







//import "./styles/_main.scss";



export class SmetioCustomCheckbox {

  constructor(data = {
    attributes: {},
    changeEvents: {
      onChecked: (value) => { },
      onUnChecked: (value) => { },
    }
  }) {


    this.getId = getId;
    this.setId = setId;
    this.generateId = generateId;
    this.isEmpty = isEmpty;
    this.getAttributes = getAttributes;
    this.toggleDisplay = toggleDisplay;
    this.setTxt = setTxt;


    this.isReadonly = data.attributes.isReadonly || false;
    this.inputIsChecked = data.attributes.checked === true ? true : false;
    this.changeEvents = data.changeEvents;

    // this.setId();
    this.getId();

    this.mainClass = "smetio-custom-checkbox-" + this.id;
    this.mainAttributes = {
      class: ["smetio-custom-checkbox", this.mainClass]
    };

    this.inputClass = "smetio-custom-checkbox-input-" + this.id;
    this.inputAttributes = {
      type: "checkbox",
      checked: this.inputIsChecked,
      "data-smetio-read-only": this.isReadonly,
      class: ["smetio-custom-checkbox-input", this.inputClass],
    };

    this.checkmarkClass = "smetio-custom-checkbox-checkmark-" + this.id;
    this.checkmarkAttributes = {
      "data-smetio-read-only": this.isReadonly,
      class: ["smetio-custom-checkbox-checkmark", this.checkmarkClass],
    };

  };
  changeReadonlyStatus({ newStatus = true }) {

    this.isReadonly = newStatus;

    $("." + this.inputClass).attr("data-smetio-read-only", this.isReadonly);
    $("." + this.checkmarkClass).attr("data-smetio-read-only", this.isReadonly);

  };

  getValue(){
    return this.inputIsChecked;
  };

  select(){
    $("." + this.inputClass).prop("checked", true).trigger("change");
  };

  deselect(){
    $("." + this.inputClass).prop("checked",false).trigger("change");
  };
  
  events() {


    $("body").on("click", "." + this.checkmarkClass + ", ." + this.inputClass, (event) => {
      // event.stopImmediatePropagation();
    });

    $("body").on("change", "." + this.inputClass, (event) => {

      // event.stopImmediatePropagation();
      this.inputIsChecked = $("." + this.inputClass).prop("checked");

      if (!this.inputIsChecked && this.changeEvents.onUnChecked) return this.changeEvents.onUnChecked(false);
      if (this.inputIsChecked && this.changeEvents.onChecked) return this.changeEvents.onChecked(true);

    });

  };


  render() {

    const output = `
      <div ${this.getAttributes(this.mainAttributes, false)}>
        <label class="smetio-custom-checkbox-container">
          <input ${this.getAttributes(this.inputAttributes, false)}>
          <span ${this.getAttributes(this.checkmarkAttributes, false)}></span>
        </label>
      </div>
    `;

    this.events();

    return output;

  };



};
