/**** 
 * 
 * ALL ERROR CODE HERE HAS TO CORRESPOND WITH WHAT IS IN THE error_codes.php FILE
 WHICH IS USED ON THE SERVER SIDE

****/

export const errorCodes = {

	// SEND THIS ERROR CODE TO THE USER IF AJAX REQUEST ENDED IN AN ERROR -- THIS USUALLY ON THE CLIENT SIDE
	FAILED_AJAX_REQUEST: "34"

};
