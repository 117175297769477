
// //import "./styles/_main.scss";
import { getId } from "../universal_methods/getId.js";
import { generateId } from "../universal_methods/generateId.js";
import { getAttributes } from "../universal_methods/getAttributes.js";
import { setTxt } from "../universal_methods/setTxt.js";
import { setId } from "../universal_methods/setId.js";

export class SmetioPreloader {

    /**** 
    
        SMETIO MODAL USES BOOTSTRAP MODAL CLASS TO GENERATE MODAL
    
    ****/
    
    constructor(){
    

        this.getId = getId;
        this.setId = setId;
        this.generateId = generateId;
        this.getAttributes = getAttributes;
        this.setTxt = setTxt;

        this.text = "txt2";

        this.setId();

        this.mainClass = "smetio-preloader-"+this.id;
        this.mainAttributes = {
            "data-smetio-is-visible": false,
            class: [
                "smetio-preloader",
                this.mainClass
            ],
        };

        this.extraTextClass = "smetio-preloader-extra-text-"+this.id;
        this.extraTextAttributes = {
            "data-smetio-is-visible": true,
            class: [
                "smetio-preloader-extra-text",
                this.extraTextClass
            ],
        };        

    };

    hide(){
        const preloader = document.querySelector("."+this.mainClass);
        if(preloader) preloader.setAttribute("data-smetio-is-visible", false);
        $(`.${this.extraTextClass}`).html("");
    };

    show(){
        const preloader = document.querySelector("."+this.mainClass);
        if(preloader) preloader.setAttribute("data-smetio-is-visible", true);
    };

    addExtraText(extraText){
        const newText = this.setTxt({
            element: `.${this.extraTextClass}`,
            txt: extraText,
            type:"html" 
        });

        $(`.${this.extraTextClass}`).html(newText);
    };

    render(){

        const textAttributes = {};

        const text = this.setTxt({
            attributes: textAttributes,
            txt: this.text,
            type:"html" 
        });
        
        return `
        
            <div ${this.getAttributes(this.mainAttributes)}>

                <div class="smetio-preloader-spinner-and-text">

                    <div class="smetio-preloader-spinner">
                        <i class=" smetio-icon smetio-icon-spinner2 smetio-icon-spinning "></i>
                    </div>
                    <div class="smetio-preloader-text">
                        ${text}
                    </div>
                    <div ${this.getAttributes(this.extraTextAttributes)} >
                       
                    </div>

                </div>
                            
            </div>
        
        `;
        
    };

}







