export function removeAllWhiteSpace(data) {

	if(typeof data !== "string") return data;
	
	return data.trim().replace(/\s+/g, '');

};















