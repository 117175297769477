//GENERAL CONSTANTS

export const SMETIO_TIMEOUT = "TIMEOUT";

export const SMETIO_UNKNOWN_ERROR = "UNKNOWN ERROR";

export const SMETIO_ACTION_FAILED = "failed";

export const SMETIO_ENGLISH_ALPHABETS = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

export const SMETIO_NUMBERS = "0123456789";

export const SMETIO_MIN_HEIGHT_FOR_FILE_UPLOAD_INDICATOR = 30;

export const SMETIO_DEVICE_TOKEN_STORE_NAME = "smetioIdToken";

export const SMETIO_TEST_COOKIE_NAME = "smetioTestingCookie";

export const SMETIO_ONLINE = "online";

export const SMETIO_OFFLINE = "offline";

export const SMETIO_HTML_BODY = "body";

export const SMETIO_PREPEND = "prepend";

export const SMETIO_APPEND = "append";

export const SMETIO_POP_UP_WORD = "popUp";

export const SMETIO_NORMAL_WORD = "normal";

export const SMETIO_VISIBLE_WORD = "visible";

export const SMETIO_INVISIBLE_WORD = "invisible";

export const SMETIO_SERVER_CONNECTION_WORD = "serverConnection";

export const SMETIO_SAD_FACE_ICON = `<i class="iconOnMessageBox smetio-icon smetio-icon-sad-face"></i>`;

export const SMETIO_ATTENTION_TEXT_COLOR_CLASS = "smetio_attentionText";

export const SMETIO_WARNING_TEXT_COLOR_CLASS = "smetio_warningText";

export const SMETIO_UNPROCESSED = "unprocessed";

export const SMETIO_LOCAL_STORAGE_CREDENTIAL_ID = "smetioWebAuthnCredentialId";


