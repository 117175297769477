import { validate_numbers } from "./validate_numbers";

export function validate_latitude(data) {

    /**** LATITUDE SHOULD BE A NUMBER ****/

    const output = validate_numbers(data);

    if( output !== true ) return output;

    /**** LATITUDE SHOULD BE A NUMBER BETWEEB -90 TO 90 ****/

    if( data < -90 || data > 90 ) return "txt153";

    return true;    


};
