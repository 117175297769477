
export function validate_hexColor(data) {
    
    const regExp = /^#[0-9A-F]{6}$/i;

    if( regExp.test(data) ) return true;

    return "txt161";

};
