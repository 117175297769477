
export function validate_json(data) {
    
    try {

        var testIfJson = JSON.parse(data);

        if (typeof testIfJson == "object") return true;

        return "txt167";

    }catch(err){
        
        return "txt167";
        
    };

};
