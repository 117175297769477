
import { getId } from "../../universal_methods/getId.js";
import { setId } from "../../universal_methods/setId.js";
import { generateId } from "../../universal_methods/generateId.js";
import { isEmpty } from "../../universal_methods/isEmpty.js";
import { getAttributes } from "../../universal_methods/getAttributes.js";
import { toggleDisplay } from "../../universal_methods/toggleDisplay.js";

import { setTxt } from "../../universal_methods/setTxt.js";




//import "./styles/_main.scss";
import { SmetioSmartbox } from "../../smetioSmartbox/v1.0.0/SmetioSmartbox.js";




export class SmetioComingSoon{

  constructor() {


    this.getId = getId;
    this.setId = setId;
    this.generateId = generateId;
    this.isEmpty = isEmpty;
    this.getAttributes = getAttributes;
    this.toggleDisplay = toggleDisplay;
    this.setTxt = setTxt;

    this.setId();
    
    this.smartBox = "";
    this.smartBoxIsClosed = false;

    this.title = "txt187";
    this.text1 = "txt253";
    this.text2 = "txt222";
    
    this.mainClass = "smetio-app-ui-coming-soon-"+this.id;
    this.mainAttributes = {
      class: [
        "smetio-app-ui-coming-soon",
        this.mainClass
      ],
    };

  };

  getUi(){
    const comingSoonText1Attributes = {};
    const comingSoonText2Attributes = {};

    const comingSoonText1 = this.setTxt({
      attributes: comingSoonText1Attributes,
      txt: this.text1,
      type:"html" 
    });	

    const comingSoonText2 = this.setTxt({
      attributes: comingSoonText2Attributes,
      txt: this.text2,
      type:"html" 
    });	    

    return `
      <div ${this.getAttributes(this.mainAttributes, false)}>

        <h2 ${this.getAttributes(comingSoonText1Attributes, false)} >
          ${comingSoonText1}
        </h2>
        
        <h5 ${this.getAttributes(comingSoonText2Attributes, false)} >
          ${comingSoonText2}
        </h5>        

      </div>
    `;

  };

  showInSmartBox(){

    if( this.smartBox && !this.smartBoxIsClosed ) return this.smartBox.activate();

    this.smartBox = new SmetioSmartbox();
    this.smartBox.title = this.title;
    this.smartBox.content = this.getUi();

    this.smartBox.onClose = ()=>{

      this.smartBoxIsClosed = true;

    };

    this.smartBox.render();

  };


  render() {

    return this.getUi();

  };

  events() {

  };

};
