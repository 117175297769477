import {checkIfItIsEmpty} from
    "./../../functions/checks/checkIfItIsEmpty.js";

import {getRandomStringsWithoutNumbers} from 
    "./../../functions/random/randomStrings.js";

import {changeFirstLetterToLowerCase} from 
    "./../../functions/change/changeFirstLetterToLowerCase.js"; 

import {generateId} from "./generateId.js"; 


export function setId(){

    if(this.id) return this.id;

    this.id = generateId();

    return this.id;

    // if( checkIfItIsEmpty(this.id) ){
    
    //     this.id = generateId();

    //     return this.id;

    // }else{

    //     return this.id;

    // };
    
    
};






