
import { getId } from "../../universal_methods/getId.js";
import { setId } from "../../universal_methods/setId.js";
import { generateId } from "../../universal_methods/generateId.js";
import { isEmpty } from "../../universal_methods/isEmpty.js";
import { getAttributes } from "../../universal_methods/getAttributes.js";
import { toggleDisplay } from "../../universal_methods/toggleDisplay.js";

import { setTxt } from "../../universal_methods/setTxt.js";




//import "./styles/_main.scss";
// import { debounce } from "throttle-debounce";




export class SmetioActionBar {

    constructor() {


        this.getId = getId;
        this.setId = setId;
        this.generateId = generateId;
        this.isEmpty = isEmpty;
        this.getAttributes = getAttributes;
        this.toggleDisplay = toggleDisplay;
        this.setTxt = setTxt;

        this.setId();

        this.createNewOnClick = false;
        this.hasBeenRendered = false;
        this.onSearch = false;

        this.mainClass = "smetio-app-ui-action-bar" + this.id;
        this.mainAttributes = {
            class: [
                "smetio-app-ui-action-bar",
                this.mainClass
            ],
        };

        this.allBtnClass = "smetio-app-ui-action-bar-btn-" + this.id;

        this.iconClass = [
            // "smetio-icon-tetiary",
            "smetio-app-ui-action-bar-btn",
            this.allBtnClass
        ];

        this.searchDivClass = "smetio-app-ui-action-bar-search-div-" + this.id;
        this.searchDivAttributes = {
            class: [
                "smetio-app-ui-action-bar-search-div",
                this.searchDivClass
            ],
        };

        this.searchDivInputClass = "smetio-app-ui-action-bar-search-div-input-" + this.id;
        this.searchDivInputAttributes = {
            type: "search",
            autofocus: true,
            placeholder: "txt266",
            focus: true,
            class: [
                "smetio-app-ui-action-bar-search-div-input",
                this.searchDivInputClass
            ],
        };

        /**** 
   * THE EVENTS FOR EACH BUTTON ARE SET DYNAMICALLY FROM THE APP CLASS VIA THE USER INTERFACE
   * 
   *  ****/

        this.buttons = {
            createNew: { icon: "plus", attributes: { title: "txt198" }, events: { mousedown: () => { console.log("you clicked new ----"); } } },
            createNewFromFile: { icon: "file-plus", attributes: { title: "txt1748" }, events: { mousedown: () => { console.log("you clicked new ----"); } } },
            // edit:{icon:"edit-3", attributes:{ title:"txt202" }, events:{mousedown:()=>{ console.log("you clicked edit")}}},
            listView: { icon: "list", attributes: { title: "txt199" }, events: { mousedown: () => { console.log("you clicked list view"); } } },
            cardView: { icon: "th", attributes: { title: "txt200" }, events: { mousedown: () => { console.log("you clicked card view"); } } },
            filter: { icon: "filter1", attributes: { title: "txt267" }, events: { mousedown: () => { console.log("you clicked filter"); } } },
            search: { icon: "search1", attributes: { title: "txt201" }, events: { mousedown: () => { console.log("you clicked search "); } } }

        };

    };

    getButtons() {

        let buttons = "";

        for (const key in this.buttons) {

            this[key + "BtnClass"] = "smetio-app-ui-action-bar-" + key + "Btn-" + this.id;
            this.buttons[key]["attributes"]["class"] = [
                ...this.iconClass,
                "smetio-app-ui-action-bar-" + key + "Btn",
                this[key + "BtnClass"]
            ];

            if (!this.buttons[key]["attributes"]["data-smetio-isVisible"]) {

                this.buttons[key]["attributes"]["data-smetio-isVisible"] = "yes";

            };

            this.setTxt({
                attributes: this.buttons[key]["attributes"],
                txt: this.buttons[key]["attributes"]["title"],
                type: "title"
            });

            buttons += `
        <div ${this.getAttributes(this.buttons[key].attributes, false)}>        
          <i class="smetio-icon smetio-icon-${this.buttons[key]["icon"]}"></i>                                       
        </div>      
      `;

            if (!this.buttons[key]["events"]) continue;

            for (const eventKey in this.buttons[key]["events"]) {

                $("body").on(eventKey, "." + this[key + "BtnClass"], (event) => {

                    return this.buttons[key]["events"][eventKey](event);

                });

            };

        };

        return buttons;

    };


    hide(btnName) {

        if (!this.buttons[btnName]) return false;

        if (!this.hasBeenRendered) {

            return this.buttons[btnName]["attributes"]["data-smetio-isVisible"] = "no";

        };

        const theClass = "smetio-app-ui-action-bar-" + btnName + "Btn-" + this.id;

        return $("." + theClass).attr("data-smetio-isVisible", "no");

    };

    hideAll() {

        for (const key in this.buttons) {
            this.hide(key);
        };

    };

    show(btnName) {

        if (!this.buttons[btnName]) return false;

        if (!this.hasBeenRendered) {

            return this.buttons[btnName]["attributes"]["data-smetio-isVisible"] = "yes";

        };

        const theClass = "smetio-app-ui-action-bar-" + btnName + "Btn-" + this.id;

        return $("." + theClass).attr("data-smetio-isVisible", "yes");

    };

    showAll() {

        for (const key in this.buttons) {
            this.show(key);
        };
    };

    getSearchInput() {

        const placeholder = this.setTxt({
            attributes: this.searchDivInputAttributes,
            txt: "txt266",
            type: "placeholder"
        });

        return `
      <div ${this.getAttributes(this.searchDivAttributes, false)} >
        <div class="smetio-app-ui-action-bar-search-div-input-element">
          <input ${this.getAttributes(this.searchDivInputAttributes, false)} >
        </div>
      </div>
    `;

    // return`
    //   <div ${this.getAttributes(this.searchDivAttributes, false)} >
    //     <div class="smetio-app-ui-action-bar-search-div-icon">
    //       <i class="smetio-icon smetio-icon-search1"></i>          
    //     </div>
    //     <div class="smetio-app-ui-action-bar-search-div-input-element">
    //       <input ${this.getAttributes(this.searchDivInputAttributes, false)} >
    //     </div>
    //   </div>
    // `;
    };

    changeSearchWord(newSearchWord){
        $("." + this.searchDivInputClass).val(newSearchWord).trigger("change");
    };

    changeView(newView){
        const btnClass = "smetio-app-ui-action-bar-" + newView + "Btn-" + this.id;
        $("."+btnClass).trigger("mousedown");
    };
    render() {

        const output = `

      <div ${this.getAttributes(this.mainAttributes, false)} >
                                    
        ${this.getButtons()}     
        ${this.getSearchInput()}                
        
      </div>
    
    `;

        this.hasBeenRendered = true;

        this.events();

        return output;

    };

    async events() {

        const debounce = (await import(/* webpackPrefetch: true */ "throttle-debounce")).debounce;

        /**** 
     * THE EVENTS FOR EACH BUTTON ARE SET DYNAMICALLY FROM THE APP CLASS VIA THE USER INTERFACE
     * 
     *  ****/

        $("body").on("click", ".smetio-app-ui-action-bar-searchBtn", () => {
            $("." + this.searchDivInputClass).focus();
            $("." + this.searchDivInputClass).attr("autofocus", "true");
        });

        let lastSearchWord = "";
        // $("body").on("keyup search change", "." + this.searchDivInputClass, debounce(1000, ()=> {
        $("body").on("keyup search change", "." + this.searchDivInputClass, debounce(1000, ()=> {

            let searchWord = $("." + this.searchDivInputClass).val();
            if(searchWord == lastSearchWord) return false;

            lastSearchWord = searchWord;

            // if( !isEmpty(searchWord) ) searchWord = searchWord.toLowerCase();

            // console.log("this.onSearch is ",this.onSearch);
            $("." + this.searchDivInputClass).trigger("blur");
            if(this.onSearch) return this.onSearch(searchWord);

        }));


    }


};
