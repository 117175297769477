//import {test_function} from "./../functions/test_function.js";



import { getId } from "../../universal_methods/getId.js";
import { setId } from "../../universal_methods/setId.js";
import { generateId } from "../../universal_methods/generateId.js";
import { isEmpty } from "../../universal_methods/isEmpty.js";
import { getAttributes } from "../../universal_methods/getAttributes.js";
import { toggleDisplay } from "../../universal_methods/toggleDisplay.js";

import { setTxt } from "../../universal_methods/setTxt.js";







//import "./styles/_main.scss";




export class SmetioCreditCardDetailsCollector {

    constructor(data = {}) {

        this.getId = getId;
        this.setId = setId;
        this.generateId = generateId;
        this.isEmpty = isEmpty;
        this.getAttributes = getAttributes;
        this.toggleDisplay = toggleDisplay;
        this.setTxt = setTxt;

        this.stripe = data.stripe;
        this.defaultPath = data.defaultPath;

        this.language = data.language;
        this.billingAddress = data.billingAddress;
        this.publicProfileId = data.publicProfileId;
        this.clientSecret = data.clientSecret;
        this.setupIntentId = data.setupIntentId;
        this.user = data.user;
        this.texts = data.texts;
        this.themeName = data.themeName;
        this.setupIntentStatus = data.setupIntentStatus;
        this.smetioPresentationId = data.smetioPresentationId;
        this.cardDetails = data.cardDetails;
        this.attachedBusinessToken = data.attachedBusinessToken;
        this.paymentMethodToken = data.paymentMethodToken;
        this.finalRedirect = data.finalRedirect;


        this.elements = "";

        this.setId();

        this.mainClass = "smetio-credit-card-details-collector-" + this.id;
        this.mainAttributes = {
            class: ["smetio-credit-card-details-collector", this.mainClass],
        };

        this.errorClass = "smetio-credit-card-details-collector-error-" + this.id;
        this.errorId = "smetio-credit-card-details-collector-error-id" + this.id,
        this.errorAttributes = {
            id: this.errorId,
            class: ["smetio-credit-card-details-collector-error", this.errorClass],
        };

        this.saveBtnClass = "smetio-credit-card-details-collector-save-btn-" + this.id;
        this.saveBtnId = "smetio-credit-card-details-collector-saveBtn-id" + this.id,
        this.saveBtnAttributes = {
            id: this.saveBtnId,
            class: ["smetio-credit-card-details-collector-save-btn", this.saveBtnClass],
        };

    };

    getCreditCardForm() {

        setTimeout(() => {
            this.init();
        }, 200);


        const saveText = this.setTxt({
            attributes: {},
            txt: "txt299",
            type: "html"
        });


        return `
            <div ${this.getAttributes(this.mainAttributes, false)}>

            <div id="address-element">
            <!-- Elements will create form elements here -->
            </div>

            <div id="payment-element">
            <!-- Elements will create form elements here -->
            </div>

            <div ${this.getAttributes(this.errorAttributes, false)}>
            <!-- Display error message to your customers here -->
            </div>

            <button ${this.getAttributes(this.saveBtnAttributes, false)}>
            ${saveText}
            </button>
      
            </div>
      `;
    };

    getSetupIntentStatusMessage() {

        const possibleStatusDetails = {
            succeeded: {
                heading: "txt118",
                message: "txt476",
                icon: "smetio-icon-check-circle1"
            },
            processing: {
                heading: "txt185",
                message: "txt477",
                icon: "smetio-icon-envelope-open-o"
            },
            requires_payment_method: {
                heading: "txt111",
                message: "txt478",
                icon: "smetio-icon-warning2"
            }

        };

        const headingText = this.setTxt({
            attributes: {},
            txt: possibleStatusDetails[this.setupIntentStatus].heading,
            type: "html"
        });

        const messageText = this.setTxt({
            attributes: {},
            txt: possibleStatusDetails[this.setupIntentStatus].message,
            type: "html"
        });

        setTimeout(() => {
            window.close();
        }, 3500);

        let displayCardInfo = "";
        let subTitle = "";
        let paragraph = "";

        if (this.cardDetails.display_brand && this.cardDetails.last4) {

            subTitle = `${this.cardDetails.display_brand}**** ${this.cardDetails.last4}`;
            paragraph = `${this.cardDetails.exp_month}/${this.cardDetails.exp_year}`;

            displayCardInfo = `
                  <div>${subTitle}</div>
                  <div>${paragraph}</div>        
            `;

        };
          
        let redirectTimeText = this.setTxt({
            attributes: {},
            txt: "txt1747",
            type: "html"
        });


        let redirectCountDownSeconds = 5;
          
        redirectTimeText = redirectTimeText.replace("[#53c0nd5]", `<span style="font-size:150%;" class="smetio-redirect-times">${redirectCountDownSeconds}</span>`);
    
        const output = `
            <div data-smetio-status="${this.setupIntentStatus}" class="smetio-credit-card-details-collector-status-message">
                  <h1 data-smetio-status="${this.setupIntentStatus}" classs="smetio-h1-text-size" > 
                        <i class="smetio-icon ${possibleStatusDetails[this.setupIntentStatus].icon}"></i> <br>
                        ${headingText} 
                  </h1>
                  <p data-smetio-status="${this.setupIntentStatus}" class="smetio-p-text-size" > 
                        ${messageText} 
                  </p>

                  <h2 class="smetio-h2-text-size">${redirectTimeText}</h2>
            </div>
        `;

        if(displayCardInfo === "") displayCardInfo = output;
                            
        let timer = setInterval(() => {
            redirectCountDownSeconds = redirectCountDownSeconds - 1;

            $(".smetio-redirect-times").html(redirectCountDownSeconds);

            if (redirectCountDownSeconds < 1) {
                clearInterval(timer);
                location.href = this.finalRedirect;
            }

        }, 1000);


        return output;

    };

    render() {

        this.events();

        return this.setupIntentStatus ? this.getSetupIntentStatusMessage() : this.getCreditCardForm();

    };

    async getPreloader() {

        const {SmetioPreloader} = (await import(/* webpackPrefetch: true */ "../../smetioPreloader/SmetioPreloader.js"));
        this.preloader = new SmetioPreloader();
        const preloader = this.preloader.render();

        $("." + this.mainClass).prepend(preloader);

    };

    init() {

        const options = {
            clientSecret: this.clientSecret,
            // Fully customizable with appearance API.
            appearance: {/*...*/ },
        };

        // Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in a previous step
        this.elements = this.stripe.elements(options);

        // Create and mount the Payment Element
        const paymentElement = this.elements.create("payment");
        paymentElement.mount("#payment-element");

        const billingAddressOptions = {
            mode: "billing",
            defaultValues: {
                name: `${this.user.firstName} ${this.user.lastName}`,
                address: {
                    line1: this.billingAddress.streetAddress,
                    line2: this.billingAddress.houseNumber,
                    city: this.billingAddress.city,
                    state: this.billingAddress.state,
                    postal_code: this.billingAddress.postCode,
                    country: this.billingAddress.country,
                },
            },
        };
        const addressElement = this.elements.create("address", billingAddressOptions);
        addressElement.mount("#address-element");

        setTimeout(() => {
            $(`.${this.saveBtnClass}`).fadeIn();
        }, 200);

    };

    async confirmPayment() {

        // alert("trying to submit");

        if (!this.preloader) await this.getPreloader();
        this.preloader.show();
          
        const redirectParams = {};

        redirectParams["themeName"] = this.themeName;
        redirectParams["attachedBusinessToken"] = this.attachedBusinessToken;
        redirectParams["paymentMethodToken"] = this.paymentMethodToken;
        redirectParams["finalRedirect"] = this.finalRedirect;
        //redirectParams["billingAddressDetails"] =redirectParams;

        let getParameters = "";
    

        for (const key in redirectParams) {

            getParameters += `&${key}=${redirectParams[key]}`;

        };


        const return_url = `${this.defaultPath}creditCardDetailsCollectionStatus/${this.publicProfileId}/${this.language}/?${getParameters}`;

        // console.log("This is the return_url: ", return_url);
        $(".the-return-url").html(return_url);

        const result = await this.stripe.confirmSetup({
            elements: this.elements,
            confirmParams: {
                // Return URL where the customer should be redirected after the SetupIntent is confirmed.
                return_url
            },
        });

        if (result.error) return this.handleErrors(result.error.message);

        this.preloader.hide();

    };

    handleErrors(error) {
        if (this.preloader) this.preloader.hide();

        $("." + this.errorClass).html(error);
    };

    events() {

        // $("body").on("submit", "#"+this.formId, (event)=>{

        //   event.preventDefault();
        //   return this.confirmPayment();

        // });	

        $("body").on("click", `.${this.saveBtnClass}`, (event) => {

            event.preventDefault();
            return this.confirmPayment();

        });


    };

};
