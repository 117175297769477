export const getCountryNameFromIsoCode = async(data={isoCode:"", language:""} ) => {

    const { isoCode, language } = data;

    const countries = await import(/* webpackPrefetch: true */ 'i18n-iso-countries');

    countries.registerLocale(require(`i18n-iso-countries/langs/${language}.json`));
    return countries.getName(isoCode.toUpperCase(), language);

    // countries.registerLocale(frLocale)
    // const countryObj = countries.getNames('fr', 'official')
};
