
import { validate_hexColor } from "./validate_hexColor";
import { validate_hslColor } from "./validate_hslColor";
import { validate_rgbColor } from "./validate_rgbColor";

export function validate_color(data) {
    
    if( validate_hexColor(data) === true || validate_rgbColor(data) === true || validate_hslColor(data) === true) return true;
    
    return "txt166";

};
