


//import "./styles/_main.scss";

// import { removeAllWhiteSpace } from "../../../../functions/remove/removeAllWhiteSpace.js";
import { SmetioAppsBasics } from "../../smetioAppsBasics/v1.0.0/SmetioAppsBasics.js";
import { SmetioDataPresentation } from "../../../smetioDataPresentation/v1.0.0/SmetioDataPresentation.js";



export class SmetioProfile extends SmetioAppsBasics {

  constructor(data={
    appName: "",
    brandText: "",
    themeName: "",
    logo: "",
    sideMenuItems: "",
    brandName: "",
    language: "",
    useBrandNameNotLogo: false
  }) {

    super(data);

    // this.appName = data.appName || "smetio_profile";
    // this.brandText = data.brandText || "";
    // this.themeName = data.themeName || "true-brand-light-100";
    // this.logo = data.logo || "img/3ack_logo.svg";
    // this.sideMenuItems = data.sideMenuItems || false;
    // this.brandName = data.brandName || "3ack";
    // this.useBrandNameNotLogo = data.useBrandNameNotLogo || false;
    // this.language = data.language || "en";

    // super();

  };

  createNewEmail() {

    this.smetioAppUi.actionBar.hide("listView");
    this.smetioAppUi.actionBar.show("search");
    console.log("new email created ");

  };

  setSideMenuItems(sideMenuItems) {
    this.sideMenuItems = sideMenuItems;
  };

  getSideMenuItems() {

    if(this.sideMenuItems) return this.sideMenuItems;

    this.sideMenuItems = {

      personalDetails:{
        id: "personalDetails",
        text: "txt203",
        icon: "profile",
        showViewBtns: true,
        contentHolder: "",
        onClick: () => {
          console.log("you clicked personal ");
          this.smetioAppUi.actionBar.hideAll();
          this.smetioAppUi.actionBar.show("edit");
          this.smetioAppUi.actionBar.buttons.edit.events["mousedown"] = () => {
            console.log("Personal details edited");
          };

          // const datapresenter = new SmetioDataPresentation();
          // let newContent = datapresenter.render();

          let newContent = "";

          for (let i = 0; i < 200; i++) {
            const datapresenter = new SmetioDataPresentation();
            newContent += datapresenter.render();            
          }

          this.sideMenuItems["personalDetails"]["contentHolder"].update(newContent);

        },
        onRendered: () => {

        }
      },

      userAddresses:{
        id: "userAddresses",
        text: "txt204",
        icon: "home7",
        showViewBtns: true,
        contentHolder: "",
        onClick: () => {

          console.log("you clicked addresses ");
          this.smetioAppUi.actionBar.hideAll();
          this.smetioAppUi.actionBar.show("createNew");
          this.smetioAppUi.actionBar.buttons.createNew.events["mousedown"] = () => {
            console.log("new Address Created");
          };


        },
        onRendered: () => {

        }
      },
      userEmails:{
        id: "userEmails",
        text: "txt205",
        icon: "mail1",
        showViewBtns: false,
        contentHolder: "",
        onClick: () => {
          console.log("you clicked email ");
          this.smetioAppUi.actionBar.hideAll();
          /*
          this.smetioAppUi.actionBar.show("createNew");
          this.smetioAppUi.actionBar.buttons.createNew.events["mousedown"] = () => {
            console.log("you clicked new after clicking email");
            return this.createNewEmail();
          };*/
        },
        onRendered: () => {
          console.log("i am being rendered now %%%%%%")
        }
      }

    };

    return this.sideMenuItems;
  };

  events() {


  };


};


/**** REMEMBER TO ADD/IMPORT THIS FILE IN smetioApps.js FILE ****/
// setTimeout(() => {

//   let appIsSmetioProfile = $("body").attr("data-smetio-app");

//   appIsSmetioProfile = removeAllWhiteSpace(appIsSmetioProfile);

//   if (appIsSmetioProfile !== "smetio_profile") return false;

//   const smetioProfile = new SmetioProfile();

//   smetioProfile.init();

// }, 1000);


