
import { getId } from "../../universal_methods/getId.js";
import { setId } from "../../universal_methods/setId.js";
import { generateId } from "../../universal_methods/generateId.js";
import { isEmpty } from "../../universal_methods/isEmpty.js";
import { getAttributes } from "../../universal_methods/getAttributes.js";
import { toggleDisplay } from "../../universal_methods/toggleDisplay.js";

import { setTxt } from "../../universal_methods/setTxt.js";






//import { render } from "./methods/render.js";
//import { events } from "./methods/events.js";


//import "./styles/_main.scss";
// import { SmetioAppUiCalendarHandler } from "../../smetioAppUiCalendarHandler/v1.0.0/SmetioAppUiCalendarHandler.js";
import { SmetioAppUiContactHandler } from "../../smetioAppUiContactHandler/v1.0.0/SmetioAppUiContactHandler.js";
import { SmetioAppUiMessageHandler } from "../../smetioAppUiMessageHandler/v1.0.0/SmetioAppUiMessageHandler.js";



export class SmetioTaskBar {

  constructor() {


    this.getId = getId;
    this.setId = setId;
    this.generateId = generateId;
    this.isEmpty = isEmpty;
    this.getAttributes = getAttributes;
    this.toggleDisplay = toggleDisplay;
    this.setTxt = setTxt;

    // this.calendarHandler = new SmetioAppUiCalendarHandler();
    this.contactsHandler = new SmetioAppUiContactHandler();
    this.messageHandler = new SmetioAppUiMessageHandler();

    this.setId();


    this.mainClass = "smetio-app-ui-task-bar-" + this.id;
    this.mainAttributes = {
      class: [
        "smetio-app-ui-task-bar",
        this.mainClass
      ],
    };

    this.iconClass = [
      "smetio-app-ui-task-bar-icon",
      // "smetio-icon-tetiary",
    ];

    this.phoneBtnClass = "smetio-app-ui-task-bar-phoneBtn-"+this.id;
    this.phoneBtnAttributes = {
      class: [
        ...this.iconClass,
        this.phoneBtnClass
      ],
    };
    this.setTxt({
      attributes: this.phoneBtnAttributes,
      txt: "txt191",
      type:"title" 
    });	

    this.messageBtnClass = "smetio-app-ui-task-bar-messageBtn-"+this.id;
    this.messageBtnAttributes = {
      class: [
        ...this.iconClass,
        this.messageBtnClass
      ],
    };
    this.setTxt({
      attributes: this.messageBtnAttributes,
      txt: "txt192",
      type:"title" 
    });	    

    this.contactBtnClass = "smetio-app-ui-task-bar-contactBtn-"+this.id;
    this.contactBtnAttributes = {
      class: [
        ...this.iconClass,
        this.contactBtnClass
      ],
    };
    this.setTxt({
      attributes: this.contactBtnAttributes,
      txt: "txt194",
      type:"title" 
    });	    

    this.calendarBtnClass = "smetio-app-ui-task-bar-calendarBtn-"+this.id;
    this.calendarBtnAttributes = {
      class: [
        ...this.iconClass,
        this.calendarBtnClass
      ],
    };
    this.setTxt({
      attributes: this.calendarBtnAttributes,
      txt: "txt193",
      type:"title" 
    });	

  };

  render() {

    const output = `

      <div ${this.getAttributes(this.mainAttributes, false)} >
                
        <div ${this.getAttributes(this.phoneBtnAttributes, false)} ">            
            <i class="smetio-icon smetio-icon-phone1"></i>       
        </div>
        
        <div ${this.getAttributes(this.messageBtnAttributes, false)} >            
            <i class="smetio-icon smetio-icon-envelope1"></i>       
        </div>

        <div ${this.getAttributes(this.contactBtnAttributes, false)} >            
            <i class="smetio-icon smetio-icon-address-book1"></i>         
        </div>
        
        <div ${this.getAttributes(this.calendarBtnAttributes, false)}>            
            <i class="smetio-icon smetio-icon-calendar1"></i>       
        </div>	                    

      </div>    
    
    `;

    this.events();

    return output;

  };

  hide(){
 
    $("."+this.mainClass).attr("data-smetio-mode", "hidden");
 
  };

  show(){

    $("."+this.mainClass).attr("data-smetio-mode", "");

  };

  events() {

    $("body").on("mousedown", "." + this.phoneBtnClass, () => {

      console.log("You clicked phone");

    });

    $("body").on("mousedown", "." + this.messageBtnClass, () => {
      
      //console.log("You clicked message ");
      this.messageHandler.open();

    });

    $("body").on("mousedown", "." + this.contactBtnClass, () => {

      //console.log("You clicked contact ");
      this.contactsHandler.open();
    });

    $("body").on("mousedown", "." + this.calendarBtnClass, () => {

      //console.log("You clicked calendar ");
      // this.calendarHandler.open();

    });

  }


};
