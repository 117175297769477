//import {test_function} from "./../functions/test_function.js";



import { getId } from "../../universal_methods/getId.js";
import { setId } from "../../universal_methods/setId.js";
import { generateId } from "../../universal_methods/generateId.js";
import { isEmpty } from "../../universal_methods/isEmpty.js";
import { getAttributes } from "../../universal_methods/getAttributes.js";
// import { toggleDisplay } from "../../universal_methods/toggleDisplay.js";
// import { universal_handleErrorFromServer } from "../../universal_methods/universal_handleErrorFromServer.js";
// import { combineSmetioAttributes } from "../../universal_methods/combineSmetioAttributes.js";
import { setTxt } from "../../universal_methods/setTxt.js";




// import { smetioTxt } from
//   "../../../variables/smetioTxt.js";

// import { SmetioOverlayPreloader } from
//   "../../smetioOverlayPreloader/SmetioOverlayPreloader.js";







// //import "./styles/_main.scss";
import { SmetioCustomCheckbox } from "../../smetioCustomCheckbox/v1.0.0/SmetioCustomCheckbox.js";
import { smetio_copy, smetio_delete, smetio_edit, smetio_view } from "../../../constants/smetioDataRepresentationMenuItems.js";
import { convertModelToInputs } from "../../universal_methods/convertModelToInputs.js";
import { SmetioSmartbox } from "../../smetioSmartbox/v1.0.0/SmetioSmartbox.js";
// import personalDetails from "../../../../../production/img/appImages/SVG/personalDetails.js";
// import { SmetioAjax } from "../../smetioAjax/SmetioAjax.js";
import { isValidated } from "./../../../functions/validations/isValidated";
import { debounce } from "throttle-debounce";
import { getSimplePreloader } from "../../../functions/get/getSimplePreloader.js";
import { getCountryNameFromIsoCode } from "../../../functions/get/getCountryNameFromIsoCode.js";
import { SmetioPreloader } from "../../smetioPreloader/SmetioPreloader.js";

// const axios = require('axios/dist/browser/axios.cjs'); // browser

import { default as axios } from "axios";
// import { extractCdnPathFromOneValueOfSmetioFileInput } from "@schaniag/private";
// import { newVersionOfConvertModelToInputs } from "../../universal_methods/newVersionOfConvertModelToInputs.js";
// import { getTitleAndParagraphForBusinessHour } from "../../../functions/get/getTitleAndParagraphForBusinessHour.js";

export class SmetioDataPresentation {

      constructor(data = {
            id: "",

            viewStyle: "cardView",
            coverImage: "",
            logo: "",
            showLogo: true,
            title: "",
            subTitle: "",
            paragraph: "",
            alertObject:{
                  title:"",
                  icon:"",
                  type: "",
                  message:["txt234"],
                  onClick: {
                        functionPath: "businessLocations/copyBusinessLocation",
                        functionParameters: {
                              // token,
                              // addressToken:address.token,
                              // accountType 
                        },
                  }
            },
            menuItems: {
                  delete: {
                        text: "",
                        icon: "",
                        onClick: () => { }
                  },
                  view: {
                        text: "",
                        icon: "",
                        onClick: () => { }
                  }
            },
            uniqueValues: {
                  groupId: "", // USED TO IDENTIFY A GIVEN  SmetioDataPresentation AS PART OF A SMETIO DATA GROUP
                  // "Some id that can be used to identify the data in a database should be put here",  
                  inputsModel: {
                        modelPath: "loggedInUser/profileDetailsModels/personalDetailsModel",
                        map: { // USED TO TELL WHICH ELEMENT IN THE INPUT MODEL THAT IS USED TO ASSIGN VALUES TO THE SUBTITLE AND PARAGRAPH
                              subTitle: {
                                    // subTitle IS A COMBINATION OF firstName AND lastName SEPERATED BY SPACE
                                    seperator: " ",
                                    firstName: true,
                                    lastName: true
                              },
                              paragraph: {
                                    // paragraph IS JUST THE phoneNumber WITHOUT ANY SEPERATOR 
                                    seperator: "none",
                                    phoneNumber: true
                              }
                        },
                        endpoints: {
                              submission: {
                                    method: "POST",
                                    url: submissionEndpoint // WHERE THE SUBMITTED FORM SHOULD BE SENT
                              },
                              retrieval: {
                                    method: "GET",
                                    url: submissionEndpoint // WHERE THE INPUT MODEL SHOULD BE RETRIEVED FROM IF IT IS NOT PRESENT UPON CREATION
                              }
                        },
                        model: {
                              firstname: {
                                    // SEE SmetioObjectToInput FOR ALL THE OPTIONS THAT EACH ITEM/INPUT MODEL CAN CONTAIN
                              }
                        }

                  }
            },
            onSelected: (uniqueValues) => {
                  // WHAT SHOULD HAPPEN WHEN THIS DATA PRESENTATION IS SELECTED
            },
            onDeselected: (uniqueValues) => {
                  // WHAT SHOULD HAPPEN WHEN THIS DATA PRESENTATION IS DESELECTED
            },
            onClick: {}
      }) {


            this.getId = getId;
            this.setId = setId;
            this.generateId = generateId;
            this.isEmpty = isEmpty;
            this.getAttributes = getAttributes;
            this.setTxt = setTxt;

            this.convertModelToInputs = convertModelToInputs;

            this.id = data.id || this.getId();


            this.viewStyle = data.viewStyle || "cardView";
            this.coverImage = data.coverImage || "/img/pelspels_location.jpg";
            this.logo = data.logo || "";
            this.showLogo = data.showLogo;
            this.title = data.title || "";
            this.subTitle = data.subTitle || "";
            this.paragraph = data.paragraph || "";
            this.alertObject = data.alertObject || {};
            this.smetioCustomCheckbox = "";
            this.coverImageIsDynamic = data.coverImageIsDynamic || false;
            this.logoIsDynamic = data.logoIsDynamic || false;
            this.uniqueValues = {
                  smetioPresentationId: this.id,
                  ...data.uniqueValues
            };
            this.groupId = this.uniqueValues.groupId || this.id + "-group";

            this.model = this.uniqueValues.inputsModel ? this.uniqueValues.inputsModel.model : this.getSampleInputsModel();
            this.currentInputValues = {};
            this.changeEventToInputModels = false;

            this.inputModelsAndDisplayMap = this.uniqueValues.inputsModel.map;

            this.preloader = false;
            this.smartbox = false;
            this.menuItems = data.menuItems;
            /*     this.menuItems = {
          view: smetio_view,
          // delete: smetio_delete,
          edit: smetio_edit,
          // copy: smetio_copy
          ...data.menuItems
        }; */
            this.onSelected = data.onSelected ? data.onSelected : (uniqueValues) => { return false; };
            this.onDeselected = data.onDeselected ? data.onDeselected : (uniqueValues) => { return false; };
            this.onClick = data.onClick;



            if (this.menuItems.view && typeof this.menuItems.view.onClick !== "object") {
                  this.menuItems.view.onClick = (uniqueValues) => {
                        this.viewData(uniqueValues);
                        setTimeout(() => {
                              this.bigCancelBtnIsClicked();
                        }, 500);
                  };
            };

            if (this.menuItems.edit && typeof this.menuItems.edit.onClick !== "object") {
                  this.menuItems.edit.onClick = (uniqueValues) => {
                        return this.editData(uniqueValues);
                  };
            };





            this.mainClass = "smetio-data-presentation-" + this.id;
            this.groupClass = "smetio-data-presentation-" + this.groupId;
            this.mainAttributes = {
                  title: this.title,
                  "data-smetio-view-style": this.viewStyle,
                  class: ["smetio-data-presentation", this.groupClass, this.mainClass],
            };


            this.infoOnHoverClass = "smetio-data-presentation-info-on-hover-" + this.id;
            this.infoOnHoverAttributes = {
                  "data-smetio-view-style": this.viewStyle,
                  "data-smetio-active": false,
                  "data-smetio-is-visible": false,
                  class: ["smetio-data-presentation-info-on-hover", this.infoOnHoverClass],
            };

            this.menuClass = "smetio-data-presentation-menu-" + this.id;
            this.menuAttributes = {
                  "data-smetio-view-style": this.viewStyle,
                  "data-smetio-active": false,
                  class: ["smetio-data-presentation-menu", this.menuClass],
            };

            this.menuBtnClass = "smetio-data-presentation-menu-btn-" + this.id;
            this.menuBtnAttributes = {
                  "data-smetio-view-style": this.viewStyle,
                  "data-smetio-active": false,
                  class: ["smetio-data-presentation-menu-btn", this.menuBtnClass],
            };

            this.menuItemClass = "smetio-data-presentation-menu-item-" + this.id;
            // this.menuItemAttributes = {
            //   "data-smetio-active": false,
            //   class: ["smetio-data-presentation-menu-item", this.menuItemClass],
            // };

            this.imagesClass = "smetio-data-presentation-images-" + this.id;
            this.imagesAttributes = {
                  "data-smetio-view-style": this.viewStyle,
                  "data-smetio-active": false,
                  class: ["smetio-data-presentation-images", this.imagesClass],
            };

            this.coverImageClass = "smetio-data-presentation-cover-image-" + this.id;
            this.coverImageAttributes = {
                  "data-smetio-view-style": this.viewStyle,
                  "data-smetio-active": false,
                  class: ["smetio-data-presentation-cover-image", this.coverImageClass],
            };

            this.logoClass = "smetio-data-presentation-logo-" + this.id;
            this.logoAttributes = {
                  "data-smetio-view-style": this.viewStyle,
                  "data-smetio-active": false,
                  class: ["smetio-data-presentation-logo", this.logoClass],
            };

            this.textClass = "smetio-data-presentation-text-" + this.id;
            this.textAttributes = {
                  "data-smetio-view-style": this.viewStyle,
                  "data-smetio-active": false,
                  class: ["smetio-data-presentation-text", this.textClass],
            };

            this.titleClass = "smetio-data-presentation-title-" + this.id;
            this.titleAttributes = {
                  "data-smetio-view-style": this.viewStyle,
                  "data-smetio-active": false,
                  class: ["smetio-data-presentation-title", this.titleClass],
            };

            this.subTitleClass = "smetio-data-presentation-subTitle-" + this.id;
            this.subTitleAttributes = {
                  "data-smetio-view-style": this.viewStyle,
                  "data-smetio-active": false,
                  class: ["smetio-data-presentation-subTitle", this.subTitleClass],
            };

            this.paragraphClass = "smetio-data-presentation-paragraph-" + this.id;
            this.paragraphAttributes = {
                  "data-smetio-view-style": this.viewStyle,
                  "data-smetio-active": false,
                  class: ["smetio-data-presentation-paragraph", this.paragraphClass],
            };

            this.checkboxClass = "smetio-data-presentation-checkbox-" + this.id;
            this.checkboxAttributes = {
                  "data-smetio-active": false,
                  "data-smetio-view-style": this.viewStyle,
                  class: ["smetio-data-presentation-checkbox", this.checkboxClass],
            };

            this.bigEditBtnClass = "smetio-data-presentation-big-edit-btn-" + this.id;
            this.bigEditBtnAttributes = {
                  "data-smetio-is-visible": true,
                  "data-smetio-view-style": this.viewStyle,
                  class: ["smetio-data-presentation-big-edit-btn", this.bigEditBtnClass],
            };

            this.submitBtnClass = "smetio-data-presentation-submit-btn-" + this.id;
            this.submitBtnAttributes = {
                  "data-smetio-is-visible": false,
                  "data-smetio-view-style": this.viewStyle,
                  class: ["smetio-data-presentation-submit-btn", this.submitBtnClass],
            };

            this.bigCancelBtnClass = "smetio-data-presentation-big-cancel-btn-" + this.id;
            this.bigCancelBtnAttributes = {
                  "data-smetio-is-visible": false,
                  "data-smetio-view-style": this.viewStyle,
                  class: ["smetio-data-presentation-big-cancel-btn", this.bigCancelBtnClass],
            };


            this.extraContentClass = "smetio-data-presentation-extra-content-" + this.id;
            this.extraContentAttributes = {
                  "data-smetio-is-visible": false,
                  "data-smetio-view-style": this.viewStyle,
                  class: ["smetio-data-presentation-extra-content", this.extraContentClass],
            };

            this.alertIconClass = "smetio-data-presentation-alert-icon-" + this.id;
            this.alertIconAttributes = {
                  title:"txt750",
                  "data-smetio-is-visible": Object.keys(this.alertObject).length > 0  ? true : false,
                  class: ["smetio-data-presentation-alert-icon", this.alertIconClass],
            };



      };

      getViewStyle() {
            const viewStyle = $("." + this.menuClass).attr("data-smetio-view-style");
            return viewStyle;
      };
      show() {
            $("." + this.mainClass).attr("data-smetio-is-visible", true);
      };

      hide() {
            $("." + this.mainClass).attr("data-smetio-is-visible", false);
      };

      delete(){
            $(`.${this.mainClass}`).remove();
      };

      addChangeEventToInputModels() {
            for (const key in this.model) {

                  if (!this.model[key]["details"]["events"]) this.model[key]["details"]["events"] = {};

                  this.model[key]["details"]["events"]["onChange"] = (value) => {
                        this.model[key]["details"]["attributes"]["value"] = value;

                        console.log(" %%%%%%%%%% This is the new value: ", value);

                        if (this.model[key]["details"]["element"] === "select") {

                              for (const optionKey in this.model[key]["details"]["options"]) {

                                    const optionValue = this.model[key]["details"]["options"][optionKey]["attributes"]["value"];
                                    this.model[key]["details"]["options"][optionKey]["attributes"]["selected"] = false;

                                    if (optionValue === value) {
                                          this.model[key]["details"]["options"][optionKey]["attributes"]["selected"] = true;
                                    };

                              };

                        };

                  };
            };

            this.changeEventToInputModels = true;
      };

      changeParagraph(newParagraphy) {

            this.paragraph = newParagraphy;

            const paragraph = this.setTxt({
                  element: "." + this.paragraphClass,
                  txt: newParagraphy,
                  type: "html"
            });

            $("." + this.paragraphClass).html(paragraph);

      };

      changeSubtitle(newSubtitle) {

            this.subTitle = newSubtitle;

            const subTitle = this.setTxt({
                  element: "." + this.subTitleClass,
                  txt: newSubtitle,
                  type: "html"
            });

            $("." + this.subTitleClass).html(subTitle);

      };

      async updateTextAndImagesInDisplay(data = { title: "", subTitle: "", paragraph: "", coverPhoto: {}, logo: {} }) {
            const { title, subTitle, paragraph, coverPhoto, logo } = data;

            const extractCdnPathFromOneValueOfSmetioFileInput = (await import(/* webpackPrefetch: true */ "@schaniag/private")).extractCdnPathFromOneValueOfSmetioFileInput;

            this.changeTitle(title);
            this.changeSubTitle(subTitle);
            this.changeParagraph(paragraph);

            // const logoCdnPath = extractCdnPathFromOneValueOfSmetioFileInput(logo);
            // const coverPhotoCdnPath = coverPhoto ? extractCdnPathFromOneValueOfSmetioFileInput(coverPhoto) : "";

            if(logo) {
                  this.changeLogo( typeof logo == "object" ? extractCdnPathFromOneValueOfSmetioFileInput(logo) : logo );
                  if(this.uniqueValues.inputsModel.endpoints.submission.parameters) this.uniqueValues.inputsModel.endpoints.submission.parameters.currentLogo = logo;
            };

            if(coverPhoto) {
                  this.changeCoverImage(  typeof coverPhoto == "object" ? extractCdnPathFromOneValueOfSmetioFileInput(coverPhoto) : coverPhoto );
                  if(this.uniqueValues.inputsModel.endpoints.submission.parameters) this.uniqueValues.inputsModel.endpoints.submission.parameters.currentCoverPhoto = coverPhoto;
            };

      };


      updateSubTitleDisplay() {

            const subTitle = this.inputModelsAndDisplayMap["subTitle"];
            const subTitleSeperator = subTitle.seperator;
            let newSubTitle = "";

            for (const key in subTitle) {

                  if (key == "seperator") continue;

                  console.log("This model is ", this.model);

                  const value = this.model[key]["details"]["attributes"]["value"];
                  newSubTitle += value + subTitleSeperator;

            };

            this.changeSubtitle(newSubTitle);

      };

      refreshToSeeChange() {

      };
      async updateDisplay() {

            const getPureModelFromModelWithSection = (await import(/* webpackPrefetch: true */ "@schaniag/private")).getPureModelFromModelWithSection;

            const pureModel = getPureModelFromModelWithSection(this.model);


            if (this.inputModelsAndDisplayMap.getFunctionDetailsFromServerAfterSubmitingData) return false;

            // console.log("Proceeding to update display");

            for (const key in this.inputModelsAndDisplayMap) {

                  const item = this.inputModelsAndDisplayMap[key];

                  let display = "";

                  if (key == "updateByFunction") {

                        const funcPath = this.inputModelsAndDisplayMap[key];
                        const funcName = this.inputModelsAndDisplayMap[key].split("/").pop();

                        // console.log("The funcname is ", funcName)

                        const func = await import(/* webpackPrefetch: true */ `../../../functions${funcPath}.js`);

                        // console.log("The func is ", func);

                        const { subTitle, paragraph } = func[funcName](this.model);

                        this.changeSubtitle(subTitle);

                        return this.changeParagraph(paragraph);

                  };

      
                  for (const field in item) {

                        // if (field == "seperator" || !this.model[field]) continue;
                        if (field == "seperator" || !pureModel[field]) continue;

                        const seperator = item.seperator == "none" ? "" : item.seperator;
        
                        // let value = this.model[field]["details"]["attributes"]["value"];
                        let value = pureModel[field]["details"]["attributes"]["value"];

                        if (field == "country") value = await getCountryNameFromIsoCode({ isoCode: value, language: window.smetioVisitor.language });

                        display += value + seperator;

                  };

                  if (key == "subTitle") this.changeSubtitle(display);

                  if (key == "paragraph") this.changeParagraph(display);

                  display = "";

            };


            // this.updateSubTitleDisplay();

      };

      editData(uniqueValues) {

            this.viewData(uniqueValues);

            setTimeout(() => {

                  $("." + this.bigEditBtnClass).trigger("mousedown");

            }, 100);

            // return this.viewData(uniqueValues);

      };

      async changeReadOnlyStatus(newStatus) {

            const getPureModelFromModelWithSection = (await import(/* webpackPrefetch: true */ "@schaniag/private")).getPureModelFromModelWithSection;

            const pureModel = getPureModelFromModelWithSection(this.model);

            for (const key in pureModel) {

                  console.log(" ######### readonly status The key is ", key);


                  if (!pureModel[key]["inputClass"]) return true;

                  pureModel[key]["inputClass"].changeReadonlyStatus({ newStatus });

            };
      };

      getBigCancelBtn() {
            this.setTxt({
                  attributes: this.bigCancelBtnAttributes,
                  txt: "txt234",
                  type: "title"
            });

            return `
      <div ${this.getAttributes(this.bigCancelBtnAttributes, false)}>
        <i class="smetio-icon smetio-icon-cross1"></i>
      </div>
    `;
      };

      getBigEditBtn() {

            const cancelBtn = this.getBigCancelBtn();

            this.setTxt({
                  attributes: this.bigEditBtnAttributes,
                  txt: "txt202",
                  type: "title"
            });

            return `
      ${cancelBtn}
      <div ${this.getAttributes(this.bigEditBtnAttributes, false)}>
        <i class="smetio-icon smetio-icon-edit2"></i>
      </div>
    `;
      };

      getSubmitBtn(text = "txt299") {

            text = this.setTxt({
                  attributes: this.submitBtnAttributes,
                  txt: text,
                  type: "html"
            });

            return `

      <div class="smetio-data-presentation-submit-btn-container">
        <button ${this.getAttributes(this.submitBtnAttributes, false)}>
          ${text}
        </button>
      </div>
    `;
      };

      getAllValuesFromInputModel() {
            const output = [];

            for (const key in this.model) {
                  const item = this.model[key];
                  if (item["renderAsInput"] == false) continue;
                  if (!item["details"]) continue;
                  if (!item["details"]["attributes"] && !item["details"]["validation"]) continue;
                  const value = item["details"]["attributes"]["value"] || "";

                  if (!this.isEmpty(value)) output.push(value);
            };

            return output;
      };

      containSearchWord(searchWord) {

            if (!this.isEmpty(searchWord)) searchWord = searchWord.toLowerCase();

            const valuesFromModel = this.getAllValuesFromInputModel();
            const valuesTosearch = [this.title, this.subTitle, this.paragraph, ...valuesFromModel];

            for (let i = 0; i < valuesTosearch.length; i++) {
                  let value = valuesTosearch[i];
                  if (this.isEmpty(value)) continue;

                  value = value.toLowerCase();

                  if (value.includes(searchWord)) return true;

            };

            return false;
      };

      getSampleInputsModel() {
            //   SEE SmetioObjectToInput FOR ALL THE OPTIONS THAT EACH ITEM/INPUT MODEL CAN CONTAIN
            return {

                  firstname: {

                        inputClass: false, // WILL BE ASSIGNED A SmetioObjectToInput CLASS IN THE convertModelToInputs. AND THE SmetioObjectToInput CAN BE USED TO CONTROL THE INPUT

                        details: {

                              label: "txt50",
                              attributes: {
                                    value: "Charles"
                              },
                              capitalizeFirstChar: true,
                              explanation: {

                                    title: "txt175",
                                    article: [
                                          {
                                                heading: "txt175",
                                                paragraphs: ["txt176", "txt179"]
                                          },
                                          {
                                                heading: "txt177",
                                                paragraphs: ["txt178"]
                                          }
                                    ]

                              },
                              validation: {
                                    type: "text",
                                    required: true,
                                    minLength: 2,
                                    maxLength: 50,
                                    value: "Charles"
                              }
                        }
                  },

                  lastname: {
                        inputClass: false, // WILL BE ASSIGNED A SmetioObjectToInput CLASS IN THE convertModelToInputs. AND THE SmetioObjectToInput CAN BE USED TO CONTROL THE INPUT
                        details: {

                              label: "Comment",
                              element: "textarea",
                              attributes: {
                                    value: "My name is Charles Emakpor and i really love to leave comments"
                              },
                              capitalizeFirstChar: true,
                              validation: {
                                    type: "text",
                                    required: true,
                                    minLength: 2,
                                    maxLength: 50,
                                    value: "My name is Charles Emakpor and i really love to leave comments"
                              }
                        }

                  },

                  username: {

                        inputClass: false, // WILL BE ASSIGNED A SmetioObjectToInput CLASS IN THE convertModelToInputs. AND THE SmetioObjectToInput CAN BE USED TO CONTROL THE INPUT
                        details: {

                              label: "txt77",
                              instruction: "txt78",
                              attributes: {
                                    value: "chem_charles@yahoo.com"
                              },
                              unCapitalizeAll: true,
                              validation: {
                                    type: "sun",
                                    required: true,
                                    value: "chem_charles@yahoo.com"
                              },
                              events: {
                                    onChange: (value) => {

                                          // if (this.emailIsValid(value) === true) this.model.usernameType.details.validation.value = "email";
                                          // if (this.validateInternationalPhoneNumber(value) === true) this.model.usernameType.details.validation.value = "mobile";

                                    }
                              }
                        }

                  },

                  usernameConfirm: {
                        inputClass: false, // WILL BE ASSIGNED A SmetioObjectToInput CLASS IN THE convertModelToInputs. AND THE SmetioObjectToInput CAN BE USED TO CONTROL THE INPUT
                        details: {

                              label: "txt79",
                              instruction: "txt80",
                              preventCopyAndPaste: true,
                              unCapitalizeAll: true,
                              attributes: {
                                    value: ""
                              },
                              mustBeSameAs: {
                                    key: "username",
                                    errorMessage: "txt80"
                              },
                              validation: {
                                    type: "sun", // SUN = SMETIO USERNAME
                                    required: true,
                                    value: ""
                              }
                        }
                  },

                  password: {

                        inputClass: false, // WILL BE ASSIGNED A SmetioObjectToInput CLASS IN THE convertModelToInputs. AND THE SmetioObjectToInput CAN BE USED TO CONTROL THE INPUT
                        details: {

                              label: "txt47",
                              instruction: "txt42",
                              attributes: {
                                    value: "myPassword"
                              },
                              validation: {
                                    type: "spwd", // spwd = SMETIO PASSWORD
                                    required: true,
                                    value: ""
                              }
                        }
                  },

                  passwordConfirm: {
                        inputClass: false, // WILL BE ASSIGNED A SmetioObjectToInput CLASS IN THE convertModelToInputs. AND THE SmetioObjectToInput CAN BE USED TO CONTROL THE INPUT
                        details: {

                              label: "txt48",
                              // orientation: "horizontal",
                              preventCopyAndPaste: true,
                              instruction: "txt49",
                              attributes: {
                                    value: "myPassword"
                              },
                              mustBeSameAs: {
                                    key: "password",
                                    errorMessage: "txt49"
                              },
                              validation: {
                                    type: "spwd",
                                    required: true,
                                    value: ""
                              }
                        }
                  },

                  testCheckbox: {
                        inputClass: false, // WILL BE ASSIGNED A SmetioObjectToInput CLASS IN THE convertModelToInputs. AND THE SmetioObjectToInput CAN BE USED TO CONTROL THE INPUT
                        details: {

                              noAsteriskWithLabel: true,
                              label: "txt53",
                              labelPosition: "down",
                              appendToLabel: "<a href=\"https://schaniag.com/legal_policies\">Legal policies</a>",
                              element: "smetioCustomCheckbox",
                              orientation: "horizontal",
                              // orientation: "vertical",
                              attributes: {
                                    checked: true
                              },
                              expectedValue: true,
                              errorMessage: "txt69",
                              validation: {
                                    type: "boolan",
                                    required: true,
                                    value: ""
                              }
                        }
                  },

                  legalPoliciesAccepted: {
                        inputClass: false, // WILL BE ASSIGNED A SmetioObjectToInput CLASS IN THE convertModelToInputs. AND THE SmetioObjectToInput CAN BE USED TO CONTROL THE INPUT
                        details: {

                              label: "txt53",
                              noAsteriskWithLabel: true,
                              labelPosition: "down",
                              appendToLabel: "<a href=\"https://schaniag.com/legal_policies\">Legal policies</a>",
                              element: "smetioOnOffSwitch",
                              orientation: "horizontal",
                              // orientation: "vertical",
                              attributes: {
                                    checked: true
                              },
                              expectedValue: true,
                              errorMessage: "txt69",
                              validation: {
                                    type: "boolan",
                                    required: true,
                                    value: ""
                              }
                        }
                  },


                  usernameType: {
                        renderAsInput: false,
                        details: {
                              validation: {
                                    type: "text",
                                    required: true,
                                    value: ""
                              }
                        }
                  }

            };

      };

      getMenuItems() {

            let items = "";

            for (const key in this.menuItems) {

                  const menuItem = this.menuItems[key];


                  // console.log("This is the menuItem: ", menuItem);

                  const textAttributes = {
                        class: ["smetio-data-presentation-menu-item-text"],
                  };
                  const text = this.setTxt({
                        attributes: textAttributes,
                        txt: menuItem.text,
                        type: "html"
                  });
                  const uniqueClass = `${this.menuItemClass}-${key}`;
                  const newAttributes = {
                        class: ["smetio-data-presentation-menu-item", this.menuItemClass, uniqueClass],
                        "data-smetio-is-visible": menuItem.isVisible !== true ? false : true,
                  };
                  this.menuItems[key]["className"] = uniqueClass;

                  items += `
        <div ${this.getAttributes(newAttributes, false)}>
          <div class="smetio-data-presentation-menu-item-icon">
            <i class="smetio-icon smetio-icon-${menuItem.icon}"></i>
          </div>
          <div ${this.getAttributes(textAttributes, false)}>
            ${text}
          </div>          
        </div>
      `;
            };

            return items;

      };

      getMenu() {
            const items = this.getMenuItems();
            return `
      <div ${this.getAttributes(this.menuAttributes, false)}>
        ${items}
      </div>
    `;
      };

      getMenuBtn() {
            return `
      <div ${this.getAttributes(this.menuBtnAttributes, false)}>
        <i class="smetio-icon smetio-icon-more-vertical"></i>
      </div>
    `;
      };

      changeLogo(cdnLink) {
            $(`.${this.logoClass}`).html(!this.isEmpty(cdnLink) ? `<img src="${cdnLink}" >` : "");
      };

      getLogo() {

            if (!this.showLogo) return "";

            const logoImg = this.isEmpty(this.logo) ? "" : `<img src="${this.logo}" >`;

            return `
      <div ${this.getAttributes(this.logoAttributes, false)}>
        ${logoImg}
      </div>  
    `;

      };

      getSvgImageAsTxt(data = { url: "" }) {

            if (!this.preloader) this.preloader = new SmetioPreloader();

            $("." + this.coverImageClass).html(this.preloader.render());

            const { url } = data;

            axios.get(url).then((response) => {

                  $("." + this.coverImageClass).html(response.data);

            }).catch((error) => {
                  // handle error
                  console.log(error);
            }).finally(() => {
                  // always executed
            });



      };

      changeCoverImage(cdnLink) {

            // console.log("cdnLink ", cdnLink);

            $(`.${this.coverImageClass}`).html(`<img src="${cdnLink}" >`);

      };

      getCoverPicturesAndLogo() {

            const logo = this.getLogo();

            // this.coverImageIsDynamic = true;
            // this.coverImage = personalDetails();
            let coverImage = `<img src="${this.coverImage}" >`;
            // if (this.coverImageIsDynamic) coverImage = personalDetails();
            // if (this.coverImageIsDynamic) coverImage = this.coverImage;

            if (this.coverImageIsDynamic) {
                  coverImage = getSimplePreloader();
                  this.getSvgImageAsTxt({ url: this.coverImage });
            };

            return `
      <div ${this.getAttributes(this.imagesAttributes, false)}>

        <div ${this.getAttributes(this.coverImageAttributes, false)}>
          ${coverImage}
        </div>   

        ${logo}

      </div>
    `;

      };

      select() {
            $("." + this.checkboxClass).attr("data-smetio-active", true);
            $("." + this.mainClass).attr("data-smetio-active", true);

            this.setTxt({
                  element: "." + this.checkboxClass,
                  txt: "txt262",
                  type: "title"
            });

            if (this.onSelected) this.onSelected({ uniqueValues: this.uniqueValues, menuItems: this.menuItems });

      };

      deselect() {
            $("." + this.checkboxClass).attr("data-smetio-active", false);
            $("." + this.mainClass).attr("data-smetio-active", false);

            this.setTxt({
                  element: "." + this.checkboxClass,
                  txt: "txt261",
                  type: "title"
            });

            if (this.onDeselected) this.onDeselected({ uniqueValues: this.uniqueValues, menuItems: this.menuItems });

      };
      getCheckbox() {
            this.smetioCustomCheckbox = new SmetioCustomCheckbox({
                  attributes: {},
                  changeEvents: {
                        onChecked: (value) => {

                              this.select();
                              // $("." + this.checkboxClass).attr("data-smetio-active", value);
                              // $("." + this.mainClass).attr("data-smetio-active", value);

                              // this.setTxt({
                              //   element: "." + this.checkboxClass,
                              //   txt: "txt262",
                              //   type: "title"
                              // });

                              // if (this.onSelected) this.onSelected({uniqueValues:this.uniqueValues, menuItems:this.menuItems});

                        },
                        onUnChecked: (value) => {

                              this.deselect();
                              // $("." + this.checkboxClass).attr("data-smetio-active", value);
                              // $("." + this.mainClass).attr("data-smetio-active", value);

                              // this.setTxt({
                              //   element: "." + this.checkboxClass,
                              //   txt: "txt261",
                              //   type: "title"
                              // });

                              // if (this.onDeselected) this.onDeselected({uniqueValues:this.uniqueValues, menuItems:this.menuItem});

                        },
                  }
            });

            this.setTxt({
                  attributes: this.checkboxAttributes,
                  txt: "txt261",
                  type: "title"
            });

            return `
      <div ${this.getAttributes(this.checkboxAttributes, false)}>
        ${this.smetioCustomCheckbox.render()}
      </div>   
    `;
      };
      unRender() {
            $("." + this.mainClass).remove();
      };
      changeTitle(newTitle) {

            if(!newTitle) return false;

            this.title = newTitle;
            $("." + this.titleClass).html(newTitle);
      };
      changeSubTitle(newSubTitle) {

            if(!newSubTitle) return false;

            this.subTitle = newSubTitle;
            $("." + this.subTitleClass).html(newSubTitle);
      };
      changeParagraph(newParagraph) {

            if(!newParagraph) return false;

            this.paragraph = newParagraph;
            $("." + this.paragraphClass).html(newParagraph);
      };

      getAlertIcon() {
            return `
      <div ${this.getAttributes(this.alertIconAttributes, false)}>
        <i class="smetio-icon smetio-icon-${this.alertObject.icon || "exclamation-triangle"}"></i>
      </div>
    `;
      };


      render() {

            const menu = this.getMenu();
            const menuBtn = this.getMenuBtn();
            const coverPicturesAndLogo = this.getCoverPicturesAndLogo();
            const checkbox = this.getCheckbox();
            const alertIcon = this.getAlertIcon();

            this.setTxt({
                  attributes: this.mainAttributes,
                  txt: this.title,
                  type: "title"
            });

            const title = this.setTxt({
                  attributes: this.titleAttributes,
                  txt: this.title,
                  type: "html"
            });

            const subTitle = this.setTxt({
                  attributes: this.subTitleAttributes,
                  txt: this.subTitle,
                  type: "html"
            });

            const paragraph = this.setTxt({
                  attributes: this.paragraphAttributes,
                  txt: this.paragraph,
                  type: "html"
            });

            const output = `
      <div ${this.getAttributes(this.mainAttributes, false)}>
      
      
        <div ${this.getAttributes(this.infoOnHoverAttributes, false)}>
          <div class="smetio-data-presentation-info-on-hover-preloader">
            <i class=" smetio-icon smetio-icon-spinner2 smetio-icon-spinning "></i>
          </div>
        </div>
       
        ${alertIcon}
        ${checkbox}
        ${coverPicturesAndLogo}
        
        <div ${this.getAttributes(this.textAttributes, false)}>
          <h2 ${this.getAttributes(this.titleAttributes, false)} >${title} </h2>
          <h4 ${this.getAttributes(this.subTitleAttributes, false)} > ${subTitle} </h4>
          <p ${this.getAttributes(this.paragraphAttributes, false)}>${paragraph} </p>
        </div>
          
        ${menuBtn}
        ${menu}
      </div>
    `;

            this.events();

            return output;

      };

      async submissionsAreValid() {

            const getPureModelFromModelWithSection = (await import(/* webpackPrefetch: true */ "@schaniag/private")).getPureModelFromModelWithSection;

            const pureModel = getPureModelFromModelWithSection(this.model);
            for (const key in pureModel) {
                  const isValidated = await pureModel[key].inputClass.valueHasChanged();
                  // console.log("the key is: " + key + " and the value is: " + isValidated);
                  if (!isValidated) return false;
            };
            return true;
      };

      async getSubmissionData() {

            const getPureModelFromModelWithSection = (await import(/* webpackPrefetch: true */ "@schaniag/private")).getPureModelFromModelWithSection;

            let submissionData = {
                  formData: {}
            };

            const pureModel = getPureModelFromModelWithSection(this.model);

            for (const key in pureModel) {
                  const value = pureModel[key].inputClass.getValue();
                  submissionData.formData[key] = value;
            };

            return submissionData;

      };

      async storeCurrentInputValues() {

            const getPureModelFromModelWithSection = (await import(/* webpackPrefetch: true */ "@schaniag/private")).getPureModelFromModelWithSection;
            const pureModel = getPureModelFromModelWithSection(this.model);

            for (const key in pureModel) {
                  let value = "";
                  if (pureModel[key].inputClass) value = pureModel[key].inputClass.getValue();
                  this.currentInputValues[key] = value;
            };

      };

      async restoreStoredInputValues() {

            const getPureModelFromModelWithSection = (await import(/* webpackPrefetch: true */ "@schaniag/private")).getPureModelFromModelWithSection;
            const pureModel = getPureModelFromModelWithSection(this.model);

            for (const key in pureModel) {
                  const value = this.currentInputValues[key];
                  if (!isEmpty(value)) pureModel[key].inputClass.setValue(value);
            };

      };

      async changeDefaultEvents({ functionParameters, events }) {

            const { onEditBtnIsClicked, onCancelBtnIsClicked, onSaveBtnIsClicked } = events;

            if (onSaveBtnIsClicked) {

                  if (onSaveBtnIsClicked.includes("doNothing")) return this.submitData = () => { return false; };

                  const functionName = onSaveBtnIsClicked.split("/").pop();
                  const func = await import(/* webpackPrefetch: true */ `../../../functions/${onSaveBtnIsClicked}.js`);
                  this.submitData = () => {
                        if (func[functionName]) func[functionName](functionParameters);
                  };
            };

            if (onEditBtnIsClicked) {

                  if (onEditBtnIsClicked.includes("doNothing")) return this.bigEditBtnIsClicked = () => { return false; };

                  const functionName = onEditBtnIsClicked.split("/").pop();
                  const func = await import(/* webpackPrefetch: true */ `../../../functions/${onEditBtnIsClicked}.js`);
                  this.bigEditBtnIsClicked = () => {
                        if (func[functionName]) func[functionName](functionParameters);
                  };
            };

            if (onCancelBtnIsClicked) {

                  if (onCancelBtnIsClicked.includes("doNothing")) return this.bigCancelBtnIsClicked = () => { return false; };

                  const functionName = onCancelBtnIsClicked.split("/").pop();
                  const func = await import(/* webpackPrefetch: true */ `../../../functions/${onCancelBtnIsClicked}.js`);
                  this.bigCancelBtnIsClicked = () => {
                        if (func[functionName]) func[functionName](functionParameters);
                  };
            };

      };

      getAlertSmartbox(){

            if (!this.alertSmartbox) this.alertSmartbox = new SmetioSmartbox();
    
      };

      async getAndCallFallbackFunction(fallbackFunctionDetails = {}) {

            const { functionPath, functionParameters, events, isForAlert } = fallbackFunctionDetails;

            if (!this.preloader) this.preloader = new SmetioPreloader();
            if (!this.smartbox && !isForAlert) this.smartbox = new SmetioSmartbox();
            if(isForAlert) this.getAlertSmartbox();
    
            functionParameters.preloaderClass = this.preloader;
            functionParameters.model = this.model;
            functionParameters.uniqueValues = this.uniqueValues;
            functionParameters.titleClass = this.titleClass; // CAN BE USED TO CHANGE THE CONTENT 
            functionParameters.subTitleClass = this.subTitleClass; // CAN BE USED TO CHANGE THE CONTENT
            functionParameters.paragraphClass = this.paragraphClass; // CAN BE USED TO CHANGE THE CONTENT
            functionParameters.textClass = this.textClass;
            functionParameters.smartbox = isForAlert ? this.alertSmartbox : this.smartbox;
            functionParameters.smetioPresentationId = this.id;
            functionParameters.smetioPresentationClass = this;

            if (events) this.changeDefaultEvents({ functionParameters, events });
            console.log("fallbackFunctionDetails is :", fallbackFunctionDetails);

            // return true;

            const functionName = functionPath.split("/").pop();
            const func = await import(/* webpackPrefetch: true */ `../../../functions/${functionPath}.js`);

            if (func[functionName]) func[functionName](functionParameters);
      };

      async submitData() {

            this.preloader.show();

            const submissionsAreValid = await this.submissionsAreValid();

            if (!submissionsAreValid) {

                  window.smetioVisitor.showNotification({
                        title: "txt111",
                        type: "danger",
                        body: [
                              {
                                    message: "txt300",
                                    // errorCode: result.errorCode,
                                    // affectedField:affectedField
                              },
                              {
                                    message: "txt301",
                              }
                        ]

                  });

                  // console.log("The form is not valid yet.");
                  this.preloader.hide();
                  return false;
            };


            const submissionData = await this.getSubmissionData();

            await axios.post(this.uniqueValues.inputsModel.endpoints.submission.url, {
                  modelPath: this.uniqueValues.inputsModel.modelPath,
                  ...this.uniqueValues.inputsModel.endpoints.submission.parameters,
                  ...submissionData

            }).then((response) => {

                  let result = response.data;
                  // const res = result.fallbackFunctionDetails ? result.msg : result;

                  this.showResponseNotification(result);
                  this.storeCurrentInputValues();
                  this.updateDisplay();
                  if (result.fallbackFunctionDetails) this.getAndCallFallbackFunction(result.fallbackFunctionDetails);

            }).catch((error) => {

                  console.log(error);
                  this.showErrorNotice();

            }).finally(() => {
                  this.preloader.hide();
            });

      };

      async composeView(data = { content, config: {} }) {

            let { content, config } = data;

            config = config || {
                  editBtn: true,
                  cancelBtn: true,
                  submitBtn: true,
                  extraContent: false,
                  noModelConversion: false
            };

            // import { newVersionOfConvertModelToInputs } from "../../universal_methods/newVersionOfConvertModelToInputs.js";

            const newVersionOfConvertModelToInputs = (await import(/* webpackPrefetch: true */ "../../universal_methods/newVersionOfConvertModelToInputs.js")).newVersionOfConvertModelToInputs;


            if (!this.changeEventToInputModels && !config.noModelConversion) this.addChangeEventToInputModels();
            // content += !config.noModelConversion ? await this.convertModelToInputs() : "";

            // content += !config.noModelConversion ? await newVersionOfConvertModelToInputs({model:this.model, withFullContainerDiv:true, submissionFunctionDetails:{submissionFunction:this.submitData}}) : "";
            content += !config.noModelConversion ? await newVersionOfConvertModelToInputs({ model: this.model, withFullContainerDiv: true, instructionTitle:this.title }) : "";

            content += config.editBtn ? this.getBigEditBtn() : "";

            const extraContent = ` 
      <div ${this.getAttributes(this.extraContentAttributes, false)} >
      </div>
    `;
            content += config.extraContent ? extraContent : "";

            content += config.submitBtn ? this.getSubmitBtn(config.submitBtn ? config.submitBtn.text : "") : "";

            this.smartbox.updateContent(content);

            if (!config.noModelConversion) this.storeCurrentInputValues();
            if (!config.noModelConversion) this.changeReadOnlyStatus(true);

            if (this.smartbox.currentState !== "close") $("." + this.bigEditBtnClass).trigger("mousedown");
      };

      async viewData(uniqueValues) {

            if (!this.smartbox) this.smartbox = new SmetioSmartbox();
            if (!this.preloader) this.preloader = new SmetioPreloader();

            if (this.smartbox.hasBeenRendered && this.smartbox.currentState !== "close") {
                  this.storeCurrentInputValues();
                  return this.smartbox.reactivate();
            };

            if (!this.changeEventToInputModels) this.addChangeEventToInputModels();

            let content = this.preloader.render();

            this.smartbox.title = this.title;
            this.smartbox.content = content;
            this.smartbox.onClose = () => { this.smartbox = null; };
            this.smartbox.render();
            // this.smartbox.sidelize();
            this.preloader.show();

            if (!this.model) return this.retrieveInputModel(content);

            return this.composeView({ content });

            /*     content += await this.convertModelToInputs();
        content += this.getBigEditBtn();
        content += this.getSubmitBtn();
        this.smartbox.updateContent(content);
        
    
        this.storeCurrentInputValues();
        this.changeReadOnlyStatus(true); */

      };

      changeExtraContent(newContent){
            $(`.${this.extraContentClass}`).html(newContent);
      };

      changeHoverContent(newContent){
            $(`.${this.infoOnHoverClass}`).html(newContent);    
      };

      hideSubmitBtn(){
            $(`.${this.submitBtnClass}`).attr("data-smetio-is-visible", false);
      };

      showSubmitBtn(){
            $(`.${this.submitBtnClass}`).attr("data-smetio-is-visible", true);
      };

      showNotice(data = {}) {
            const { title, type, msg } = data;
            return window.smetioVisitor.showNotification({ title, type, body: [{ message: msg }] });
      };

      showErrorNotice(msg) {
            // console.log("########### The error message is: ", msg);
            return this.showNotice({ title: "txt111", type: "danger", msg: msg || "txt423" });
      };

      showResponseNotification(result) {
            console.log("The result is:", result, "and the msg is :", result.msg);
            if (result.error) return this.showErrorNotice(result.msg);
            if (result.success) return this.showNotice({ title: "txt118", type: "success", msg: result.msg });
      };

      async retrieveInputModel(content) {

            // const params = {modelPath: this.uniqueValues.inputsModel.modelPath};
            // if(this.uniqueValues.token) params.token = this.uniqueValues.token;

            await axios.get(this.uniqueValues.inputsModel.endpoints.retrieval.url, {
                  params: {
                        ...this.uniqueValues.inputsModel.endpoints.retrieval.parameters,
                        modelPath: this.uniqueValues.inputsModel.modelPath
                  }
            }).then((response) => {

                  const result = response.data;

                  if (result.error) return this.showErrorNotice(result.msg);

                  if (result.fallbackFunctionDetails) {
                        const config = result.fallbackFunctionDetails.config || {
                              editBtn: true,
                              submitBtn: false,
                              extraContent: true,
                              noModelConversion: true
                        };
                        this.composeView({
                              content,
                              config
                        });
                        return this.getAndCallFallbackFunction(result.fallbackFunctionDetails);
                        // return this.composeView({content, noModelConversion:true});
                  };

                  this.uniqueValues.inputsModel.model = result;
                  this.model = result;


                  if (content == false) {
                        this.userIsStillHovering = true;
                        return this.showHoverInfo();
                  };

                  return this.composeView({ content });

            }).catch((error) => {

                  console.log(error);
                  this.showErrorNotice();

            }).finally(() => {
                  this.preloader.hide();
            });


      };

      async showHoverInfo() {

            // if (!this.preloader) this.preloader = new SmetioPreloader();

            // $("." + this.infoOnHoverClass).html(this.preloader.render());

            // this.preloader.show();

            if (!this.smartbox) this.smartbox = new SmetioSmartbox();

            if (!this.userIsStillHovering || this.smartbox.hasBeenRendered) return false;

            $("." + this.infoOnHoverClass).attr("data-smetio-is-visible", true);

            let content = "";

            if (!this.model) return this.retrieveInputModel(false);

            // content = await this.convertModelToInputs();
            const newVersionOfConvertModelToInputs = (await import(/* webpackPrefetch: true */ "../../universal_methods/newVersionOfConvertModelToInputs.js")).newVersionOfConvertModelToInputs;

            content = await newVersionOfConvertModelToInputs({ model: this.model, withFullContainerDiv: true });

            $("." + this.infoOnHoverClass).html(content);

            this.changeReadOnlyStatus(true);

      };

      bigEditBtnIsClicked() {

            this.changeReadOnlyStatus(false);
            $("." + this.bigEditBtnClass).attr("data-smetio-is-visible", false);
            $("." + this.bigCancelBtnClass).attr("data-smetio-is-visible", true);
            $("." + this.submitBtnClass).attr("data-smetio-is-visible", true);

      };

      bigCancelBtnIsClicked() {

            this.changeReadOnlyStatus(true);
            this.restoreStoredInputValues();
            $("." + this.bigEditBtnClass).attr("data-smetio-is-visible", true);
            $("." + this.bigCancelBtnClass).attr("data-smetio-is-visible", false);
            $("." + this.submitBtnClass).attr("data-smetio-is-visible", false);

      };

      showAlert(){

            if(typeof this.alertObject.onClick == Object) return this.getAndCallFallbackFunction(this.alertObject.onClick);
    
            if(!this.alertObject.message) return false;

            this.getAlertSmartbox();

            // let whyText = this.setTxt({
            //   attributes: {},
            //   txt: "txt747",
            //   type: "html"
            // });

            // whyText = whyText.replace("[#icon]", `<i class="smetio-icon smetio-icon-${this.alertObject.icon || "exclamation-triangle"}"></i>`)
    
            const reasonText = this.setTxt({
                  attributes: {},
                  txt: "txt748",
                  type: "html"
            });    

            let message = "";

            for (let i = 0; i < this.alertObject.message.length; i++) {
                  const text = this.alertObject.message[i];
                  const messageText = this.setTxt({
                        attributes: {},
                        txt: text,
                        type: "html"
                  });
      
                  message += `<p class="smetio-margin-top-20"> ${messageText} </p>`;

            };

            const icon = this.alertObject.icon || "exclamation-triangle";
            const heading = `<i class="smetio-icon smetio-icon-${icon}"></i>`;

            const alertContent = `
      <div>

        <h4 class="smetio-h4-heading smetio-margin-bottom-30 smetio-text-center">
          ${heading}
        </h4>

        <h6 class="smetio-h6-heading smetio-margin-bottom-30">
          ${reasonText}
        </h6>

        ${message}

      </div>
    `;

            this.alertSmartbox.title = this.alertObject.title || "txt670";
            this.alertSmartbox.content = alertContent;

            if (this.alertSmartbox.hasBeenRendered && this.alertSmartbox.currentState !== "close") {

                  return this.alertSmartbox.reactivate();

            };

            this.alertSmartbox.render();

      };

      events() {

            if (window["dataPresentationEventsIsSet-" + this.id]) return false;

            window["dataPresentationEventsIsSet-" + this.id] = true;

            $("body").on("mousedown", "." + this.menuBtnClass, (event) => {

                  event.stopImmediatePropagation();

                  $(".smetio-data-presentation-menu").attr("data-smetio-active", false);
                  $("." + this.menuClass).attr("data-smetio-active", true);

            });

            $("body").on("mouseenter", "." + this.mainClass, () => {
                  $(".smetio-data-presentation-menu").attr("data-smetio-active", false);
            });

            $("body").on("mouseenter", "." + this.textClass, debounce(500, () => {

                  // this.showHoverInfo();

            }));

            $("body").on("mouseover", "." + this.textClass, () => {
                  this.userIsStillHovering = true;
            });

            $("body").on("mouseleave", "." + this.textClass, () => {
                  this.userIsStillHovering = false;
                  $("." + this.infoOnHoverClass).attr("data-smetio-is-visible", false);
            });

            $("body").on("mouseover", "." + this.infoOnHoverClass, () => {
                  // $("." + this.infoOnHoverClass).attr("data-smetio-is-visible", true);
            });

            $("body").on("mouseleave", "." + this.infoOnHoverClass, () => {
                  $("." + this.infoOnHoverClass).attr("data-smetio-is-visible", false);
                  this.userIsStillHovering = false;
            });

            /*     $("body").on("mousedown", "." + this.infoOnHoverClass, (event) => {
          event.stopImmediatePropagation();
        }); */


            $("body").on("mousedown", "." + this.mainClass, () => {

                  if(this.onClick) return this.getAndCallFallbackFunction(this.onClick);
                  // return this.viewData();
                  return this.editData(this.uniqueValues);
      
            });

            $("body").on("mousedown", "." + this.menuClass, (event) => {
                  event.stopImmediatePropagation();
            });

            $("body").on("mousedown", "." + this.checkboxClass, (event) => {
                  event.stopPropagation();
            });

            $("body").on("mouseleave", "." + this.menuClass, () => {
                  $("." + this.menuClass).attr("data-smetio-active", false);
            });

            $("body").on("mousedown", () => {

                  $(".smetio-data-presentation-menu").attr("data-smetio-active", false);

            });

            $("body").on("mousedown", "." + this.bigEditBtnClass, () => {

                  this.bigEditBtnIsClicked();

                  // this.editData(this.uniqueValues);
                  // console.log("You clicked edit btn");
                  // this.changeReadOnlyStatus(false);
                  // $("." + this.bigEditBtnClass).attr("data-smetio-is-visible", false);
                  // $("." + this.bigCancelBtnClass).attr("data-smetio-is-visible", true);
                  // $("." + this.submitBtnClass).attr("data-smetio-is-visible", true);


            });

            $("body").on("mousedown", "." + this.bigCancelBtnClass, () => {

                  this.bigCancelBtnIsClicked();

                  // this.changeReadOnlyStatus(true);
                  // this.restoreStoredInputValues();
                  // $("." + this.bigEditBtnClass).attr("data-smetio-is-visible", true);
                  // $("." + this.bigCancelBtnClass).attr("data-smetio-is-visible", false);
                  // $("." + this.submitBtnClass).attr("data-smetio-is-visible", false);

            });
            $("body").on("mousedown", "." + this.submitBtnClass, () => {
                  this.submitData();
            });


            $("body").on("mousedown", "." + this.alertIconClass, (event) => {
                  event.stopImmediatePropagation();
                  this.showAlert();
            });
    

            for (const key in this.menuItems) {

                  const menuItem = this.menuItems[key];
                  const onClick = menuItem["onClick"];
                  const className = menuItem["className"];
                  $("body").on("click", "." + className, (event) => {
                        event.stopPropagation();
                  });

                  $("body").on("mousedown", "." + className, (event) => {
                        event.stopPropagation();
                        // if (onClick) return onClick(this.uniqueValues);
                        // $(".smetio-data-presentation-menu").attr("data-smetio-active", false);
                        $("." + this.menuClass).attr("data-smetio-active", false);

                        if (typeof onClick === "function") return onClick(this.uniqueValues);

                        if (typeof onClick === "object") return this.getAndCallFallbackFunction(onClick);

                        return false;

                  });

            };


      };

};
