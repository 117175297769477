export async function checkBrowserPermissions(){

	const permissionsNames = [
	  "geolocation",
	  "notifications",
	  "push",
	  "midi",
	  "camera",
	  "microphone",
	  "speaker",
	  "device-info",
	  "background-fetch",
	  "background-sync",
	  "bluetooth",
	  "persistent-storage",
	  "ambient-light-sensor",
	  "accelerometer",
	  "gyroscope",
	  "magnetometer",
	  "clipboard",
	  "display-capture",
	  "nfc"
	];

	  const allPermissions = []
	  // We use Promise.all to wait until all the permission queries are resolved
	  await Promise.all(
	    permissionsNames.map(async permissionName => {
	        try {

	          let permission
	          switch (permissionName) {
	            case 'push':
	              // Not necessary but right now Chrome only supports push messages with  notifications
	              permission = await navigator.permissions.query({name: permissionName, userVisibleOnly: true})
	              break;

	            default:
	              permission = await navigator.permissions.query({name: permissionName})

	          }
	          //console.log(permission)
	          //allPermissions.push({permissionName, state: permission.state})
	          this.browserPermissions[permissionName] = permission.state;

	          permission.onchange = (e)=>{

				// detecting if the event is a change
				if (e.type === 'change'){

					// checking what the new permissionStatus state is
					const newState = e.target.state;
					this.browserPermissions[permissionName] = newState;

					console.log(this.browserPermissions);

					if (newState === 'denied') {

						//console.log('why did you decide to block us?')

					} else if (newState === 'granted') {

						//console.log('We will be together forever!')

					} else {

						//console.log('Thanks for reverting things back to normal')

					};
				};

	          };

	        }catch(e){

	          //allPermissions.push({permissionName, state: 'error', errorMessage: e.toString()});
	          this.browserPermissions[permissionName] = "unavailable";

	        };

	    })

	  );

	  //console.log(allPermissions);

	  //return allPermissions



};