
export async function registerMouseAndScrollPosition(event){

		// TEMPORARY FIX
		return false

    this.scrollY = $(document).scrollTop();
    this.scrollX = $(document).scrollLeft();


	const interactionData = {

		mouseX: this.mouseX,
		mouseY: this.mouseY,
		scrollY: parseInt(this.scrollY),
		scrollX: parseInt(this.scrollX)

	};

	await this.getGeolocation();

	this.SmetioAjax.dataToSendToServer = {

		visitedWebsite: {
			type: "json", 
			value:JSON.stringify(this.visitedWebsite) 
		},
		latitude: {
			type: "lat", 
			value:this.geoLocation.latitude 
		},
		longitude: {
			type: "lo",  
			value:this.geoLocation.longitude 
		},	
		interactionData: {
			type: "json",
			value: JSON.stringify(interactionData)
		}		
	};

	this.SmetioAjax.success = (result)=>{

		delete this.SmetioAjax.request.data.interactionData;

		this.handleUserLoginStatus(result);

	};

	this.SmetioAjax.urlToConnectTo = this.serverMouseAndScrollPositionLocation;

	await this.SmetioAjax.sendRequest();	


};