import { validate_url } from "../../validations/validate_url";

export const bookAppointment = async ( data = {}) => {
    
      const {treatmentDetails, event} = data;

      if(event) event.preventDefault();


      const href = event.target.href;

      const isValidUrl = validate_url(href);

      console.log("href is ",href, " and is isValidUrl is ",isValidUrl);

      
      if(isValidUrl === true) return location.href = href;

      console.log("treatmentDetails is ",treatmentDetails);

      const { SmetioSmartbox }= (await import(/* webpackPrefetch: true */ "../../../classes/smetioSmartbox/v1.0.0/SmetioSmartbox"));
      const { setTxtV2 } = (await import(/* webpackPrefetch: true */ "../../../classes/universal_methods/setTxtV2"));

      if (!window["appointmentSmartbox"]) window["appointmentSmartbox"] = new SmetioSmartbox();

      //   if (window["appointmentSmartbox"].hasBeenRendered && window["appointmentSmartbox"].currentState !== "close") return window["appointmentSmartbox"].reactivate();
      if (window["appointmentSmartbox"].hasBeenRendered && window["appointmentSmartbox"].currentState !== "close") return window["appointmentSmartbox"].animate({animation:"shakeX"});
      

      const reachoutText = setTxtV2({
            attributes: {},
            txt: "txt1491",
            type: "html"
      });           
    
      const footerContent = $(".smetio-template-1-bottom").html();

      const content = `
    
          <div class="smetio-customer-website-treatment-book-appointment">
                <h3 class="smetio-information-text">${reachoutText}</h3>
                ${footerContent}
          </div>
      `;

      window["appointmentSmartbox"].position = "fixed";
      window["appointmentSmartbox"].title = "txt1356";
      window["appointmentSmartbox"].content = content;
      window["appointmentSmartbox"].onClose = () => { window["appointmentSmartbox"] = null; };
      window["appointmentSmartbox"].render();
};
