import { isSmetioTxtVariable } from "./isSmetioTxtVariable";
import { removeTextsToTextsInVeiw } from "./removeTextsToTextsInVeiw";
import { addTextsToTextsInVeiw } from "./addTextsToTextsInVeiw";
// import { smetioTxt } from "../../variables/smetioTxt";


export function setTxt (params = {}) {
    
    /**** 
     * this.labelSpanAttributes = {
        id: "",
        title: "",
        class: ["", ""],

     * }
        params = {
            element: "#idName" or ".className", @OPTIONAL
            attributes:this.labelSpanAttributes, @OPTIONAL
            txt: "txt50", @COMPULSORY
            type:"title or html or placeholder or alt or value EVEN OTHER HTML ATTRIBUTES" @COMPULSORY
        }
    
     * 
     * SMETIO TEXT CAN BE SET FOR AN ELEMENT BEFORE IT IS RENDERED OR AFTER IT HAS ALREADY BEEN RENDERED 
     *
     *  1. IF THE TEXT IS SET BEFORE THE ELEMENT IS RENDERED, THEN THE ATTRIBUTE OF THE ELEMENT NEEDS TO 
     *  BE PASSED TO THIS FUNCTION 
     * 
     *  ****/
    
    if( !this.isSmetioTxtVariable ) this.isSmetioTxtVariable = isSmetioTxtVariable;
    if( !this.addTextsToTextsInVeiw ) this.addTextsToTextsInVeiw = addTextsToTextsInVeiw;
    if( !this.removeTextsToTextsInVeiw ) this.removeTextsToTextsInVeiw = removeTextsToTextsInVeiw;

    // let text = window["smetioTexts"][params.txt];
    let text = window["smetioTexts"][params.txt];
    // console.log("This is the text ", text);

    if(text === undefined && params.attributes) return params.txt;

    const txtClass = "smetio-"+params.txt+"-"+params.type;
    const currentTypeText = "data-smetio-current-txt-"+params.type;

    if( params.attributes ){

        /**** params.txt MUST BE A SMETIO TXT VARIABLE i.e txt FOLLOWED BY NUMBERS ****/

        if( !this.isSmetioTxtVariable(params.txt) ) return params.txt;   

        if(!params.attributes.class) params.attributes.class = [];
        
        params.attributes[currentTypeText] = params.txt;

        params.attributes.class.push(txtClass);

        this.addTextsToTextsInVeiw(params.txt);

        /****  
         * FOR TITLE, PLACEHOLDER AND OTHER HTML ATTRIBUTES THE TEXT WILL SIMPLY BE SET ON THE ATTRIBUTE 
         * ****/

        if( params.fromGetAttributes !== true && params.type !== "html") params.attributes[params.type] = text; 
                
        return text;
         
    };

    /**** 
     * IF THE IS SET AFTER THE ELEMENT HAS ALREADY BEEN RENDERED THEN THE CLASS OR ID OF THE 
     * ELEMENT NEEDS TO BE SENT 
     * 
     * OBTAIN AND REMOVE CURRENT TXT DATA
     * ****/

    const currentTxt =  $(params.element).attr(currentTypeText);
    const currentTxtClass = "smetio-"+currentTxt+"-"+params.type;

    $(params.element).removeClass(currentTxtClass);

    this.removeTextsToTextsInVeiw(currentTxt);

    /**** ADD THE NEW TXT DATA AND DISPLAY THE TEXT ****/

    if( this.isSmetioTxtVariable(params.txt) ) {

        $(params.element).attr(currentTypeText, params.txt);

        $(params.element).addClass(txtClass);

        this.addTextsToTextsInVeiw(params.txt);

    }else{

        text =  params.txt;
    };
    
    if(params.type === "html") $(params.element).html(text);   

    $(params.element).attr(params.type, text);

    return text;


};
