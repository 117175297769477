export async function getIpAddressFromWebsocket(){

	if( !$("#iframe") ){

		const theIframe = `<iframe id="iframe" sandbox="allow-same-origin"></iframe>`;

		$("body").append(theIframe);

	};

	let ip = "";

	await this.detectRTC.DetectLocalIPAddress((result)=>{ 

		console.log(result);

		try{

			if(result.indexOf("Public:") != -1 ){

				console.log("i found public ip address ");
				ip = result.replace("Public:", "");
				this.websocket_ip.ip = ip;

				console.log("ip address at found is "+ip);
				$(".smetio-signup-page-input-firstname").val(ip);
				//alert(this.websocket_ip.ip);
				console.log(this.websocket_ip);
				//return result.replace("Public:", "");

				return true;

			};

			let nodva = result.replace("Local:", "");
			let dva = $(".smetio-signup-page-input-lastname").val();
			$(".smetio-signup-page-input-lastname").val(dva+" "+nodva);

		}catch(err){

			console.log(err);
		};		 

	});

	console.log("ip address at return is "+ip);

	return ip;

/*
	//get the IP addresses associated with an account
	function getIPs(callback){
	    var ip_dups = {};
	    //compatibility for firefox and chrome
	    var RTCPeerConnection = window.RTCPeerConnection
	        || window.mozRTCPeerConnection
	        || window.webkitRTCPeerConnection;
	    var useWebKit = !!window.webkitRTCPeerConnection;
	    //bypass naive webrtc blocking using an iframe
	    if(!RTCPeerConnection){
	        //NOTE: you need to have an iframe in the page right above the script tag
	        //
	        //<iframe id="iframe" sandbox="allow-same-origin" style="display: none"></iframe>
	        //<script>...getIPs called in here...
	        //
	        var win = iframe.contentWindow;
	        RTCPeerConnection = win.RTCPeerConnection
	            || win.mozRTCPeerConnection
	            || win.webkitRTCPeerConnection;
	        useWebKit = !!win.webkitRTCPeerConnection;
	    }
	    //minimal requirements for data connection
	    var mediaConstraints = {
	        optional: [{RtpDataChannels: true}]
	    };
	    var servers = {iceServers: [{urls: "stun:stun.services.mozilla.com"}]};
	    //construct a new RTCPeerConnection
	    var pc = new RTCPeerConnection(servers, mediaConstraints);
	    function handleCandidate(candidate){
	        //match just the IP address
	        var ip_regex = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/
	        var ip_addr = ip_regex.exec(candidate)[1];
	        //remove duplicates
	        if(ip_dups[ip_addr] === undefined)
	            callback(ip_addr);
	        ip_dups[ip_addr] = true;
	    }
	    //listen for candidate events
	    pc.onicecandidate = function(ice){
	        //skip non-candidate events
	        if(ice.candidate)
	            handleCandidate(ice.candidate.candidate);
	    };
	    //create a bogus data channel
	    pc.createDataChannel("");
	    //create an offer sdp
	    pc.createOffer(function(result){
	        //trigger the stun server request
	        pc.setLocalDescription(result, function(){}, function(){});
	    }, function(){});
	    //wait for a while to let everything done
	    setTimeout(function(){
	        //read candidate info from local description
	        var lines = pc.localDescription.sdp.split('\n');
	        lines.forEach(function(line){
	            if(line.indexOf('a=candidate:') === 0)
	                handleCandidate(line);
	        });
	    }, 1000);
	}

	//log IP addresses
	getIPs(function(ip){
	    //local IPs
	    if (ip.match(/^(192\.168\.|169\.254\.|10\.|172\.(1[6-9]|2\d|3[01]))/))
	        console.log("Local IPs: "+ip);
	    //IPv6 addresses
	    else if (ip.match(/^[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7}$/))
	        console.log("IPv6 Addresses: "+ip);
	    //assume the rest are public IPs
	    else
	        console.log("Public IPs: "+ip);
	});
*/

};