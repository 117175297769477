//import {test_function} from "./../functions/test_function.js";



import { getId } from "./../../universal_methods/getId.js";
import { setId } from "./../../universal_methods/setId.js";
import { generateId } from "./../../universal_methods/generateId.js";
import { isEmpty } from "./../../universal_methods/isEmpty.js";
import { getAttributes } from "./../../universal_methods/getAttributes.js";
import { toggleDisplay } from "./../../universal_methods/toggleDisplay.js";
import { preventCopyAndPaste } from "../../../functions/prevent/preventCopyAndPaste.js";
import { validateValueLength } from "../../../functions/validations/validateValueLength.js";

import { combineSmetioAttributes } from "./../../universal_methods/combineSmetioAttributes.js";
import { changeFirstLetterToUpperCase } from "../../../functions/change/changeFirstLetterToUpperCase.js";
import { changeFirstLetterToLowerCase } from "../../../functions/change/changeFirstLetterToLowerCase.js";

import { debounce } from "./../../universal_methods/debounce";
import { setTxt } from "../../universal_methods/setTxt.js";

import { errorTexts as inputErrorText } from "@schaniag/private";




import { SmetioExplanation } from
    "./../../smetioExplanation/v1.0.0/SmetioExplanation.js";


// //import "./styles/_main.scss";

// import { render } from "./methods/render.js";

// import { getLabel } from "./methods/getLabel.js";
// import { events } from "./methods/events.js";
// import { getInstruction } from "./methods/getInstruction.js";
// import { getInput } from "./methods/getInput.js";
// import { getInputType } from "./methods/getInputType.js";
// import { getElementInput } from "./methods/getElementInput.js";
// import { getElementSelect } from "./methods/getElementSelect.js";
// import { getOptions } from "./methods/getOptions.js";
// import { isFocused } from "./methods/isFocused.js";
// import { isUnFocused } from "./methods/isUnFocused.js";
// import { valueHasChanged } from "./methods/valueHasChanged.js";
// import { showError } from "./methods/showError.js";
// import { isValidated } from "../../../functions/validations/isValidated.js";
// import { hideError } from "./methods/hideError.js";
// import { getElementSmetioOnOffSwitch } from "./methods/getElementSmetioOnOffSwitch.js";
// import { renderVerticalInput } from "./methods/renderVerticalInput.js";
// import { renderHorizontalInput } from "./methods/renderHorizontalInput.js";
// import { getLabelOrInputDiv } from "./methods/getLabelOrInputDiv.js";
// import { valueHasChangedForSmetioOnOffSwitch } from "./methods/valueHasChangedForSmetioOnOffSwitch.js";
// import { handleCapitalization } from "./methods/handleCapitalization.js";
// import { getValue } from "./methods/getValue.js";
// import { SmetioOnOffSwitch } from "../../smetioOnOffSwitch/SmetioOnOffSwitch.js";

import { getHtmlInputTypeByType, isValid } from "@schaniag/private";
// import { SmetioBusinessHour} from "../../smetioBusinessHour/v1.0.0/SmetioBusinessHour.js";

// import { SmetioFileUploader} from './../../smetioFileUploader/v1.0.0/SmetioFileUploader';
// import { SmetioFileInput } from "../../smetioFileInput/v1.0.0/SmetioFileInput.js";

export class SmetioObjectToInput {

    constructor(inputObj) {


        this.getId = getId;
        this.setId = setId;
        this.generateId = generateId;
        this.isEmpty = isEmpty;
        this.getAttributes = getAttributes;
        this.toggleDisplay = toggleDisplay;
        this.preventCopyAndPaste = preventCopyAndPaste;

        this.combineSmetioAttributes = combineSmetioAttributes;
        this.validateValueLength = validateValueLength;
        this.changeFirstLetterToUpperCase = changeFirstLetterToUpperCase;
        this.changeFirstLetterToLowerCase = changeFirstLetterToLowerCase;

        // this.getOptions = getOptions;
        // this.isValidated = isValidated;
        this.debounce = debounce;
        this.setTxt = setTxt;


        this.SmetioExplanation = SmetioExplanation;

        this.setId();

        // this.render = render;
        // this.events = events;
        // this.getInstruction = getInstruction;
        // this.getLabel = getLabel;
        // this.getInput = getInput;
        // this.getInputType = getInputType;
        // this.getElementInput = getElementInput;
        // this.getElementSelect = getElementSelect;
        // this.isFocused = isFocused;
        // this.isUnFocused = isUnFocused;
        // this.valueHasChanged = valueHasChanged;
        // this.showError = showError;
        // this.hideError = hideError;
        // this.getElementSmetioOnOffSwitch = getElementSmetioOnOffSwitch;
        // this.renderVerticalInput = renderVerticalInput;
        // this.renderHorizontalInput = renderHorizontalInput;
        // this.getLabelOrInputDiv = getLabelOrInputDiv;
        // this.valueHasChangedForSmetioOnOffSwitch = valueHasChangedForSmetioOnOffSwitch;
        // this.handleCapitalization = handleCapitalization;
        // this.getValue = getValue;


        this.mainObj = inputObj;

        //console.log(this.mainObj);

        /*
            EXAMPLE OF this.mainObj/inputObj
        
            this.mainObj = {
        
                modelId: "", // USED TO IDENTIFY THE MODEL TO WHICH THE INPUT BELONG - CAN BE USED TO TARGET OTHER INPUTS WITHIN THE MODEL
                addToWindow: true, // OR FALSE 
                label: "txt50",  
                instruction:"txt42", // OR DIV   
                isReadonly:true,    // OR FALSE
                isHidden: true,  // OR FALSE - IF THE ELEMENT SHOULD BE SHOWN TO THE USER OR NOT
                photoAndDescriptionSettings: { // FOR SMETIO PHOTO CHECKBOX, PHOTO CHECK RADIO -- SEE SmetioPhotoAndDescription CLASS FOR FULL DETAILS
                    orientation:"vertical", 
                    boxWidth:"45%", 
                    boxMargin:"2.5px", 
                    boxPadding:"2.5px", 
                    photoWidth:"50px",
                    photoVPosition: "center",
                    descriptionVPosition: "center",
                },
                attributes: {
                    id: "cff",
                    title: "txt51",
                    placeholder: "txt52",
                    value: "some value",,
                    name:"",
                    class:["class1", "class2"]
                },
                element: "input or select or textarea or smetioOnOffSwitch or smetioOpenCloseTime",
                explanation:{
                    title: "txt41",
                    article: [
                        {
                            heading: "txt42"
                            paragraphs:["txt43","txt44"],
                        },
                        {
                            heading: "txt42"
                            paragraphs:["txt43","txt44"],
                        }
                    ]
                            
                },
                labelPosition: "up" or "down", // AFFECTS IF THE LABLE SHOULD COME BEFORE THE INPUT AND/OR IF THE LABEL SHOULD BE LEFT OR RIGHT OF THE INPUT
                orientation:"horizontal" or "vertical", // IF INPUT AND LABEL SHOULD BE ARRANGED FROM LEFT TO RIGHT OR FROM UP TO DOWN
  


                appendToLabel: `<a href="https://schaniag.com/legal_policies">Legal policies</a>`, // IT CAN BE AN HTML ELEMENT OR TEXT THAT CAN BE ADDED TO THE LABEL
                useDataList: true, // true or false,
                capitalizeFirstChar: true, // true or false -- true MEANS THE FIRST CHARACTER OF THE ENTER VALUE SHOULD BE AUTOMATICALLY SET TO CAPITAL LETTER
                unCapitalizeFirstChar: true, // true or false -- true MEANS THE FIRST CHARACTER OF THE ENTER VALUE SHOULD BE AUTOMATICALLY SET TO SMALL LETTER
                        
                capitalizeAll: true, // true or false -- true MEANS ALL THE GIVEN VALUE SHOULD BE SET TO CAPITAL LETTER IF THEY ARE NOT ALREADY        
                unCapitalizeAll: true, // true or false -- true MEANS ALL THE GIVEN VALUE SHOULD BE SET TO SMALL LETTER IF THEY ARE NOT ALREADY
                preventCopyAndPaste: true or false, // Default is false true MEANS NO COPY AND PASTE WILL BE ALLOWED FOR THE INPUT
                options: [ // USEFULL FOR CHECK RADIO AND SELECT AND DATALIST
                    {
                        text: "",
                        photo: "https://link-to-photo", // FOR PHOTO CHECKBOX AND PHOTO RADIO
                        attributes: {
                            value: "",                 
                            selected: true // OR FALSE   
                        }
         
                    }
                ],
                validation: {
                    type: "text",
                    required: true,
                    expectedValue: {
                        value:true, // AN EXPECTED VALUE THAT MUST BE ENTERED TO AN INPUT
                        errorText: "" // THE ERROR TEXT TO SHOW IF THE GIVEN VALUE IS NOT SAME AS EXPECTED VALUE
                    },
                    minLength: 2,
                    maxLength: 50,
                    errorText: "txt69", // THE ERROR TO SHOW IF THE EXPECTED VALUE IS NOT PROVIDED 
                    mustBeSameAs: {
                        key: "password", // MEANS THE VALUE GIVEN FOR THE INPUT MUST BE SAME AS THAT OF ANOTHER INPUT IN THE SAME MODEL
                        errorText: "txt49" // THE ERROR MESSAGE TO SHOW IF THE GIVEN VALUES DO NOT MATCH
                    },                                  
                },
                events: {
                    onChange: (value)=>{
        
                    },
                    onFocus: (value)=>{
        
                    },
                    onFocusOut: (value)=>{
        
                    },
                    onChecked: (value)=>{
                        FOR SMETIO SWITCH OR CUSTOM CHECKBOX
                    },                    
                    onUnChecked: (value)=>{
                        FOR SMETIO SWITCH OR CUSTOM CHECKBOX
                    },         
                }
            },
        */






        /**** SETTING THE VALUES BELOW TO FALSE DOES NOT MEAN THEIR VALUES SHOULD BE TRUE OR FALSE ****/
        this.label = false;
        this.instruction = false;
        this.input = false;
        this.type = false;
        this.selectOptions = false;
        this.value = this.mainObj.attributes.value;
        this.inputName = this.mainObj.attributes.name;
        this.error = "";
        this.isReadonly = this.mainObj.isReadonly || false;
        this.isHidden = this.mainObj.isHidden || false;
        this.addToWindow = this.mainObj.addToWindow;
        this.modelId = this.mainObj.modelId;
        this.doNothingOnFocusOut = this.mainObj.doNothingOnFocusOut;
        this.doNothingOnFocus = this.mainObj.doNothingOnFocus;
        this.smetioOnOffSwitch = "";
        this.smetioCustomCheckbox = "";

        /**** FOR SELECT AND DATALIST ****/
        this.options = false;
        this.datalist = false;

        /**** 
         * this.element CAN EITHER BE input or select or textarea or SmetioOnOffSwitch or smetioCheckbox or 
         * customCheckBox 
         * ****/
        this.element = this.mainObj.element || "input";

        this.mainClass = "smetio-input-div-" + this.id;
        this.mainAttributes = {
            "data-smetio-is-visible": this.isHidden ? "false" : "true",
            class: [

                "smetio-input-div",

                this.mainClass
            ],
        };

        this.labelOrInputDivDownClass = "smetio-labelOrInputDivDown-" + this.id;
        this.labelOrInputDivDownAttributes = {

            class: [

                "smetio-labelOrInputDiv",
                "smetio-labelOrInputDivDown",
                this.labelOrInputDivDownClass
            ],
        };

        this.labelOrInputDivUpClass = "smetio-labelOrInputDivUp-" + this.id;
        this.labelOrInputDivUpAttributes = {

            class: [

                "smetio-labelOrInputDiv",
                "smetio-labelOrInputDivUp",
                this.labelOrInputDivUpClass
            ],
        };
        this.labelOrInputDivExtraClass = "smetio-labelOrInputDivExtra-" + this.id;
        this.labelOrInputDivExtraAttributes = {

            class: [

                "smetio-labelOrInputDivExtra",
                this.labelOrInputDivExtraClass
            ],
        };


        this.horizontalInputDivClass = "smetio-horizontalInputDiv-" + this.id;
        this.horizontalInputDivAttributes = {

            class: [

                "smetio-horizontalInputDiv",
                this.horizontalInputDivClass
            ],
        };


        this.labelClass = "smetio-input-label-" + this.id;
        this.labelAttributes = {
            "data-smetio-read-only": this.isReadonly,
            class: [

                "smetio-input-label",

                this.labelClass
            ],
        };


        this.labelSpanClass = "smetio-input-labelSpan-" + this.id;
        this.labelSpanAttributes = {

            class: [
                "smetio-h5-text-size",
                "smetio-input-labelSpan",

                this.labelSpanClass
            ],
        };


        this.labelSpanExtrasClass = "smetio-input-labelSpan-extras" + this.id;
        this.labelSpanExtrasAttributes = {
            "data-smetio-read-only": this.isReadonly,
            class: [

                "smetio-input-labelSpan-extras",

                this.labelSpanExtrasClass
            ],
        };

        this.instructionClass = "smetio-input-instruction-" + this.id;
        this.instructionAttributes = {

            class: [

                "smetio-input-instruction",

                this.instructionClass
            ],
        };

        this.modelSpecificClass = this.mainObj.modelDetail.id + "_" + this.mainObj.modelDetail.currentKey;
        this.inputClass = "smetio-input-" + this.id;
        this.inputAttributes = {
            "data-smetio-read-only": this.isReadonly,
            "data-smetio-model-id": this.modelId,
            name:this.inputName,
            value: this.mainObj.attributes.value,
            class: [

                "smetio-input",

                this.modelSpecificClass,

                this.inputClass,


            ],
        };

        /**** USED FOR SELECT OPTIONS <option></option> AND ALSO FOR DATALIST OPTIONS <option>****/
        this.optionsClass = "smetio-options-" + this.id;
        this.optionsAttributes = {

            class: [

                "smetio-options",

                this.optionsClass
            ],
        };

        this.dataListClass = "smetio-dataList-" + this.id;
        this.dataListAttributes = {
            id: "smetio-dataList-id-" + this.id,
            class: [

                "smetio-dataList",

                this.dataListClass
            ],
        };


        this.errorClass = "smetio-input-error-" + this.id;
        this.errorAttributes = {

            class: [

                "smetio-input-error",

                this.errorClass
            ],
        };


    };

    valueHasChangedForSmetioOnOffSwitch() {

        this.hideError();

        this.value = this.smetioOnOffSwitch.getValue();

        this.error = this.mainObj.validation.expectedValue.errorText || "txt184";

        // const output = this.mainObj.attributes.value === this.mainObj.validation.expectedValue.value ? this.hideError() : this.showError();

        const output = this.value == this.mainObj.validation.expectedValue.value ? this.hideError() : this.showError();

        return output;
    };

    valueHasChangedForSmetioBusinessHour() {

        this.hideError();

        this.value = this.smetioBusinessHour.getValue();

        const isValidInput = this.smetioBusinessHour.isValidInput();

        if (isValidInput !== true) return this.showError(isValidInput);

        return this.hideError();
    };

    valueHasChangedForSmetioFileInput() {
        this.hideError();
        this.value = this.smetioFileInput.getValue();

        // console.log("This is the value received from smetioFileInput: ", this.value);

        if (this.isEmpty(this.value) && this.mainObj.validation.required == true) return this.showError("txt56");

        return this.hideError();
    };

    async valueHasChangedForSmetioColorInputt() {
        this.hideError();
        this.value = this.smetioColorInput.getValue();

        // console.log("This is the value received from smetioColorInput: ", this.value);

        // if( this.isEmpty(this.value.code) && this.mainObj.validation.required == true ) return this.showError("txt56");

        const codeIsValidHexColor = await this.smetioColorInput.codeIsValidHexColor();

        // console.log("This is the codeIsValidHexColor: ", codeIsValidHexColor);

        if (codeIsValidHexColor !== true) return this.showError(codeIsValidHexColor);

        return this.hideError();
    };

    async valueHasChangedForSmetioPhotoCheckbox() {
        // this.hideError();
        this.value = this.smetioPhotoCheckbox.getValue();

        return this.smetioPhotoCheckbox.isValid();

        // return this.hideError();
    };

    async valueHasChangedForSmetioPhotoRadioInput() {
        // this.hideError();

        console.log("Calling value has changed on SmetioPhotoRadioInput");

        this.value = this.smetioPhotoRadioInput.getValue();

        return this.smetioPhotoRadioInput.isValid();

        // return this.hideError();
    };

    openSectionContainer() {

        $(`.smetio-input-section-content-${this.mainObj.modelDetail.id}`).attr("data-smetio-is-visible", "yes");
        $(`.smetio-input-section-title-${this.mainObj.modelDetail.id} div`).html(" <i class=\"smetio-icon smetio-icon-angle-up\"></i>");
    };

    async valueHasChanged() {

        if (this.mainObj.element === "smetioOnOffSwitch" || this.mainObj.element === "smetioCustomCheckbox") return this.valueHasChangedForSmetioOnOffSwitch();

        if (this.mainObj.element === "smetioBusinessHour") return this.valueHasChangedForSmetioBusinessHour();

        if (this.mainObj.element === "smetioFileInput") return this.valueHasChangedForSmetioFileInput();

        if (this.mainObj.element === "smetioColorInput") return this.valueHasChangedForSmetioColorInputt();

        if (this.mainObj.element === "smetioPhotoCheckbox") return this.valueHasChangedForSmetioPhotoCheckbox();

        if (this.mainObj.element === "smetioPhotoRadioInput") return this.valueHasChangedForSmetioPhotoRadioInput();

        this.value = $("." + this.inputClass).val();

        console.log("|||||| this.value is ", this.value," and the name is ", this.inputName);

        this.mainObj.attributes.value = this.value;

        /**** CHECK IF IT IS EMPTY ****/
        if (this.isEmpty(this.value) && this.mainObj.validation.required == true) return this.showError("txt56");

        if (this.mainObj.validation.mustBeSameAs) {

            const mustBeSameAsInputClass = this.mainObj.modelDetail.id + "_" + this.mainObj.validation.mustBeSameAs["key"];
            const mustBeSameAsInputValue = $("." + mustBeSameAsInputClass).val();

            if (this.value !== mustBeSameAsInputValue) return this.showError(this.mainObj.validation.mustBeSameAs["errorText"] || "txt304");

        };

        if (this.isEmpty(this.value)) return this.hideError();

        /**** CHECK IF THE FIRST CHARACTER OR THE ENTIRE VALUE SHOULD BE CAPITALIZED OR UNCAPITALIZED ****/

        await this.handleCapitalization();

        /**** CHECK IF THE LENGTH REQUIREMENT IS MET ****/

        let isValidated = true;

        const { minLength, maxLength } = this.mainObj.validation;
        if (minLength && this.value.length < minLength) return this.showError(inputErrorText.minLength);

        if (maxLength && this.value.length > maxLength) return this.showError(inputErrorText.maxLength);

        /**** VALIDATE THE EXPECTED DATA ****/

        let { type, errorText, expectedValue } = this.mainObj.validation;

        if (typeof (expectedValue) === "object") {
            if (this.value !== expectedValue.value) return this.showError(expectedValue.errorText || "txt303");
        };

        errorText = errorText || inputErrorText[type];

        isValidated = isValid(this.value, type, errorText);
        if (isValidated !== true) return this.showError(isValidated);

        if (this.mainObj.element === "select") $("." + this.inputClass).attr("value", this.value);

        const onChange  =  this.mainObj.events ? this.mainObj.events.onChange : null;

        if (!this.mainObj.events || !onChange) return this.hideError();

        if (typeof onChange === "function") return onChange(this.value);

        // this.hideError();

        if (typeof onChange === "object") {

            this.hideError();

            onChange.functionParameters["value"] = this.value;
            onChange.functionParameters["changedInputClass"] = this;

            console.log("I am now calling onChanged and the value is ", this.value);

            return await this.getAndCallFallbackFunction(onChange);
        };

        return this.hideError();

    };

    async getElementSelect() {

        //if( !this.mainObj.options ) throw "Smetio said you cannot create select without options";

        /**** 
            options: [ // USEFULL FOR CHECK RADIO AND SELECT
                {
                    text: "",
                    attributes: {
                        value: "",                 
                        selected: true, // OR FALSE                                          
                    }
                }
            ],	  
         * ****/


        this.options = await this.getOptions({
            purpose: "select",
            optionsArr: this.mainObj.options
        });


        const output = `
            <select ${this.getAttributes(this.inputAttributes, false)}>
                ${this.options}
            </select>
        `;

        return output;

    };


    async getLabel() {

        /**** RETURN this.label IF IT IS ALREADY SET ****/

        if (this.label) return this.label;


        let extras = "";

        if (this.mainObj.validation.required && this.mainObj.noAsteriskWithLabel !== true) {

            /**** SHOW THE USER THAT THE INPUT IS REQUIRED/MUST BE FILLED ****/
            extras += "*";

        };

        if (this.mainObj.explanation) {

            /**** 
             * GET THE QUESTION MARK SIGN TO INDICATE THAT THERE IS AN EXPLANATION OF THE INPUT
             * 
             * ****/
            const smetioExplanation = new this.SmetioExplanation(this.mainObj.explanation);

            extras += await smetioExplanation.render();

        };


        /**** ADD THE LABEL TEXT TO THE this.texts ****/

        console.log("This is the label TXT text", this.mainObj.label);

        const text = await this.setTxt({
            attributes: this.labelSpanAttributes,
            txt: this.mainObj.label,
            type: "html"
        });

        console.log("This is the label text", text);

        const output = `
            <label ${this.getAttributes(this.labelAttributes, false)} >
                <h5 ${this.getAttributes(this.labelSpanAttributes, false)} >
                    ${text}
                </h5>
                <span ${this.getAttributes(this.labelSpanExtrasAttributes, false)}>${extras}</span>            
            </label>
            <br>
        `;

        return output;

    };

    async getInstruction() {

        /**** 
         * INSTRUCTIONS ARE USED TO INSTRUCT THE USER ON WHAT KIND OF VALUE IS EXPECTED IN THE INPUT
         * IT CAN ALSO BE USED TO INDICATE TO THE USER IF THEY HAVE PROVIDED THE EXPECTED VALUE AND/OR IN THE
         * EXPECTED FORMAT
         * 
         * FOR EXAMPLE INSTRUCTION IS USED TO TELL THE USER ON THE SIGNUP PAGE THAT PASSWORD NEEDS 
         * NUMBERS, LETTERS, SYMBOLS ETC
         * 
         * THE INSTRUCTION CAN BE A SIMPLE TEXT OR IT CAN BE A DIV CONTAINING OTHER DIVs/ELEMENTS
         * 	TEXT HERE MEANS "txt*" AS USED IN smetioTxt.js
         * SO CHECK IF THE INSTRUCTION IS A TEXT OR NOT, IT IS ENOUGH TO SIMPLY CHECK FOR 
         * THE PRESENCE OF < AND/OR > AS THIS WILL INDICATE THE PRESENCE OF HTML TAG/DIV
         * 
         * SIMPLY RETURN this.instruction OF IT IS ALREADY SET
         * ****/

        if (this.instruction) return this.instruction;

        const text = this.mainObj.instruction || "";

        if (this.isEmpty(text)) return "";

        const output = await this.setTxt({
            attributes: this.instructionAttributes,
            txt: text,
            type: "html"
        });

        return output;

    };
    setValue(newValue) {

        if (this.mainObj.element === "smetioBusinessHour") return this.smetioBusinessHour.setValue(newValue);

        this.value = newValue;
        $("." + this.inputClass).val(newValue).trigger("change");

    };
    setValueWithTrigger(data={}){
        const {newValue} = data;
        $("." + this.inputClass).val(newValue);
    };

    getValue() {

        return this.value;

    };

    getLabelOrInputDiv(data) {

        const labelOrInputDiv_up = `
    
            <div ${this.getAttributes(this.labelOrInputDivUpAttributes, false)} >
                ${data.content}
                <div ${this.getAttributes(this.labelOrInputDivExtraAttributes, false)}>
                    <!-- EXTRA STUFFS CAN BE PROGRAMMATICALLY PLACED OR REMOVED FROM THIS DIV-->
                </div>
            </div>
        
        `;

        const labelOrInputDiv_down = `
    
            <div ${this.getAttributes(this.labelOrInputDivDownAttributes, false)} >
                ${data.content}
                <div ${this.getAttributes(this.labelOrInputDivExtraAttributes, false)}>
                    <!-- EXTRA STUFFS CAN BE PROGRAMMATICALLY PLACED OR REMOVED FROM THIS DIV-->
                </div>                
            </div>      
        `;

        const labelOrInputDiv = {
            up: labelOrInputDiv_up,
            down: labelOrInputDiv_down
        };

        return labelOrInputDiv[data.position];

    };


    async getAndCallFallbackFunction(fallbackFunctionDetails = {}) {

        const  {getAndCallFallbackFunction} = (await import(/* webpackPrefetch: true */ "../../../functions/get/getAndCallFallbackFunction.js"));

        return getAndCallFallbackFunction(fallbackFunctionDetails);

    };

    getChangeEventsAndAttributesForCheckboxAndSwitch() {

        if (!this.mainObj.events) this.mainObj.events = {};
        const { onChecked, onUnChecked } = this.mainObj.events;

        const changeEvents = {
            onChecked: async (value) => {

                this.mainObj.attributes.value = value || true;

                if (typeof onChecked === "function") return onChecked(value);

                if (typeof onChecked === "object") {

                    onChecked.functionParameters["value"] = value;

                    return await this.getAndCallFallbackFunction(onChecked);
                };

                // if (this.mainObj.events) return this.mainObj.events.onChecked(value);

                // this.mainObj.attributes.value = value || true;

                return this.valueHasChanged();
            },
            onUnChecked: async (value) => {
                // if (this.mainObj.events) return this.mainObj.events.onUnChecked(value);

                // this.mainObj.attributes.value = false;

                // return this.valueHasChanged();

                this.mainObj.attributes.value = value || false;

                // const { onUnChecked } = this.mainObj.events;

                if (typeof onUnChecked === "function") return onUnChecked(value);

                if (typeof onUnChecked === "object") {

                    onUnChecked.functionParameters["value"] = value;

                    return await this.getAndCallFallbackFunction(onUnChecked);
                };

                return this.valueHasChanged();
            }
        };

        const attributes = {
            readonly: this.readonly,
            ...this.mainObj.attributes
        };

        return { attributes, changeEvents };


    };

    async getElementSmetioOnOffSwitch() {

        const SmetioOnOffSwitch = (await import(/* webpackPrefetch: true */ "../../smetioOnOffSwitch/SmetioOnOffSwitch.js")).SmetioOnOffSwitch;

        const { attributes, changeEvents } = this.getChangeEventsAndAttributesForCheckboxAndSwitch();

        this.smetioOnOffSwitch = new SmetioOnOffSwitch({ attributes, changeEvents });

        this.value = this.smetioOnOffSwitch.getValue();

        return this.smetioOnOffSwitch.render();

    };

    async getElementSmetioBusinessHour() {
        // const { attributes, changeEvents } = this.getChangeEventsAndAttributesForCheckboxAndSwitch();

        const SmetioBusinessHour = (await import(/* webpackPrefetch: true */ "../../smetioBusinessHour/v1.0.0/SmetioBusinessHour.js")).SmetioBusinessHour;

        this.smetioBusinessHour = new SmetioBusinessHour(this.mainObj);
        this.smetioBusinessHour.onStartTimeChange = () => {
            return this.valueHasChanged();
        };
        this.smetioBusinessHour.onEndTimeChange = () => {
            return this.valueHasChanged();
        };
        this.smetioBusinessHour.onStatusChanged = () => {
            return this.valueHasChanged();
        };

        return this.smetioBusinessHour.render();
    };

    async getElementSmetioCustomCheckbox() {

        const SmetioCustomCheckbox = (await import(/* webpackPrefetch: true */ "../../smetioCustomCheckbox/v1.0.0/SmetioCustomCheckbox.js")).SmetioCustomCheckbox;

        const { attributes, changeEvents } = this.getChangeEventsAndAttributesForCheckboxAndSwitch();

        this.smetioCustomCheckbox = new SmetioCustomCheckbox({ attributes, changeEvents });

        this.value = this.smetioCustomCheckbox.getValue();

        return this.smetioCustomCheckbox.render();

    };

    async getElementSmetioPhotoCheckbox() {
        const { attributes, changeEvents } = this.getChangeEventsAndAttributesForCheckboxAndSwitch();

        const SmetioPhotoCheckbox = (await import(/* webpackPrefetch: true */ "../../smetioPhotoCheckbox/v1.0.0/smetioPhotoCheckbox.js")).SmetioPhotoCheckbox;


        this.mainObj.events = changeEvents;

        this.smetioPhotoCheckbox = new SmetioPhotoCheckbox(this.mainObj);

        this.value = this.smetioPhotoCheckbox.getValue();

        return this.smetioPhotoCheckbox.render();

    };

    async getElementSmetioPhotoRadioInput() {
        const { attributes, changeEvents } = this.getChangeEventsAndAttributesForCheckboxAndSwitch();

        const SmetioPhotoRadioInput = (await import(/* webpackPrefetch: true */ "../../smetioPhotoRadioInput/v1.0.0/SmetioPhotoRadioInput.js")).SmetioPhotoRadioInput;

        this.mainObj.events = changeEvents;

        this.smetioPhotoRadioInput = new SmetioPhotoRadioInput(this.mainObj);

        this.value = this.smetioPhotoRadioInput.getValue();

        return this.smetioPhotoRadioInput.render();

    };

    async getElementSmetioFileInput() {

        // import { SmetioFileInput } from "../../smetioFileInput/v1.0.0/SmetioFileInput.js";
        const SmetioFileInput = (await import(/* webpackPrefetch: true */ "../../smetioFileInput/v1.0.0/SmetioFileInput.js")).SmetioFileInput;

        if (!this.mainObj.attributes.events) this.mainObj.attributes.events = {};
        this.mainObj.attributes.events.onFocus = () => {
            this.hideError();
        };

        this.smetioFileInput = new SmetioFileInput(this.mainObj);

        return this.smetioFileInput.render();

    };

    async getElementInput() {

        this.inputAttributes["type"] = await this.getInputType();

        if (this.mainObj.useDataList) {

            this.options = await this.getOptions({
                purpose: "datalist",
                optionsArr: this.mainObj.options
            });

            this.datalist = `
                <datalist ${this.getAttributes(this.dataListAttributes, false)} >
                    ${this.options}
                </datalist>
            `;

            this.inputAttributes["list"] = this.dataListAttributes.id;

        };

        if (!this.datalist) this.datalist = "";

        let output = `
            <input ${this.getAttributes(this.inputAttributes, false)} >
            ${this.datalist}
        `;

        return output;

    };


    async getElementSmetioColorInput() {

        const SmetioColorInput = (await import(/* webpackPrefetch: true */ "./../../smetioColorInput/v1.0.0/SmetioColorInput.js")).SmetioColorInput;

        if (!this.mainObj.events) this.mainObj.events = {};
        this.mainObj.events.onChange = () => {
            return this.valueHasChanged();
        };
        this.smetioColorInput = new SmetioColorInput(this.mainObj);

        return this.smetioColorInput.render();

    };

    async getInput() {

        /**** RETURN this.input IF IT IS ALREADY SET ****/

        if (this.input) return this.input;

        /**** COMBINE THE this.mainObj.attributes WITH THE this.inputAttributes ****/

        //if( this.mainObj.attributes.class && $.isArray(this.mainObj.attributes.class) ) throw "Smetio said class must be an array";

        this.inputAttributes = await this.combineSmetioAttributes([
            this.inputAttributes,
            this.mainObj.attributes
        ]);

        let output = "";

        switch (this.element) {

        case "input":
            output = this.getElementInput();
            break;

        case "select":
            output = this.getElementSelect();
            break;

        case "textarea":
            output = this.getElementTextarea();
            break;

        case "smetioOnOffSwitch":
            output = await this.getElementSmetioOnOffSwitch();
            break;

        case "smetioCustomCheckbox":
            output = await this.getElementSmetioCustomCheckbox();
            break;
        case "smetioBusinessHour":
            output = await this.getElementSmetioBusinessHour();
            break;

        case "smetioFileInput":
            output = await this.getElementSmetioFileInput();
            break;

        case "smetioColorInput":
            output = await this.getElementSmetioColorInput();
            break;

        case "smetioPhotoCheckbox":
            output = await this.getElementSmetioPhotoCheckbox();
            break;
        case "smetioPhotoRadioInput":
            output = await this.getElementSmetioPhotoRadioInput();
            break;
        default:
            output = this.getElementInput();
            break;
        };

        return output;

    };

    getInputType() {

        /**** IF this.type IS ALREADY SET THEN RETURN IT ****/

        if (this.type) return this.type;

        /**** 
         * THIS FUNCTION SHOULD RETURN A VALID HTML INPUT TYPE
         * 
         * IF INPUT TYPE IS NOT SET THEN EXTRACT IT FROM 
         * THE this.mainObj.attributes OR this.mainObj.validation.type
         * 
         * ****/

        if (this.mainObj.attributes.type) return this.mainObj.attributes.type;

        // let output = "text";
        return getHtmlInputTypeByType(this.mainObj.validation.type);

        /*         
            switch (this.mainObj.validation.type) {
        
                case 'smetioPassword':
                case 'spwd':
                case 'password':
                case 'pwd':
                    output = "password";
                    break;
        
                case 'email':
                case 'em':
                    output = "email";
                    break;
        
                case 'number':
                case 'num':
                case 'integer':
                case 'int':
                    output = "number";
                    break;
        
                case 'date':
                case 'dt':
                    output = "date";
                    break;
        
                case 'boolan':
                case 'bo':
                    output = "checkbox";
                    break;
        
                case 'week':
                case 'wk':
                    output = "week";
                    break;
        
                case 'time':
                case 'tm':
                    output = "time";
                    break;
        
                case 'dateTime':
                case 'dtm':
                    output = "datetime-local";
                    break;
        
                case 'hexColor':
                case 'hc':
                case 'rgbColor':
                case 'rgbc':
                case 'rgbaColor':
                case 'rgbac':
                case 'hslColor':
                case 'hslc':
                case 'hslaColor':
                case 'hslac':
                case 'color':
                case 'clr':
                    output = "color";
                    break;
        
                default:
                    output = "text";
                    break;
        
            };
        
            return output; 
            
        */


    };

    async getOptions(data = {}) {

        if (this.options) return this.options;

        /**** PURPOSE CAN BE select or datalist ****/
        const purpose = data.purpose;
        const optionsArr = data.optionsArr;

        if (!$.isArray(optionsArr)) throw "Smetio said options must be provided and must be array";

        let output = "";


        for (let i = 0; i < optionsArr.length; i++) {

            const option = optionsArr[i];

            const attributes = await this.combineSmetioAttributes([
                this.optionsAttributes,
                option.attributes
            ]);



            let text = await this.setTxt({
                attributes,
                txt: option.text,
                type: "html"
            });

            if (purpose === "select") {

                output += ` <option ${this.getAttributes(attributes, false)}>${text}</option>`;

                continue;

            };

            attributes["value"] = await this.setTxt({
                attributes,
                txt: option.text,
                type: "value"
            });

            output += ` <option ${this.getAttributes(attributes, false)}>`;

        };

        return output;

    };

    getElementTextarea() {
        this.value = this.inputAttributes["value"];
        if (this.inputAttributes["value"]) delete this.inputAttributes["value"];
        const output = `
            <textarea ${this.getAttributes(this.inputAttributes, false)}>${this.value}</textarea>
        `;

        return output;
    };
    hideError() {

        $("." + this.inputClass).attr("data-smetio-error", "no");
        $("." + this.errorClass).html("").fadeOut();

        return true;

    };

    handleCapitalization(params) {

        /**** CHECK IF THE FIRST CHARACTER SHOULD BE CAPITALIZED OR UNCAPITALIZED ****/

        if (this.mainObj.capitalizeFirstChar && this.mainObj.unCapitalizeFirstChar) throw "Smetio said you cannot set both capitalizeFirstChar and unCapitalizeFirstChar to true at the same time ";

        if (this.mainObj.capitalizeAll && this.mainObj.unCapitalizeAll) throw "Smetio said you cannot set both capitalizeAll and unCapitalizeAll to true at the same time ";

        if (this.mainObj.capitalizeAll && this.mainObj.capitalizeFirstChar) throw "Smetio said you cannot set both capitalizeAll and capitalizeFirstChar to true at the same time ";

        if (this.mainObj.capitalizeAll && this.mainObj.unCapitalizeFirstChar) throw "Smetio said you cannot set both capitalizeAll and unCapitalizeFirstChar to true at the same time ";

        if (this.mainObj.unCapitalizeAll && this.mainObj.capitalizeFirstChar) throw "Smetio said you cannot set both unCapitalizeAll and capitalizeFirstChar to true at the same time ";

        if (this.mainObj.unCapitalizeAll && this.mainObj.unCapitalizeFirstChar) throw "Smetio said you cannot set both unCapitalizeAll and unCapitalizeFirstChar to true at the same time ";


        let capUnCapValue = "";

        if (this.mainObj.capitalizeFirstChar && this.value[0] !== this.value[0].toUpperCase()) {

            capUnCapValue = this.changeFirstLetterToUpperCase(this.value);

            $("." + this.inputClass).val(capUnCapValue);

            return this.valueHasChanged();

        };

        if (this.mainObj.unCapitalizeFirstChar && this.value[0] !== this.value[0].toLowerCase()) {

            capUnCapValue = this.changeFirstLetterToLowerCase(this.value);

            $("." + this.inputClass).val(capUnCapValue);

            return this.valueHasChanged();

        };

        /**** CHECK IF THE ENTIRE VALUE SHOULD BE CAPITALIZED OR UNCAPITALIZED ****/


        if (this.mainObj.capitalizeAll && this.value !== this.value.toUpperCase()) {

            capUnCapValue = this.value.toUpperCase();

            $("." + this.inputClass).val(capUnCapValue);

            return this.valueHasChanged();

        };

        if (this.mainObj.unCapitalizeAll && this.value !== this.value.toLowerCase()) {

            capUnCapValue = this.value.toLowerCase();

            $("." + this.inputClass).val(capUnCapValue);

            return this.valueHasChanged();

        };

        return true;

    };

    async showError(error) {

        this.openSectionContainer();

        if (error) this.error = error;

        await this.setTxt({
            element: "." + this.errorClass,
            txt: this.error,
            type: "html"
        });

        /*
            await this.setTxt({
                element: "."+this.errorClass,
                txt: this.error,
                type:"title" 
            });    
        */
        $("." + this.inputClass).attr("data-smetio-error", "yes");

        $("." + this.errorClass).fadeIn();

        return false;

    };

    isFocused() {

        // const smetioFileUploader = new SmetioFileUploader();
        // smetioFileUploader.render();


        this.value = $("." + this.inputClass).val();

        if (this.mainObj.instruction) $("." + this.instructionClass).fadeIn();

        this.hideError();

        if (this.mainObj.events && this.mainObj.events.onFocus) this.mainObj.events.onFocus(this.value);


    };

    isUnFocused() {

        $("." + this.instructionClass).fadeOut();

        this.valueHasChanged();

        if (this.mainObj.events && this.mainObj.events.onFocusOut) this.mainObj.events.onFocusOut(this.value);

    };

    changeReadonlyStatus({ newStatus = true }) {

        this.value = $("." + this.inputClass).val();

        this.isReadonly = newStatus;
        if (this.isEmpty(this.value) && newStatus == true) $("." + this.inputClass).val("N/A");

        if ((this.value === "N/A" || this.value === "n/a") && newStatus == false) $("." + this.inputClass).val("");

        $("." + this.labelClass).attr("data-smetio-read-only", this.isReadonly);
        $("." + this.labelSpanExtrasClass).attr("data-smetio-read-only", this.isReadonly);
        $("." + this.inputClass).attr("data-smetio-read-only", this.isReadonly);

        if (this.element === "smetioOnOffSwitch") this.smetioOnOffSwitch.changeReadonlyStatus({ newStatus });
        if (this.element === "smetioCustomCheckbox") this.smetioCustomCheckbox.changeReadonlyStatus({ newStatus });
        if (this.element === "smetioBusinessHour") this.smetioBusinessHour.changeReadonlyStatus({ newStatus });
        if (this.element === "smetioFileInput") this.smetioFileInput.changeReadonlyStatus({ newStatus });
        if (this.element === "smetioColorInput") this.smetioColorInput.changeReadonlyStatus({ newStatus });
        if (this.element === "smetioPhotoCheckbox") this.smetioPhotoCheckbox.changeReadonlyStatus({ newStatus });
        if (this.element === "smetioPhotoRadioInput") this.smetioPhotoRadioInput.changeReadonlyStatus({ newStatus });


        $("." + this.instructionClass).fadeOut();
        this.hideError();
    };
    renderHorizontalInput() {

        const instruction = `
            <div ${this.getAttributes(this.instructionAttributes, false)}>
                ${this.instruction}
            </div>
        `;

        const inputMakeup = instruction + this.input;

        let labelOrInput_up = this.label;
        let labelOrInput_down = inputMakeup;
        this.labelOrInputDivDownAttributes["data-smetio-element"] = this.mainObj.element;
        this.labelOrInputDivUpAttributes["data-smetio-content"] = "label";
        this.labelOrInputDivDownAttributes["data-smetio-content"] = "input";

        if (this.mainObj.labelPosition === "down") {

            labelOrInput_up = inputMakeup;
            labelOrInput_down = this.label;

            this.labelOrInputDivUpAttributes["data-smetio-element"] = this.mainObj.element;
            this.labelOrInputDivDownAttributes["data-smetio-element"] = "";
            this.labelOrInputDivUpAttributes["data-smetio-content"] = "input";
            this.labelOrInputDivDownAttributes["data-smetio-content"] = "label";

        };

        if (this.mainObj.element === "smetioOnOffSwitch" || this.mainObj.element === "smetioCustomCheckbox") this.labelOrInputDivDownAttributes["data-smetio-content"] = "";

        const output = `
    
            <div ${this.getAttributes(this.mainAttributes, false)} >
            
                <div ${this.getAttributes(this.horizontalInputDivAttributes, false)} >
    
                    ${this.getLabelOrInputDiv({ content: labelOrInput_up, position: "up" })}                
    
                    ${this.getLabelOrInputDiv({ content: labelOrInput_down, position: "down" })}
    
                </div>
              
                <div ${this.getAttributes(this.errorAttributes, false)} >
                    Error message will be shown here
                </div>
                
            </div>
    
        `;

        return output;

    };

    renderVerticalInput() {

        let labelOrInput_up = this.label;
        let labelOrInput_down = this.input;

        if (this.mainObj.labelPosition === "down") {

            labelOrInput_up = this.input;
            labelOrInput_down = this.label;

        };


        const output = `
    
            <div ${this.getAttributes(this.mainAttributes, false)} >           
    
                ${this.getLabelOrInputDiv({ content: labelOrInput_up, position: "up" })}
                
                <div ${this.getAttributes(this.instructionAttributes, false)}>
                    ${this.instruction}
                </div>
    
                ${this.getLabelOrInputDiv({ content: labelOrInput_down, position: "down" })}
               
                <div ${this.getAttributes(this.errorAttributes, false)} >
                    Error message will be shown here
                </div>
                
            </div>
    
        `;

        return output;

    };

    hide() {
        $(`.${this.mainClass}`).attr("data-smetio-is-visible", false);
    };

    show() {
        $(`.${this.mainClass}`).attr("data-smetio-is-visible", true);
    };

    addClassToWindow() {
        if (!this.modelId) throw "Smetio said you cannot add class to window without model id. Model id must be added in the backend";
        if (!window.smetioInputClasses) window.smetioInputClasses = {};

        if (!window.smetioInputClasses[this.modelId]) window.smetioInputClasses[this.modelId] = {};

        if (window.smetioInputClasses[this.modelId][this.inputName]){
            
            console.log("Affected input name is : " + this.inputName);

            throw "Smetio said the provided input name has already been used. You must provide unique input names for all the inputs in a model";

        };

        window.smetioInputClasses[this.modelId][this.inputName] = this;
    };

    async render() {

        if (this.addToWindow) this.addClassToWindow();
        const appendToLabel = this.mainObj.appendToLabel || "";

        this.label = await this.getLabel();
        this.label = this.label + appendToLabel;

        this.instruction = await this.getInstruction();
        this.input = await this.getInput();

        if (this.element === "smetioPhotoCheckbox" || this.element === "smetioPhotoRadioInput") return this.input;

        let output = this.renderVerticalInput();

        if (this.mainObj.orientation === "horizontal") output = this.renderHorizontalInput();

        if (this.mainObj.preventCopyAndPaste === true) {

            setTimeout(() => {

                this.preventCopyAndPaste(this.inputClass);

            }, 300);

        };

        this.events();

        return output;



    };

    events() {

        $("body").on("focus", "." + this.inputClass, () => {
            if(this.doNothingOnFocus == true) return false;
            this.isFocused();
        });

        $("body").on("focusout", "." + this.inputClass, () => {
            if(this.doNothingOnFocusOut == true) return false;
            this.isUnFocused();
        });

        $("body").on("input propertychange change", "." + this.inputClass, this.debounce(this.valueHasChanged, 500));


        // $("body").on("click", () => {

        //     console.log("you clicked on lable class");
        //     const newStatus = !this.isReadonly ? true : false;
        //     this.changeReadonlyStatus({ newStatus });

        // });

    };
};
