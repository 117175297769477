import {randomGenerator} from 
    "./randomGenerator.js";

//import {SMETIO_ENGLISH_ALPHABETS} from
  //  "./../../constants/smetio_general_constants.js";

import * as smetioConstant from
    "./../../constants/smetio_general_constants.js";


/*
 * SMETIO_ENGLISH_ALPHABETS IS A CONSTANT OF ALL ENGLISH LETTER FROM A-Z 
 * 
 * */



export function getRandomStringsWithoutNumbers(stringLength){
		
	// THIS FUNCTION GENERATES RANDOM STRINGS WHICH IS MADE UP OF ONLY ENGLISH ALPHABETS IN UPPER AND LOWER CASES
	
	let charactersInLowerCase = smetioConstant.SMETIO_ENGLISH_ALPHABETS.toLowerCase();
	
	let characters = smetioConstant.SMETIO_ENGLISH_ALPHABETS+charactersInLowerCase;
		
	return randomGenerator(characters, stringLength);
	

};


export function getRandomStringsAndNumbers(stringLength){
	
	// THIS FUNCTION GENERATES RANDOM STRINGS WHICH IS MADE UP OF BOTH NUMBERS AND ENGLISH ALPHABETS IN UPPER AND LOWER CASES
	
	let charactersInLowerCase = smetioConstant.SMETIO_ENGLISH_ALPHABETS.toLowerCase();
	
	let characters = smetioConstant.SMETIO_ENGLISH_ALPHABETS+charactersInLowerCase+smetioConstant.SMETIO_NUMBERS;
		
	return randomGenerator(characters, stringLength);
	
};

