import { validate_integer } from "./validate_integer";

export function validate_week(data) {

    /**** THERE ARE 52 WEEKS IN A YEAR ****/
    const isInteger = validate_integer(data);

    if( isInteger !== true ) return isInteger; 
    
    if( data > 0 && data <= 52) return true;

    return "txt182";

};
