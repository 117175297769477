import { setId } from
    "./../universal_methods/setId.js";

import { getId } from
    "./../universal_methods/getId.js";
import { getAttributes } from "./../universal_methods/getAttributes.js";
import { isEmpty } from "./../universal_methods/isEmpty.js";

// import {add_items} from "./methods/add_items.js";
// import {slideLeft} from "./methods/slideLeft.js";
// import {slideRight} from "./methods/slideRight.js";
// import {getController} from "./methods/getController.js";
// import {render} from "./methods/render.js";
// import {events} from "./methods/events.js";
// import {rightControllerIsClicked} from "./methods/rightControllerIsClicked.js";
// import {leftControllerIsClicked} from "./methods/leftControllerIsClicked.js";
// import {slideShowIndicatorIsClicked} from "./methods/slideShowIndicatorIsClicked.js";
import "tocca";




// //import "./styles/_main.scss";
import { checkIfItIsEmpty } from "../../../app.js";


export class SmetioSlideShow {

    constructor() {

        // console.log("This is slide show 2");

        this.setId = setId;
        this.getId = getId;
        this.getAttributes = getAttributes;
        this.isEmpty = isEmpty;



        // this.slideLeft = slideLeft;
        // this.slideRight = slideRight;
        // this.add_items = add_items;
        // this.getController = getController;
        // this.render = render;
        // this.events = events;
        // this.leftControllerIsClicked = leftControllerIsClicked;
        // this.rightControllerIsClicked = rightControllerIsClicked;
        // this.slideShowIndicatorIsClicked = slideShowIndicatorIsClicked;

        this.setId();

        this.items = "";
        this.output = "";

        this.indicator = "";
        this.show_indicator = "yes";
        this.show_controller = "yes";
        this.showLeftController = null;
        this.showRightController = null;
        this.indicatorPlacement = "bottom";
        this.allow_swipe = "yes";
        this.speed = 1;
        this.controllerLeftIcon = "caret-left";
        this.controllerRightIcon = "caret-right";
        this.controllerLeft = "";
        this.controllerRight = "";
        this.customController = "";
        this.controllerPlacement = "middle";
        this.showcontrollerBySlide = true;
        this.visibleSlide = 0;
        this.viewStyle = "cardView";


        this.firstItemAdded = false;
        this.totalItems = 0;
        this.maxMovement = 0;
        this.activeItem = 0;
        this.nextSlidPosition = 0;

        this.clickedController = "";

        this.onSlideChanged = (data = { currentSlideNumber: 1, nextSlideNumber: 1 }) => { return false; };


        this.mainClass = "smetio-slideShow-container-" + this.id;
        this.mainAttributes = {

            id: this.id,
            "data-smetio-view-style" : this.viewStyle,
            class: [

                "smetio-slideShow-container2",
                "smetio-app-noLeftSideMenuSwipControl",
                this.mainClass
            ],
        };

        this.slideShowControllerLeftClass = "smetio-slideShow-controller-left-" + this.id;
        this.slideShowControllerLeftAttributes = {

            class: [

                "smetio-slideShow-controller",
                "smetio-slideShow-controller-left",
                this.slideShowControllerLeftClass
            ]

        };

        this.slideShowControllerRightClass = "smetio-slideShow-controller-right-" + this.id;
        this.slideShowControllerRightAttributes = {

            class: [

                "smetio-slideShow-controller",
                "smetio-slideShow-controller-right",
                this.slideShowControllerRightClass
            ]
        };


        this.slideShowIndicatorClass = "smetio-slideShow-indicator-" + this.id;
        this.slideShowIndicatorAttributes = {

            class: [

                "smetio-slideShow-indicator",
                this.slideShowIndicatorClass
            ],
        };


        this.slideShowIndicatorHolderTopClass = "smetio-slideShow-indicator-holder-top-" + this.id;
        this.slideShowIndicatorHolderTopAttributes = {

            class: [

                "smetio-slideShow-indicator-holder",
                "smetio-slideShow-indicator-holder-top",
                this.slideShowIndicatorHolderTopClass

            ]
        };

        this.slideShowItemsHolderClass = "smetio-slideShow-items-holder-" + this.id;
        this.slideShowItemsHolderAttributes = {

            class: [

                "smetio-slideShow-items-holder",
                this.slideShowItemsHolderClass

            ],
        };

        this.slideShowIndicatorHolderBottomClass = "smetio-slideShow-indicator-holder-bottom-" + this.id;
        this.slideShowIndicatorHolderBottomAttributes = {

            class: [

                "smetio-slideShow-indicator-holder",
                "smetio-slideShow-indicator-holder-bottom",
                this.slideShowIndicatorHolderBottomClass

            ]
        };

        this.slideShowItemClass = "smetio-slideShow-item-" + this.id;
        this.slideShowItemAttributes = {

            class: [

                "smetio-slideShow-item",
                this.slideShowItemClass
            ],
        };

        this.slideShowItemContentHolderClass = "smetio-slideShow-item-content-holder-" + this.id;


    };

    changeItemContent(itemNumber, newContent) {

        // console.log("itemNumber IS : "+itemNumber);
        // console.log("newContent IS : "+newContent);

        // $("."+this.slideShowItemClass+"-"+itemNumber).html("")
        // $("."+this.slideShowItemClass+"-"+itemNumber).html(newContent);
        $("." + this.slideShowItemContentHolderClass + "-" + itemNumber).html(newContent);
    };

    clearItems(){

        
        this.items = "";
        this.totalItems = 0;
        this.maxMovement = 0;
        this.activeItem = 0;
        this.nextSlidPosition = 0;

        this.clickedController = "";
    }

    /*
    import {SmetioSideMenu} from
        "./../../smetioSideMenu/SmetioSideMenu.js";
    
    import {SmetioSideMenuItem} from
        "./../../smetioSideMenuItem/SmetioSideMenuItem.js";
    
    import {checkIfItIsEmpty} from
        "./../../functions/checks/checkIfItIsEmpty.js";     
    */


    add_items(item) {

        /**** 
         * 
         * THE ITEM HAS TO BE AN OBJECT
         * 
         * item = {
         * 
         *  showIcon: true, OR false IF AN ICON SHOULD BE SHOWN ON TOP OF THE INDICATOR
         *  class: "any class you like",
         *  content: `
         * 
         *      <div> CAN CONTAIN IMAGES OR OTHER DIVS AND ANYTHING </div>
         * `,
         *  indicator: {     
         *      icon: search, // FROM font awesom
         *      text: 1, // NUMBERS OR TEXTS
         *      isActive: "yes", OR no
         *      class: "anyClass you like"
         *  }
         * 
         * }
         * 
         * 
    
         $.each(item, function(key, value) {
             
            item[key] = changeUndefineToEmptyString( value );
    
         });
    
         $.each(item["indicator"], function(key, value) {
             
            item["indicator"][key] = changeUndefineToEmptyString( value );
    
         });
    ****/

        this.slideShowIndicatorAttributes["class"] = [
            "smetio-slideShow-item",
            this.slideShowItemClass,
            this.slideShowItemClass + "-" + this.totalItems
        ];

        if (!this.isEmpty(item.class)) {

            this.slideShowIndicatorAttributes["class"].push(item.class);

        };

        const slideShowItemAttributes = {
            "data-smetio-view-style" : this.viewStyle,
            class: [

                "smetio-slideShow-item",
                this.slideShowItemClass,
                this.slideShowItemClass + "-" + this.totalItems,
                this.slideShowItemContentHolderClass + "-" + this.totalItems,
            ],
        };

        // this.items += `
        // <div ${this.getAttributes(this.slideShowItemAttributes, false)} >

        //     ${item.content}

        // </div>
        // `;

        this.items += `
        <div ${this.getAttributes(slideShowItemAttributes, false)} >

            ${item.content}

        </div>
        `;

        /**** INCREASE THE NUMBERS OF ITEMS IN THE SLIDE SHOW ****/

        this.totalItems += 1;

        /**** ADD A NEW INDICATOR TO THE SLIDE ****/

        this.slideShowIndicatorAttributes["data-smetio-active"] = item.indicator.isActive;
        this.slideShowIndicatorAttributes["data-smetio-target"] = this.id;
        this.slideShowIndicatorAttributes["data-smetio-target-item"] = this.totalItems;

        this.slideShowIndicatorAttributes["class"] = [
            "smetio-slideShow-indicator",
            this.slideShowIndicatorClass,
            this.slideShowIndicatorClass + "_" + this.totalItems

        ];

        if (!this.isEmpty(item.indicator.class)) {

            this.slideShowIndicatorAttributes["class"].push(item.indicator.class);

        };

        let icon = !item.showIcon ? "" : `<span><i class="smetio-icon smetio-icon-${item.indicator.icon}"> </i></span>`;


        this.indicator += `
        <div ${this.getAttributes(this.slideShowIndicatorAttributes, false)} >
                        
            ${icon}
            
            <span>${item.indicator.text}</span>
           
        </div>
        `;

        return false;

    };

    appendNewItem(item) {
        this.add_items(item);
        this.reRender();

    };

    displayLeftController(){
        console.log("showing left controller");
        $(`.${this.slideShowControllerLeftClass}`).attr("data-smetio-visible", "yes");
        $(`.${this.slideShowControllerLeftClass}`).fadeIn();      
    };

    hideLeftController(){
        $(`.${this.slideShowControllerLeftClass}`).attr("data-smetio-visible", "no");
        $(`.${this.slideShowControllerLeftClass}`).fadeOut();
    };

    displayRightController(){
        $(`.${this.slideShowControllerRightClass}`).attr("data-smetio-visible", "yes");
        $(`.${this.slideShowControllerRightClass}`).fadeIn();
    };
    
    hideRightController(){
        $(`.${this.slideShowControllerRightClass}`).attr("data-smetio-visible", "no");
        $(`.${this.slideShowControllerRightClass}`).fadeOut();
    };

    getController() {

        /**** 
         * this.customController HAS TO BE AN OBJECT 
         * this.customController = {
         *    left: 
         * 	  right: 
         * }
         * 
         * ****/

        if (checkIfItIsEmpty(this.customController)) {

            this.controllerLeft = `<i class="smetio-icon smetio-icon-${this.controllerLeftIcon}"></i>`;
            this.controllerRight = `<i class="smetio-icon smetio-icon-${this.controllerRightIcon}"></i>`;

        } else {

            this.controllerLeft = this.customController.left;
            this.controllerRight = this.customController.right;

        };

    };

    leftControllerIsClicked() {



        --this.activeItem;

        this.nextSlidPosition = this.activeItem * 100;

        // console.log(this.nextSlidPosition+" is the next position 1");

        /**** 
         * TO CHECK IF THE FORWARD CONTROLLER SHOULD BE HIDDEN WHEN THE LAST ITEM IN THE SLIDE SHOW IS
         * VISIBLE AND THE BACK CONTROLLER SHOULD BE HIDDEN WHEN THE FIRST ITEM IN THE SLIDE SHOW IS
         * VISIBLE
         * ****/

        this.showLeftController = this.showLeftController || this.show_controller;

        if (this.showLeftController === "yes") {

            $("." + this.slideShowControllerRightClass).fadeIn();

        };


        if (this.nextSlidPosition >= (this.totalItems * 100)) {

            this.nextSlidPosition = (this.totalItems - 1) * 100;

        };

        if (this.nextSlidPosition <= 0) {

            this.nextSlidPosition = 0;

            this.activeItem = 0;

            if (this.showLeftController === "yes") {

                /**** HIDE THIS CONTROLLER SINCE IT IS THE LAST ITEM THAT IS NOW VISIBLE ****/
                $("." + this.slideShowControllerLeftClass).fadeOut();

            };

        };

        // console.log(this.nextSlidPosition+" is the next position 3");

        $("." + this.slideShowItemsHolderClass).css(
            "margin-left", "-" + this.nextSlidPosition + "%"
        );

        $("." + this.slideShowIndicatorClass).attr("data-smetio-active", "no");

        const itemIndicator = this.activeItem + 1;

        $("." + this.slideShowIndicatorClass + "_" + itemIndicator).attr("data-smetio-active", "yes");

        this.visibleSlide = this.nextSlidPosition / 100;

        if (this.onSlideChanged) {
            this.onSlideChanged({
                nextSlidPosition: this.nextSlidPosition / 100,
                direction: "left"
            });
        };


    };

    rightControllerIsClicked() {



        this.maxMovement = (this.totalItems - 1) * 100;

        ++this.activeItem;

        /**** 
         * TO CHECK IF THE FORWARD CONTROLLER SHOULD BE HIDDEN WHEN THE LAST ITEM IN THE SLIDE SHOW IS
         * VISIBLE AND THE BACK CONTROLLER SHOULD BE HIDDEN WHEN THE FIRST ITEM IN THE SLIDE SHOW IS
         * VISIBLE
    	
         * ****/

        this.showRightController = this.showRightController || this.show_controller;

        if (this.showRightController === "yes") {

            $("." + this.slideShowControllerLeftClass).fadeIn();

        };


        this.nextSlidPosition = this.activeItem * 100;

        if (this.nextSlidPosition >= this.maxMovement) {

            this.nextSlidPosition = this.maxMovement;

            this.activeItem = this.totalItems - 1;

            if (this.showRightController === "yes") {

                /**** HIDE THIS CLICKED/RIGHT CONTROLLER SINCE IT IS THE LAST ITEM THAT IS NOW VISIBLE ****/

                $("." + this.slideShowControllerRightClass).fadeOut();

            };

        };

        $("." + this.slideShowItemsHolderClass).css(

            "margin-left", "-" + this.nextSlidPosition + "%"

        );

        $("." + this.slideShowIndicatorClass).attr("data-smetio-active", "no");

        const itemIndicator = this.activeItem + 1;

        $("." + this.slideShowIndicatorClass + "_" + itemIndicator).attr("data-smetio-active", "yes");

        this.visibleSlide = this.nextSlidPosition / 100;

        if (this.onSlideChanged) {
            this.onSlideChanged({
                nextSlidPosition: this.nextSlidPosition / 100,
                direction: "right"
            });
        };

    };

    setDefaultOutput() {

        let output = `
    
        
        <div 
            id="${this.getId()}" 
            class=" smetio-slideShow-container "
            data-smetio-items="3"
            data-smetio-active = "0"
        >
          
            <div 
                data-target="${this.getId()}" 
                class=" smetio-slideShow-controller  smetio-slideShow-controller-left"
            >
                <i class="smetio-icon smetio-icon-caret-left"></i>
            </div>

            <div 
                data-target="${this.getId()}" 
                class=" smetio-slideShow-controller  smetio-slideShow-controller-right"
            >
                <i class="smetio-icon smetio-icon-caret-right"></i>
            </div>


            <div class="smetio-slideShow-items-holder">

                ${this.items}

            </div>


            <div class="smetio-slideShow-indicator-holder">

                <div data-smetio-active="yes" class="smetio-slideShow-indicator smetio-slideShow-indicator_1">
                    <span>1<span>
                </div>

                <div class="smetio-slideShow-indicator smetio-slideShow-indicator_2">
                    <span>2<span>
                </div>

                <div class="smetio-slideShow-indicator smetio-slideShow-indicator_3">
                    <span>3<span>
                </div>
                                                
            </div>


        </div>
   

    `;

        return output;

    };

    slideLeft() {

        $("." + this.slideShowControllerLeftClass).trigger("mousedown");

        return false;

        //this.leftControllerIsClicked();

        //return false;

    };

    slideRight() {

        $("." + this.slideShowControllerRightClass).trigger("mousedown");

        return false;

        //return this.rightControllerIsClicked();

        //this.rightControllerIsClicked();

        //return false;

    };

    triggerIndicatorClick(indicatorNumber){

        console.log("You just triggered indicator click ___--=============== ");

        $(`.${this.slideShowIndicatorClass}_${indicatorNumber}`).trigger("mousedown");

        // $("." + this.slideShowIndicatorClass + "_" + indicatorNumber).trigger("mousedown");

    };

    slideShowIndicatorIsClicked(event) {

        const previouslyViewedSlide = this.visibleSlide;

        this.activeItem = $(event.currentTarget).attr("data-smetio-target-item");
        this.activeItem = parseInt(this.activeItem) - 1;

        this.nextSlidPosition = this.activeItem * 100;


        $("." + this.slideShowItemsHolderClass).css(
            "margin-left", "-" + this.nextSlidPosition + "%"
        );


        ++this.activeItem;

        $("." + this.slideShowIndicatorClass).attr("data-smetio-active", "no");
        $("." + this.slideShowIndicatorClass + "_" + this.activeItem).attr("data-smetio-active", "yes");

        this.visibleSlide = this.nextSlidPosition / 100;

        if (this.onSlideChanged) {
            this.onSlideChanged({
                nextSlidPosition: this.nextSlidPosition / 100,
                previouslyViewedSlide,
                direction: previouslyViewedSlide > (this.nextSlidPosition / 100) ? "left" : "right"
            });
        };

        /**** 
         * TO CHECK IF THE FORWARD CONTROLLER SHOULD BE HIDDEN WHEN THE LAST ITEM IN THE SLIDE SHOW IS
         * VISIBLE AND THE BACK CONTROLLER SHOULD BE HIDDEN WHEN THE FIRST ITEM IN THE SLIDE SHOW IS
         * VISIBLE
         * 
         * ****/

        if (this.show_controller === "yes") {

            let firstSlideIsVisible = $("." + this.slideShowIndicatorClass + "_1").attr("data-smetio-active");

            if (firstSlideIsVisible === "yes") {

                $("." + this.slideShowControllerLeftClass).fadeOut();
                $("." + this.slideShowControllerRightClass).fadeIn();

                return false;

            };

            let lastSlideIsVisible = $("." + this.slideShowIndicatorClass + "_" + this.totalItems).attr("data-smetio-active");

            if (lastSlideIsVisible === "yes") {

                $("." + this.slideShowControllerLeftClass).fadeIn();
                $("." + this.slideShowControllerRightClass).fadeOut();

                return false;

            };

            if (firstSlideIsVisible !== "yes" && lastSlideIsVisible !== "yes") {

                $("." + this.slideShowControllerLeftClass).fadeIn();
                $("." + this.slideShowControllerRightClass).fadeIn();

                return false;

            };

        };

    };

    prepareForRender() {
        this.getController();

        this.mainAttributes["data-smetio-view-style"] = this.viewStyle;
        this.mainAttributes["data-smetio-items"] = this.totalItems;
        this.mainAttributes["data-smetio-active"] = 0;
        this.mainAttributes["data-smetio-allow-swipe"] = this.allow_swipe;
        this.mainAttributes["data-smetio-show-controllerBySlide"] = this.showcontrollerBySlide;


        this.slideShowControllerLeftAttributes["data-target"] = this.id;
        this.slideShowControllerLeftAttributes["data-smetio-visible"] = this.showLeftController || this.show_controller;
        this.slideShowControllerLeftAttributes["data-smetio-placement"] = this.controllerPlacement;


        this.slideShowControllerRightAttributes["data-target"] = this.id;
        this.slideShowControllerRightAttributes["data-smetio-visible"] = this.showRightController || this.show_controller;
        this.slideShowControllerRightAttributes["data-smetio-placement"] = this.controllerPlacement;


        this.slideShowIndicatorHolderTopAttributes["data-smetio-visible"] = this.show_indicator;
        this.slideShowIndicatorHolderTopAttributes["data-smetio-placement"] = this.indicatorPlacement;


        this.slideShowItemsHolderAttributes["data-smetio-speed"] = this.speed;


        this.slideShowIndicatorHolderBottomAttributes["data-smetio-visible"] = this.show_indicator;
        this.slideShowIndicatorHolderBottomAttributes["data-smetio-placement"] = this.indicatorPlacement;

        return `
          
            <div ${this.getAttributes(this.slideShowControllerLeftAttributes, false)} >
                ${this.controllerLeft}
            </div>

            <div ${this.getAttributes(this.slideShowControllerRightAttributes, false)} >
                ${this.controllerRight}
            </div>

            <div ${this.getAttributes(this.slideShowIndicatorHolderTopAttributes, false)} >
                ${this.indicator}                                                
            </div>

            <div ${this.getAttributes(this.slideShowItemsHolderAttributes, false)} >
                ${this.items}
            </div>

            <div ${this.getAttributes(this.slideShowIndicatorHolderBottomAttributes, false)} >
                ${this.indicator}                                                
            </div>
        
        `;

    };

    reRender(){
        const content = this.prepareForRender();
        $(`.${this.mainClass}`).html(content);
    };

    scrollToTop(){
        $(`.${this.slideShowItemClass}, .${this.slideShowItemsHolderClass }, .${this.mainClass}`).animate({ scrollTop: 0 }, "fast");
    };

    render() {

        // this.getController();

        // this.mainAttributes["data-smetio-items"] = this.totalItems;
        // this.mainAttributes["data-smetio-active"] = 0;
        // this.mainAttributes["data-smetio-allow-swipe"] = this.allow_swipe;
        // this.mainAttributes["data-smetio-show-controllerBySlide"] = this.showcontrollerBySlide;


        // this.slideShowControllerLeftAttributes["data-target"] = this.id;
        // this.slideShowControllerLeftAttributes["data-smetio-visible"] = this.show_controller;
        // this.slideShowControllerLeftAttributes["data-smetio-placement"] = this.controllerPlacement;


        // this.slideShowControllerRightAttributes["data-target"] = this.id;
        // this.slideShowControllerRightAttributes["data-smetio-visible"] = this.show_controller;
        // this.slideShowControllerRightAttributes["data-smetio-placement"] = this.controllerPlacement;


        // this.slideShowIndicatorHolderTopAttributes["data-smetio-visible"] = this.show_indicator;
        // this.slideShowIndicatorHolderTopAttributes["data-smetio-placement"] = this.indicatorPlacement;


        // this.slideShowItemsHolderAttributes["data-smetio-speed"] = this.speed;


        // this.slideShowIndicatorHolderBottomAttributes["data-smetio-visible"] = this.show_indicator;
        // this.slideShowIndicatorHolderBottomAttributes["data-smetio-placement"] = this.indicatorPlacement;
        /* 
        
                
                  
                    <div ${this.getAttributes(this.slideShowControllerLeftAttributes, false)} >
                        ${this.controllerLeft}
                    </div>
        
                    <div ${this.getAttributes(this.slideShowControllerRightAttributes, false)} >
                        ${this.controllerRight}
                    </div>
        
                
                    <div ${this.getAttributes(this.slideShowIndicatorHolderTopAttributes, false)} >
        
                        ${this.indicator}
                                                        
                    </div>
        
        
                    <div ${this.getAttributes(this.slideShowItemsHolderAttributes, false)} >
        
                        ${this.items}
        
                    </div>
        
        
                    <div ${this.getAttributes(this.slideShowIndicatorHolderBottomAttributes, false)} >
                        ${this.indicator}                                                
                    </div>
        
        
                </div>
           
            `;
        
             */

        const content = this.prepareForRender();

        let output = `        
            <div ${this.getAttributes(this.mainAttributes, false)} >
                ${content}
            </div>    
        `;

        this.events();

        return output;

    };

    events() {

        //const isEmpty = this.isEmpty;


        $("body").on("mousedown", "." + this.slideShowControllerRightClass, () => {

            return this.rightControllerIsClicked();

        });


        $("body").on("mousedown", "." + this.slideShowControllerLeftClass, () => {

            return this.leftControllerIsClicked();

        });



        $("body").on("mousedown", "." + this.slideShowIndicatorClass, (event) => {

            return this.slideShowIndicatorIsClicked(event);

        });


        $("body").on("swipeleft", "." + this.mainClass + "[data-smetio-allow-swipe='yes']", () => {

            //let targetSlidShow = $(this).attr("id");

            //$("#"+targetSlidShow+" .smetio-slideShow-controller-right").trigger("click");

            $("." + this.slideShowControllerRightClass).trigger("mousedown");

        });

        $("body").on("swiperight", "." + this.mainClass + "[data-smetio-allow-swipe='yes']", () => {

            //let targetSlidShow = $(this).attr("id");

            //$("#"+targetSlidShow+" .smetio-slideShow-controller-left").trigger("click");

            $("." + this.slideShowControllerLeftClass).trigger("mousedown");

        });





    };










};
