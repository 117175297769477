export async function setVisitorToken(visitorToken){

	if(!this.hasLocalStorage) return;

	this.visitorToken = visitorToken;

	await localStorage.setItem("vToken", this.visitorToken);

	return true;

};