export async function showNotificationWithServiceWorker(data){

	const notification = await this.mainServiceWorker.showNotification(data.title, {
		body: data.body,
		icon: data.icon,
		vibrate: data.vibration,
		tag: data.tag
    });

/*
    if(isTimed){

	    // close the notification after 10 seconds
	    setTimeout(() => {
	        notification.close();
	    }, duration);

    };
    
    notification.addEventListener('click', () => {

    	if(data.onclick){

    		return data.onclick();

    	};
        
    });    


    notification.addEventListener('error', (error) => {

    	console.log("There was error ***");
    	console.log(error);
    });

    notification.addEventListener('show', () => {

    	console.log("notice is shown **");

    });        
*/

};