export function isLegalPoliciesAccepted(){

	if( !this.browser.hasLocalStorage){

		return false;

	};

	this.browser.legalPoliciesAccepted = localStorage.getItem("legalPoliciesAccepted");
	this.browser.legalPoliciesAcceptedDate = localStorage.getItem("legalPoliciesAcceptedDate");
	this.browser.versionOfLegalPoliciesAccepted = localStorage.getItem("versionOfLegalPoliciesAccepted");

	if(this.browser.legalPoliciesAccepted != "yes" || parseInt(this.smetioLegalPolicyCurrentVersion) != parseInt(this.browser.versionOfLegalPoliciesAccepted) ){

		return this.showLegalPolicyNotice();

	};

	return false;

};