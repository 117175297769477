export async function documentVisibilityHasChanged(e){

	/**** 
	 * THIS MEANS THAT THE USER HAS NAVIGATED TO ANOTHER TAB OR 
	 * RETURN FROM ANOTHER TAB TO THE PAGE
	 * 
	 * ****/
	 this.currentTabStatus = "active";

	 if(document.visibilityState === "hidden"){

	 	this.currentTabStatus = "hidden";

	 };

	/**** UPDATE THE TAB STATUS ****/	
	 
	await this.getGeolocation();

	delete this.SmetioAjax.dataToSendToServer;

	this.SmetioAjax.dataToSendToServer = {

		visitedWebsite: {
			type: "json", 
			value:JSON.stringify(this.visitedWebsite) 
		},
		latitude: {
			type: "lat", 
			value:this.geoLocation.latitude 
		},
		longitude: {
			type: "lo",  
			value:this.geoLocation.longitude 
		},
		tabStatus: {
			type: "str",
			value: this.currentTabStatus
		}
				
	};

	this.SmetioAjax.urlToConnectTo = this.serverPingLocation;

	return this.SmetioAjax.sendRequest();



};