
import { getId } from "../../universal_methods/getId.js";
import { setId } from "../../universal_methods/setId.js";
import { generateId } from "../../universal_methods/generateId.js";
import { isEmpty } from "../../universal_methods/isEmpty.js";
import { getAttributes } from "../../universal_methods/getAttributes.js";
import { toggleDisplay } from "../../universal_methods/toggleDisplay.js";

import { setTxt } from "../../universal_methods/setTxt.js";




//import "./styles/_main.scss";
import { SmetioSmartbox } from "../../smetioSmartbox/v1.0.0/SmetioSmartbox.js";
import { SmetioComingSoon } from "../../smetioComingSoon/v1.0.0/SmetioComingSoon.js";




export class SmetioAppUiNotificationHandler {

  constructor() {


    this.getId = getId;
    this.setId = setId;
    this.generateId = generateId;
    this.isEmpty = isEmpty;
    this.getAttributes = getAttributes;
    this.toggleDisplay = toggleDisplay;
    this.setTxt = setTxt;

    this.setId();

    this.title = "txt220";

    this.smartboxIsOpen = false;

    this.smartbox = new SmetioSmartbox();
    this.comingSoon = new SmetioComingSoon();
    
    this.smartbox.onRender = ()=>{
      this.smartboxIsOpen = true;
    };
    this.smartbox.onClose = ()=>{
      this.smartboxIsOpen = false;
    };
    
    /****  
     * this.notifications IS JUST A COUNT OF THE NUMBERS OF NOTIFICATIONS
     * this.visibleNotification IS WHAT IS SHOWN TO THE USER NEXT TO THE BELL ICON
     * ****/
    this.notifications = 0;   
    this.visibleNotification = this.notifications;

    this.mainClass = "smetio-app-ui-notification-icon-"+this.id;
    this.mainAttributes = {
        class: [
            "smetio-app-ui-notification-icon",
            "smetio-icon-primary",                
            this.mainClass
        ],
    };

    this.notificationNumbersClass = "smetio-app-ui-notification-numbers-"+this.id;
    this.notificationNumbersAttributes = {
        class: [
            "smetio-app-ui-notification-numbers",               
            this.notificationNumbersClass
        ],
    };
    this.notificationNumbersAttributes["data-smetio-notification-is-available"] = "no";
    

    this.notificationNumberTextClass = "smetio-app-ui-notification-numbers-text" + this.id;
    this.notificationNumberTextAttributes = {
        class: [
            "smetio-app-ui-notification-numbers-text",               
            this.notificationNumberTextClass
        ],
    };
  
  };

  getNotifications(){
    return this.comingSoon.render();  
  };

  resetSmartbox(){

    this.smartbox.sidelize();
    this.smartbox.activate();
   
  };

  open(){

    if(this.smartboxIsOpen) return this.resetSmartbox();

    this.smartbox.title = this.title;
    this.smartbox.content = this.getNotifications();
    this.smartbox.render();
    this.smartbox.sidelize();

  };

  hide(){

    $("."+this.notificationNumberTextClass).html("");
    $("."+this.notificationNumbersClass).attr("data-smetio-notification-is-available", "no");

  };

  show(){

    if(this.notifications <= 0) return this.hide();

    $("."+this.notificationNumberTextClass).html(this.visibleNotification);
    $("."+this.notificationNumbersClass).attr("data-smetio-notification-is-available", "yes");
      
  };

  increaseNotification(){

    this.notifications += 1;

    this.visibleNotification = this.notifications;

    if(this.notifications  > 999 ) this.visibleNotification = "999+";

  };

  decreaseNotification(){

    this.notifications -= 1;

    if(this.notifications < 0 ) this.notifications = 0;

    this.visibleNotification = this.notifications;

  };  

  render() {

    this.setTxt({
      attributes: this.mainAttributes,
      txt: this.title,
      type:"title" 
    });	

    const output = `
      <div ${this.getAttributes(this.mainAttributes, false)} >
      
        <div ${this.getAttributes(this.notificationNumbersAttributes , false)} >          
            <h1>                         
                <strong ${this.getAttributes(this.notificationNumberTextAttributes, false)}>
                  ${this.visibleNotification}
                </strong>              
            </h1>              
        </div>      

        <i class="smetio-icon smetio-icon-bell-o"></i>   

      </div>        
    `;

    this.events();

    return output;

  };


  events() {

    $("body").on("mousedown", "."+this.mainClass, () => {

      return this.open();

    });
  
  }


};
