//import {test_function} from "./../functions/test_function.js";



// import { getId } from "../../universal_methods/getId.js";
// import { setId } from "../../universal_methods/setId.js";
// import { generateId } from "../../universal_methods/generateId.js";
// import { isEmpty } from "../../universal_methods/isEmpty.js";
// import { getAttributes } from "../../universal_methods/getAttributes.js";
// import { toggleDisplay } from "../../universal_methods/toggleDisplay.js";
// import { universal_handleErrorFromServer } from "../../universal_methods/universal_handleErrorFromServer.js";
// import { combineSmetioAttributes } from "../../universal_methods/combineSmetioAttributes.js";
// import { setTxt } from "../../universal_methods/setTxt.js";






// import { SmetioOverlayPreloader } from
//   "../../smetioOverlayPreloader/SmetioOverlayPreloader.js";


// import { SmetioAjax } from
//   "../../smetioAjax/SmetioAjax.js";



import { checkIfItIsEmpty } from "../../../functions/checks/checkIfItIsEmpty.js";

import { copyToClipboard } from "../../../functions/copyAndPaste/copyToClipboard.js";

import { debounce } from "throttle-debounce";

//import "./styles/_main.scss";

export class SmetioIcons {

  constructor() {


    // this.getId = getId;
    // this.setId = setId;
    // this.generateId = generateId;
    // this.isEmpty = isEmpty;
    // this.getAttributes = getAttributes;
    // this.toggleDisplay = toggleDisplay;
    // this.setTxt = setTxt;

    // this.universal_handleErrorFromServer = universal_handleErrorFromServer;



    // this.combineSmetioAttributes = combineSmetioAttributes;

    // this.SmetioOverlayPreloader = new SmetioOverlayPreloader();
    // this.SmetioAjax = new SmetioAjax();
    // this.errorCode = errorCode;

    // this.setId();





  };

  downloadAndDisplayIcons(){
    let categories = null;
    let icon = null;
    let output = null;

    const smetioIcons = "/js/jsons/smetio_website/smetioIcons.json";

    $.getJSON(smetioIcons, (result)=>{

        $(".content-icons").html("");

        $.each(result, (key, value)=>{

            if(key == ".smetio"){

                return true;

            };

            const selectOption = `
                <option value="${key}">${key}</option>
            `;

            $("select").append(selectOption);

            if( $.isArray(value) ){

                for(let i=0; i < value.length; i++){

                    icon =`

                        <div 
                            class="smetio-iconHolder ${key}_category" 
                            title="${value[i]}"
                            data-smetio-iconClass="smetio-icon smetio-icon-${value[i]}"

                        >

                            <div class="smetio-iconTag" >
                                <i class="smetio-icon smetio-icon-${value[i]}"></i>
                            </div>                            

                            <div class="smetio-iconName">

                                <p class="smetio-elipsedText"> ${value[i]}</p>

                            </div>
                            

                        </div>
                    `;

                    $(".content-icons").append(icon);

                };

                return true;
                
            };


            icon =`

                <div 
                    class="smetio-iconHolder ${key}_category" 
                    title="${value}"
                    data-smetio-iconClass="smetio-icon smetio-icon-${value}"                    
                >

                    <div>
                        <i class="smetio-icon smetio-icon-${value}"></i>
                    </div> 

                    <p> ${value} </p>

                </div>
            `;
          
            $(".content-icons").append(icon);

        });


    });

  };

  render() {

    // this.getId(); 

    $("body").attr("data-app", "smetio_icons");

    const output = `
    
      <div class="smetio-content">

        <div class="smetio-icons-head">
          
          <h1> Smetio icons </h1>
          <p>
            Click on icons to copy classes to clipboard 
          </p>

          <div class="searchDiv">

            <select>
              <option value=""></option>
            </select>
            <input class="smetio-icon-search" type="search" placeholder="Search for icons eg mic">

          </div>

        </div>

          <div class="content-icons">

            <h2>Loading Icons </h2>

            <p>Please wait... </p>

          </div>

      </div>
    
    `;

    $("body").html(output);

    this.downloadAndDisplayIcons();
    this.events();

  };

  events() {


    $("select").change(function(){

        $(".smetio-icon-search").val("");

        $(".smetio-iconHolder").hide();

        const value = $(this).val();

        if( checkIfItIsEmpty(value) ){

            $(".smetio-iconHolder").show();

            return false;

        };
      
        $("."+value+"_category").show();
      
    });

    

    $("body").on("keyup", ".smetio-icon-search", debounce(500, function(){

        $("select").val("");

        $(".smetio-iconHolder").hide();

        let value = $(this).val();
        value = value.toLowerCase();

        if( checkIfItIsEmpty(value) ){

            $(".smetio-iconHolder").show();

            return false;

        };        

        $(".smetio-iconHolder").each(function(){

            let myClasses = $(this).attr("class");
            myClasses.toLowerCase();

            if(myClasses.indexOf(value) > 0){

                $(this).show();

            };

        });

    }));

    $("body").on("mousedown", ".smetio-iconHolder", function(){

        const theDataToCopy = $(this).attr("data-smetio-iconClass");

        copyToClipboard({

            theDataToCopy: theDataToCopy,

            onSuccessFunc: ()=>{

                alert("copied");

            },

            onFailedFunc: ()=>{

                alert("Unable to copy");

            }

        });

    });

    // $("body").on("mousedown", "."+this.uploadFilesBtnClass, ()=>{

    //     $("."+this.uploadFilesInputClass).trigger("click");

    // });	

  };


};
