export function validate_integer(data) {
    
   const regex = /^([0-9])+$/;

   if( regex.test(data) ) return true;

   return "txt160";


};
