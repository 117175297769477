export function getPeerJsId(){

     /**** 
      * EACH VISITED PAGE WILL HAVE ITS OWN PEERID AND THIS WILL BE STORED IN THE DATABASE
      * WHERE IT CAN BE RETRIEVED AND USED TO COMMUNICATE WITH THE VISITOR OF THE PAGE
      * 
      * 
      * ****/

	this.visitedWebsite.peerJsId = this.getRandomStringsAndNumbers(50);

	this.peerJsObject = new Peer(this.visitedWebsite.peerJsId, {
		host: this.gv.link_to_webRtcSignal,
		port: '443',
		path: '/peerjs',
		secure: true,
		debug: 3
	});






    /****
     * peerJsId SHOULD BE CREATED USING: THE DEVICE, THE BROWSER AND DOMAIN VALUE
     * THIS WAY MESSAGES WILL STILL BE SENT EVEN WHEN THE USER NAVIGATES TO A NEW
     * URL OF THE SAME DOMAIN
     * 
     * this.peerJsObject CAN BE USED TO IMPLIMENT COMMUNICATION WITH THE VISITOR 
     * FOR EXAMPLE IMPLIMENT 
     * this.peerJsObject.on('open')
     * this.peerJsObject.on('call') etc SEE PeerJs DOCUMENTATION FOR INFORMATION
     * 
	const peerJsId_hashObject = {

		domain: this.visitedWebsite.domain,
        browser: this.browser,  
        device: this.device  

	};

	this.data.peerJsId = this.hash.sha1(peerJsId_hashObject);     * 
     * ****/    


};