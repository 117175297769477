

export function validate_email(theEmail){

    const regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9_.+-])+\.)+([a-zA-Z0-9]{2,4})+$/;

    if( regex.test(theEmail) ) return true;

    return "txt58";
    
};