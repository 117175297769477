
import { getId } from "../../universal_methods/getId.js";
import { setId } from "../../universal_methods/setId.js";
import { generateId } from "../../universal_methods/generateId.js";
import { isEmpty } from "../../universal_methods/isEmpty.js";
import { getAttributes } from "../../universal_methods/getAttributes.js";
import { toggleDisplay } from "../../universal_methods/toggleDisplay.js";

import { setTxt } from "../../universal_methods/setTxt.js";


//import "./styles/_main.scss";


export class SmetioSideMenu {

  constructor() {


    this.getId = getId;
    this.setId = setId;
    this.generateId = generateId;
    this.isEmpty = isEmpty;
    this.getAttributes = getAttributes;
    this.toggleDisplay = toggleDisplay;
    this.setTxt = setTxt;

    this.setId();

    this.userDevice = window.smetioVisitor.device.formFactor.toLowerCase();

    this.onSwipeLeft = () => false;
    this.onItemClick = this.onSwipeLeft;

    this.status = "close";
    this.placement = "left";

    /*
    SHOULD BE AN ARRAY OF OBJECTS
    EACH OBJECT REPRESENT THE ITEM (ICON AND TEXT ) THAT IS SHOWN WHEN THE SIDE MENU IS OPENED
    this.items = [

      {
        id: "userAddresses", MUST BE SET AND MUST BE UNIQUE FROM OTHERS IN THE ARRAY
        text: "txt204",
        icon: "home7",
        showViewBtns: true or false, IF THE VIEW BUTTONS (listView/cardView) ON THE ACTIONBAR SHOULD BE SHOWN WHEN THIS ITEM IS CLICKED
        contentHolder: new SmetioAppUiSideMenuItemContent(), THIS IS TAKEN CARE OF BY THE addContentHolderTosideMenuItems() FUNCTION WHICH SHOULD BE IN ALL SMETIO APP CLASS
        onClick: () => {

          WHAT SHOULD HAPPEN WHEN THE ITEM IS CLICKED. BY DEFAULT, THE contentHolder WILL BE SHOWN AND THIS FUNCTION IS IMPLEMENTED INSIDE THE USER INTERFACE CLASS 
          BUT MORE ACTIONS CAN BE PERFORMED AS SHOWN BELOW
          FOR EXAMPLE THE USERS ADDRESS CAN BE LOADED FROM THE SERVER ETC

          console.log("you clicked addresses ");
          this.smetioAppUi.actionBar.hideAll();
          this.smetioAppUi.actionBar.show("createNew");
          this.smetioAppUi.actionBar.buttons.createNew.events["mousedown"] = () => {
            console.log("new Address Created");
          };


        },
        onRendered: () => {

          WHAT SHOULD HAPPEN WHEN THE DETAILS OF THIS ITEM IS USED TO CREATE HTML ELEMENT (div). THE DEFAULT BEHAVOR IS SET/IMPLEMENTED IN THE USER INTERFACE CLASS

        }        
      }  

    ];*/

    this.items = "";
    this.activeMenu = "";

    /*
      THIS WILL BE USED TO STORE INSTANCES OF SmetioDataGroup ASSOCIATED WITH EACH SIDE MENU ITEMS
      FOR EXAMPLE
      this.dataGroups["locations"] = new SmetioDataGroup();
    */
    this.dataGroups = {};

    /*
      THIS WILL BE USED TO STORE SEARCH TERM/WORD ASSOCIATED WITH EACH SIDE MENU ITEMS
      FOR EXAMPLE
      this.searchTerms["locations"] = "via";
    */
    this.searchTerms = {};

    /*
    THIS WILL BE USED TO STORE THE VIEWS ASSOCIATED WITH EACH SIDE MENU ITEMS
    FOR EXAMPLE
    this.searchTerms["locations"] = "listView";
  */
    this.views = {};

    this.mainClass = "smetio-app-ui-sideMenu-" + this.id;
    this.mainAttributes = {
      class: [
        "smetio-app-ui-sideMenu",
        this.mainClass
      ],
    };
    this.mainAttributes["data-open-status"] = this.status;
    this.mainAttributes["data-side"] = this.placement;


    this.itemsHolderClass = "smetio-app-ui-sideMenu-items-holder" + this.id;
    this.itemsHolderAttributes = {
      class: [
        "smetio-app-ui-sideMenu-items-holder",
        this.itemsHolderClass
      ],
    };
    this.itemsHolderAttributes["data-open-status"] = this.status;

    this.itemClass = "smetio-app-ui-sideMenu-item" + this.id;
    this.itemAttributes = {
      class: [
        "smetio-app-ui-sideMenu-item",
        this.itemClass
      ],
    };
    this.itemAttributes["data-smetio-active"] = "no";

    this.itemIconClass = "smetio-app-ui-sideMenu-item-icon" + this.id;
    this.itemIconAttributes = {
      class: [
        "smetio-app-ui-sideMenu-item-icon",
        this.itemTextClass
      ],
    };
    this.itemIconAttributes["data-open-status"] = this.status;

    this.itemTextClass = "smetio-app-ui-sideMenu-item-text" + this.id;
    this.itemTextAttributes = {
      class: [
        "smetio-app-ui-sideMenu-item-text",
        this.itemTextClass
      ],
    };
    this.itemTextAttributes["data-open-status"] = this.status;


  };


  setStatus(newStatus) {

    this.status = newStatus;

    $("." + this.mainClass).attr("data-open-status", newStatus);
    $("." + this.itemIconClass).attr("data-open-status", newStatus);
    $("." + this.itemTextClass).attr("data-open-status", newStatus);

    return false;

  };

  getStatus() {

    return this.status;

  };

  fullyOpen() {

    return this.setStatus("fully_open");

  };

  halfOpen() {

    return this.setStatus("half_open");

  };

  close() {

    return this.setStatus("close");

  };


  rotateStatus() {

    switch (this.status) {

      case "close": return this.fullyOpen();
        break;
      case "fully_open": return this.halfOpen();
        break;
      case "half_open": return this.close();
        break;
      default: return this.close();
    };

  }

  moveLeftWard() {

    if (this.status === "close") return false;

    return this.rotateStatus();

  };

  getItem(data = {
    id: " @COMPULSORY",
    icon: " @COMPULSORY",
    text: " @COMPULSORY",
    onClick: " @COMPULSORY",
    index: " @COMPULSORY"
  }) {

    /**** 
     * THE index IS NEEDED SO IT CAN BE USED TO SET THE ID OF THE ITEM IF IT IS GENERATED 
     *  ****/

    const id = data.id || this.generateId(6);

    this.items[data.index]["id"] = id;

    const itemAttributes = { id: id, ...this.itemAttributes };

    const textAttributes = {}

    const text = this.setTxt({
      attributes: textAttributes,
      txt: data.text,
      type: "html"
    });

    this.setTxt({
      attributes: itemAttributes,
      txt: data.text,
      type: "title"
    });

    /*
    if( this.items[data.index]["onRendered"] ) this.items[data.index]["onRendered"]({
      id
    });
    */

    if (this.items[data.index]["onRendered"]) this.items[data.index]["onRendered"]();

    return `
      <div ${this.getAttributes(itemAttributes, false)} >

            <div ${this.getAttributes(this.itemIconAttributes, false)} >
              <i class="smetio-icon smetio-icon-${data.icon}"></i>  
            </div>

            <div ${this.getAttributes(this.itemTextAttributes, false)} >

              <p ${this.getAttributes(textAttributes, false)} > 
                ${text}
              </p>

            </div>
      </div>
    `;

  }

  renderItems() {

    // if(!this.items || !$.isArray(this.items) ) throw "Smetio said items must be provided and it must be an array of object";

    let items = "";

    // for (let index = 0; index < this.items.length; index++) {

    for (const key in this.items) {

      const item = this.items[key];
      item["index"] = key;

      items += this.getItem(item);

    };

    return items;

  };

  render() {

    const item = this.renderItems();

    const output = `

      <div ${this.getAttributes(this.mainAttributes, false)} >

        <div ${this.getAttributes(this.itemsHolderAttributes, false)} >
          ${item}      
        </div>                                    
        
      </div>
    
    `;

    this.events();

    return output;

  };

  createItemsEvents() {

    for (const key in this.items) {

      const id = this.items[key]["id"];

      $("body").on("mousedown", "#" + id, async () => {

        this.activeMenu = key;

        if (typeof this.items[key]["onClick"] === "function") return this.items[key]["onClick"]();

        const { functionPath, functionParameters } = this.items[key]["onClick"];

        const functionName = functionPath.split("/").pop();
        const func = await import(/* webpackPrefetch: true */ `./../../../functions/${functionPath}.js`);
       
        if (func[functionName]) func[functionName](functionParameters || {});

      });

    };

  };

  events() {

    $("body").on("mousedown", "." + this.itemClass, (event) => {

      $("." + this.itemClass).attr("data-smetio-status", "inactive");
      $(event.currentTarget).attr("data-smetio-status", "active");

      const id = $(event.currentTarget).attr("id");

      return this.onItemClick({ id });

    });

    $("body").on("swipeleft", "." + this.mainClass, () => {

      return this.onSwipeLeft();

    });

    setTimeout(() => {
      this.createItemsEvents();
    }, 300);

  };


};
