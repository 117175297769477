//import {test_function} from "./../functions/test_function.js";



// import { bookAppointment } from "../../../functions/apps/bookings/bookAppointment.js";
import { SmetioSetUpAndInitialization } from "../../smetioSetUpAndInitialization/v1.0.0/SmetioSetUpAndInitialization.js";



// import {getId} from "./../../universal_methods/getId.js";  
// import {setId} from "./../../universal_methods/setId.js";
// import {generateId} from "./../../universal_methods/generateId.js";
// import {isEmpty} from "./../../universal_methods/isEmpty.js";  
// import {getAttributes} from "./../../universal_methods/getAttributes.js";  
// import {toggleDisplay} from "./../../universal_methods/toggleDisplay.js"; 
// import {universal_handleErrorFromServer} from "./../../universal_methods/universal_handleErrorFromServer.js";  
// import {combineSmetioAttributes} from "./../../universal_methods/combineSmetioAttributes.js";  
// import { setTxt } from "../../universal_methods/setTxt.js";



export class Smetio3ackMainWebsiteController extends SmetioSetUpAndInitialization {

      constructor(data = {}) {

            super(data);

            this.data = data;
            this.addToSubmissionData = {};
     

            // this.usefulLinks = data.usefulLinksObj ? data.usefulLinksObj.links : [];

            // this.mainPageClass = "smetio-page-" + this.id;
            // this.mainPageAttributes = {
            //   class: ["smetio-page", this.mainPageClass],
            // };

      };

  

      async displayLanguageIcon(){

            const SmetioLanguageOptions = (await import(/* webpackPrefetch: true */ "../../smetioLanguageOptions/v1.0.0/SmetioLanguageOptions.js")).SmetioLanguageOptions;

            this.languageOptions = new SmetioLanguageOptions(this.data);
            const languageIcon = this.languageOptions.render();
            $(".smetio-3ack-main-website-languages-nav").html(languageIcon);

      };

  
      async render() {
  
            console.log("RENDERING MAIN WEBSITE CONTROLLER ***************");
 
            this.displayLanguageIcon();
  
            this.removeSplashScreen();

      };


      events() {

            // $("body").on("mousedown", "."+this.uploadFilesBtnClass, ()=>{

            //     $("."+this.uploadFilesInputClass).trigger("click");

            // });	

      };

};
