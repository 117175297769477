import { Smetio3ackMainWebsiteController } from "../../smetio3ackMainWebsiteController.js/v1.0.0/Smetio3ackMainWebsiteController.js";



export class Smetio3ackBlogPostPageController extends Smetio3ackMainWebsiteController {

      constructor(data = {}) {

            super(data);

            this.data = data;
            this.addToSubmissionData = {};
     

            // this.usefulLinks = data.usefulLinksObj ? data.usefulLinksObj.links : [];

            // this.mainPageClass = "smetio-page-" + this.id;
            // this.mainPageAttributes = {
            //   class: ["smetio-page", this.mainPageClass],
            // };

      };

  

      // async displayLanguageIcon(){

      //       const SmetioLanguageOptions = (await import(/* webpackPrefetch: true */ "../../smetioLanguageOptions/v1.0.0/SmetioLanguageOptions.js")).SmetioLanguageOptions;

      //       this.languageOptions = new SmetioLanguageOptions(this.data);
      //       const languageIcon = this.languageOptions.render();
      //       $(".smetio-3ack-main-website-languages-nav").html(languageIcon);

      // };

  


      async render() {
  

            this.displayLanguageIcon();
  
            this.removeSplashScreen();

            const { SmetioTextEditor } = (await import(/* webpackPrefetch: true */ "../../smetioTextEditor/v1.0.0/SmetioTextEditor.js"));
      
            const ste = new SmetioTextEditor({ value: this.extraParameters, attributes: { isReadonly: true } });

            const output = ste.render();

            $(".smetio-3ack-sub-page-blog-post-content-writing").html(output);

      };


      events() {

            // $("body").on("mousedown", "."+this.uploadFilesBtnClass, ()=>{

            //     $("."+this.uploadFilesInputClass).trigger("click");

            // });	

      };

};
