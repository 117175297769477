export function showExplanation(){

	const smartBox = new this.SmetioSmartbox();
	smartBox.title = this.title;
	smartBox.appendTo = this.smartBoxContainer;

	let content = "";

	for (var i = 0; i < this.mainObj.article.length; i++) {
		
		const article = this.mainObj.article[i];
		const paragraphs = this.getParagraphs(article.paragraphs);

		let newArticleTitleAttributes = {};
		newArticleTitleAttributes = {...this.articleTitleAttributes, ...newArticleTitleAttributes};

		const heading = this.setTxt({
			attributes:newArticleTitleAttributes,
			txt: article.heading,
			type:"html" 
		});	
		
		content += `

			<h2 ${this.getAttributes(newArticleTitleAttributes, false)} >
				
				${heading}

			</h2>

			<div class="smetio-explanation-article-paragraphs">

				${paragraphs}
				
			</div>
		`;
	
	};

	smartBox.content = `

		<div class="smetio-explanation-article">
			${content}
		</div>

	`;

	smartBox.render();

};