export function supportsGeolocation(){

	if(!navigator.geolocation){

		return false;

	};

	return true;

};