export async function pingServer(){

		// TEMPORARY FIX
		return false

	await this.getGeolocation();

	this.SmetioAjax.dataToSendToServer = {

		visitedWebsite: {
			type: "json", 
			value:JSON.stringify(this.visitedWebsite) 
		},
		latitude: {
			type: "lat", 
			value:this.geoLocation.latitude 
		},
		longitude: {
			type: "lo",  
			value:this.geoLocation.longitude 
		}		
		
	};

	this.SmetioAjax.urlToConnectTo = this.serverPingLocation;

	this.SmetioAjax.success = (result)=>{

		//delete this.SmetioAjax.request.data;

		this.handleUserLoginStatus(result);

	};

	await this.SmetioAjax.sendRequest();

};