import {quickNotification} from
    "./../notification/quickNotification.js";

export function copyToClipboard(obj={}){

	const theDataToCopy = obj.theDataToCopy;
	const onSuccessFunc = obj.onSuccessFunc;
	const onFailedFunc = obj.onFailedFunc;

	navigator.clipboard.writeText(theDataToCopy).then(function(){

		if(onSuccessFunc){

			return onSuccessFunc();

		};

		quickNotification({

			message: "Id has been copied to clipboard. Use CTR+V to paste",
			putMessageOnElement: ""

		});

	}, function(){

		if(onFailedFunc){

			return onFailedFunc();

		};

		quickNotification({

			message: "Could not copy to clipboard. Please check clipboard permission",
			putMessageOnElement: ""

		});

	});
	



};