//import {test_function} from "./../functions/test_function.js";



import { getId } from "./../../../universal_methods/getId.js";
import { setId } from "./../../../universal_methods/setId.js";
import { generateId } from "./../../../universal_methods/generateId.js";
import { isEmpty } from "./../../../universal_methods/isEmpty.js";
import { getAttributes } from "./../../../universal_methods/getAttributes.js";
import { toggleDisplay } from "./../../../universal_methods/toggleDisplay.js";
import { setTxt } from "../../../universal_methods/setTxt.js";



/*
import { getHead } from
    "./methods/head/getHead.js";


   
import { getBody } from
    "./methods/body/getBody.js";
*/







// //import "./styles/_main.scss";
import { SmetioTaskBar } from "../../../smetioTaskBar/v1.0.0/SmetioTaskBar.js";
import { SmetioSideBar } from "../../../smetioSideBar/v1.0.0/SmetioSideBar.js";
import { SmetioActionBar } from "../../../smetioActionBar/v1.0.0/SmetioActionBar.js";
import { SmetioSideMenu } from "../../../smetioSideMenu/v1.0.0/SmetioSideMenu.js";
import { SmetioMenuBar } from "../../../smetioMenuBar/v1.0.0/SmetioMenuBar.js";

import { SMETIO_LINK_LOGO_TEXT } from "../../../../constants/links.js";
import { SmetioAppUiNotificationHandler} from "../../../smetioAppUiNotificationHandler/v1.0.0/smetioAppUiNotificationHandler.js";
// import { SmetioAppUiLanguageHandler } from "../../../smetioAppUiLanguageHandler/v1.0.0/SmetioAppUiLanguageHandler.js";
import { SmetioLanguageOptions } from "../../../smetioLanguageOptions/v1.0.0/SmetioLanguageOptions.js";
import { removeSplashScreen } from "../../../universal_methods/removeSplashScreen.js";
// import { getAndCallFallbackFunction } from "../../../../functions/get/getAndCallFallbackFunction.js";
// import { log } from "@tensorflow/tfjs";
// import { SmetioAppUiOnlineStatusHandler } from "../../../smetioAppUiOnlineStatusHandler/v1.0.0/SmetioAppUiOnlineStatusHandler .js";
// import { removeSplashScreen } from './../../../universal_methods/removeSplashScreen';



export class SmetioAppUserInterface {

    constructor(data) {


        this.getId = getId;
        this.setId = setId;
        this.generateId = generateId;
        this.isEmpty = isEmpty;
        this.getAttributes = getAttributes;
        this.toggleDisplay = toggleDisplay;
        this.setTxt = setTxt;
        this.removeSplashScreen = removeSplashScreen;


        this.taskBar = new SmetioTaskBar();
        this.sideBar = new SmetioSideBar();
        this.actionBar = new SmetioActionBar();
        this.sideMenu = new SmetioSideMenu();
        this.menuBar = new SmetioMenuBar();
        this.notificationHandler = new SmetioAppUiNotificationHandler();
        // this.languagesHandler = new SmetioAppUiLanguageHandler();
        this.languagesHandler = new SmetioLanguageOptions(data);
        this.languagesHandler.showOnlyIcon = true;
        // this.onlineStatusHandler = new SmetioAppUiOnlineStatusHandler();

        this.userDevice = window.smetioVisitor.device.formFactor.toLowerCase();
        this.content_for_section2 = "";
        this.sideMenuToUse = data.sideMenuToUse;

        this.actionBar.onSearch = (searchWord)=>{
            
            const activeMenu = this.sideMenu.activeMenu;
            if(!this.sideMenu.dataGroups[activeMenu]) return false;

            this.sideMenu.searchTerms[activeMenu] = searchWord;

            if(!this.sideMenuItems[activeMenu].onSearch) return this.sideMenu.dataGroups[activeMenu].searchForData(searchWord);

            // if(this.sideMenu.dataGroups[activeMenu].onSearch) return this.getAndCallFallbackFunction(this.sideMenu.dataGroups[activeMenu].onSearch);
            if(typeof this.sideMenuItems[activeMenu].onSearch === "function") return this.sideMenuItems[activeMenu].onSearch(searchWord);

            if( typeof this.sideMenuItems[activeMenu].onSearch === "object") {

                this.sideMenuItems[activeMenu].onSearch.functionParameters["searchWord"] = searchWord;
                return this.getAndCallFallbackFunction(this.sideMenuItems[activeMenu].onSearch);

            };
            
            // return false;
            
        };

        this.setId();

        /*
            EXAMPLE
            TO BE SET BY THE APP CLASS (SmetioProfile, SmetioPay etc)
            this.sideMenuItems =  {

                personalDetails: {
                id: "personalDetails",
                text: "txt203",
                icon: "profile",
                onClick:()=>{
                    console.log("you clicked personal ")
                },
                onRendered:()=>{
        
                }
                }, 
        
                userAddresses:{
                id: "userAddresses",
                text: "txt204",
                icon: "home7",
                onClick:()=>{
                    console.log("you clicked addresses ")
                },
                onRendered:()=>{
                },      
                userEmails:{
                id: "userEmails",
                text: "txt205",
                icon: "mail1",
                onClick:()=>{
                    console.log("you clicked email ")
                },
                onRendered:()=>{
                    
                }  
        
            };
        */
        this.sideMenuItems = "";
        this.contentsForMenuItems = "";

        this.logo = SMETIO_LINK_LOGO_TEXT;
        this.logoAltText = "txt208";
        // this.splashScreenImage = this.logo;

        this.brandText = "Profile";
        this.brandName = "Smetio";
        this.useBrandNameNotLogo = false;
        this.profilePicture = "/img/placeholders/profile_placeholder.svg";
        this.profilePictureAltText = "txt209";
        this.userFullName = "Emakpor Charles";

        this.appInUse = "";
        this.headIsHiden = false;


       
        this.mainClass = "smetio-app-ui-"+this.id;
        this.mainAttributes = {          
            class: [              
                "smetio-app-ui",          
                this.mainClass
            ],
        }; 

        this.headClass = "smetio-app-ui-head-"+this.id;
        this.headAttributes = {          
            class: [              
                "smetio-app-ui-head",          
                this.headClass
            ],
        };   
        this.headAttributes["data-smetio-isVisible"] = "yes";    
       
        this.bodyClass = "smetio-app-ui-body-"+this.id;
        this.bodyAttributes = {          
            class: [              
                "smetio-app-ui-body",          
                this.bodyClass
            ],
        };   
        this.bodyAttributes["data-smetio-isVisible"] = "yes";          
        
        
        this.bodySection2Class = "smetio-app-ui-body-section-2-" + this.id;
        this.bodySection2Attributes = {
            class: [
                "smetio-app-ui-body-section-2",
                this.bodySection2Class
            ],
        };
        this.bodySection2Attributes["data-open-status"] = "close";

        this.logoImgClass = "smetio-app-ui-head-section-2-logoImg-" + this.id;
        this.logoImgAttributes = {
            class: [
                "smetio-app-ui-head-section-2-logoImg",
                this.logoImgClass
            ],
        };


        this.profileClass = "smetio-app-ui-profile-" + this.id;
        this.profileAttributes = {
            class: [
                "smetio-app-ui-profile",
                "smetio-icon-primary",
                "btn",
                this.profileClass
            ],
        };        
        
        this.profilePictureClass = "smetio-app-ui-head-section-2-profilePicture-" + this.id;
        this.profilePictureAttributes = {
            class: [
                "smetio-app-ui-head-section-2-profilePicture",
                this.profilePictureClass
            ],
        };
               
        this.appContentClass = "smetio-content-"+this.id;
        this.appContentAttributes = {
            class: [
                "smetio-content",
                this.appContentClass
            ],
        };    
              
    };

    async getAndCallFallbackFunction(fallbackFunctionDetails){

        const getAndCallFallbackFunction = (await import(/* webpackPrefetch: true */ "../../../../functions/get/getAndCallFallbackFunction.js")).getAndCallFallbackFunction;

        // import { getAndCallFallbackFunction } from "../../../../functions/get/getAndCallFallbackFunction.js";
        return getAndCallFallbackFunction(fallbackFunctionDetails);
    };

    getBrand() {

        this.setTxt({
            attributes: this.logoImgAttributes,
            txt: this.logoAltText,
            type: "alt"
        });

        this.logoImgAttributes["src"] = this.logo;

        const logoImg = `
            <img ${this.getAttributes(this.logoImgAttributes, false)} >
        `;

        const logoText = `
            <h1> ${this.brandName}</h1>
        `;
        let logo = this.useBrandNameNotLogo ? logoText : logoImg;

        return `
            <div id="smetio-app-ui-logo" >                    
                ${logo}
                <h2> ${this.brandText}</h2>
            </div>        
        `;

    };

    getProfilePicture(){

        this.setTxt({
            attributes: this.profilePictureAttributes,
            txt: this.profilePictureAltText,
            type: "alt"
        });
        this.profilePictureAttributes["src"] = this.profilePicture;
        this.profilePictureAttributes["title"] = this.userFullName;

        return `
            <img ${this.getAttributes(this.profilePictureAttributes, false)} >        
        `;
        
    };

    getProfilePicAndOnlineStatus() {

     
        return `
        
            <div ${this.getAttributes(this.profileAttributes, false)} >       

                ${ this.getProfilePicture() }
                
          

            </div>	        
        `;        

    };

    getNotificationIcon(){        
        return this.notificationHandler.render();
    };

    getLanguagesIcon(){
        return this.languagesHandler.render();
    };

    setEventsForSideBarAndSideMenu(){

        this.menuBar.onClick = ()=>{
            this.sideMenu.rotateStatus();
            return this.setStatusOpenForBody( this.sideMenu.getStatus() );
        };
    
        this.menuBar.onDblClick = ()=>{
            this.sideMenu.halfOpen();
            return this.setStatusOpenForBody( this.sideMenu.getStatus() );
        };    
    
    
        this.sideMenu.onSwipeLeft = ()=>{
    
            if( this.userDevice === "desktop" ) return false;  
    
            this.sideMenu.close();
    
            return this.setStatusOpenForBody( this.sideMenu.getStatus() );
    
        };
    
        this.sideMenu.onItemClick = ()=>{
           
            if( this.userDevice === "desktop" ) return false;  

            this.sideMenu.close();
    
            return this.setStatusOpenForBody( this.sideMenu.getStatus() );
    
        };  

    };

    getHeadSection1(){

        this.setEventsForSideBarAndSideMenu();

        return this.menuBar.render();
    };

    getHeadSection2() {

        return `

            <div class="smetio-app-ui-head-section-2"> 
            
                <!--  head section 2 -->
                
                ${this.getBrand()}

                <div class="smetio-app-ui-top-control" >

                    <div class="smetio-app-ui-top-control-language-info">
                        ${this.getLanguagesIcon()}  
                    </div>
                                 
                
                    ${this.getNotificationIcon()}
                    
                    ${this.getProfilePicAndOnlineStatus()}                    
                    
                </div>
                                    
            </div>

        `;

    };    

    getHead(){
        return `
            <!-- HEAD -->
            
                    
            <div ${this.getAttributes(this.headAttributes, false)} >
                
                <!--  head section 1 -->

                ${ this.getHeadSection1() }

                <!--  head section 1 -->
                
                ${this.getHeadSection2()}
                            
            </div>	    
    
        `;
    };

    setStatusOpenForBody(newStatus) {

        $("." + this.bodySection2Class).attr("data-open-status", newStatus);
    };

    hideHeadAndTaskBar(){

        if(this.headIsHiden) return false;
                        
        $("."+this.headClass).attr("data-smetio-mode", "hidden");

        $("."+this.bodyClass).attr("data-smetio-mode", "full");

        this.taskBar.hide();
        
        this.headIsHiden = true;

    };

    showHeadAndTaskBar(){

        if(!this.headIsHiden) return false;

        $("."+this.headClass).attr("data-smetio-mode", "");
        $("."+this.bodyClass).attr("data-smetio-mode", "");

        this.taskBar.show();

        this.headIsHiden = false;
    };

    addEventsFunctionsToMenuItemss(){

        
        // for (let i = 0; i < this.sideMenuItems.length; i++) {
        for (const key in this.sideMenuItems) {
            // const storedOnRendered = this.sideMenuItems[i]["onRendered"];

            const storedOnRendered = this.sideMenuItems[key]["onRendered"];

            const newOnrenderedFunc = (data)=>{

                if(  storedOnRendered ) storedOnRendered();

                this.contentsForMenuItems += this.sideMenuItems[key]["contentHolder"].render();
            };
            
            this.sideMenuItems[key]["onRendered"] = newOnrenderedFunc;
            
            /**** 
             * HIDE THE HEADER AT THE TOP WHEN THE USER IS SCROLLING DOWN  
             * AND REVERSE IT WHEN THE USER IS SCROLLING UP
             * ****/
            const storedScrollingDown = this.sideMenuItems[key]["contentHolder"]["onScrollingDown"];
            const newScrollingDown = ()=>{
                
                if(storedScrollingDown) storedScrollingDown();

                this.hideHeadAndTaskBar();
            };
            this.sideMenuItems[key]["contentHolder"]["onScrollingDown"] = newScrollingDown;


            const storedScrollingUp = this.sideMenuItems[key]["contentHolder"]["onScrollingUp"];
            const newScrollingUp = ()=>{
                
                if(storedScrollingUp) storedScrollingUp();

                this.showHeadAndTaskBar();

            };
            this.sideMenuItems[key]["contentHolder"]["onScrollingUp"] = newScrollingUp;
            
        }

    };    

    getBodySection1(){
        this.addEventsFunctionsToMenuItemss();
        this.sideMenu.items = this.sideMenuItems;
        return this.sideMenu.render();
    };

    getBodySection2() {

        const taskBar = this.taskBar.render();
        const sideBar = this.sideBar.render();
        const actionBar = this.actionBar.render();

        return `
                
            <!-- body section 2 -->                                  
            <div ${this.getAttributes(this.bodySection2Attributes, false)} >                                    
                <div class="smetio-app-ui-loaded-contents">                           
                    ${actionBar}                                                            
                    <div class="smetio-app-ui-loaded-contents-content" >                    
                        <div ${this.getAttributes(this.appContentAttributes, false)} > 
                            
                        </div>                    
                    </div>	                                                            
                </div>                
                ${taskBar}    
                ${sideBar}                                                        
            </div>		                        
        `;
    }

    getBody(){

        return  `
        
            <div ${this.getAttributes(this.bodyAttributes, false)} >

                <!-- body section 1 -->	        	            
                
                ${ this.getBodySection1() }
                            
                <!-- body section 2 -->
                
                ${ this.getBodySection2() }
                          
            </div>	    
        `;

    };

    // getSplashScreen(){

    //     const splashScreenLogo = this.splashScreenLogo || this.logo;        
    //     const splashScreenTextAttributes = {};

    //     const splashScreenText = this.setTxt({
    //         attributes:splashScreenTextAttributes,
    //         txt: this.splashScreenText || "txt2",
    //         type:"html" 
    //     });	
    
    //     return `
    //         <div class="smetio-app-ui-splash-screen">
    //             <img src="${splashScreenLogo}">
    //             <h4>${splashScreenText}</h4>
    //         </div>
    //     `;

    // };

    // removeSplashScreen(){
    //     $(".smetio-app-ui-splash-screen").fadeOut().remove();
    // };

    render(container = "body") {

        /**** 
        
            * GET THE MODAL THAT WILL BE SHOWN TO THE USER IN ORDER FOR THEM TO 
                CHANGE THEIR ONLINE STATUS OR LOGOUT
                
            * GET THE MODAL THAT WILL BE SHOWN TO THE USER IN ORDER FOR THEM TO 
                CHANGE THEIR LANGUAGE AND REGION/COUNTRY    

        ****/
        
        const smetio_ui = `            
                              
            <div ${this.getAttributes(this.mainAttributes, false)}  >                
                
                ${this.getHead()}
                
                ${this.getBody()}        	    	    

            </div>        
        `;

        $(container).append(smetio_ui);

        setTimeout(() => {
            // this.onlineStatusHandler.getCurrentOnlineStatus();
            this.notificationHandler.show();

            $("."+this.appContentClass).append(this.contentsForMenuItems);

        }, 200);

        setTimeout(() => {

            /**** TRIGGER A MOUSEDOWN ON THE FIRST ITEM ON THE SIDE MENU ****/
            let firstItemId = Object.values(this.sideMenuItems)[0]["id"];

            console.log("****(*&*&&&**&**&*  this.sideMenuToUse IS ", this.sideMenuToUse);
            // console.log("firstItemId is ", firstItemId );

            firstItemId = this.sideMenuToUse ?? firstItemId;

            console.log("firstItemId is ", firstItemId );

            $("#"+firstItemId).trigger("mousedown");

            this.removeSplashScreen();

        }, 350);        

        this.events();
    };

    events() {

        $("body").on("swiperight", "." + this.bodySection2Class, (event) => {

            if (this.userDevice === "desktop") return false;

            if ($(event.currentTarget).is(".smetio-app-noLeftSideMenuSwipControl *")) return false;
            this.sideMenu.fullyOpen();

            return this.setStatusOpenForBody(this.sideMenu.getStatus());

        });

        $("body").on("swipeleft", "." + this.bodySection2Class, (event) => {

            if (this.userDevice === "desktop") return false;

            if ($(event.currentTarget).is(".smetio-app-noLeftSideMenuSwipControl *")) return false;

            this.sideMenu.moveLeftWard();

            return this.setStatusOpenForBody(this.sideMenu.getStatus());

        });
     
        $("body").on("mousedown", "."+this.profileClass, (event) => {

            // return this.onlineStatusHandler.showOnlineStatusIndicators();

        });  
                                         
    };


};
