export function getFormFactor(){

	if( this.is.desktop() ){

		return "Desktop";

	};

	if( this.is.mobile() ){

		return "Mobile";
		
	};

	if( this.is.tablet() ){

		return "Tablet";
		
	};	

	return "Unknown";		

};