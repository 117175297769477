/* SIMPLY CHECKS IF AN INPUT IS EMPTY --- MORE RELIABLY*/

import { removeAllWhiteSpace } from "../remove/removeAllWhiteSpace";

export function checkIfItIsEmpty(input){

	if(input === null || input === undefined || input === NaN || input === "NaN") return true;

	let output = false;

	if(typeof input === "string") {
		output = removeAllWhiteSpace(input).length < 1 ? true : false;
	};

	if( typeof input === "object" ) {
		output = Object.entries(input).length < 1 ? true : false;
	};

	return output;
	
};
