


import {validateValueLength} from
    "./validateValueLength.js"; 

export function validate_smetioPassword(thePassword){

	//const regex = /^([a-zA-Z0-9])+$/;

	/**** CHECK THAT THE PASSWORD CONTAIN A MINIMUM OF 8 AND A MAXIMUM OF 50 CHARACTERS ****/

	let lengthIsValid = validateValueLength(thePassword, 8, 50);

	if(lengthIsValid != true){

		return lengthIsValid;

	};

	/**** CHECK THAT THE PASSWORD CONTAIN AT LEAST ONE CAPITAL LETTER ****/

	let regex = /[A-Z]/;    

    if( !regex.test(thePassword) ){

        return "txt64";

    };

	/**** CHECK THAT THE PASSWORD CONTAIN AT LEAST ONE SMALL LETTER ****/

	regex = /[a-z]/;    

    if( !regex.test(thePassword) ){

        return "txt65";

    };

	/**** CHECK THAT THE PASSWORD CONTAIN AT LEAST ONE NUMBER ****/

	regex = /[0-9]/;    

    if( !regex.test(thePassword) ){

        return "txt66";

    };

	/**** CHECK THAT THE PASSWORD CONTAIN AT LEAST ONE OF THE FOLLOWING SPECIAL CHARACTERS ****/

	regex = /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/;     

    if( !regex.test(thePassword) ){

        return "txt67";

    };

	return true;   

};