
import { getId } from "../../universal_methods/getId.js";
import { setId } from "../../universal_methods/setId.js";
import { generateId } from "../../universal_methods/generateId.js";
import { isEmpty } from "../../universal_methods/isEmpty.js";
import { getAttributes } from "../../universal_methods/getAttributes.js";
import { toggleDisplay } from "../../universal_methods/toggleDisplay.js";

import { setTxt } from "../../universal_methods/setTxt.js";



//import "./styles/_main.scss";




export class SmetioMenuBar {

  constructor() {


    this.getId = getId;
    this.setId = setId;
    this.generateId = generateId;
    this.isEmpty = isEmpty;
    this.getAttributes = getAttributes;
    this.toggleDisplay = toggleDisplay;
    this.setTxt = setTxt;

    this.setId();

    this.onClick = ()=>{ return false;};
    this.onDblClick  = ()=>{ return false;};


    this.mainClass = "smetio-app-ui-menu-bar-container" + this.id;
    this.mainAttributes = {
      class: [
        "smetio-app-ui-menu-bar-container",        
        this.mainClass
      ],
    };
   
    this.barBtnClass = "smetio-app-ui-menu-bar-"+this.id;
    this.barBtnAttributes = {
      class: [
        "smetio-app-ui-menu-bar",
        this.barBtnClass
      ],
    };


  };

  render() {

    const output = `
 
      <div ${this.getAttributes(this.mainAttributes, false)} > 

        <div ${this.getAttributes(this.barBtnAttributes, false)} > 
          <i class="smetio-icon smetio-icon-bars"></i>        
        </div>

      </div>
    `;

    this.events();

    return output;

  };

  events() {

    $("body").on("mousedown", "." + this.barBtnClass, () => {

      return this.onClick();

    });

    $("body").on("dblclick", "." + this.barBtnClass, () => {
      
      return this.onDblClick();

    });

    

  }


};
