

export function events(){

	$("body").on("mousedown", "."+this.mainClass, (event)=>{

		event.stopPropagation();
		
	    this.showExplanation();

	});	

	

};