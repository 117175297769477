export async function registerClickedLinks(event) {


      const target = $(event.currentTarget);
      this.clickedLinkHref = target.attr("href");
      const targetAttr = target.attr("target");

      /**** NOW VISIT THE CLICKED LINK WEATHER OR NOT THE REQUEST IS COMPLETED ****/

      setTimeout(() => {
            // window.open(this.clickedLinkHref, targetAttr);
            if (window.parent) {
                  window.parent.location.href = this.clickedLinkHref;
            } else {
                  window.location.href = this.clickedLinkHref;
            };

      }, 300);

      // TEMPORARY FIX
      return false;



      await this.getGeolocation();

      this.SmetioAjax.dataToSendToServer = {

            visitedWebsite: {
                  type: "json",
                  value: JSON.stringify(this.visitedWebsite)
            },
            latitude: {
                  type: "lat",
                  value: this.geoLocation.latitude
            },
            longitude: {
                  type: "lo",
                  value: this.geoLocation.longitude
            },
            clickedLinkHref: {
                  type: "str",
                  value: this.clickedLinkHref
            }

      };

      this.SmetioAjax.success = (result) => {

            delete this.SmetioAjax.request.data.clickedLinkHref;

            this.handleUserLoginStatus(result);

      };

      this.SmetioAjax.urlToConnectTo = this.serverClickLinkLocation;

      await this.SmetioAjax.sendRequest();

      /**** NOW VISIT THE CLICKED LINK ****/

      window.open(this.clickedLinkHref, targetAttr);

}