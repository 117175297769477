export function getBrowserBrand(){


	if(this.is.phantom()){

		return "Phantom";

	};

	if(this.is.ie()){

		return "Internet explorer";

	};


	if(this.is.edge()){

		return "Edge";

	};

	if(this.is.firefox()){

		return "Firefox";

	};	

	if(this.is.opera()){

		return "Opera";

	};	

/*
	if(this.is.operaMini()){

		return "Opera mini";

	};
*/
	if(this.is.chrome()){

		return "Chrome";

	};


	if(this.is.safari()){

		return "Safari";

	};		

	return "Unknown";	

};