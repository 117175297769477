export function getBrowserPlatform(){

	if(this.is.androidPhone()){

		return "Android phone";

	};

	if(this.is.androidTablet()){

		return "Android tablet";

	};

	if(this.is.android()){

		return "Android";

	};	

	if(this.is.blackberry()){

		return "Blackberry";

	};

	if(this.is.ios()){

		return "Ios";

	};			

	if(this.is.mac()){

		return "Mac";

	};

	if(this.is.ipad()){

		return "Ipad";

	};	

	if(this.is.iphone()){

		return "Iphone";

	};

	if(this.is.ipod()){

		return "Ipod";

	};

	if(this.is.windowsPhone()){

		return "Windows phone";

	};

	if(this.is.windowsTablet()){

		return "Windows tablet";

	};

	if(this.is.windowsTablet()){

		return "Windows tablet";

	};	

	if(this.is.windows()){

		return "Windows";

	};	

	if(this.is.linux()){

		return "Linux";

	};

	return this.device.platform;

};