export function supportsNotification(){

	// Let's check if the browser supports notifications
	if (!("Notification" in window)) {

		return false;

	};

	return true;

};