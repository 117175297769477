import { SmetioAjax } from "../smetioAjax/SmetioAjax";
import { deleteRequestData } from "./deleteRequestData";
import { universal_handleErrorFromServer } from "./universal_handleErrorFromServer";
//import { handleAjaxError } from "./handleAjaxError";
import { errorCodes } from "../../constants/errorCodes";

export function sendDataToServer(params={
    server: " @OPTIONAL IF this.server IS SET",
    timeOut: " @OPTIONAL",
    onBeforeSend: " @OPTIONAL MUST BE A FUNCTION",
    onSuccess: " @OPTIONAL MUST BE A FUNCTION",
    dataToServer: " @OPTIONAL MUST BE AN OBJECT"

}) {

    if(!this.SmetioAjax) this.SmetioAjax = new SmetioAjax();
    if(!this.deleteRequestData) this.deleteRequestData = deleteRequestData;
    if(!this.handleErrorFromServer)  this.handleErrorFromServer = universal_handleErrorFromServer;
    //if(!this.handleAjaxError) this.handleAjaxError = handleAjaxError;
    if(!this.errorCodes) this.errorCodes = errorCodes;

	this.SmetioAjax.dataType = "json";
	this.SmetioAjax.timeOut = params.timeOut || 100000;
	this.SmetioAjax.urlToConnectTo = params.server || this.server; 

    if( this.isEmpty(this.SmetioAjax.urlToConnectTo) ) throw "Smetio said server cannot be empty";

    /**** ALWAYS ADD THE window.smetioVisitor.visitedWebsite TO ALL POST/AJAX REQUEST ****/

    if( !this.SmetioAjax.dataToSendToServer) this.SmetioAjax.dataToSendToServer = {};

    if( !this.SmetioAjax.dataToSendToServer.visitedWebsite ){
        this.SmetioAjax.dataToSendToServer.visitedWebsite =  {
			type: "json", 
			value:JSON.stringify(window.smetioVisitor.visitedWebsite) 
		};
    };

    if(params.dataToServer) {
       
        for (const key in params.dataToServer) {
            this.SmetioAjax.dataToSendToServer[key] = params.dataToServer[key];
        };
  
    };

	this.SmetioAjax.beforeSend = ()=>{

        if( this.SmetioOverlayPreloader ) this.SmetioOverlayPreloader.show();

        /**** 
         * this.onBeforeSend MUST BE DECLARED OR ASSIGNED EITHER PERMANENTLY OR RIGHT BEFORE THE this.sendDataToServer
         * FUNCTION IS CALLED OR RIGHT BEFORE THE FUNCTION THAT CALLS this.sendDataToServer IS CALLED 
         * FOR EXAMPLE this.examineModelValuesAndSendToServer 
         * ****/        
        if( this.onBeforeSend ) this.onBeforeSend();

        if( params.onBeforeSend ) params.onBeforeSend();
		
	};

	this.SmetioAjax.success = (result)=>{

        if(!result) return false;

		if( this.SmetioOverlayPreloader ) this.SmetioOverlayPreloader.hide();

		this.deleteRequestData();	

		
        if(result.error){

            if(this.onServerError) this.onServerError(result);

            return this.handleErrorFromServer(result);

        };

        /**** 
         * this.onSuccess MUST BE DECLARED OR ASSIGNED EITHER PERMANENTLY OR RIGHT BEFORE THE this.sendDataToServer
         * FUNCTION IS CALLED OR RIGHT BEFORE THE FUNCTION THAT CALLS this.sendDataToServer IS CALLED 
         * FOR EXAMPLE this.examineModelValuesAndSendToServer 
         * ****/

        if(result.success && this.onSuccess) return this.onSuccess(result);

        if(params.onSuccess) return params.onSuccess(result);
       
					
	};

	this.SmetioAjax.error = (request, status, error)=>{

		console.log(request);

		console.log(status);

		console.log(error);		

        if( this.SmetioOverlayPreloader ) this.SmetioOverlayPreloader.hide();

		this.deleteRequestData();

        /**** 
         * this.onAjaxError MUST BE DECLARED OR ASSIGNED EITHER PERMANENTLY OR RIGHT BEFORE THE this.sendDataToServer
         * FUNCTION IS CALLED OR RIGHT BEFORE THE FUNCTION THAT CALLS this.sendDataToServer IS CALLED 
         * FOR EXAMPLE this.examineModelValuesAndSendToServer 
         * ****/
        if(this.onAjaxError) this.onAjaxError(request, status, error);

        if(params.onError) params.onError(request, status, error);

        return this.handleErrorFromServer({
            affectedField: "notification",
            message: "txt101",
            errorCode: this.errorCodes.FAILED_AJAX_REQUEST
    
        });	

		//return this.handleAjaxError();	

	};	

	return this.SmetioAjax.sendRequest();

    

};
