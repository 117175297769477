
import { getId } from "../../universal_methods/getId.js";
import { setId } from "../../universal_methods/setId.js";
import { generateId } from "../../universal_methods/generateId.js";
import { isEmpty } from "../../universal_methods/isEmpty.js";
import { getAttributes } from "../../universal_methods/getAttributes.js";
import { toggleDisplay } from "../../universal_methods/toggleDisplay.js";

import { setTxt } from "../../universal_methods/setTxt.js";




//import "./styles/_main.scss";
import { SmetioSmartbox } from "../../smetioSmartbox/v1.0.0/SmetioSmartbox.js";
import { SmetioComingSoon } from "../../smetioComingSoon/v1.0.0/SmetioComingSoon.js";




export class SmetioAppUiAppsAndUtilitiesHandler {

  constructor() {


    this.getId = getId;
    this.setId = setId;
    this.generateId = generateId;
    this.isEmpty = isEmpty;
    this.getAttributes = getAttributes;
    this.toggleDisplay = toggleDisplay;
    this.setTxt = setTxt;

    this.setId();

    this.title = "txt197";

    this.smartboxIsOpen = false;

    this.smartbox = new SmetioSmartbox();
    this.comingSoon = new SmetioComingSoon();
    
    this.smartbox.onRender = ()=>{
      this.smartboxIsOpen = true;
    };
    this.smartbox.onClose = ()=>{
      this.smartboxIsOpen = false;
    };
    

    
  };

  getAppaAndUtilities(){
    return this.comingSoon.render();
  };

  resetSmartbox(){

    this.smartbox.sidelize();
    this.smartbox.activate();
   
  };

  open(){

    if(this.smartboxIsOpen) return this.resetSmartbox();

    this.smartbox.title = this.title;
    this.smartbox.content = this.getAppaAndUtilities();
    this.smartbox.render();
    this.smartbox.sidelize();

  };
  

  /*
  render() {

    this.events();

    return output;

  };


  events() {

    
    $("body").on("mousedown", "."+this.mainClass, () => {

      return this.open();

    });
    


  }
*/

};
