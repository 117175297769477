import { validate_numbers } from "./validate_numbers";

export function validate_longitude(data) {

    /**** LONGITUDE SHOULD BE A NUMBER ****/

    const output =  validate_numbers(data);

    if( output !== true ) return output;

    /**** LONGITUDE SHOULD BE A NUMBER BETWEEB -180 TO 180 ****/

    if( data < -180 || data > 180 ) return "txt154";

    return true;


};
