export function preventCopyAndPaste(ele){

     const noticeDetails = {
          
          title: "txt185",
          type: "friendly",
          body:  [
               {
                    message:"txt186",
                    errorCode: ""
               }
          ]
          
     };
      

	$("."+ele).bind("cut copy paste",function(e){
        
          e.preventDefault();
          return window.smetioVisitor.showNotification(noticeDetails);	                    
     });
	
	$("."+ele).keydown(function(event) {
        
        if (event.ctrlKey==true && (event.which == "118" || event.which == "86")) {
        	
            event.preventDefault();
            return window.smetioVisitor.showNotification(noticeDetails);	 
            
         }
        
	});

};