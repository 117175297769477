import {getRandomStringsWithoutNumbers} from 
    "./../../functions/random/randomStrings.js";

import {changeFirstLetterToLowerCase} from 
    "./../../functions/change/changeFirstLetterToLowerCase.js"; 

export function generateId(data={}){

	const length = data.length || 6;	
    const id = getRandomStringsWithoutNumbers(length);
    
    return changeFirstLetterToLowerCase(id);

};