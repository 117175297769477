import { validate_latitude } from "./validate_latitude";
import { validate_longitude } from "./validate_longitude";

export function validate_longitudeAndLatitude(data) {
    
    const itIsLatitude = validate_latitude(data.latitude);
    
    if( itIsLatitude !== true ) return itIsLatitude;

    const itIsLongitude = validate_longitude(data.longitude);

    if( itIsLongitude !== true ) return itIsLongitude;

    return true;

};
