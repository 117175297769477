

// import {getId} from "../../universal_methods/getId.js";  
// import {setId} from "../../universal_methods/setId.js";
// import {generateId} from "../../universal_methods/generateId.js";
// import {isEmpty} from "../../universal_methods/isEmpty.js";  
// import {getAttributes} from "../../universal_methods/getAttributes.js";  
// import {toggleDisplay} from "../../universal_methods/toggleDisplay.js"; 



import { SmetioPage } from "../../smetioPage/v1.0.0/SmetioPage";


//import "./styles/_main.scss";


export class SmetioLegalPoliciesPage extends SmetioPage {

      constructor(data = {}) {

            super(data);

            this.modelNotNeeded = true;
            this.displayMessageAfterRender = true;

            this.businessName = data.businessName;
            this.organisationNumber = data.organisationNumber;
            this.policy = data.extraParameters ? data.extraParameters.policy : null;
            this.policies = {
                  cookiePolicy: {
                        txt: "txt1340",
                        func: this.getCookiePolicy
                  },
                  privacyPolicy: {
                        txt: "txt1341",
                        func: this.getPrivacyPolicy
                  },
                  termsOfUse: {
                        txt: "txt1342",
                        func: this.getTermsOfUse
                  },
                  termsOfService: {
                        txt: "txt1343",
                        func: this.getTermsOfService
                  },
                  termsOfPurchase: {
                        txt: "txt1344",
                        func: this.getTermsOfPurchase
                  }

            };


            this.mainClass = "smetio-congratulation-page" + this.id;
            this.mainAttributes = {
                  class: ["smetio-congratulation-page", this.mainClass],
            };

            // setTimeout(() => {

            //   this.displayCongratulationMessage();

            // }, this.defaultTimeout);

      };

      async getCookiePolicy() {
            return "Loading cookie policy";
      };
      async getPrivacyPolicy() {
            return "Loading privacy policy";
      };
      async getTermsOfUse() {
            return "Loading terms of use";
      };
      async getTermsOfService() {
            return "Loading terms of service";
      };
      async getTermsOfPurchase() {
            return "Loading terms of purchase";
      };

      async getLinksToAllPolicies() {

            let output = "";

            for (const key in this.policies) {

                  const policy = this.policies[key];
                  const { txt } = policy;
                  const link = `${this.baseUrl}legalPolicies/${key}/${this.language}`;

                  output += `
        <div class="smetio-legal-policies-page-policy-link">
          <a href="${link}">
            ${this.setTxt({ attributes: {}, txt, type: "html" })} 
          </a>
        </div>
      `;

            };

            return `
      <div class="smetio-legal-policies-page-policy-link-holder">
        ${output}
      </div>
    `;

      };

      async displayMessage() {


            // const getCongratulationHeading = (await import(/* webpackPrefetch: true */ `../../../functions/get/getCongratulationHeading`)).getCongratulationHeading;
            // const getParameterFromUrl = (await import(/* webpackPrefetch: true */ `../../../functions/get/getParameterFromUrl`)).getParameterFromUrl;
            // const isEmpty = (await import(/* webpackPrefetch: true */ `../../universal_methods/isEmpty`)).isEmpty;

            await this.getBaseUrl();

            const loginLink = `${this.baseUrl}/login/${this.language}`;


            const policy = this.policies[this.policy] || {};
            const heading = policy.txt || "txt54";
            const func = policy.func;
            const policyContent = Object.keys(policy).length > 0 ? await func() : await this.getLinksToAllPolicies();

            const content = `
      <div class="smetio-legal-policies-page smetio-margin-top-50 smetio-text-center  "> 
        <h1> ${this.setTxt({ attributes: {}, txt: heading, type: "html" })} - ${this.businessName} </h1>

        <div> ${policyContent} </div>
        
      </div>
    `;

            // console.log("Displaying good bye message...");

            this.changeRightSideUniqueContent(content);

      };

      events() {

            // $("body").on("mousedown", "."+this.uploadFilesBtnClass, ()=>{

            //     $("."+this.uploadFilesInputClass).trigger("click");

            // });	

      };

};
