export function validate_time(data) {

    const timeReg_3 = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/
    const timeReg_2 = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/
    
    const isValid = data.match(timeReg_2) || data.match(timeReg_3);

    if( isValid ) return true;
    
    return "txt183";

};
