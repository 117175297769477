export function validate_date(data) {
    try {
        new Date(data).toISOString();

        return true;

    } catch (e) { 

        return "txt156"; 
    }    
};
