export function isSmetioTxtVariable(data){

	if(!data) return false;

	return data.match(/^txt([0-9]+)$/);

/*
	if (it.match(/^txt([0-9]+)$/)) {

	  alert("yes");

	} else {

	  alert("no");
	}
*/

};