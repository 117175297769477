export async function showNativeNotification(data={}){

      let icon = data.icon || this.logo;
      let duration = data.duration || 10000;
      let isTimed = data.isTimed || true;

      data.body = this.smetioNotification.getBody();
      data.title = this.smetioNotification.getTitle();

      if(this.device.formFactor.toLowerCase() != "desktop"){

            return this.showNotificationWithServiceWorker(data);

      };

      const notification = new Notification(data.title, {
            body: data.body,
            icon
      });

      if(isTimed){

	    // close the notification after 10 seconds
	    setTimeout(() => {
	        notification.close();
	    }, duration);

      };
    
      notification.addEventListener("click", () => {

    	if(data.onclick){

    		return data.onclick();

    	};
        
            //return false;
      });    


      notification.addEventListener("error", (error) => {

    	console.log("There was error ***");
    	console.log(error);
      });

      notification.addEventListener("show", () => {

    	console.log("notice is shown **");

      });        


};