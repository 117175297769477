


// //import "./styles/_main.scss";

// import { removeAllWhiteSpace } from "../../../../functions/remove/removeAllWhiteSpace.js";
import { appFilters } from "../../../../functions/apps/appFilters/appFilters.mjs";
import { SmetioAppsBasics } from "../../../smetioApps/smetioAppsBasics/v1.0.0/SmetioAppsBasics.js";

// import axios from "axios";
// import { SmetioPreloader } from "../../../smetioPreloader/SmetioPreloader.js";
// import { SmetioDataPresentation } from "../../../smetioDataPresentation/v1.0.0/SmetioDataPresentation.js";
// import { hide } from './../../../smetioOverlayPreloader/methods/hide';
// import { SmetioDataGroup } from "../../../smetioDataGroup/v1.0.0/SmetioDataGroup.js";
// import { SmetioPreloader } from "../../../smetioPreloader/SmetioPreloader.js";
// import axios from "axios";
// import personalDetails from "../../../../../../production/img/appImages/SVG/personalDetails.js";
// import personalDetails_test from "../../../../../../production/img/appImages/SVG/personalDetails_test.js";



export class Admin3ack extends SmetioAppsBasics {

    constructor(data = {
        appName: "",
        brandText: "",
        themeName: "",
        logo: "",
        sideMenuItems: "",
        brandName: "",
        language: "",
        apiEndPoint: "",
        accountType: "",
        useBrandNameNotLogo: false,
        appUserInterface: "business"
    }) {

        super(data);

        // this.appUserInterface = data.appUserInterface || "business";
        window.appUserInterface = this.appUserInterface;

        // this.filters = {
        //     businessLocations: {
        //         orderBy: "createdOn DESC",
        //         limit: 20,
        //         itemsPerSection:12
        //     },
        //     businesses: {
        //         limit:20,
        //         itemsPerSection:12
        //     },
        //     paymentMethods: {
        //         limit:20,
        //         itemsPerSection:12  
        //     },
        //     profileDetails: {
        //         limit: 20,
        //         itemsPerSection:12
        //     },
        //     teamMembers: {
        //         limit: 20,
        //         itemsPerSection:12
        //     },
        //     treatments: {
        //         limit: 20,
        //         itemsPerSection:12
        //     },
        //     skills: {
        //         limit: 20,
        //         itemsPerSection:12
        //     },
        //     website: {
        //         limit: 20,
        //         itemsPerSection:12
        //     },
        //     texts: {
        //         limit: 10,
        //         orderBy: "createdOn DESC",
        //         itemsPerSection:20
        //     },
        //     users: {
        //         limit: 10,
        //         orderBy: "createdOn DESC",
        //         itemsPerSection:20
        //     },
        //     blogs: {
        //         limit: 10,
        //         orderBy: "createdOn DESC",
        //         itemsPerSection:20
        //     },            
        // };

        this.filters = appFilters;
        this.itemsPerSection = {};
        this.dataRetrieverEndpoint = {};

        for (const key in this.filters) {
            const { itemsPerSection } = this.filters[key];
            this.dataRetrieverEndpoint[key] = `${window.apiEndPoint}/apps/${this.appUserInterface}/${key}/retrieve/${this.language}`;
            this.itemsPerSection[key] = itemsPerSection;
        };

    };


    setSideMenuItems(sideMenuItems) {
        this.sideMenuItems = sideMenuItems;
    };

    //   showAccountSetup() {

    //     // this.dataRetrieverEndpoint

    //         console.log("Setting up account")

    //         const accountSetupInterface = `

    //             <div style="min-width:100vw; width:100vw; min-height:100vh; height:100vh; position:fixed; z-index:500;">
    //                 <h1>Welcome to 3ack </h1>
    //                 <h4> Let's help you setup your account </h4>

    //                 <h6> Click on the button below to begin </h6>

    //                 <button class="setUpAccoutBtn"> Let's get started </button>
    //             </div>

    //         `;

    //         $("body").append(accountSetupInterface);

    //         $("body").on("mousedown", ".setUpAccoutBtn", () => {


    //         });

    //   };



    // async getSideMenuItems() {

    //   if (this.sideMenuItems) return this.sideMenuItems;

    //   // console.log("Requesting side menu items ********** ");


    //   const axios = (await import(/* webpackPrefetch: true */ `axios`)).default;

    //   const apiEndPoint = window.apiEndPoint;
    //   const language = window.userLanguage;

    //   const url = `${apiEndPoint}/sideMenuItems/${language}`;

    //   const res = await axios.get(url, { params:{sideMenu:"main"} }).then(async (response) => {

    //     let result = response.data;

    //     // console.log("result is ", result);

    //     if (result.error) return window.smetioVisitor.showNotification({ type: "danger", body: [{ message: result.msg }] });

    //     this.sideMenuItems = result.msg;

    //     window.currentSideMenuItems = this.sideMenuItems;
    //     // window.currentlyVisibleSideMenuItemName = "businessLocations";
    //     window.currentSmetioAppUi = this.smetioAppUi;

    //     return this.sideMenuItems;

    //   }).catch((error) => {

    //     console.log(error);
    //     return window.smetioVisitor.showNotification({ type: "danger", body: [{ message: "txt423" }] });

    //   }).finally(() => {
    //     // preloader.hide();
    //   });

    //   return res;

    // };

    events() {


    };


};


