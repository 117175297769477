
import { generateId } from "./generateId.js";
import { SmetioObjectToInput } from "../smetioObjectToInput/v1.0.0/SmetioObjectToInput.js";
import { examineModelValuesAndSendToServer } from "./examineModelValuesAndSendToServer.js";

export async function convertModelToInputs(model){	

	if(!this.SmetioObjectToInput) this.SmetioObjectToInput =  SmetioObjectToInput;
	// if( !this.examineModelValuesAndSendToServer ) this.examineModelValuesAndSendToServer = examineModelValuesAndSendToServer;

	if(!this.model) this.model = model || this.getModel();

	let output = "";

	/**** 
	 * modelId WILL BE USED TO IDENTIFY INPUTS THAT ARE FROM THE SAME MODEL
	 * 
	 * ****/

	if( !this.generateId ) this.generateId = generateId;

	const modelId = this.generateId();

	for( let key in this.model ){

		if(!this.model[key]["details"] || this.model[key]["renderAsInput"] === false ) continue;

		this.model[key]["details"]["modelDetail"] = {
			id: modelId,
			currentKey: key
		};

		this.model[key]["inputClass"] = new this.SmetioObjectToInput(this.model[key]["details"]);
		const input = await this.model[key]["inputClass"].render();

		output += input;

	};

	return output;


};