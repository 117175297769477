export async function registerServiceWorker(){

		// TEMPORARY FIX
		return false


	if(!this.browser.supportsServiceWorker){

		return false;

	};

	await navigator.serviceWorker.register(this.mainServiceWorkerLocation).then( async (result) => {
		
		console.log("Service Worker is registered", result);

		this.mainServiceWorker = result;

		this.mainServiceWorker.addEventListener('notificationclick', function(event) {

		  console.log('On notification click: ', event.notification.body);
		  event.notification.close();
		  
		});

	}).catch( (error) => {

		console.error("Service Worker Error", error);
	
	});




/*

_2/PROJECTS/CODE_OUTSIDE_WEB_ROOT/WEBPACK/production/js/serviceWorkers/main_serviceWorker.js



	this.mainServiceWorker = async () => {
	  if ('serviceWorker' in navigator) {
	    try {
	      const registration = await navigator.serviceWorker.register(
	        '/sw-test/sw.js',
	        {
	          scope: '/sw-test/',
	        }
	      );
	      if (registration.installing) {
	        console.log('Service worker installing');
	      } else if (registration.waiting) {
	        console.log('Service worker installed');
	      } else if (registration.active) {
	        console.log('Service worker active');
	      }
	    } catch (error) {
	      console.error(`Registration failed with ${error}`);
	    }
	  }
	};
*/


};