import {checkIfItIsEmpty} from
    "./../../functions/checks/checkIfItIsEmpty.js";

import {getRandomStringsWithoutNumbers} from 
    "./../../functions/random/randomStrings.js";

import {changeFirstLetterToLowerCase} from 
    "./../../functions/change/changeFirstLetterToLowerCase.js";    
    
import { setUserReadableTextAttributes } from "./setUserReadableTextAttributes.js";

export function getAttributes(attributesObj, useBasicAttributes = true){

    let attributes = "";

    if(useBasicAttributes){

        /**** 
        
            AVOID UNDEFINED ERROR
        
        ****/

        if( checkIfItIsEmpty(this.extra_attributes) ){
        
            this.extra_attributes = "";
        
        }; 

        if( checkIfItIsEmpty(this.extra_class) ){
        
            this.extra_class = "";
        
        };
        
        /**** 
        
            SET THE OTHER ATTRIBUTES
        
        ****/        
        
        if( checkIfItIsEmpty(this.id) ){
        
            this.id = getRandomStringsWithoutNumbers(6);
            this.id = changeFirstLetterToLowerCase(this.id);
            
        };

        attributes += `id="${this.id}"`;

        
        if( !checkIfItIsEmpty(this.placeholder) ){
        
            attributes += `placeholder="${this.placeholder}"`;
        
        };    

        if( !checkIfItIsEmpty(this.value) ){
        
            attributes += `value="${this.value}"`;
        
        };                  

        if( !checkIfItIsEmpty(this.inputName) ){
        
            attributes += `name="${this.inputName}"`;
        
        }; 

        if( !checkIfItIsEmpty(this.type) ){
        
            attributes += `type="${this.type}"`;
        
        };

    };


    /**** UPDATED/MOST RELEVANT CODE STARTS HERE ****/
    
    if( checkIfItIsEmpty(attributesObj) ){
    
        return attributes;
    
    }; 

    if(!this.setUserReadableTextAttributes) this.setUserReadableTextAttributes = setUserReadableTextAttributes;

    attributesObj = this.setUserReadableTextAttributes(attributesObj);

    $.each(attributesObj, (key, value) => { 

        if( checkIfItIsEmpty(value) ) {
            
            attributes += `${key}=""`; 
            return true;

        };

        /**** 
         * TAKE CARE OF CLASS
         * 
         * CHECK IF THE ATTRIBUTE IS CLASS AND THROW ERROR IF THE VALUE/CLASS IS NOT AN ARRAY
         * 
         * ****/

        if( key === "class" && !$.isArray(value) ) throw "Smetio said class must be an array";

        if( $.isArray(value)){

            let collectedValues = "";

            for(let i=0; i < value.length; i++ ){

                collectedValues += value[i]+" ";

            };

            value = collectedValues;

        };   
                
        /**** TAKE CARE OF ATTRIBUTES SUCH AS  
             "required", "checked", "disabled", "autofocus
         * ****/

        const attributesThatShouldNotHaveQuotes = ["required", "checked", "selected", "disabled", "autofocus", "readonly"]; 

        if( $.inArray(key, attributesThatShouldNotHaveQuotes) !== -1 ){

            if(value === true) attributes += " "+key+" ";
            
            return true;

        }; 

        /**** TAKE CARE OF OTHER ATTRIBUTES SUCH AS: id, type alt etc ****/

        attributes += `${key}="${value}"`;

    });

    return attributes;

};





