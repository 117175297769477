export function supportsPushManager(){

	if ("PushManager" in window) {

		return true;

	};

	return false;

};