
import { validate_alphanumeric } from "./validate_alphanumeric";
import { validate_boolan } from "./validate_boolan";
import { validate_color } from "./validate_color";
import { validate_date } from "./validate_date";
import { validate_email } from "./validate_email";
import { validate_hexColor } from "./validate_hexColor";
import { validate_hslColor } from "./validate_hslColor";
import {validate_integer} from "./validate_integer";
import { validate_ipAddress } from "./validate_ipAddress";
import { validate_json } from "./validate_json";
import { validate_latitude } from "./validate_latitude";
import { validate_longitude } from "./validate_longitude";
import { validate_longitudeAndLatitude } from "./validate_longitudeAndLatitude";
import { validate_numbers } from "./validate_numbers";
import { validate_phoneNumber } from "./validate_phoneNumber";
import { validate_rgbColor } from "./validate_rgbColor";
import { validate_smetioPassword } from "./validate_smetioPassword";
import { validate_smetioUsername } from "./validate_smetioUsername";
import { validate_string } from "./validate_string";
import { validate_text } from "./validate_text";
import { validate_time } from "./validate_time";
import { validate_url } from "./validate_url";
import { validate_week } from "./validate_week";



export function isValidated(data={}) {
    
    let isValid = false;
    const value = data.value;
    const type =  data.type;

    switch (type) {

        case 'json':
        case 'JSON':		
                isValid = validate_json(value);
            break;

		case 'smetioPassword': 
		case 'spwd':
		case 'password':
		case 'pwd':		
            isValid = validate_smetioPassword(value);
			break;

        case 'smetioUsername':
            case 'sun':		
                isValid = validate_smetioUsername(value);
            break;

		case 'email':
		case 'em':
				isValid = validate_email(value);
			break;

		case 'number':			
		case 'num':
                isValid = validate_numbers(value);
		    break;
            
		case 'integer':
		case 'int':		
				isValid = validate_integer(value);
			break;

		case 'date':
		case 'dt':
        case 'dateTime':
        case 'dtm':            
				isValid = validate_date(value);
			break;

		case 'boolan':
		case 'bo':
				isValid = validate_boolan(value);
			break;

		case 'week':
		case 'wk':
				isValid = validate_week(value);
			break;

		case 'time':
		case 'tm':
				isValid = validate_time(value);
			break;	

            // ANY COMBINATION OF TEXTS, NUMBERS, SYMBOLS AND SPACE
        case 'string':
        case 'str':
            isValid = validate_string(value);
            break;            

            // ONLY LETTERS/ALPHABETS
        case 'text':
        case 'txt':
                isValid = validate_text(value);
            break;            

        case 'phoneNumber':			
        case 'pn':
                isValid = validate_phoneNumber(value);    
            break;

        case 'url':
        case 'ur':
                isValid = validate_url(value);
            break;            

        case 'ipAddress':
        case 'ip':
                isValid = validate_ipAddress(value);
            break;

            // ONLY NUMBERS AND ALPHABETS -- NO SPACE OR SYMBOLS
        case 'alphaNumeric':
        case 'an':
                isValid = validate_alphanumeric(value);
            break;

        case 'latitude':
        case 'lat':
                isValid = validate_latitude(value);
            break;

        case 'longitude':
        case 'long':
                isValid = validate_longitude(value);
            break;

        case 'longitudeAndLatitude':
        case 'lola':
                isValid = validate_longitudeAndLatitude(value);
            break;	


		case 'hexColor':
		case 'hc':
                isValid = validate_hexColor(value);
			break;		 

		case 'rgbColor':
		case 'rgbc':	
		case 'rgbaColor':
		case 'rgbac':        
                isValid = validate_rgbColor(value);
            break;		

		case 'hslColor':
		case 'hslc':
		case 'hslaColor':
		case 'hslac':	
                isValid = validate_hslColor(value);
            break;	

		case 'color':
		case 'clr':					
				isValid = validate_color(value);
			break;			

		default:
			isValid = "txt169";
			break;

	};

	return isValid;


};
